import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as ClearIcon } from '@/assets/svg-icons/clear-red-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import WarningModal from '@/components/shared/modal/WarningModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import { useCancelAgreementMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { FlexContainer } from '@/styles/shared/container/styles';

type Props = {
    agreementId: string;
};

const CancelAgreement: React.FC<Props> = ({ agreementId }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const navigate = useNavigate();
    const [isWarning, setIsWarning] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { errorMessage, setError, clearError } = useError();

    const [cancelAgreement] = useCancelAgreementMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: () => {
            setIsWarning(false);
            setIsSuccess(true);
            clearError();
        }
    });

    const handleCancelAgreement = () => {
        cancelAgreement({
            variables: {
                agreementId
            }
        });
    };

    const navigateToDashboard = () => {
        navigate(ROUTES.FullPaths.AgreementList);
    };

    return (
        <>
            <FlexContainer $justify="center" $marginBottom={theme?.space[12]}>
                <CustomButton
                    onClick={() => setIsWarning(true)}
                    label={t('CANCEL')}
                    icon={<ClearIcon />}
                    buttonType={ButtonsTypesEnum.DestructiveMain}
                    style={{ border: 'none', boxShadow: 'none' }}
                />
            </FlexContainer>

            {isWarning && (
                <WarningModal
                    isDestructive={true}
                    accept={handleCancelAgreement}
                    deny={() => setIsWarning(false)}
                    title={t('CANCEL_AGREEMENT')}
                    message={t('CANCEL_AGREEMENT_WARNING_MESSAGE')}
                    subMessage={t('CANCEL_AGREEMENT_WARNING_SUBMESSAGE')}
                />
            )}

            {isSuccess && (
                <SuccessModal
                    title={t('CANCEL_AGREEMENT')}
                    message={t('CANCEL_AGREEMENT_SUCCESS_MESSAGE')}
                    modalType={SuccessModalTypeEnum.Success}
                    onConfirm={navigateToDashboard}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} />}
        </>
    );
};

export default CancelAgreement;
