import styled from 'styled-components';

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
`;

export const LogoImage = styled.img`
    &.logo {
        margin-bottom: ${({ theme }) => theme.space[8]};
    }

    &.navbar-logo {
        height: 28px;
        width: 105px;
    }
`;

export const LogoLabel = styled('span')<{ $labelSize: string }>`
    display: flex;
    align-self: flex-end;
    line-height: initial;
    font-style: normal;
    font-weight: 400;
    color: ${({ theme }) => theme.color.grey[30]};
    margin-top: 0;
    font-size: ${({ $labelSize }) => $labelSize};
`;
