import { useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';
import PUBLIC_ROUTES from '@constants/routes/public-routes';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import VersionUpdateModal from '@/components/shared/modal/VersionUpdateModal';
import { AppContextProvider } from '@/store/app-context/AppContext';
import ErrorContextProvider from '@/store/error/error-context';

import AdminLayout from './components/admin/AdminLayout';
import CompanyLogo from './components/shared/logo/CompanyLogo';
import Navbar from './components/shared/menu/Navbar';
import { apolloClient } from './config/apollo-client';
import { theme } from './config/theme';
import AuthGuard from './guards/AuthGuard';
import { StyledAppContent, StyledAppFooter, StyledAppHeader, StyledAppLayout } from './styles';

function App() {
    const location = useLocation();
    const isOnAdminRoute = useMemo(
        () => location.pathname.includes('/admin/'),
        [location.pathname]
    );

    return (
        <ThemeProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
                <AppContextProvider>
                    <ErrorContextProvider>
                        <VersionUpdateModal />
                        <AuthGuard publicRoutes={PUBLIC_ROUTES}>
                            {isOnAdminRoute ? (
                                <AdminLayout>
                                    <Outlet />
                                </AdminLayout>
                            ) : (
                                <StyledAppLayout>
                                    <StyledAppHeader>
                                        <Navbar />
                                    </StyledAppHeader>
                                    <StyledAppContent>
                                        <Outlet />
                                    </StyledAppContent>
                                    <StyledAppFooter>
                                        <CompanyLogo />
                                    </StyledAppFooter>
                                </StyledAppLayout>
                            )}
                        </AuthGuard>
                    </ErrorContextProvider>
                </AppContextProvider>
            </ApolloProvider>
        </ThemeProvider>
    );
}

export default App;
