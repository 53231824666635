import { Navigate, Route, Routes } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import AgreementRawDataReport from '@/pages/admin/CommercialAgreements/AgreementRawDataReport';
import CommercialAgreementsArchiveTable from '@/pages/admin/CommercialAgreements/Archive';
import Brands from '@/pages/admin/CommercialAgreements/Brands';
import Definitions from '@/pages/admin/CommercialAgreements/Definitions';
import Distributors from '@/pages/admin/CommercialAgreements/Distributors';
import PaymentFrequencies from '@/pages/admin/CommercialAgreements/PaymentFrequencies';
import Products from '@/pages/admin/CommercialAgreements/Products';
import Regions from '@/pages/admin/CommercialAgreements/Regions';
import SpendLimit from '@/pages/admin/CommercialAgreements/SpendLimit';
import TargetSkus from '@/pages/admin/CommercialAgreements/TargetSkus';
import ThirdParties from '@/pages/admin/CommercialAgreements/ThirdParties';
import Zones from '@/pages/admin/CommercialAgreements/Zones';

import ProtectedAdminRoute from '../ProtectedAdminRoute';

import AssetsArchiveTable from './Assets';
import Materials from './Materials';
import RawDataReport from './Reports/RawDataReport';
import Users from './Users';

const AdminRoutes: React.FC = () => (
    <Routes>
        <Route path={ROUTES.Admin.Path} element={<ProtectedAdminRoute />}>
            <Route index path={ROUTES.Admin.Users.Path} element={<Users />} />
            <Route path={ROUTES.Admin.Assets.Path} element={<AssetsArchiveTable />} />
            <Route path={ROUTES.Admin.Materials.Path} element={<Materials />} />
            <Route path={ROUTES.Admin.Reports.Path}>
                <Route index element={<Navigate to={ROUTES.Admin.Reports.RawDataReport} />} />
                <Route path={ROUTES.Admin.Reports.RawDataReport} element={<RawDataReport />} />
            </Route>
            <Route path={ROUTES.Admin.Attributes.Path}>
                <Route path={ROUTES.Admin.Attributes.Brands} element={<Brands />} />
                <Route path={ROUTES.Admin.Attributes.Products} element={<Products />} />
                <Route path={ROUTES.Admin.Attributes.TargetSkus} element={<TargetSkus />} />
                <Route path={ROUTES.Admin.Attributes.ThirdParties} element={<ThirdParties />} />
                <Route
                    path={ROUTES.Admin.Attributes.Archive}
                    element={<CommercialAgreementsArchiveTable />}
                />
                <Route
                    path={ROUTES.Admin.Attributes.PaymentFrequencies}
                    element={<PaymentFrequencies />}
                />
                <Route path={ROUTES.Admin.Attributes.Zones} element={<Zones />} />
                <Route path={ROUTES.Admin.Attributes.Regions} element={<Regions />} />
                <Route path={ROUTES.Admin.Attributes.Distributors} element={<Distributors />} />
                <Route
                    path={ROUTES.Admin.Attributes.AgreementRawDataReport}
                    element={<AgreementRawDataReport />}
                />
                <Route path={ROUTES.Admin.Attributes.SpendLimit} element={<SpendLimit />} />
                <Route path={ROUTES.Admin.Attributes.Definitions} element={<Definitions />} />
            </Route>
        </Route>
    </Routes>
);

export default AdminRoutes;
