import { TableDataProvider } from '@/hooks/admin/useTableData';
import PaymentFrequenciesTable from '@/pages/admin/CommercialAgreements/PaymentFrequencies/partials/PaymentFrequenciesTable';

const PaymentFrequencies = () => (
    <TableDataProvider>
        <PaymentFrequenciesTable />
    </TableDataProvider>
);

export default PaymentFrequencies;
