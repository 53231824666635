import { Route, Routes } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import AssetDeclarationPreview from '@/pages/asset/pages/declaration/AssetDeclarationPreview';
import AssetDeclarationScan from '@/pages/asset/pages/declaration/AssetDeclarationScan';

import NotFound from '../misc/NotFound';
import ProtectedSRRoute from '../ProtectedSRRoute';
import DeclarationPreview from '../shared/declaration/DeclarationPreview';
import DeclarationScan from '../shared/declaration/DeclarationScan';
import SigningArea from '../shared/sign/SignArea';

import AssetList from './pages/asset-list';
import Contract from './pages/contract';
import ContractPreview from './pages/contract-preview';
import CreateContract from './pages/create-contract';
import Protocol from './pages/protocol';
import ProtocolPreview from './pages/protocol-preview';
import SignContract from './pages/sign-contract';
import SignProtocol from './pages/sign-protocol';

const AssetRoutes: React.FC = () => (
    <Routes>
        <Route path={ROUTES.AssetList.Path} element={<ProtectedSRRoute />}>
            <Route index element={<AssetList />} />
            <Route path={ROUTES.AssetList.Protocol.Path}>
                <Route index element={<Protocol />} />
                <Route
                    path={ROUTES.AssetList.Protocol.Declaration.Preview}
                    element={<AssetDeclarationPreview />}
                />
                <Route
                    path={ROUTES.AssetList.Protocol.Declaration.Scan}
                    element={<AssetDeclarationScan />}
                />
                <Route path={ROUTES.AssetList.Protocol.ProtocolPreview.Path}>
                    <Route index element={<ProtocolPreview />} />
                    <Route path={ROUTES.AssetList.Protocol.ProtocolPreview.Sign.Path}>
                        <Route index element={<SignProtocol />} />
                        <Route
                            path={ROUTES.AssetList.Protocol.ProtocolPreview.Sign.SigningArea}
                            element={<SigningArea />}
                        />
                    </Route>
                </Route>
                <Route path={ROUTES.None} element={<NotFound />} />
            </Route>
            <Route path={ROUTES.AssetList.Contract.Path}>
                <Route index element={<Contract />} />
                <Route
                    path={ROUTES.AssetList.Contract.Declaration.Preview}
                    element={<DeclarationPreview />}
                />
                <Route path={ROUTES.AssetList.Contract.ContractPreview.Path}>
                    <Route index element={<ContractPreview />} />
                    <Route path={ROUTES.AssetList.Protocol.ProtocolPreview.Sign.Path}>
                        <Route index element={<SignContract />} />
                        <Route
                            path={ROUTES.AssetList.Protocol.ProtocolPreview.Sign.SigningArea}
                            element={<SigningArea />}
                        />
                    </Route>
                </Route>
                <Route path={ROUTES.None} element={<NotFound />} />
                <Route
                    path={ROUTES.AssetList.Contract.Declaration.Scan}
                    element={<DeclarationScan />}
                />
            </Route>

            <Route path={ROUTES.AssetList.CreateContract.Path}>
                <Route index element={<CreateContract />} />
                <Route
                    path={ROUTES.AssetList.CreateContract.Declaration.Preview}
                    element={<AssetDeclarationPreview />}
                />
                <Route
                    path={ROUTES.AssetList.CreateContract.Declaration.Scan}
                    element={<AssetDeclarationScan />}
                />
                <Route path={ROUTES.None} element={<NotFound />} />
            </Route>
        </Route>
    </Routes>
);

export default AssetRoutes;
