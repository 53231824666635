import { Fragment, useContext, useEffect, useState } from 'react';

import { SIGNATURE_ROLES } from '@constants/keys/signature-roles-keys';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { GENERATE_PROTOCOL_FORM_KEYS } from '@/constants/keys/form-data-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    AgreementDetailsDto,
    AgreementTypeEnum,
    useCompleteAgreementMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import SignSection from '@/pages/shared/sign/SignSection';
import AppContext from '@/store/app-context/AppContext';
import {
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';
import { getCurrentUserEmail } from '@/utils/authorizationUtils';

type Props = {
    isAdditionalDocuments?: boolean;
    isGenerateProtocol?: boolean;
};

const SignAgreement: React.FC<Props> = ({
    isAdditionalDocuments = false,
    isGenerateProtocol = false
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation('translation');
    const { setFormData, clearAllFormData } = useFormData();
    const { setError, clearError, errorMessage } = useError();
    const { navbar, sign, agreementDetails: agreementDetailsState } = useContext(AppContext);
    const [completeAgreement, { loading }] = useCompleteAgreementMutation();
    const [isSuccessModalShown, setIsSuccessModalOpened] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);

    const agreement =
        agreementDetailsState.currentAgreementDetails ??
        getCurrentContractData<AgreementDetailsDto>();
    const { agreementId, customerEmail, pocEmployee, srName, type } = agreement;

    const POCSign = sign.signatures?.find(
        (signature) => signature.signer === SIGNATURE_ROLES.POC
    )?.signature;
    const SRSign = sign.signatures?.find(
        (signature) => signature.signer === SIGNATURE_ROLES.SALES_REPRESENTATIVE
    )?.signature;
    const POCProtocolSign = sign.signatures?.find(
        (signature) => signature.signer === SIGNATURE_ROLES.POC_PROTOCOL
    )?.signature;
    const SRProtocolSign = sign.signatures?.find(
        (signature) => signature.signer === SIGNATURE_ROLES.SALES_REPRESENTATIVE_PROTOCOL
    )?.signature;
    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallback: sign.clearSigns
        });

        isTypePtcOrStock
            ? setIsValid(!!POCSign && !!SRSign && !!SRProtocolSign && !!POCProtocolSign)
            : setIsValid(!!POCSign && !!SRSign);

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleCompleteTransmissionProtocol = () => {
        if (isTypePtcOrStock) {
            if (SRSign && POCSign && POCProtocolSign && SRProtocolSign && isValid) {
                setIsSuccessModalOpened(true);
            }
        } else {
            if (SRSign && POCSign && isValid) {
                setIsSuccessModalOpened(true);
            }
        }
    };

    const isTypePtcOrStock = type === AgreementTypeEnum.FwStock || type === AgreementTypeEnum.Ptc;
    const handleCompleteAgreement = () => {
        if (!isValid) {
            return;
        }

        isTypePtcOrStock
            ? completeAgreement({
                  variables: {
                      input: {
                          agreementId,
                          customerEmail,
                          pocPersonnelName: pocEmployee!.trim(),
                          srSignature: SRSign!,
                          pocPersonnelSignature: POCSign!,
                          pocEmployeeSignatureProtocol: POCProtocolSign!,
                          srSignatureProtocol: SRProtocolSign!
                      }
                  }
              })
                  .then(() => {
                      setIsSuccessModalOpened(true);
                  })
                  .catch((error) => {
                      setError(error, ErrorTypeEnum.ApolloError);
                  })
            : completeAgreement({
                  variables: {
                      input: {
                          agreementId,
                          customerEmail,
                          pocPersonnelName: pocEmployee!.trim(),
                          srSignature: SRSign!,
                          pocPersonnelSignature: POCSign!
                      }
                  }
              })
                  .then(() => {
                      setIsSuccessModalOpened(true);
                  })
                  .catch((error) => {
                      setError(error, ErrorTypeEnum.ApolloError);
                  });
    };
    const handleConfirmSuccess = () => {
        sign.clearSigns();
        !isAdditionalDocuments && clearAllFormData();
        setIsSuccessModalOpened(false);
        isAdditionalDocuments && !isGenerateProtocol
            ? navigate(-2)
            : isAdditionalDocuments && isGenerateProtocol
            ? navigate(-4)
            : navigate(ROUTES.FullPaths.AgreementList);
    };

    useEffect(() => {
        if (SRSign) {
            setFormData(GENERATE_PROTOCOL_FORM_KEYS.SR_SIGNATURE, SRSign);
        }
    }, [SRSign]); // eslint-disable-line

    useEffect(() => {
        if (POCSign) {
            setFormData(GENERATE_PROTOCOL_FORM_KEYS.POC_EMPLOYEE_SIGNATURE, POCSign);
        }
    }, [POCSign]); // eslint-disable-line

    return (
        <Fragment>
            <ScrollableContainer>
                <HeaderContainer $padding={theme?.space[0]}>
                    <StyledPageTitle $marginY={theme?.space[0]}>
                        {isAdditionalDocuments ? t('SIGN_PROTOCOL') : t('SIGN_AGREEMENT')}
                    </StyledPageTitle>
                    <CustomButton
                        label={t('FINISH_AND_SEND')}
                        buttonType={isValid ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                        icon={<TickIcon />}
                        onClick={
                            isAdditionalDocuments
                                ? handleCompleteTransmissionProtocol
                                : handleCompleteAgreement
                        }
                        loading={loading}
                    />
                </HeaderContainer>

                <Content>
                    <FlexContainer $justify="center" $marginBottom={theme?.space[32]}>
                        {!POCSign || !SRSign ? <InfoMessage message={t('SIGN_SUBTITLE')} /> : ''}
                    </FlexContainer>

                    <SignSection
                        signatureIndex={1}
                        sectionTitle={t('SALES_REPRESENTATIVE')}
                        signer={SIGNATURE_ROLES.SALES_REPRESENTATIVE}
                        name={srName}
                        email={getCurrentUserEmail()}
                        sign={SRSign}
                    />
                    <SignSection
                        signatureIndex={1}
                        sectionTitle={t('POC_EMPLOYEE')}
                        signer={SIGNATURE_ROLES.POC}
                        name={pocEmployee!}
                        email={customerEmail!}
                        sign={POCSign}
                    />
                    {isTypePtcOrStock && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledPageTitle $marginY={theme?.space[0]}>
                                {t('SIGN_PROTOCOL')}
                            </StyledPageTitle>
                            <SignSection
                                signatureIndex={1}
                                sectionTitle={t('SALES_REPRESENTATIVE')}
                                signer={SIGNATURE_ROLES.SALES_REPRESENTATIVE_PROTOCOL}
                                name={srName}
                                email={getCurrentUserEmail()}
                                sign={SRProtocolSign}
                            />
                            <SignSection
                                signatureIndex={1}
                                sectionTitle={t('POC_EMPLOYEE')}
                                signer={SIGNATURE_ROLES.POC_PROTOCOL}
                                name={pocEmployee!}
                                email={customerEmail!}
                                sign={POCProtocolSign}
                            />
                        </div>
                    )}
                </Content>
            </ScrollableContainer>

            {isSuccessModalShown && (
                <SuccessModal
                    title={t('SUCCESSFULLY_SIGNED')}
                    message={!isAdditionalDocuments ? t('AGREEMENT_FINISHED_AND_SENT') : ''}
                    onConfirm={handleConfirmSuccess}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Fragment>
    );
};

export default SignAgreement;
