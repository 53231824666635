import { ApolloQueryResult } from '@apollo/client';
import ROUTES from '@constants/routes/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import { ReactComponent as ViewDetailsIcon } from '@/assets/svg-icons/view-details-icon.svg';
import CustomButton from '@/components/shared/UI/CustomButton';
import IconContainer from '@/components/shared/UI/IconContainer';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import {
    AgreementDetailsDto,
    DocumentStatusEnum,
    DocumentTypeEnum,
    GetAgreementDetailsQuery,
    GetAgreementDetailsQueryVariables
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useStatus from '@/hooks/status/useStatus';
import {
    AgreementCardContainer,
    StyledAgreementCardStatus,
    StyledAgreementCardStatusLine
} from '@/pages/agreement/Agreements/partials/AgreementCard/styles';
import { previewPdf } from '@/pages/asset/shared/utils/preview-pdf';
import { HeaderContainer } from '@/styles/shared/container/styles';
import {
    ListItemCardDate,
    ListItemCardFooter,
    ListItemCardFooterWrapper
} from '@/styles/shared/list-item/styles';
import { formatDate } from '@/utils/formatDate';

import AgreementDetailsInfo from './partials/AgreementDetailsInfo';

type Props = {
    agreementDetails: AgreementDetailsDto;
    refetchAgreementDetails: (
        variables?: GetAgreementDetailsQueryVariables | undefined
    ) => Promise<ApolloQueryResult<GetAgreementDetailsQuery>>;
};

const AgreementDetailsCard: React.FC<Props> = ({ agreementDetails, refetchAgreementDetails }) => {
    const { setError } = useError();
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const { getStatusLabel, getStatusStyle } = useStatus();
    const { agreementId } = agreementDetails;
    const navigate = useNavigate();

    return (
        <>
            <HeaderContainer $padding={theme?.space[0]}>
                <StyledPageTitle $marginY={theme?.space[0]}>{t('CA_DETAILS')}</StyledPageTitle>
                {agreementDetails.status !== DocumentStatusEnum.ForValidation &&
                    agreementDetails.status !== DocumentStatusEnum.Canceled && (
                        <CustomButton
                            onClick={() => {
                                agreementDetails.status === DocumentStatusEnum.Draft ||
                                agreementDetails.status === DocumentStatusEnum.Prepared
                                    ? navigate(ROUTES.AgreementList.AgreementPreview.Path)
                                    : previewPdf(
                                          agreementId,
                                          DocumentTypeEnum.CommercialAgreement,
                                          setError
                                      );
                            }}
                            label={t('PREVIEW_PDF')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<ViewDetailsIcon />}
                        />
                    )}
            </HeaderContainer>

            <AgreementCardContainer>
                {agreementDetails && (
                    <AgreementDetailsInfo
                        agreementDetails={agreementDetails}
                        refetchAgreementDetails={refetchAgreementDetails}
                    />
                )}

                <ListItemCardFooter>
                    <ListItemCardFooterWrapper>
                        <StyledAgreementCardStatus
                            $style={getStatusStyle(agreementDetails?.status)}>
                            {getStatusLabel(agreementDetails?.status)}
                        </StyledAgreementCardStatus>

                        <ListItemCardDate>
                            <IconContainer>
                                <CalendarIcon />
                            </IconContainer>
                            <span>{formatDate(agreementDetails?.agreementDate)}</span>
                        </ListItemCardDate>
                    </ListItemCardFooterWrapper>
                </ListItemCardFooter>

                <StyledAgreementCardStatusLine $style={getStatusStyle(agreementDetails?.status)} />
            </AgreementCardContainer>
        </>
    );
};

export default AgreementDetailsCard;
