import styled from 'styled-components';

export const StyledCard = styled.div`
    position: relative;
    border-radius: 4px;
    width: 100%;
    box-shadow: ${({ theme }) => theme.shadow.lightHover};
    background-color: ${({ theme }) => theme.color.default.white}};
    padding: ${({ theme: { space } }) => `${space[24]} ${space[16]}`};
`;
