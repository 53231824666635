const baseUnit = 4;

// example usage
// padding: ${({ theme }) => theme.space[8]};
export const space = {
    0: `${0 * baseUnit}px`, // 0px
    1: `${0.25 * baseUnit}px`, // 1px
    2: `${0.5 * baseUnit}px`, // 2px
    4: `${1 * baseUnit}px`, // 4px
    8: `${2 * baseUnit}px`, // 8px
    12: `${3 * baseUnit}px`, // 12px
    16: `${4 * baseUnit}px`, // 16px
    20: `${5 * baseUnit}px`, // 20px
    24: `${6 * baseUnit}px`, // 24px
    28: `${7 * baseUnit}px`, // 28px
    32: `${8 * baseUnit}px`, // 32px
    36: `${9 * baseUnit}px`, // 36px
    40: `${10 * baseUnit}px`, // 40px
    44: `${11 * baseUnit}px`, // 44px
    48: `${12 * baseUnit}px`, // 48px
    52: `${13 * baseUnit}px`, // 52px
    56: `${14 * baseUnit}px`, // 56px
    60: `${15 * baseUnit}px`, // 60px
    64: `${16 * baseUnit}px`, // 64px
    68: `${17 * baseUnit}px`, // 68px
    72: `${18 * baseUnit}px`, // 72px
    76: `${19 * baseUnit}px`, // 76px
    80: `${20 * baseUnit}px`, // 80px
    84: `${21 * baseUnit}px`, // 84px
    88: `${22 * baseUnit}px`, // 88px
    92: `${23 * baseUnit}px`, // 92px
    96: `${24 * baseUnit}px`, // 96px
    100: `${25 * baseUnit}px`, // 100px
    144: `${36 * baseUnit}px`, // 100px
    200: ` ${50 * baseUnit}px`, // 100px
    252: ` ${63 * baseUnit}px`, // 100px
    300: `${75 * baseUnit}px`, // 328px
    312: `${78 * baseUnit}px`, // 328px
    328: `${82 * baseUnit}px`, // 328px
    maxLayoutWidth: '450px'
};
