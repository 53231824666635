const PREVIEW_STYLE = `
        #protocol {
            font-size: 4pt;
            width: 100%;
            box-sizing: border-box;
            padding: 0pt;
            margin: auto;
            padding: 8px;
            background-color: white;
        }
        #title {
            font-size: 8pt;
            width: 75%;
        }
        #logo {
            left: 1rem;
            width: 3rem;
            height: 1.6rem;
            margin-top: 10px;
        }
        #signs-container {
            display: flex;
        }
        #employee-sign,
        #poc-personnel-sign {
            display: none;
        }
        
`;

export default PREVIEW_STYLE;
