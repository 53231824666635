import styled from 'styled-components';

export const CommentModalBtnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.space[12]};

    button {
        width: ${({ theme }) => theme.space[100]};
    }
`;
