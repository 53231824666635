import styled from 'styled-components';

type LabelProps = {
    $direction: string | undefined;
    $hasMargin?: boolean | undefined;
};

type ValueProps = {
    $bolder: boolean | undefined;
};

export const StyledLabelContainer = styled.div<LabelProps>`
    display: flex;
    color: ${({ theme }) => theme.color.grey[40]};
    font-size: ${({ theme }) => theme.fontSize.xxlg};
    flex-direction: ${({ $direction }) => $direction};
`;

export const StyledLabel = styled.span<LabelProps>`
    font-weight: 400;
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSize.xlg};
    margin-right: ${({ $direction, $hasMargin }) =>
        $direction === 'row' && $hasMargin ? '12px' : '0px'};
`;

export const StyledValue = styled.span<ValueProps>`
    font-weight: ${({ $bolder }) => ($bolder ? 600 : 400)};
    font-size: ${({ $bolder, theme }) => ($bolder ? theme.fontSize.h6 : theme.fontSize.xxlg)};
    color: ${({ theme }) => theme.color.default.black};
    overflow-x: hidden;
`;
