import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

import CustomCard from '@/components/shared/UI/CustomCard';

export const SignAreaContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const SignAreaContent = styled(Content)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[20]};
    background-color: ${({ theme }) => theme.color.default.bg};
    padding: 0 ${({ theme }) => theme.space[16]};
`;

export const SignAreaSignerName = styled.span`
    font-size: ${({ theme }) => theme.fontSize.h6};
`;

export const SignAreaCard = styled(CustomCard)`
    padding: 0 !important;
`;

export const StyledCanvas = styled.canvas`
    z-index: 1000;
    position: inherit;
`;

export const SignAreaDottedLine = styled.span`
    display: block;
    position: absolute;
    top: 80%;
    left: 10%;
    width: 80%;
    border-bottom: 2px dashed ${({ theme }) => theme.color.grey[90]};
`;
