import styled from 'styled-components';

export const StyledNotScannedErrorLabel = styled.span`
    display: block;
    padding: 0 ${({ theme }) => theme.space[16]};
    font-size: ${({ theme }) => theme.fontSize.xxlg};
    line-height: ${({ theme }) => theme.lineHeight[24]};
    color: ${({ theme }) => theme.color.red[40]};
    text-align: end;
`;
