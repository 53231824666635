import { ApolloQueryResult } from '@apollo/client';
import { Button, message, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    GetUsersQuery,
    useChangeUserIsActiveMutation,
    UserDto,
    UserRoleEnum
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { useUserContext } from '@/store/user/useUserContext';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetUsersQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();
    const { isSuperAdmin } = useUserContext();
    const [changeUserIsActive] = useChangeUserIsActiveMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await onChangeSuccess();
        }
    });

    const handleToggleUserStatus = async ({ id, isActive }: { id: string; isActive: boolean }) => {
        try {
            await changeUserIsActive({
                variables: {
                    id,
                    isActive: !isActive
                }
            });
            message.success(t(isActive ? 'DEACTIVATED_SUCCESS' : 'ACTIVATED_SUCCESS'));
        } catch (error) {
            setError(error, ErrorTypeEnum.ApolloError);
            await message.error(t(isActive ? 'DEACTIVATE_FAIL' : 'ACTIVATED_FAIL'));
        }
    };

    const colDef: ColumnsType<UserDto> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            width: 200,
            sorter: true
        },
        {
            key: 'email',
            title: t('EMAIL'),
            dataIndex: 'email',
            width: 200
        },
        {
            key: 'role',
            title: t('ROLE'),
            dataIndex: 'role',
            width: 200,
            sorter: true,
            render: (_, { role }: UserDto) => {
                let color = 'green';

                if (role === UserRoleEnum.Admin) {
                    color = 'geekblue';
                }

                if (role === UserRoleEnum.SuperAdmin) {
                    color = 'gold';
                }

                return (
                    <Tag color={color} key={role} style={{ textTransform: 'uppercase' }}>
                        {t(role)}
                    </Tag>
                );
            }
        },
        {
            key: 'active',
            title: t('ACTIVE'),
            dataIndex: 'active',
            width: 100,
            sorter: true,
            render: (_, { id, isActive }: UserDto) =>
                isSuperAdmin ? (
                    <Button
                        key={`deactivate-${id as string}`}
                        danger={isActive}
                        onClick={async () => await handleToggleUserStatus({ id, isActive })}>
                        {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                    </Button>
                ) : (
                    <Tag color={isActive ? 'green' : 'red'} style={{ textTransform: 'uppercase' }}>
                        {t(isActive ? 'ACTIVE' : 'DEACTIVATED')}
                    </Tag>
                )
        }
    ];

    return { colDef };
};

export default useColDefs;
