import { useState } from 'react';

import TABLE_CONSTANTS from '@/constants/admin/tables';
import { SortEnumType } from '@/graphql/main';

const { InitialPageSize } = TABLE_CONSTANTS;

const usePaginate = () => {
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(InitialPageSize);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortingOrder, setSortingOrder] = useState<Record<string, SortEnumType>>();
    const handlePageChange = (page: number, pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPage(page - 1);
    };

    const itemsToSkip = currentPage * pageSize;

    return {
        sortingOrder,
        setSortingOrder,
        handlePageChange,
        totalItems,
        pageSize,
        setTotalItems,
        itemsToSkip
    };
};

export default usePaginate;
