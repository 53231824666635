import { useContext, useEffect, useState } from 'react';

import envconfig from '@/config/envconfig';
import AppContext from '@/store/app-context/AppContext';

type DispatchRequest = {
    email: string;
    password: string;
    rememberMe: boolean;
};

const useLogin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { navbar } = useContext(AppContext);

    const dispatchLogin = async (request: DispatchRequest) => {
        setIsLoading(true);

        return await fetch(envconfig.authenticationUrl, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            rightSide: undefined,
            leftSide: undefined
        });
    }, []); // eslint-disable-line

    return {
        loading: isLoading,
        dispatchLogin
    };
};

export default useLogin;
