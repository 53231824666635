import { Fragment, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import { ReactComponent as EmailIcon } from '@/assets/svg-icons/email-icon.svg';
import { ReactComponent as ViewDeclarationIcon } from '@/assets/svg-icons/preview-declaration-icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/svg-icons/profile-icon.svg';
import { ReactComponent as ScanIcon } from '@/assets/svg-icons/scan-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import ContractIcon from '@/components/shared/asset-icons/ContractIcon';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomInput from '@/components/shared/UI/CustomInput';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import ERROR_CODES from '@/constants/errors/error-codes';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    ContractDto,
    DocumentStatusEnum,
    GetContractByIdQuery,
    useGetContractByIdQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useStatus from '@/hooks/status/useStatus';
import { setCacheVal } from '@/services/utils/cacheUtils';
import AppContext from '@/store/app-context/AppContext';
import {
    Container,
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { StyledNotScannedErrorLabel } from '@/styles/shared/label/styles';
import {
    ListItemCardCustomerName,
    ListItemCardDate,
    ListItemCardFooter,
    ListItemCardFooterWrapper,
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardRowHeader,
    ListItemCardValue,
    ListItemRightCol
} from '@/styles/shared/list-item/styles';
import { saveCurrentContractData } from '@/utils/assetStorage';
import { formatDate } from '@/utils/formatDate';

import MaterialsTable from '../../shared/components/MaterialsTable';
import { StyledAssetCard, StyledAssetContent } from '../../shared/styles/styles';
import { AssetCardStatusLabel, AssetCardStatusLine } from '../asset-list/partials/AssetCard/styles';

const Contract: React.FC = () => {
    const { t } = useTranslation('translation');
    const { setError, clearError, errorMessage } = useError();
    const { navbar, contract: ctxContract } = useContext(AppContext);
    const navigate = useNavigate();
    const { assetId } = useParams();
    const { getStatusLabel, getStatusStyle } = useStatus();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [declarationGuidState, setDeclarationGuidState] = useState<string | null | undefined>(
        ctxContract.currentContract?.declarationGuid
    );
    const params = useParams();
    const theme = useTheme();

    const { loading: loadingContract, refetch } = useGetContractByIdQuery({
        variables: {
            input: +assetId!
        },
        onCompleted: (data: GetContractByIdQuery) => {
            ctxContract.setContract(data.contract as ContractDto);
            clearError();
            checkValidity();
        },
        onError: (error: unknown) => setError(error, ErrorTypeEnum.ApolloError),
        skip: !!ctxContract.currentContract && ctxContract.currentContract.id.toString() === assetId
    });

    useEffect(() => {
        // Here we check if the declaration has been scanned and if yes
        if (declarationGuidState === null || declarationGuidState === '') {
            refetch();
            setDeclarationGuidState(ctxContract.currentContract?.declarationGuid);
        }
        checkValidity();
    }, [ctxContract.currentContract?.declarationGuid]); // eslint-disable-line

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        checkValidity();
    }, []); // eslint-disable-line

    useEffect(() => {
        checkValidity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ctxContract.currentContract?.customerEmail,
        ctxContract.currentContract?.customerPersonnelRole,
        ctxContract.currentContract?.customerPersonnelName,
        ctxContract.currentContract?.declarationGuid,
        ctxContract.currentContract?.models
    ]);

    const handlePreview = () => {
        if (ctxContract.currentContract) {
            saveCurrentContractData<ContractDto>(ctxContract.currentContract);
        }
        navigate(ROUTES.AssetList.Contract.ContractPreview.Path);
    };

    const getCurrentContract = () => {
        return { ...ctxContract.currentContract } || {};
    };

    const handleModelChange = (id: number, quantity: number) => {
        const newContract = getCurrentContract();

        if (newContract?.models) {
            const modelIndex = newContract.models.findIndex((x) => x.id === id);

            if (modelIndex !== -1) {
                const updatedModel = {
                    ...newContract.models[modelIndex],
                    quantity
                };
                const updatedModels = [...newContract.models];
                updatedModels[modelIndex] = updatedModel;
                newContract.models = updatedModels;
                ctxContract.setContract(newContract as ContractDto);
            }
        }
    };

    const onCustomerMailChange = (mail: string) => {
        const newContract = getCurrentContract();
        newContract.customerEmail = mail;
        ctxContract.setContract(newContract as ContractDto);
    };

    const onCustomerPersonnelNameChange = (name: string) => {
        const newContract = getCurrentContract();
        newContract.customerPersonnelName = name;
        ctxContract.setContract(newContract as ContractDto);
    };

    const onCustomerPersonnelRoleChange = (role: string) => {
        const newContract = getCurrentContract();
        newContract.customerPersonnelRole = role;
        ctxContract.setContract(newContract as ContractDto);
    };

    const handleViewDeclaration = () => {
        navigate(ROUTES.AssetList.Contract.Declaration.Preview, {
            state: ctxContract.currentContract?.declarationGuid
        });
    };

    const handleScanDeclaration = () => {
        navigate(ROUTES.AssetList.Contract.Declaration.Scan, {
            state: {
                route: ROUTES.FullPaths.Contract(+params.assetId!),
                assetType: ctxContract.currentContract?.assetType
            }
        });
    };
    const checkValidity = () => {
        setIsValid(
            !!ctxContract.currentContract?.customerEmail &&
                ctxContract.currentContract.status === DocumentStatusEnum.Prepared &&
                !!ctxContract.currentContract.customerPersonnelName &&
                !!ctxContract.currentContract.customerPersonnelRole &&
                !!ctxContract.currentContract.declarationGuid &&
                !ctxContract.currentContract.models.some((m) => m.quantity < 1)
        );
    };

    useEffect(() => {
        setCacheVal(LOCAL_STORAGE_KEYS.CustomerName, ctxContract.currentContract?.customerName);
    }, [ctxContract.currentContract]);

    return (
        <Fragment>
            <ScrollableContainer $paddingX={theme?.space[0]}>
                <SpinnerContainer loading={loadingContract}>
                    <HeaderContainer $marginTop={theme?.space[0]} $marginBottom={theme?.space[0]}>
                        <StyledPageTitle $marginY={theme?.space[0]}>
                            {t('SIGN_CONTRACT')}
                        </StyledPageTitle>
                    </HeaderContainer>
                    <Fragment>
                        {!ctxContract.currentContract?.declarationGuid && (
                            <StyledNotScannedErrorLabel>
                                {t(ERROR_CODES.DeclarationNotScanned)}
                            </StyledNotScannedErrorLabel>
                        )}
                        {ctxContract.currentContract && (
                            <Fragment>
                                <StyledAssetContent>
                                    <StyledAssetCard>
                                        <ListItemCardRowHeader>
                                            <ListItemCardCustomerName
                                                direction="row"
                                                value={ctxContract.currentContract.customerName}
                                                bolder={true}
                                            />
                                        </ListItemCardRowHeader>

                                        <ListItemCardRow>
                                            <div>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('STATUS')}
                                                />
                                                <AssetCardStatusLabel
                                                    $style={getStatusStyle(
                                                        ctxContract.currentContract.status
                                                    )}>
                                                    {getStatusLabel(
                                                        ctxContract.currentContract.status
                                                    )}
                                                </AssetCardStatusLabel>
                                            </div>

                                            <ListItemRightCol>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('CONTRACT_NO')}
                                                />
                                                <ListItemCardValue
                                                    direction="column"
                                                    value={
                                                        ctxContract.currentContract
                                                            .contractNumber ?? undefined
                                                    }
                                                />
                                            </ListItemRightCol>
                                        </ListItemCardRow>

                                        <ListItemCardRow>
                                            <div>
                                                <ListItemCardLabel
                                                    direction={'column'}
                                                    label={t('CONTRACTED')}
                                                />
                                                <ListItemCardValue
                                                    direction={'column'}
                                                    value={
                                                        ctxContract.currentContract.contracted ??
                                                        undefined
                                                    }
                                                />
                                            </div>
                                            <ListItemRightCol>
                                                <ListItemCardLabel
                                                    direction={'column'}
                                                    label={t('CONT_QUANTITY')}
                                                />
                                                <ListItemCardValue
                                                    direction={'column'}
                                                    value={
                                                        ctxContract.currentContract.contractedQuantity.toString() ??
                                                        undefined
                                                    }
                                                />
                                            </ListItemRightCol>
                                        </ListItemCardRow>

                                        <ListItemCardRow>
                                            <ListItemCardLabel
                                                direction="column"
                                                label={t('DECLARATION')}
                                            />
                                            <div>
                                                {!ctxContract.currentContract?.declarationGuid && (
                                                    <CustomButton
                                                        label={t('SCAN')}
                                                        buttonType={ButtonsTypesEnum.Secondary}
                                                        icon={<ScanIcon />}
                                                        onClick={handleScanDeclaration}
                                                    />
                                                )}
                                                {ctxContract.currentContract?.declarationGuid && (
                                                    <CustomButton
                                                        label={t('VIEW')}
                                                        buttonType={ButtonsTypesEnum.Secondary}
                                                        icon={<ViewDeclarationIcon />}
                                                        onClick={handleViewDeclaration}
                                                    />
                                                )}
                                            </div>
                                        </ListItemCardRow>

                                        <FlexContainer
                                            $flexDirection="column"
                                            $marginBottom={theme?.space[16]}>
                                            <ListItemCardLabel
                                                direction={'column'}
                                                label={t('CUSTOMER_EMAIL')}
                                            />

                                            <CustomInput
                                                icon={<EmailIcon />}
                                                inputType={'email'}
                                                onChange={onCustomerMailChange}
                                                placeholder={t('ENTER_CUSTOMER_EMAIL')}
                                                defaultValue={
                                                    ctxContract.currentContract.customerEmail ?? ''
                                                }
                                            />
                                        </FlexContainer>

                                        <FlexContainer
                                            $flexDirection="column"
                                            $marginBottom={theme?.space[16]}>
                                            <ListItemCardLabel
                                                direction={'column'}
                                                label={t('CUSTOMER_PERSONNEL_NAME')}
                                            />

                                            <CustomInput
                                                icon={<ProfileIcon />}
                                                onChange={onCustomerPersonnelNameChange}
                                                placeholder={t('ENTER_POC_EMPLOYEE_NAME')}
                                                isLettersOnly
                                                defaultValue={
                                                    ctxContract.currentContract
                                                        .customerPersonnelName ?? ''
                                                }
                                                value={
                                                    ctxContract.currentContract
                                                        .customerPersonnelName ?? ''
                                                }
                                            />
                                        </FlexContainer>

                                        <FlexContainer
                                            $flexDirection="column"
                                            $marginBottom={theme?.space[16]}>
                                            <ListItemCardLabel
                                                direction={'column'}
                                                label={t('CUSTOMER_PERSONNEL_ROLE')}
                                            />
                                            <CustomInput
                                                icon={<ProfileIcon />}
                                                onChange={onCustomerPersonnelRoleChange}
                                                placeholder={t('ENTER_CUSTOMER_PERSONNEL_ROLE')}
                                                defaultValue={
                                                    ctxContract.currentContract
                                                        .customerPersonnelRole ?? ''
                                                }
                                            />
                                        </FlexContainer>

                                        <FlexContainer $marginBottom={theme?.space[12]}>
                                            <CustomButton
                                                onClick={handlePreview}
                                                label={t('SIGN')}
                                                buttonType={
                                                    isValid
                                                        ? ButtonsTypesEnum.Main
                                                        : ButtonsTypesEnum.Disabled
                                                }
                                                icon={<SignIcon />}
                                                style={{ width: '100%' }}
                                            />
                                        </FlexContainer>

                                        <ListItemCardFooterWrapper>
                                            <ContractIcon />

                                            <ListItemCardFooter $gap={theme?.space[12]}>
                                                <ListItemCardDate>
                                                    <CalendarIcon />
                                                    <span>
                                                        {formatDate(
                                                            ctxContract.currentContract.lastModified
                                                        )}
                                                    </span>
                                                </ListItemCardDate>
                                            </ListItemCardFooter>
                                        </ListItemCardFooterWrapper>

                                        <AssetCardStatusLine
                                            $style={getStatusStyle(
                                                ctxContract.currentContract.status
                                            )}
                                        />
                                    </StyledAssetCard>
                                </StyledAssetContent>

                                <Container $paddingY={theme?.space[16]}>
                                    <HeaderContainer
                                        $padding={theme?.space[0]}
                                        $marginTop={theme?.space[0]}
                                        $marginBottom={theme?.space[0]}>
                                        <h3>{t('ARTICLES_LIST')}</h3>
                                    </HeaderContainer>

                                    <MaterialsTable
                                        models={ctxContract.currentContract.models}
                                        onArticlesChange={handleModelChange}
                                        noMaterialsMessage={t('NO_MATERIALS_IN_PROTOCOL')}
                                    />
                                </Container>
                            </Fragment>
                        )}
                    </Fragment>
                </SpinnerContainer>
            </ScrollableContainer>
            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Fragment>
    );
};

export default Contract;
