import { ApolloQueryResult } from '@apollo/client';
import { message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import DropdownWithSearch from '@/components/shared/UI/DropdownWithSearch';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    GetZonesQuery,
    useGetSupervisorsQuery,
    useGetSupervisorsWithoutZonesQuery,
    useUpdateZoneMutation,
    ZoneDto
} from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetZonesQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();

    const [updateZoneMutation] = useUpdateZoneMutation({
        onError: (error) => {
            message.error(t(error.message));
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: async () => {
            await onChangeSuccess();
            await refetchSupervisors();
            message.success(t('SUPERVISOR_UPDATED_SUCCESSFULLY'));
        }
    });
    const { data: supervisors } = useGetSupervisorsQuery({
        fetchPolicy: 'network-only',
        variables: { searchText: '' },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });
    const { data: supervisorsWithoutZone, refetch: refetchSupervisors } =
        useGetSupervisorsWithoutZonesQuery({
            fetchPolicy: 'network-only',
            onError: (error) => {
                setError(error, ErrorTypeEnum.ApolloError);
            }
        });
    const supervisorsForDropdown = supervisorsWithoutZone?.supervisorsWithoutZone.map(
        (supervisor) => ({
            label: supervisor.email,
            key: supervisor.id
        })
    );

    const colDef: ColumnsType<ZoneDto> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            sortDirections: ['descend', 'ascend'],
            sorter: true
        },
        {
            key: 'supervisorEmail',
            title: t('SUPERVISOR_EMAIL'),
            dataIndex: 'supervisorEmail',
            sorter: true,
            render: (_, zone) => (
                <DropdownWithSearch
                    placeholder={t('SUPERVISOR')}
                    items={supervisorsForDropdown}
                    onClear={() => null}
                    style={{ minWidth: 400 }}
                    onChangeHandler={async (value: string) =>
                        await updateZoneMutation({
                            variables: { input: { zoneId: zone.id, supervisorId: Number(value) } }
                        })
                    }
                    value={
                        supervisors?.supervisors.find(
                            (supervisor) => supervisor.id === zone.supervisorId
                        )?.email ?? null
                    }
                />
            )
        }
    ];

    return { colDef };
};

export default useColDefs;
