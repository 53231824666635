import { useCallback, useContext } from 'react';

import AppContext from '@/store/app-context/AppContext';

const useFormData = () => {
    const { additionalDocsFormData } = useContext(AppContext);

    const getFormData = useCallback(
        (key: string) => {
            return additionalDocsFormData.data?.get(key);
        },
        [additionalDocsFormData]
    );

    const clearFormData = (key: string) => {
        additionalDocsFormData.data?.delete(key);
    };

    const clearAllFormData = () => {
        additionalDocsFormData?.setFormData(new FormData());
    };

    const setFormData = useCallback(
        (key: string, value: string | Blob) => {
            const newData = new FormData();

            if (additionalDocsFormData.data) {
                for (const [key, value] of additionalDocsFormData.data.entries()) {
                    newData.set(key, value);
                }
            }

            newData.set(key, value);
            additionalDocsFormData.setFormData(newData);
        },
        [additionalDocsFormData]
    );

    return { getFormData, setFormData, clearFormData, clearAllFormData };
};

export default useFormData;
