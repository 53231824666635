import styled from 'styled-components';

type StyledPageTitleProps = {
    $marginY?: string;
};

export const StyledPageTitle = styled.h2<StyledPageTitleProps>`
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSize.h2};
    font-family: ${({ theme }) => theme.fontFamily.interNormal};
    line-height: ${({ theme }) => theme.lineHeight[24]};
    margin-bottom: ${({ theme }) => theme.space[52]};
    margin: ${({ $marginY }) => $marginY ?? $marginY} 0;
`;
