export const isValidEmail = (email: string) => {
    if (!email) {
        return;
    }

    const regex = /^\S+@\S+\.\S+$/;

    return regex.test(email);
};

export const isInvalidNumber = (input: string) => {
    const regex = /^0$|^[1-9]\d*(\.\d+)?$|^0\.\d*[1-9]\d*$/;

    return regex.test(input);
};
