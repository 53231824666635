import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components';

import { FilterTypesEnum } from '@/enums/assets/filter-types.enum';
import {
    AgreementDto,
    DocumentStatusEnum,
    GetAgreementStatusesPerUserQuery,
    useGetAgreementStatusesPerUserQuery
} from '@/graphql/main';
import { StyledStatusFilterContainer } from '@/styles/shared/status/styles';

import { FilterWithType } from '../AgreementFilters';

import StatusFilterPill from './partials/StatusFilterPill';

type Props = {
    agreements: AgreementDto[];
    onStatusSelection: (key: DocumentStatusEnum | undefined) => void;
    filters: FilterWithType[];
    isStatusSelected: boolean;
};

type StatusCount = { status: DocumentStatusEnum; count: number };

const statusKeyToEnum: Record<string, DocumentStatusEnum> = {
    DRAFT: DocumentStatusEnum.Draft,
    FOR_VALIDATION: DocumentStatusEnum.ForValidation,
    PREPARED: DocumentStatusEnum.Prepared,
    SIGNED: DocumentStatusEnum.Signed,
    DONE: DocumentStatusEnum.Done,
    CANCELED: DocumentStatusEnum.Canceled
};

const AgreementStatusFilter: React.FC<Props> = ({
    filters,
    agreements,
    onStatusSelection,
    isStatusSelected
}) => {
    const [selectedStatus, setSelectedStatus] = useState<DocumentStatusEnum | undefined>();
    const [statusesCount, setStatusesCount] = useState<StatusCount[]>();
    const theme = useTheme();

    const handleStatusesCount = (data: GetAgreementStatusesPerUserQuery) => {
        setStatusesCount(() => {
            let sumCount = 0;

            const statusesCountArr = Array.from(
                data?.agreementStatusesPerUser?.statuses.map(
                    (status: { key: DocumentStatusEnum; value: number }) => {
                        sumCount += status.value;

                        return { status: status.key, count: status.value };
                    }
                )
            );

            return [{ status: undefined, count: sumCount }, ...statusesCountArr] as StatusCount[];
        });
    };

    const queryInput = {
        customerName: filters.find((filter) => filter.type === FilterTypesEnum.Customer)?.filterKey,
        pocName: filters.find((filter) => filter.type === FilterTypesEnum.Poc)?.filterKey,
        status: filters.find((filter) => filter.type === FilterTypesEnum.AssetStatus)?.statusKey
    };

    const { refetch } = useGetAgreementStatusesPerUserQuery({
        variables: {
            input: queryInput
        },
        onCompleted: (data) => {
            handleStatusesCount(data);
        }
    });

    const statusCountMap = new Map<DocumentStatusEnum, number>();

    // Loop over array and update count in map for each status
    for (let i = 0; i < agreements?.length; i++) {
        const currentStatus = agreements[i].status;
        const currentCount = statusCountMap.get(currentStatus) ?? 0;
        statusCountMap.set(currentStatus, currentCount + 1);
    }

    const handleStatusSelection = (status: DocumentStatusEnum | undefined) => {
        if (status === selectedStatus) {
            return;
        }

        setSelectedStatus(status);
        onStatusSelection(status);
    };

    useEffect(() => {
        refetch({
            input: queryInput
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, agreements]);

    useEffect(() => {
        const statusKey = filters.find((filter: FilterWithType) => filter?.statusKey)?.statusKey;

        if (!statusKey) {
            setSelectedStatus(undefined);

            return;
        }

        handleStatusSelection(statusKeyToEnum[statusKey]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStatusSelected, filters]);
    const sortByStatus = (arr: StatusCount[], order: Array<StatusCount['status']>) =>
        arr.sort((a, b) => {
            let indexA = order.indexOf(a.status);
            let indexB = order.indexOf(b.status);

            indexA = indexA === -1 ? order.length : indexA;
            indexB = indexB === -1 ? order.length : indexB;

            return indexA - indexB;
        });

    return (
        <StyledStatusFilterContainer
            $flexWrap="wrap"
            $marginBottom={theme?.space[12]}
            $gap={theme?.space[8]}>
            {statusesCount &&
                // order is set by statusKeyToEnum keys
                sortByStatus(
                    statusesCount,
                    Object.keys(statusKeyToEnum) as DocumentStatusEnum[]
                )?.map((item, index) => {
                    return (
                        <StatusFilterPill
                            onClick={() => handleStatusSelection(item.status)}
                            key={index}
                            status={item.status}
                            isSelected={selectedStatus === item.status && isStatusSelected}
                            count={item.count}
                        />
                    );
                })}
        </StyledStatusFilterContainer>
    );
};

export default AgreementStatusFilter;
