import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import ROUTES from '@/constants/routes/routes';

import { StyledBorderlessNavLink, StyledDropdownNavLink, StyledNavLink } from '../../styles';

const NavigationLinks = () => {
    const { t } = useTranslation('translation');

    const assetsManagementMenu: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminAssets}>
                    {t('ARCHIVE')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '2',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminMaterials}>
                    {t('MATERIALS')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '3',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminReports}>
                    {t('RAW_DATA_REPORT')}
                </StyledDropdownNavLink>
            )
        }
    ];

    const attributesMenu: MenuProps['items'] = [
        {
            key: '12',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.Archive}>
                    {t('ARCHIVE')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '1',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminDefinitions}>
                    {t('DEFINITION_OF_CA')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '11',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminBrands}>
                    {t('BRANDS')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '2',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminProducts}>
                    {t('PRODUCTS')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '3',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminTargetSkus}>
                    {t('TARGET_SKUS')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '4',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminThirdParties}>
                    {t('THIRD_PARTIES')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '5',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminPaymentFrequencies}>
                    {t('PAYMENT_FREQUENCIES')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '6',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminZones}>
                    {t('ZONES')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '7',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminRegions}>
                    {t('REGIONS')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '8',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AdminDistributors}>
                    {t('DISTRIBUTORS')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '9',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.AgreementRawDataReport}>
                    {t('RAW_DATA_REPORT')}
                </StyledDropdownNavLink>
            )
        },
        {
            key: '10',
            label: (
                <StyledDropdownNavLink to={ROUTES.FullPaths.SpendLimit}>
                    {t('SPEND_LIMIT')}
                </StyledDropdownNavLink>
            )
        }
    ];

    return (
        <>
            <StyledNavLink to={ROUTES.FullPaths.AdminUsers}>{t('USERS')}</StyledNavLink>
            <Dropdown menu={{ items: assetsManagementMenu }}>
                <StyledBorderlessNavLink to="#">{t('ASSETS_MANAGEMENT')}</StyledBorderlessNavLink>
            </Dropdown>
            <Dropdown menu={{ items: attributesMenu }}>
                <StyledBorderlessNavLink to="#">
                    {t('COMMERCIAL_AGREEMENTS')}
                </StyledBorderlessNavLink>
            </Dropdown>
        </>
    );
};

export default NavigationLinks;
