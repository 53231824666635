import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DocumentStatusEnum } from '@/graphql/main';
import useStatus from '@/hooks/status/useStatus';
import useLanguage from '@/hooks/useLanguage';
import {
    StyledAgreementStatusOption,
    StyledStatusFilterCount,
    StyledStatusFilterLabel
} from '@/styles/shared/status/styles';

type Props = {
    status?: DocumentStatusEnum;
    isSelected: boolean;
    count: number;
    onClick: () => void;
};

const StatusFilterPill: React.FC<Props> = ({ status, isSelected, count, onClick }) => {
    const [label, setLabel] = useState('');
    const [styleType, setStyleType] = useState('');
    const { t } = useTranslation();
    const { currentLanguage } = useLanguage();
    const { getStatusLabel, getStatusStyle } = useStatus();

    useEffect(() => {
        if (status) {
            const label = getStatusLabel(status);
            const statusStyle = getStatusStyle(status);
            setLabel(label);
            setStyleType(statusStyle);
        }
    }, [t, currentLanguage, status, getStatusLabel, getStatusStyle]);

    if (!status) return null;

    return (
        status && (
            <StyledAgreementStatusOption
                onClick={onClick}
                selected={isSelected}
                $styleType={styleType}>
                <StyledStatusFilterLabel selected={isSelected}>{label}</StyledStatusFilterLabel>
                <StyledStatusFilterCount>{count}</StyledStatusFilterCount>
            </StyledAgreementStatusOption>
        )
    );
};

export default StatusFilterPill;
