import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import documentsImage from '@/assets/images/documents-image.png';
import draftImage from '@/assets/images/draft-image.png';
import successImage from '@/assets/images/success-image.png';
import validationImage from '@/assets/images/validation-image.png';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';

import CustomButton from '../../UI/CustomButton';
import { StyledPageTitle } from '../../UI/PageTitle/styles';
import CloseModalBar from '../partials/CloseModalBar';
import ModalBody from '../partials/ModalBody';
import ModalContainer from '../partials/ModalContainer';
import ModalFooter from '../partials/ModalFooter';
import ModalHeader from '../partials/ModalHeader';
import ModalSubMessage from '../partials/ModalSubMessage';

type Props = {
    onConfirm: () => void;
    title?: string;
    message: string;
    modalType?: SuccessModalTypeEnum;
};

const getSuccessModalImage = (modalType: SuccessModalTypeEnum) => {
    switch (modalType) {
        case SuccessModalTypeEnum.Success:
            return successImage;
        case SuccessModalTypeEnum.Draft:
            return draftImage;
        case SuccessModalTypeEnum.ForValidation:
            return validationImage;
        case SuccessModalTypeEnum.AdditionalDocuments:
            return documentsImage;
        default:
            return successImage;
    }
};

const SuccessModal: React.FC<Props> = ({ onConfirm, title, message, modalType }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [image, setImage] = useState<string>();

    useEffect(() => {
        const modalImage = getSuccessModalImage(modalType!);
        setImage(modalImage);
    }, [modalType]);

    return (
        <ModalContainer>
            <CloseModalBar
                backgroundColor={theme?.color.default.white}
                xColor="black"
                onClick={onConfirm}
            />
            <ModalHeader backgroundColor={theme?.color.default.white}>
                <img src={image} alt="Modal Image" />
            </ModalHeader>
            <ModalBody backgroundColor={theme?.color.default.white}>
                <StyledPageTitle>{title}</StyledPageTitle>
                <ModalSubMessage message={message} />
            </ModalBody>
            <ModalFooter backgroundColor={theme?.color.default.white}>
                <CustomButton
                    label={t('OK')}
                    buttonType={ButtonsTypesEnum.Secondary}
                    onClick={onConfirm}
                    style={{ width: '100%' }}
                />
            </ModalFooter>
        </ModalContainer>
    );
};

export default SuccessModal;
