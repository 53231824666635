import { Spin } from 'antd';
import styled from 'styled-components';

type SpinProps = {
    $defaultSize?: boolean | undefined;
};

export const StyledSpin = styled(Spin)<SpinProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    & > span {
        font-size: ${({ $defaultSize }) => ($defaultSize ? '5rem' : 'default')};
    }
`;
