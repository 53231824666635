import { Fragment, useContext, useEffect, useState } from 'react';

import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import ErrorMessage from '@/components/shared/message/ErrorMessage';
import CustomButton from '@/components/shared/UI/CustomButton';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import envconfig from '@/config/envconfig';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { FlexAlignCenter, ScrollableContainer } from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';
import { getToken } from '@/utils/authorizationUtils';

import PREVIEW_STYLE from './preview-style';

const ContractPreview: React.FC = () => {
    const { navbar, contract } = useContext(AppContext);
    const contractFromStorage = getCurrentContractData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [data, setData] = useState<any>();
    const { setError, clearError, errorMessage } = useError();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const sanitizedHTML = DOMPurify.sanitize(data);

    document.body.style.width = 'unset';
    document.body.style.height = 'unset';

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        fetch(envconfig.apiAddress + envconfig.previewContractUrl, {
            headers: {
                Authorization: `bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(contract.currentContract ?? contractFromStorage)
        })
            .then(async (res) => {
                const data = await res.text();
                setData(data);
                setIsLoading(false);
                clearError();
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error, ErrorTypeEnum.Error);
            });

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleSign = () => {
        navigate(ROUTES.AssetList.Protocol.ProtocolPreview.Sign.Path, { replace: true });
    };

    return (
        <Fragment>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <ScrollableContainer $height={90}>
                <SpinnerContainer loading={isLoading}>
                    {data && <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />}
                </SpinnerContainer>
            </ScrollableContainer>
            <FlexAlignCenter $justify="center" style={{ paddingTop: theme?.space[12] }}>
                <CustomButton
                    onClick={handleSign}
                    label={t('SIGN')}
                    icon={<SignIcon />}
                    buttonType={ButtonsTypesEnum.Main}
                />
            </FlexAlignCenter>
            <style id="front-end-style">{PREVIEW_STYLE}</style>
        </Fragment>
    );
};

export default ContractPreview;
