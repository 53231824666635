import styled from 'styled-components';

import { StyledPageTitle } from '../../UI/PageTitle/styles';

type TitleProps = {
    $titleColor?: string | undefined;
};

export const WarningModalTitle = styled(StyledPageTitle)<TitleProps>`
    padding: ${({ theme }) => theme.space[16]};
    color: ${({ $titleColor }) => $titleColor};
    font-size: ${({ theme }) => theme.fontSize.h5};
    font-family: ${({ theme }) => theme.fontFamily.interNormal};
`;

export const WarningModalButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: ${({ theme }) => theme.space[16]};

    button {
        width: 100%;
    }
`;
