import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { AssetDto, DocumentStatusEnum } from '@/graphql/main';
import { ListNoContentLabel, ListScrollableContainer } from '@/styles/shared/list/styles';

import AssetCard from '../AssetCard';
import { FilterWithType } from '../AssetDropdownArea';

type Pros = {
    assets: AssetDto[];
    onAssetCancellation?: () => void;
    isSelectionMode: boolean;
    onAssetsSelected: (assetsIds: number[]) => void;
    fetchNext: () => void;
    hasMore: boolean;
};

const AssetsListTable: React.FC<Pros> = ({
    assets,
    onAssetCancellation,
    isSelectionMode,
    onAssetsSelected,
    fetchNext,
    hasMore
}) => {
    const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
    const { t } = useTranslation('translation');

    useEffect(() => {
        if (!isSelectionMode) {
            setSelectedAssets(() => []);
        }
    }, [isSelectionMode]);

    const handleAssetsSelection = (assetId: number) => {
        const assetIndex = selectedAssets.indexOf(assetId);

        if (assetIndex > -1) {
            const newState = [...selectedAssets]; // Create a copy of the state
            newState.splice(assetIndex, 1); // Modify the copied state
            onAssetsSelected(newState);

            setSelectedAssets(() => [...newState]);
        } else {
            const newState = [...selectedAssets, assetId];
            onAssetsSelected(newState);
            setSelectedAssets(() => [...newState]);
        }
    };

    const getIsProtocolPreparedFilter = () => {
        const filters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AssetFilters)!);

        return filters.some((filter: FilterWithType) => {
            return filter.statusKey === DocumentStatusEnum.Prepared;
        });
    };

    return (
        <ListScrollableContainer id="scrollTarget">
            <InfiniteScroll
                className={'list-container'}
                dataLength={assets?.length ?? 0}
                next={fetchNext}
                hasMore={hasMore}
                loader={<Spin />}
                scrollableTarget="scrollTarget">
                {assets?.map((asset: AssetDto, index) => {
                    return (
                        <AssetCard
                            key={`${asset.assetId as string}_${index}`}
                            asset={asset}
                            isSelectionMode={isSelectionMode}
                            onAssetCancellation={onAssetCancellation}
                            onSelection={handleAssetsSelection}
                        />
                    );
                })}
            </InfiniteScroll>

            {assets?.length === 0 && !getIsProtocolPreparedFilter() && (
                <ListNoContentLabel>{t('NO_ASSETS_FOUND')}</ListNoContentLabel>
            )}
        </ListScrollableContainer>
    );
};

export default AssetsListTable;
