import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from '@/assets/svg-icons/chevron-icon.svg';

type ChevronArrowIconProps = {
    $isRotated: boolean;
};

type IconStrokeProps = {
    $color: string | undefined;
};

export const ChevronArrowIconContainer = styled.div<ChevronArrowIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: ${({ theme }) => theme.space[36]};
    height: ${({ theme }) => theme.space[36]};
    background-color: ${({ theme }) => theme.color.blue[15]};

    svg {
        transform: ${({ $isRotated }) => ($isRotated ? 'rotate(180deg)' : 'rotate(0deg)')};
        transition: transform 0.3s ease;
    }
`;

export const SelectChevronIcon = styled(ChevronIcon)`
    width: ${({ theme }) => theme.space[12]};
`;

export const IconStroke = styled.div<IconStrokeProps>`
    svg > path {
        stroke: ${({ $color }) => $color};
    }
`;
