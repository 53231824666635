import { useTranslation } from 'react-i18next';

import { AdminMaterialDto } from '@/graphql/main';
import { EditableColumn } from '@/types/admin/Table';

const useColDefs = () => {
    const { t } = useTranslation('translation');

    const colDefs: Array<EditableColumn<AdminMaterialDto>> = [
        {
            key: 'hermesModelId',
            title: t('ID'),
            dataIndex: 'hermesModelId',
            width: 50,
            sorter: true
        },
        {
            key: 'modelName',
            title: t('MODEL_NAME'),
            dataIndex: 'modelName',
            width: 250,
            sorter: true
        },
        {
            key: 'type',
            title: t('TYPE'),
            dataIndex: 'type',
            width: 100,
            sorter: true
        },
        {
            key: 'price',
            title: t('PRICE'),
            dataIndex: 'price',
            editable: true,
            sorter: true,
            width: 50
        }
    ];

    return colDefs;
};

export default useColDefs;
