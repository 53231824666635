import { TableDataProvider } from '@/hooks/admin/useTableData';
import TargetSkusTable from '@/pages/admin/CommercialAgreements/TargetSkus/partials/TargetSkusTable';

const TargetSkus = () => (
    <TableDataProvider>
        <TargetSkusTable />
    </TableDataProvider>
);

export default TargetSkus;
