export const PASSWORD_RULES = [
    { required: true, message: 'Please input your password!' },
    { min: 8, message: 'Password should be at least 8 characters long!' },
    {
        pattern: new RegExp('(?=.*[a-z])'),
        message: 'Should contain at least one letter!'
    },
    {
        pattern: new RegExp('(?=.*[A-Z])'),
        message: 'Should contain at least one uppercase letter!'
    },
    {
        pattern: new RegExp('(?=.*[0-9])'),
        message: 'Should contain at least one numeric!'
    }
];
