import { useState } from 'react';

import TABLE_CONSTANTS from '@constants/admin/tables';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AdminCommercialAgreementDto,
    AdminCommercialAgreementFilterRequestModelInput,
    SortEnumType,
    useGetAdminCommercialAgreementsDocumentQuery
} from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import { TableDataProvider } from '@/hooks/admin/useTableData';
import useError from '@/hooks/error/useError';
import useColDefs from '@/pages/admin/CommercialAgreements/Archive/hooks/useColDefs';

import CAFilters from './partials/CAFilters';

const CommercialAgreementsArchiveTable: React.FC = () => {
    const {
        handlePageChange,
        pageSize,
        itemsToSkip,
        setTotalItems,
        totalItems,
        sortingOrder,
        setSortingOrder
    } = usePaginate();
    const [currentSearchTerms, setCurrentSearchTerms] =
        useState<AdminCommercialAgreementFilterRequestModelInput>();
    const [tableData, setTableData] = useState<AdminCommercialAgreementDto[]>([]);
    const { clearError, setError } = useError();
    const { t } = useTranslation();

    const { loading } = useGetAdminCommercialAgreementsDocumentQuery({
        fetchPolicy: 'network-only',
        variables: {
            input: {
                ...currentSearchTerms
            },
            skip: itemsToSkip,
            take: pageSize,
            order: sortingOrder
        },
        onCompleted: (data) => {
            if (data.adminCommercialAgreements?.items) {
                setTableData(
                    data.adminCommercialAgreements?.items as AdminCommercialAgreementDto[]
                );
                clearError();
                setTotalItems(data.adminCommercialAgreements.totalCount);
            }
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const handleSearch = async (searchTerms: AdminCommercialAgreementFilterRequestModelInput) => {
        if (searchTerms !== currentSearchTerms) {
            setCurrentSearchTerms(searchTerms);
        }
    };
    const { colDefs } = useColDefs();
    const onSortChange = (sorterResult: SorterResult<AdminCommercialAgreementDto>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <TableDataProvider>
            <SectionHeading heading={t('ARCHIVE_CA')} />
            <CAFilters onSearch={handleSearch} />
            <ResizableTable
                tableData={tableData}
                colDefs={colDefs}
                emptyText={t('NO_FILTERS_HINT')}
                hasSearchBar={false}
                loading={loading}
                onPageChange={handlePageChange}
                totalPaginationItems={totalItems}
                onChange={(_, __, sorterResult) =>
                    onSortChange(sorterResult as SorterResult<AdminCommercialAgreementDto>)
                }
                size={TABLE_CONSTANTS.TableSize}
            />
        </TableDataProvider>
    );
};

export default CommercialAgreementsArchiveTable;
