import { ApolloQueryResult } from '@apollo/client';
import { Button, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import DropdownWithSearch from '@/components/shared/UI/DropdownWithSearch';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    GetRegionsQuery,
    RegionDto,
    useGetRcmcsQuery,
    useGetZonesWithSupervisorsQuery,
    useUpdateRegionMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { EditableColumn } from '@/types/admin/Table';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetRegionsQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();

    const [updateRegionMutation] = useUpdateRegionMutation({
        onError: (error) => {
            message.error(t(error.message));
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: async () => {
            message.success(t('REGION_UPDATED_SUCCESSFULLY'));
            await onChangeSuccess();
        }
    });
    const { data: allZones } = useGetZonesWithSupervisorsQuery({
        fetchPolicy: 'network-only',
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });
    const { data: rcmcs } = useGetRcmcsQuery({
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const colDef: Array<EditableColumn<RegionDto>> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            width: '20%',
            editable: true,
            sorter: true
        },
        {
            key: 'costCenter',
            title: t('COST_CENTER'),
            dataIndex: 'costCenter',
            width: '20%',
            editable: true,
            sorter: true
        },
        {
            key: 'rcmcEmail',
            title: t('RCMC_EMAIL'),
            dataIndex: 'rcmcEmail',
            sorter: true,
            render: (_, { id, rcmcId }) => (
                <DropdownWithSearch
                    placeholder={t('RCMC_EMAIL')}
                    items={rcmcs?.rcmcs
                        .filter((rcmc) => rcmc.id !== rcmcId)
                        .map((rcmc) => ({ label: rcmc.email, key: rcmc.id }))}
                    onClear={() => null}
                    style={{ minWidth: 400 }}
                    onChangeHandler={async (value: string) =>
                        await updateRegionMutation({
                            variables: { input: { regionId: id, rcmcId: Number(value) } }
                        })
                    }
                    value={rcmcs?.rcmcs.find((rcmc) => rcmc.id === rcmcId)?.email ?? null}
                />
            )
        },
        {
            key: 'zones',
            width: '35%',
            title: t('ZONES'),
            render: (_, { id, zones: zonesData }) => {
                return (
                    !!allZones?.zonesWithSupervisors?.length && (
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t('SELECT_ZONES')}
                            optionFilterProp="label"
                            value={zonesData.map((zone) => zone.id.toString())}
                            onChange={async (value) =>
                                await updateRegionMutation({
                                    variables: { input: { regionId: id, zones: value } }
                                })
                            }
                            options={allZones?.zonesWithSupervisors?.map((zone) => ({
                                value: zone?.id.toString(),
                                label: zone?.name
                            }))}
                        />
                    )
                );
            }
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            sorter: true,
            render: (_, { id, isActive }) => {
                return (
                    <Button
                        key={`deactivate-${id as string}`}
                        danger={isActive}
                        onClick={async () =>
                            await updateRegionMutation({
                                variables: { input: { isActive: !isActive, regionId: id } }
                            })
                        }>
                        {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                    </Button>
                );
            }
        }
    ];

    return { colDef };
};

export default useColDefs;
