const ROUTES = {
    Default: '/',
    None: '*',
    Authentication: {
        Path: 'authentication',
        Login: 'login',
        ForgottenPassword: 'forgotten-password',
        ConfirmEmailSent: 'confirm-email-sent',
        ResetPassword: 'reset-password',
        ChangePassword: 'change-password',
        Recovery: 'recovery'
    },

    Admin: {
        Path: 'admin',
        Users: {
            Path: 'users'
        },
        Assets: {
            Path: 'assets'
        },
        Materials: {
            Path: 'materials'
        },
        Reports: {
            Path: 'reports',
            RawDataReport: 'raw-data-report'
        },
        Attributes: {
            Path: 'attributes',
            Brands: 'brands',
            Products: 'products',
            TargetSkus: 'target-skus',
            ThirdParties: 'third-parties',
            PaymentFrequencies: 'payment-frequencies',
            Zones: 'zones',
            Regions: 'regions',
            Distributors: 'distributors',
            CostCenters: 'cost-centers',
            AgreementRawDataReport: 'raw-data-report',
            SpendLimit: 'spend-limit',
            Definitions: 'definitions',
            Archive: 'archive'
        }
    },

    AssetList: {
        Path: 'asset-list',
        Protocol: {
            Path: 'protocol/:assetId',
            DynamicPath: (assetId: number) => `protocol/${assetId}`,
            ProtocolPreview: {
                Path: 'protocol-preview',
                Sign: {
                    Path: 'sign',
                    SigningArea: 'signing-area'
                }
            },
            Declaration: {
                Path: 'declaration',
                Preview: 'declaration-protocol-preview',
                Scan: 'declaration-scan'
            }
        },
        Contract: {
            Path: 'contract/:assetId',
            DynamicPath: (assetId: number) => `contract/${assetId}`,
            ContractPreview: {
                Path: 'contract-preview',
                Sign: {
                    Path: 'sign',
                    SigningArea: 'signing-area'
                }
            },
            Declaration: {
                Path: 'declaration',
                Preview: 'declaration-contract-preview',
                Scan: 'declaration-scan'
            }
        },
        CreateContract: {
            Path: 'create-contract',
            Declaration: {
                Path: 'declaration',
                Preview: 'declaration-contract-preview',
                Scan: 'declaration-scan'
            }
        }
    },

    AgreementList: {
        Path: 'agreement-list',
        Agreement: {
            Path: 'agreement/:agreementId',
            DynamicPath: (agreementId: number) => `agreement/${agreementId}`,
            Sign: {
                Path: 'sign',
                SigningArea: 'signing-area'
            },
            AdditionalDocuments: {
                FreeBeerQuantity: 'free-beer-quantity',
                Invoice: {
                    AddInvoice: 'add-invoice',
                    Scan: 'scan'
                },
                ScanBankCertificate: 'scan-bank-certificate',
                AgreementProtocol: {
                    Generate: 'generate-agreement-protocol',
                    Scan: 'scan'
                }
            }
        },
        AgreementPreview: {
            Path: 'agreement-preview',
            Validate: {
                Path: 'validate-sign',
                SigningArea: 'signing-area'
            },
            Sign: {
                Path: 'sign',
                SigningArea: 'signing-area'
            }
        },
        Declaration: {
            Path: 'declaration',
            Preview: 'declaration-agreement-preview',
            Scan: 'declaration-scan'
        },
        CreateAgreement: {
            Path: 'create-agreement'
        }
    },

    FullPaths: {
        // Authentication
        Login: '/authentication/login',
        ForgottenPassword: '/authentication/forgotten-password',
        ConfirmEmailSent: '/authentication/confirm-email-sent',
        ResetPassword: '/authentication/reset-password',
        ChangePassword: '/authentication/change-password',
        Recovery: '/authentication/recovery',

        // Asset
        AssetList: '/asset-list',
        CreateContract: '/asset-list/create-contract',
        ProtocolSign: (assetId: number) => `/asset-list/protocol/${assetId}/protocol-preview/sign`,
        Protocol: (assetId: number) => `/asset-list/protocol/${assetId}`,
        Contract: (assetId: number) => `/asset-list/contract/${assetId}`,
        SigningArea: '/asset-list/protocol/protocol-preview/sign-protocol/signing-area',
        ProtocolPreview: '/asset-list/protocol/:assetId/protocol-preview',
        CreateContractDeclarationScan: '/asset-list/create-contract/declaration-scan',
        ProtocolDeclarationScan: (assetId: number) =>
            `/asset-list/protocol/${assetId}/declaration-scan`,

        // Agreement
        Agreement: (agreementId: number) => `/agreement-list/agreement/${agreementId}`,
        AgreementList: '/agreement-list',
        CreateAgreement: '/agreement-list/create-agreement',
        AgreementPreview: '/agreement-list/agreement/:agreementId/agreement-preview',
        AgreementScan: (agreementId: number) =>
            `/agreement-list/agreement/${agreementId}/declaration-scan`,

        // Admin
        AdminUsers: 'admin/users',
        AdminAssets: 'admin/assets',
        AdminMaterials: 'admin/materials',
        AdminReports: 'admin/reports',
        AdminRawDataReport: 'admin/reports/raw-data-report',
        AgreementRawDataReport: 'admin/attributes/raw-data-report',
        AdminBrands: 'admin/attributes/brands',
        AdminDefinitions: 'admin/attributes/definitions',
        AdminProducts: 'admin/attributes/products',
        AdminTargetSkus: 'admin/attributes/target-skus',
        AdminThirdParties: 'admin/attributes/third-parties',
        AdminPaymentFrequencies: 'admin/attributes/payment-frequencies',
        AdminZones: 'admin/attributes/zones',
        AdminRegions: 'admin/attributes/regions',
        AdminDistributors: 'admin/attributes/distributors',
        AdminCostCenters: 'admin/attributes/cost-centers',
        SpendLimit: 'admin/attributes/spend-limit',
        Archive: 'admin/attributes/archive'
    }
};

export default ROUTES;
