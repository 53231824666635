import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Long: any;
  Decimal: any;
  Any: any;
  DateTime: any;
};

export type Queries = {
  __typename?: 'queries';
  users?: Maybe<UserDtoCollectionSegment>;
  pocs: Array<Poc>;
  assets?: Maybe<AssetsConnection>;
  protocol: ProtocolDto;
  documentEmailHistory: EmailHistory;
  pocsWithAssetsPerUser: Array<Poc>;
  pocsWithAgreementsPerUser: Array<Poc>;
  customerPocs: Array<Poc>;
  customersWithAssetsPerUser: Array<CustomerDto>;
  customersWithAgreementsPerUser: Array<CustomerDto>;
  assetStatusesPerUser: DocumentsStatusesCount;
  assetOrderIdsPerUser: Array<Scalars['String']>;
  customerPerProtocolId: CustomerDto;
  srEmailSearch: Array<UserDto>;
  userCustomersByName: Array<CustomerDto>;
  createContractMaterialTypes: Array<MaterialTypeEnum>;
  materialModels: Array<Model>;
  contract: ContractDto;
  adminAssets?: Maybe<AdminAssetDtoCollectionSegment>;
  adminMaterials?: Maybe<AdminMaterialDtoCollectionSegment>;
  customersNames: Array<Scalars['String']>;
  pocsHermesIds: Array<Scalars['String']>;
  usersNames: Array<Scalars['String']>;
  supervisors: Array<UserDto>;
  supervisorsWithoutZone: Array<UserDto>;
  rcmcs: Array<UserDto>;
  assetsRawDataReport?: Maybe<AssetRawDataReportModelCollectionSegment>;
  agreements?: Maybe<AgreementsConnection>;
  agreementStatusesPerUser: DocumentsStatusesCount;
  agreementSpendLimit: Scalars['Decimal'];
  agreementDetails: AgreementDetailsDto;
  existingAgreementTypes: Array<Maybe<AgreementTypeEnum>>;
  agreementTypeTree: AgreementTypeDto;
  agreementDefinition: AgreementDefinitionDto;
  brands?: Maybe<BrandCollectionSegment>;
  products?: Maybe<ProductCollectionSegment>;
  paymentFrequencies?: Maybe<PaymentFrequencyCollectionSegment>;
  targetSkus?: Maybe<TargetSkuCollectionSegment>;
  thirdParties?: Maybe<ThirdPartyCollectionSegment>;
  distributors?: Maybe<DistributorDtoCollectionSegment>;
  regions?: Maybe<RegionDtoCollectionSegment>;
  zones?: Maybe<ZoneDtoCollectionSegment>;
  zonesWithSupervisors: Array<ZoneDto>;
  adminAgreementDefinitions?: Maybe<AgreementDefinitionCollectionSegment>;
  budgetCenters?: Maybe<BudgetCenterDtoCollectionSegment>;
  adminAssetStatuses: Array<DocumentStatusEnum>;
  adminCommercialAgreements?: Maybe<AdminCommercialAgreementDtoCollectionSegment>;
};


export type QueriesUsersArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<UserDtoSortInput>>;
};


export type QueriesAssetsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AssetDtoFilterInput>;
};


export type QueriesProtocolArgs = {
  protocolId: Scalars['Long'];
};


export type QueriesDocumentEmailHistoryArgs = {
  documentId: Scalars['Long'];
  documentType: DocumentTypeEnum;
  assetType?: InputMaybe<AssetTypeEnum>;
};


export type QueriesPocsWithAssetsPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesPocsWithAgreementsPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesCustomerPocsArgs = {
  customerId: Scalars['Long'];
};


export type QueriesCustomersWithAssetsPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesCustomersWithAgreementsPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesAssetStatusesPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesAssetOrderIdsPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesCustomerPerProtocolIdArgs = {
  protocolId: Scalars['Long'];
};


export type QueriesSrEmailSearchArgs = {
  emailText: Scalars['String'];
};


export type QueriesUserCustomersByNameArgs = {
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesMaterialModelsArgs = {
  type: MaterialTypeEnum;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesContractArgs = {
  contractId: Scalars['Long'];
};


export type QueriesAdminAssetsArgs = {
  request: AdminAssetFilterRequestModelInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AdminAssetDtoSortInput>>;
};


export type QueriesAdminMaterialsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AdminMaterialDtoSortInput>>;
};


export type QueriesCustomersNamesArgs = {
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesPocsHermesIdsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesUsersNamesArgs = {
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesSupervisorsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesRcmcsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueriesAssetsRawDataReportArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  request: AssetsRawDataReportRequestModelInput;
};


export type QueriesAgreementsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AgreementDtoFilterInput>;
};


export type QueriesAgreementStatusesPerUserArgs = {
  request: FilterRequestModelInput;
};


export type QueriesAgreementDetailsArgs = {
  agreementId: Scalars['Long'];
};


export type QueriesAgreementTypeTreeArgs = {
  type: AgreementTypeEnum;
};


export type QueriesAgreementDefinitionArgs = {
  type: AgreementTypeEnum;
  subject: AgreementSubjectEnum;
  reason: AgreementReasonEnum;
};


export type QueriesBrandsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AttributeModelBaseSortInput>>;
};


export type QueriesProductsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput>>;
};


export type QueriesPaymentFrequenciesArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AttributeModelBaseSortInput>>;
};


export type QueriesTargetSkusArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AttributeModelBaseSortInput>>;
};


export type QueriesThirdPartiesArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ThirdPartySortInput>>;
};


export type QueriesDistributorsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<DistributorDtoSortInput>>;
};


export type QueriesRegionsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<RegionDtoSortInput>>;
};


export type QueriesZonesArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ZoneDtoSortInput>>;
};


export type QueriesZonesWithSupervisorsArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<ZoneDtoSortInput>>;
};


export type QueriesAdminAgreementDefinitionsArgs = {
  request: AgreementDefinitionsFilterRequestModelInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AgreementDefinitionSortInput>>;
};


export type QueriesBudgetCentersArgs = {
  searchText?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<BudgetCenterDtoSortInput>>;
};


export type QueriesAdminCommercialAgreementsArgs = {
  request: AdminCommercialAgreementFilterRequestModelInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AdminCommercialAgreementDtoSortInput>>;
};

export type Mutations = {
  __typename?: 'mutations';
  resetPassword: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  sendResetPasswordCode: Scalars['Boolean'];
  verifyResetPasswordCode: Scalars['Boolean'];
  completeProtocol?: Maybe<ProtocolDto>;
  cancelAsset: Scalars['Boolean'];
  singleProtocolShare: Scalars['Boolean'];
  multipleProtocolsShare: Scalars['Boolean'];
  cancelProtocolShare: Scalars['Boolean'];
  resendDocumentEmail: Scalars['Boolean'];
  createContract?: Maybe<Scalars['Long']>;
  completeContract: Scalars['Boolean'];
  changeUserIsActive: Scalars['Boolean'];
  createUser: Scalars['Boolean'];
  updateAdminModelsPrices: Scalars['Boolean'];
  softDeleteAgreement: Scalars['Boolean'];
  sendAgreementForValidation: Scalars['Boolean'];
  completeAgreement?: Maybe<AgreementDetailsDto>;
  cancelAgreement: Scalars['Boolean'];
  validateAgreement: Scalars['Boolean'];
  returnAgreementToSr: Scalars['Boolean'];
  createAgreement: Scalars['Long'];
  updateAgreement: Scalars['Boolean'];
  changeBrandIsActive: Scalars['Boolean'];
  createBrand: Scalars['Boolean'];
  changeProductIsActive: Scalars['Boolean'];
  createProduct: Scalars['Boolean'];
  changeTargetSkuIsActive: Scalars['Boolean'];
  createTargetSku: Scalars['Boolean'];
  changePaymentFrequencyIsActive: Scalars['Boolean'];
  createPaymentFrequency: Scalars['Boolean'];
  changeThirdPartyIsActive: Scalars['Boolean'];
  createThirdParty: Scalars['Boolean'];
  createDistributor: Scalars['Boolean'];
  updateDistributor: Scalars['Boolean'];
  createRegion: Scalars['Boolean'];
  updateRegion: Scalars['Boolean'];
  updateZone: Scalars['Boolean'];
  resendAdditionalDocumentEmail: Scalars['Boolean'];
  setAgreementToStatusDone: Scalars['Boolean'];
  changeDefinitionIsActive: Scalars['Boolean'];
  updateSpendLimit: Scalars['Boolean'];
  createCostCenter: Scalars['Boolean'];
  updateBudgetCenter: Scalars['Boolean'];
};


export type MutationsResetPasswordArgs = {
  input: UserResetPasswordInput;
};


export type MutationsChangePasswordArgs = {
  input: UserChangePasswordInput;
};


export type MutationsSendResetPasswordCodeArgs = {
  input: UserSendResetPasswordCodeInput;
};


export type MutationsVerifyResetPasswordCodeArgs = {
  input: UserVerifyResetPasswordCodeInput;
};


export type MutationsCompleteProtocolArgs = {
  input: CompleteProtocolInput;
};


export type MutationsCancelAssetArgs = {
  assetType: AssetTypeEnum;
  assetId: Scalars['Long'];
};


export type MutationsSingleProtocolShareArgs = {
  input: SingleProtocolShareInput;
};


export type MutationsMultipleProtocolsShareArgs = {
  input: MultipleProtocolsShareInput;
};


export type MutationsCancelProtocolShareArgs = {
  protocolId: Scalars['Long'];
};


export type MutationsResendDocumentEmailArgs = {
  documentId: Scalars['Long'];
  documentType: DocumentTypeEnum;
  assetType?: InputMaybe<AssetTypeEnum>;
};


export type MutationsCreateContractArgs = {
  input: CreateContractInput;
};


export type MutationsCompleteContractArgs = {
  input: CompleteContractInput;
};


export type MutationsChangeUserIsActiveArgs = {
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationsUpdateAdminModelsPricesArgs = {
  inputModels: Array<InputMaybe<AdminMaterialPriceUpdateInput>>;
};


export type MutationsSoftDeleteAgreementArgs = {
  agreementId: Scalars['Long'];
};


export type MutationsSendAgreementForValidationArgs = {
  agreementId: Scalars['Long'];
};


export type MutationsCompleteAgreementArgs = {
  input: CompleteAgreementInput;
};


export type MutationsCancelAgreementArgs = {
  agreementId: Scalars['Long'];
};


export type MutationsValidateAgreementArgs = {
  agreementId: Scalars['Long'];
  validatorSignature: Scalars['String'];
};


export type MutationsReturnAgreementToSrArgs = {
  agreementId: Scalars['Long'];
};


export type MutationsCreateAgreementArgs = {
  input: CreateAgreementInput;
};


export type MutationsUpdateAgreementArgs = {
  input: UpdateAgreementInput;
};


export type MutationsChangeBrandIsActiveArgs = {
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationsChangeProductIsActiveArgs = {
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationsChangeTargetSkuIsActiveArgs = {
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsCreateTargetSkuArgs = {
  input: CreateTargetSkuInput;
};


export type MutationsChangePaymentFrequencyIsActiveArgs = {
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsCreatePaymentFrequencyArgs = {
  input: CreatePaymentFrequencyInput;
};


export type MutationsChangeThirdPartyIsActiveArgs = {
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsCreateThirdPartyArgs = {
  input: CreateThirdPartyInput;
};


export type MutationsCreateDistributorArgs = {
  input: CreateDistributorInput;
};


export type MutationsUpdateDistributorArgs = {
  input: UpdateDistributorInput;
};


export type MutationsCreateRegionArgs = {
  input: CreateRegionInput;
};


export type MutationsUpdateRegionArgs = {
  input: UpdateRegionInput;
};


export type MutationsUpdateZoneArgs = {
  input: UpdateZoneInput;
};


export type MutationsResendAdditionalDocumentEmailArgs = {
  documentId: Scalars['Long'];
};


export type MutationsSetAgreementToStatusDoneArgs = {
  agreementId: Scalars['Long'];
};


export type MutationsChangeDefinitionIsActiveArgs = {
  definitionId: Scalars['Long'];
  isActive: Scalars['Boolean'];
};


export type MutationsUpdateSpendLimitArgs = {
  limit: Scalars['Decimal'];
};


export type MutationsCreateCostCenterArgs = {
  input: CreateBudgetCenterInput;
};


export type MutationsUpdateBudgetCenterArgs = {
  input: UpdateBudgetCenterInput;
};

export enum ErrorMessages {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InsufficientRights = 'INSUFFICIENT_RIGHTS',
  NotEmptyField = 'NOT_EMPTY_FIELD',
  NotValidEmail = 'NOT_VALID_EMAIL',
  DeletionNotAllowed = 'DELETION_NOT_ALLOWED',
  EmailNotSent = 'EMAIL_NOT_SENT',
  SignaturesRequired = 'SIGNATURES_REQUIRED',
  CommentExceedsMaxLength = 'COMMENT_EXCEEDS_MAX_LENGTH',
  ModelPricesUpdateFailed = 'MODEL_PRICES_UPDATE_FAILED',
  PriceMustBePositive = 'PRICE_MUST_BE_POSITIVE',
  RawDataRequestNotValid = 'RAW_DATA_REQUEST_NOT_VALID',
  QuantityMustBePositiveNumber = 'QUANTITY_MUST_BE_POSITIVE_NUMBER',
  StartDateBeforeEndDate = 'START_DATE_BEFORE_END_DATE',
  OnlyDigitsAllowed = 'ONLY_DIGITS_ALLOWED',
  FailedAddingEmailToArchive = 'FAILED_ADDING_EMAIL_TO_ARCHIVE',
  InvalidPasswordResetCode = 'INVALID_PASSWORD_RESET_CODE',
  PasswordExpired = 'PASSWORD_EXPIRED',
  PasswordMustMatchUpperCase = 'PASSWORD_MUST_MATCH_UPPER_CASE',
  PasswordMustMatchLowerCase = 'PASSWORD_MUST_MATCH_LOWER_CASE',
  PasswordMustMatchNumber = 'PASSWORD_MUST_MATCH_NUMBER',
  PasswordMinimumLenght = 'PASSWORD_MINIMUM_LENGHT',
  NewPasswordMustBeDifferent = 'NEW_PASSWORD_MUST_BE_DIFFERENT',
  MaximumAttemptsForResetCode = 'MAXIMUM_ATTEMPTS_FOR_RESET_CODE',
  WrongEmailOrPassword = 'WRONG_EMAIL_OR_PASSWORD',
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
  UserCreationFailed = 'USER_CREATION_FAILED',
  UserRoleNotAllowedToBeCreated = 'USER_ROLE_NOT_ALLOWED_TO_BE_CREATED',
  UserWithThisEmailExists = 'USER_WITH_THIS_EMAIL_EXISTS',
  UserNotFound = 'USER_NOT_FOUND',
  UserValidatorNotFound = 'USER_VALIDATOR_NOT_FOUND',
  UserEmailNotFound = 'USER_EMAIL_NOT_FOUND',
  UserHasInvalidZone = 'USER_HAS_INVALID_ZONE',
  CustomerIdNotFound = 'CUSTOMER_ID_NOT_FOUND',
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  MissingCustomerEmail = 'MISSING_CUSTOMER_EMAIL',
  MissingCustomerDeclaration = 'MISSING_CUSTOMER_DECLARATION',
  DeclarationCannotBeChangedWrongStatus = 'DECLARATION_CANNOT_BE_CHANGED_WRONG_STATUS',
  PocPersonnelNameShouldBeLettersOnly = 'POC_PERSONNEL_NAME_SHOULD_BE_LETTERS_ONLY',
  InvalidPoc = 'INVALID_POC',
  DocumentEmailHistoryNotFound = 'DOCUMENT_EMAIL_HISTORY_NOT_FOUND',
  AssetMustHaveMaterials = 'ASSET_MUST_HAVE_MATERIALS',
  AssetTypeNotValid = 'ASSET_TYPE_NOT_VALID',
  DocumentTypeNotValid = 'DOCUMENT_TYPE_NOT_VALID',
  ProtocolMaterialNotFound = 'PROTOCOL_MATERIAL_NOT_FOUND',
  ProtocolIsCanceled = 'PROTOCOL_IS_CANCELED',
  ProtocolCanBeSharedOnlyToSr = 'PROTOCOL_CAN_BE_SHARED_ONLY_TO_SR',
  OnlyPendingProtocolCanBeShared = 'ONLY_PENDING_PROTOCOL_CAN_BE_SHARED',
  ProtocolNotSharedToCurrentUser = 'PROTOCOL_NOT_SHARED_TO_CURRENT_USER',
  ProtocolNotFound = 'PROTOCOL_NOT_FOUND',
  ContractNotFound = 'CONTRACT_NOT_FOUND',
  ContractIsNotPrepared = 'CONTRACT_IS_NOT_PREPARED',
  ContractModelNotFound = 'CONTRACT_MODEL_NOT_FOUND',
  AgreementEmailHistoryNotFound = 'AGREEMENT_EMAIL_HISTORY_NOT_FOUND',
  AgreementNotFound = 'AGREEMENT_NOT_FOUND',
  AgreementUpdateFailed = 'AGREEMENT_UPDATE_FAILED',
  AgreementValidationFailed = 'AGREEMENT_VALIDATION_FAILED',
  AgreementWasNotSentForValidation = 'AGREEMENT_WAS_NOT_SENT_FOR_VALIDATION',
  AgreementCannotBeSignedWrongStatus = 'AGREEMENT_CANNOT_BE_SIGNED_WRONG_STATUS',
  AdditionalDocumentsCannotBeAddedWrongStatus = 'ADDITIONAL_DOCUMENTS_CANNOT_BE_ADDED_WRONG_STATUS',
  AgreementDoesNotAllowMultipleAdditionalDocuments = 'AGREEMENT_DOES_NOT_ALLOW_MULTIPLE_ADDITIONAL_DOCUMENTS',
  AgreementCannotBeCancelled = 'AGREEMENT_CANNOT_BE_CANCELLED',
  AgreementCannotBeValidated = 'AGREEMENT_CANNOT_BE_VALIDATED',
  NoSpendLimitIsSet = 'NO_SPEND_LIMIT_IS_SET',
  DefinitionDoesNotExist = 'DEFINITION_DOES_NOT_EXIST',
  NotMappedEnumAttributeOptions = 'NOT_MAPPED_ENUM_ATTRIBUTE_OPTIONS',
  AgreementProductNotFound = 'AGREEMENT_PRODUCT_NOT_FOUND',
  AttributeNotFound = 'ATTRIBUTE_NOT_FOUND',
  AgreementInvoiceDataMissing = 'AGREEMENT_INVOICE_DATA_MISSING',
  AgreementBankCertificateMissing = 'AGREEMENT_BANK_CERTIFICATE_MISSING',
  AgreementProtocolScanMissing = 'AGREEMENT_PROTOCOL_SCAN_MISSING',
  AgreementAmountCantBeCalculated = 'AGREEMENT_AMOUNT_CANT_BE_CALCULATED',
  MissingValueForAttribute = 'MISSING_VALUE_FOR_ATTRIBUTE',
  InvalidAttributes = 'INVALID_ATTRIBUTES',
  OnlyDraftAgreementCanBeUpdated = 'ONLY_DRAFT_AGREEMENT_CAN_BE_UPDATED',
  CannotSetAgreementStatusToDone = 'CANNOT_SET_AGREEMENT_STATUS_TO_DONE',
  AgreementsListEmpty = 'AGREEMENTS_LIST_EMPTY',
  FailedCreatingInvoice = 'FAILED_CREATING_INVOICE',
  FailedCreatingBankCertificate = 'FAILED_CREATING_BANK_CERTIFICATE',
  BrandCreationFailed = 'BRAND_CREATION_FAILED',
  BrandNotFound = 'BRAND_NOT_FOUND',
  ActiveBrandWithThisNameExists = 'ACTIVE_BRAND_WITH_THIS_NAME_EXISTS',
  ProductCreationFailed = 'PRODUCT_CREATION_FAILED',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ActiveProductWithThisNameExists = 'ACTIVE_PRODUCT_WITH_THIS_NAME_EXISTS',
  PaymentFrequencyCreationFailed = 'PAYMENT_FREQUENCY_CREATION_FAILED',
  PaymentFrequencyNotFound = 'PAYMENT_FREQUENCY_NOT_FOUND',
  ActivePaymentFrequencyWithThisNameExists = 'ACTIVE_PAYMENT_FREQUENCY_WITH_THIS_NAME_EXISTS',
  TargetSkuCreationFailed = 'TARGET_SKU_CREATION_FAILED',
  TargetSkuNotFound = 'TARGET_SKU_NOT_FOUND',
  ActiveTargetSkuWithThisNameExists = 'ACTIVE_TARGET_SKU_WITH_THIS_NAME_EXISTS',
  ThirdPartyCreationFailed = 'THIRD_PARTY_CREATION_FAILED',
  ThirdPartyNotFound = 'THIRD_PARTY_NOT_FOUND',
  ActiveThirdPartyWithThisNameExists = 'ACTIVE_THIRD_PARTY_WITH_THIS_NAME_EXISTS',
  DistributorCreationFailed = 'DISTRIBUTOR_CREATION_FAILED',
  DistributorNotFound = 'DISTRIBUTOR_NOT_FOUND',
  ActiveDistributorWithThisNameExists = 'ACTIVE_DISTRIBUTOR_WITH_THIS_NAME_EXISTS',
  RegionCreationFailed = 'REGION_CREATION_FAILED',
  RegionNotFound = 'REGION_NOT_FOUND',
  ActiveRegionWithThisNameExists = 'ACTIVE_REGION_WITH_THIS_NAME_EXISTS',
  RegionIdMustBeProvided = 'REGION_ID_MUST_BE_PROVIDED',
  RegionNotValid = 'REGION_NOT_VALID',
  RegionUpdateFailed = 'REGION_UPDATE_FAILED',
  RegionWithThisRcmcAlreadyExists = 'REGION_WITH_THIS_RCMC_ALREADY_EXISTS',
  ZoneIdMustBeProvided = 'ZONE_ID_MUST_BE_PROVIDED',
  ZoneNotFound = 'ZONE_NOT_FOUND',
  ZoneUpdateFailed = 'ZONE_UPDATE_FAILED',
  ZoneWithThisSupervisorAlreadyExists = 'ZONE_WITH_THIS_SUPERVISOR_ALREADY_EXISTS',
  ZoneCannotBeRemoved = 'ZONE_CANNOT_BE_REMOVED',
  ZoneCannotBeAdded = 'ZONE_CANNOT_BE_ADDED',
  ActiveBudgetCenterWithThisNameExists = 'ACTIVE_BUDGET_CENTER_WITH_THIS_NAME_EXISTS',
  BudgetCenterNotFound = 'BUDGET_CENTER_NOT_FOUND'
}

export enum AssetTypeEnum {
  Protocol = 'PROTOCOL',
  Contract = 'CONTRACT'
}

export enum DocumentStatusEnum {
  Prepared = 'PREPARED',
  Signed = 'SIGNED',
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
  ForValidation = 'FOR_VALIDATION',
  Done = 'DONE'
}

export enum ContractedTypeEnum {
  Posm = 'POSM',
  FrdiJumbo = 'FRDI_JUMBO'
}

export enum ProtocolTypeEnum {
  PosmInstall = 'POSM_INSTALL',
  PosmUninstall = 'POSM_UNINSTALL',
  FrdiInstall = 'FRDI_INSTALL',
  FrdiUninstall = 'FRDI_UNINSTALL'
}

export enum UserRoleEnum {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Supervisor = 'SUPERVISOR',
  SalesRepresentative = 'SALES_REPRESENTATIVE',
  Rcmc = 'RCMC'
}

export enum MaterialTypeEnum {
  Fr = 'FR',
  Di = 'DI',
  Jumbo = 'JUMBO',
  Posm = 'POSM'
}

export enum DateFilterTypeEnum {
  DateCreated = 'DATE_CREATED',
  DateSigned = 'DATE_SIGNED'
}

export enum AgreementTypeEnum {
  FridgeSupport = 'FRIDGE_SUPPORT',
  FridgeInvoice = 'FRIDGE_INVOICE',
  DraughtSupport = 'DRAUGHT_SUPPORT',
  DraughtInvoice = 'DRAUGHT_INVOICE',
  Display = 'DISPLAY',
  BrandingDecoration = 'BRANDING_DECORATION',
  MenuInsert = 'MENU_INSERT',
  Maintenance = 'MAINTENANCE',
  Event = 'EVENT',
  FwStock = 'FW_STOCK',
  Ptc = 'PTC'
}

export enum AgreementReasonEnum {
  FreeBeer = 'FREE_BEER',
  ClientService = 'CLIENT_SERVICE',
  ContractorService = 'CONTRACTOR_SERVICE'
}

export enum AgreementSubjectEnum {
  Rent = 'RENT',
  FridgeInstallation = 'FRIDGE_INSTALLATION',
  RentOrPavementRight = 'RENT_OR_PAVEMENT_RIGHT',
  BiggerFridgeReinstall = 'BIGGER_FRIDGE_REINSTALL',
  OwnerFridgeShares = 'OWNER_FRIDGE_SHARES',
  RentPartiallyPaymentOneTime = 'RENT_PARTIALLY_PAYMENT_ONE_TIME',
  XbRebranding = 'XB_REBRANDING',
  TaylorMaidDisplayVisibility = 'TAYLOR_MAID_DISPLAY_VISIBILITY',
  FridgeRackBranding = 'FRIDGE_RACK_BRANDING',
  BonusSchemeXPlus_1Calculator = 'BONUS_SCHEME_X_PLUS_1_CALCULATOR',
  SpecialEventParticipationFee = 'SPECIAL_EVENT_PARTICIPATION_FEE',
  DiInstallation = 'DI_INSTALLATION',
  SpecialPrice = 'SPECIAL_PRICE',
  MaintenanceRebranding = 'MAINTENANCE_REBRANDING',
  PriorityOffer = 'PRIORITY_OFFER',
  DisplayPalletAreaInstallation = 'DISPLAY_PALLET_AREA_INSTALLATION',
  DisplayRebranding = 'DISPLAY_REBRANDING',
  TaylorMaidDisplay = 'TAYLOR_MAID_DISPLAY',
  OuterBrandingManufacture = 'OUTER_BRANDING_MANUFACTURE',
  InnerBrandingManufacture = 'INNER_BRANDING_MANUFACTURE',
  StickerManufacture = 'STICKER_MANUFACTURE',
  DumboUmbrellaManufacture = 'DUMBO_UMBRELLA_MANUFACTURE',
  ForexManufacture = 'FOREX_MANUFACTURE',
  VinylManufacture = 'VINYL_MANUFACTURE',
  AwningFabricManufactureAndInstallation = 'AWNING_FABRIC_MANUFACTURE_AND_INSTALLATION',
  SignRebranding = 'SIGN_REBRANDING',
  XbHeaderManufacture = 'XB_HEADER_MANUFACTURE',
  ScallopsManufacture = 'SCALLOPS_MANUFACTURE',
  TaylorMaidBranding = 'TAYLOR_MAID_BRANDING',
  BeerAndFood = 'BEER_AND_FOOD',
  StaffClothingManufacture = 'STAFF_CLOTHING_MANUFACTURE',
  MenuManufacture = 'MENU_MANUFACTURE',
  CustomMenuInsert = 'CUSTOM_MENU_INSERT',
  FeedingMats = 'FEEDING_MATS',
  MenuStickers = 'MENU_STICKERS',
  BeerAndFoodComboMenu = 'BEER_AND_FOOD_COMBO_MENU',
  LockDevice = 'LOCK_DEVICE',
  GridManufacture = 'GRID_MANUFACTURE',
  XbSecurity = 'XB_SECURITY',
  DiXbCabinetManufacture = 'DI_XB_CABINET_MANUFACTURE',
  IlluminatedFacilitiesRepair = 'ILLUMINATED_FACILITIES_REPAIR',
  AdvertisingFacilitiesInstallation = 'ADVERTISING_FACILITIES_INSTALLATION',
  AdvertisingFacilitiesUninstall = 'ADVERTISING_FACILITIES_UNINSTALL',
  AdvertisingFacilitiesMovement = 'ADVERTISING_FACILITIES_MOVEMENT',
  ElectricalEquipmentPurchase = 'ELECTRICAL_EQUIPMENT_PURCHASE',
  Consumables = 'CONSUMABLES',
  WindowsManufactureRepair = 'WINDOWS_MANUFACTURE_REPAIR',
  AdvertisingFacilitiesInstallationUninstall = 'ADVERTISING_FACILITIES_INSTALLATION_UNINSTALL',
  IlluminatedSignsRepair = 'ILLUMINATED_SIGNS_REPAIR',
  TapXPlus_1 = 'TAP_X_PLUS_1',
  CansXPlus_1 = 'CANS_X_PLUS_1',
  RgbXPlus_1 = 'RGB_X_PLUS_1',
  ObjectStorage = 'OBJECT_STORAGE',
  PriceReduction = 'PRICE_REDUCTION'
}

export enum AttributeTypeEnum {
  Enum = 'ENUM',
  Int = 'INT',
  String = 'STRING',
  Decimal = 'DECIMAL',
  Boolean = 'BOOLEAN'
}

export enum TransmissionProtocolTypeEnum {
  GeneratedServiceProtocol = 'GENERATED_SERVICE_PROTOCOL',
  ScannedServiceProtocol = 'SCANNED_SERVICE_PROTOCOL'
}

export enum AdditionalFormulaEnum {
  ValueFormula = 'VALUE_FORMULA',
  SinglePriceFormula = 'SINGLE_PRICE_FORMULA'
}

export enum ScannedDocumentTypeEnum {
  Declaration = 'DECLARATION',
  Invoice = 'INVOICE',
  BankCertificate = 'BANK_CERTIFICATE'
}

export type Error = {
  __typename?: 'Error';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  extensions?: Maybe<Scalars['Any']>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type UserDtoSortInput = {
  name?: InputMaybe<SortEnumType>;
  role?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type AssetDtoFilterInput = {
  and?: InputMaybe<Array<AssetDtoFilterInput>>;
  or?: InputMaybe<Array<AssetDtoFilterInput>>;
  hermesPocId?: InputMaybe<StringOperationFilterInput>;
  pocName?: InputMaybe<StringOperationFilterInput>;
  customerName?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<DocumentStatusEnumOperationFilterInput>;
  orderIds?: InputMaybe<ListStringOperationFilterInput>;
};

export type AdminAssetDtoSortInput = {
  employee?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<SortEnumType>;
  pocName?: InputMaybe<SortEnumType>;
  assetType?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  contractNumber?: InputMaybe<SortEnumType>;
  pocId?: InputMaybe<SortEnumType>;
  declaration?: InputMaybe<SortEnumType>;
  contracted?: InputMaybe<SortEnumType>;
  contractedQuantity?: InputMaybe<SortEnumType>;
};

export type AdminMaterialDtoSortInput = {
  hermesModelId?: InputMaybe<SortEnumType>;
  modelName?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
};

export type AgreementDtoFilterInput = {
  and?: InputMaybe<Array<AgreementDtoFilterInput>>;
  or?: InputMaybe<Array<AgreementDtoFilterInput>>;
  customerName?: InputMaybe<StringOperationFilterInput>;
  pocName?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<DocumentStatusEnumOperationFilterInput>;
};

export type AttributeModelBaseSortInput = {
  name?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type ProductSortInput = {
  name?: InputMaybe<SortEnumType>;
  price?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type ThirdPartySortInput = {
  name?: InputMaybe<SortEnumType>;
  sapNumber?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type DistributorDtoSortInput = {
  name?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type RegionDtoSortInput = {
  name?: InputMaybe<SortEnumType>;
  rcmcEmail?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type ZoneDtoSortInput = {
  name?: InputMaybe<SortEnumType>;
  supervisorEmail?: InputMaybe<SortEnumType>;
};

export type AgreementDefinitionSortInput = {
  type?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<SortEnumType>;
  subject?: InputMaybe<SortEnumType>;
  validationBy?: InputMaybe<SortEnumType>;
  budgetCenterId?: InputMaybe<SortEnumType>;
  additionalFormula?: InputMaybe<SortEnumType>;
  transmissionProtocolType?: InputMaybe<SortEnumType>;
  includeInLimit?: InputMaybe<SortEnumType>;
  invoiceRequired?: InputMaybe<SortEnumType>;
  bankCertificateRequired?: InputMaybe<SortEnumType>;
  paymentFrequencyRequired?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type BudgetCenterDtoSortInput = {
  name?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
};

export type AdminCommercialAgreementDtoSortInput = {
  caId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  caDate?: InputMaybe<SortEnumType>;
  caFromDate?: InputMaybe<SortEnumType>;
  caToDate?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  subject?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<SortEnumType>;
  pocHermesId?: InputMaybe<SortEnumType>;
  pocName?: InputMaybe<SortEnumType>;
  customerName?: InputMaybe<SortEnumType>;
  salesRepName?: InputMaybe<SortEnumType>;
  zone?: InputMaybe<SortEnumType>;
  ttlCostInvestmentAmount?: InputMaybe<SortEnumType>;
  documentSequenceNo?: InputMaybe<SortEnumType>;
};

export type UserDtoCollectionSegment = {
  __typename?: 'UserDtoCollectionSegment';
  items?: Maybe<Array<Maybe<UserDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Poc = {
  __typename?: 'Poc';
  id: Scalars['Long'];
  pocName: Scalars['String'];
  hermesPocId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Long']>;
};

/** A connection to a list of items. */
export type AssetsConnection = {
  __typename?: 'AssetsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AssetsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<AssetDto>>>;
};

export type ProtocolDto = {
  __typename?: 'ProtocolDto';
  id: Scalars['Long'];
  status: DocumentStatusEnum;
  assetType: AssetTypeEnum;
  customerName: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  customerVat: Scalars['String'];
  customerAddress: Scalars['String'];
  pocName: Scalars['String'];
  hermesPocId: Scalars['String'];
  pocPersonnelName?: Maybe<Scalars['String']>;
  lastModified: Scalars['DateTime'];
  pocAddress: Scalars['String'];
  srCyrillicName: Scalars['String'];
  srEmail: Scalars['String'];
  declaration?: Maybe<ScannedDocumentDto>;
  contracted: Scalars['String'];
  contractedQuantity: Scalars['Int'];
  isShared: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
  protocolType: ProtocolTypeEnum;
  contractNumber?: Maybe<Scalars['String']>;
  materials: Array<ProtocolMaterialDto>;
};

export type EmailHistory = {
  __typename?: 'EmailHistory';
  dateUpdated: Scalars['DateTime'];
  recipientName: Scalars['String'];
  recipientEmail: Scalars['String'];
  cc: Scalars['String'];
  subject: Scalars['String'];
  pdfFileName: Scalars['String'];
};

export enum DocumentTypeEnum {
  Asset = 'ASSET',
  CommercialAgreement = 'COMMERCIAL_AGREEMENT'
}

export type FilterRequestModelInput = {
  pocName?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DocumentStatusEnum>;
};

export type CustomerDto = {
  __typename?: 'CustomerDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  customerAddress?: Maybe<Scalars['String']>;
  customerVat: Scalars['String'];
  agreementSpentAmount: Scalars['Decimal'];
  declaration?: Maybe<ScannedDocumentDto>;
};

export type DocumentsStatusesCount = {
  __typename?: 'DocumentsStatusesCount';
  statuses?: Maybe<Scalars['Any']>;
};

export type UserDto = {
  __typename?: 'UserDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  email: Scalars['String'];
  role: UserRoleEnum;
  isActive: Scalars['Boolean'];
};

export type Model = {
  __typename?: 'Model';
  id: Scalars['Long'];
  type: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Decimal'];
};

export type ContractDto = {
  __typename?: 'ContractDto';
  id: Scalars['Long'];
  status: DocumentStatusEnum;
  assetType: AssetTypeEnum;
  customerName: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  customerVat: Scalars['String'];
  customerStreet: Scalars['String'];
  customerStreetNumber: Scalars['String'];
  customerCity: Scalars['String'];
  customerPersonnelName?: Maybe<Scalars['String']>;
  customerPersonnelRole?: Maybe<Scalars['String']>;
  lastModified: Scalars['DateTime'];
  srEmail: Scalars['String'];
  srCyrillicName: Scalars['String'];
  declarationGuid?: Maybe<Scalars['String']>;
  contracted: Scalars['String'];
  contractedQuantity: Scalars['Int'];
  contractNumber?: Maybe<Scalars['String']>;
  signaturesCount: Scalars['Int'];
  models: Array<ContractModelDto>;
};

export type AdminAssetDtoCollectionSegment = {
  __typename?: 'AdminAssetDtoCollectionSegment';
  items?: Maybe<Array<Maybe<AdminAssetDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AdminAssetFilterRequestModelInput = {
  assetType?: InputMaybe<AssetTypeEnum>;
  status?: InputMaybe<DocumentStatusEnum>;
  customer?: InputMaybe<Scalars['String']>;
  pocId?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  materialType?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type AdminMaterialDtoCollectionSegment = {
  __typename?: 'AdminMaterialDtoCollectionSegment';
  items?: Maybe<Array<Maybe<AdminMaterialDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AssetRawDataReportModelCollectionSegment = {
  __typename?: 'AssetRawDataReportModelCollectionSegment';
  items?: Maybe<Array<Maybe<AssetRawDataReportModel>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AssetsRawDataReportRequestModelInput = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  filterBy: DateFilterTypeEnum;
};

/** A connection to a list of items. */
export type AgreementsConnection = {
  __typename?: 'AgreementsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AgreementsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<AgreementDto>>>;
  totalCount: Scalars['Int'];
};

export type AgreementDetailsDto = {
  __typename?: 'AgreementDetailsDto';
  agreementId: Scalars['Long'];
  agreementDefinitionId: Scalars['Long'];
  customerName: Scalars['String'];
  pocName: Scalars['String'];
  hermesPocId: Scalars['String'];
  agreementNumber: Scalars['String'];
  investmentAmount: Scalars['Decimal'];
  globalCustomerLimit: Scalars['Decimal'];
  customerAgreementsSpentAmount: Scalars['Decimal'];
  paymentFrequency: Scalars['String'];
  declaration?: Maybe<ScannedDocumentDto>;
  customerEmail?: Maybe<Scalars['String']>;
  pocEmployee?: Maybe<Scalars['String']>;
  status: DocumentStatusEnum;
  type: AgreementTypeEnum;
  reason: AgreementReasonEnum;
  subject: AgreementSubjectEnum;
  agreementDate: Scalars['DateTime'];
  period: Scalars['String'];
  attributeValues: Array<AgreementAttributeValueDto>;
  additionalDocuments?: Maybe<Array<Maybe<AgreementAdditionalDocumentDto>>>;
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  budgetCenter: Scalars['String'];
  costCenter: Scalars['String'];
  customerVat: Scalars['String'];
  customerCity: Scalars['String'];
  customerAddress: Scalars['String'];
  pocAddress: Scalars['String'];
  pocCity: Scalars['String'];
  pocZone: Scalars['String'];
  pocRegion: Scalars['String'];
  pocChannel: Scalars['String'];
  srName: Scalars['String'];
  validatorName?: Maybe<Scalars['String']>;
  validatorSignature?: Maybe<Scalars['String']>;
  invoiceRequired: Scalars['Boolean'];
  bankCertificateRequired: Scalars['Boolean'];
  transmissionProtocolType: TransmissionProtocolTypeEnum;
  freeBeerQuantity?: Maybe<Scalars['Int']>;
  invoicesAmount?: Maybe<Scalars['Decimal']>;
  bankCertificate?: Maybe<ScannedDocumentDto>;
  multipleAdditionalDocumentsAllowed?: Maybe<Scalars['Boolean']>;
};

export type AgreementTypeDto = {
  __typename?: 'AgreementTypeDto';
  type: AgreementTypeEnum;
  subjects: Array<Maybe<AgreementSubjectDto>>;
};

export type AgreementDefinitionDto = {
  __typename?: 'AgreementDefinitionDto';
  id: Scalars['Long'];
  type: AgreementTypeEnum;
  reason: AgreementReasonEnum;
  subject: AgreementSubjectEnum;
  attributes: Array<Maybe<AgreementDefinitionAttributeDto>>;
};

export type BrandCollectionSegment = {
  __typename?: 'BrandCollectionSegment';
  items?: Maybe<Array<Maybe<Brand>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ProductCollectionSegment = {
  __typename?: 'ProductCollectionSegment';
  items?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type PaymentFrequencyCollectionSegment = {
  __typename?: 'PaymentFrequencyCollectionSegment';
  items?: Maybe<Array<Maybe<PaymentFrequency>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type TargetSkuCollectionSegment = {
  __typename?: 'TargetSkuCollectionSegment';
  items?: Maybe<Array<Maybe<TargetSku>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ThirdPartyCollectionSegment = {
  __typename?: 'ThirdPartyCollectionSegment';
  items?: Maybe<Array<Maybe<ThirdParty>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DistributorDtoCollectionSegment = {
  __typename?: 'DistributorDtoCollectionSegment';
  items?: Maybe<Array<Maybe<DistributorDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type RegionDtoCollectionSegment = {
  __typename?: 'RegionDtoCollectionSegment';
  items?: Maybe<Array<Maybe<RegionDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneDtoCollectionSegment = {
  __typename?: 'ZoneDtoCollectionSegment';
  items?: Maybe<Array<Maybe<ZoneDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneDto = {
  __typename?: 'ZoneDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  supervisorId?: Maybe<Scalars['Long']>;
  supervisorEmail?: Maybe<Scalars['String']>;
};

export type AgreementDefinitionCollectionSegment = {
  __typename?: 'AgreementDefinitionCollectionSegment';
  items?: Maybe<Array<Maybe<AgreementDefinition>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AgreementDefinitionsFilterRequestModelInput = {
  type?: InputMaybe<AgreementTypeEnum>;
  reason?: InputMaybe<AgreementReasonEnum>;
  subject?: InputMaybe<AgreementSubjectEnum>;
  validationBy?: InputMaybe<UserRoleEnum>;
  budgetCenterId?: InputMaybe<Scalars['Long']>;
  additionalFormula?: InputMaybe<AdditionalFormulaEnum>;
  transmissionProtocolType?: InputMaybe<TransmissionProtocolTypeEnum>;
  includeInLimit?: InputMaybe<Scalars['Boolean']>;
  invoiceRequired?: InputMaybe<Scalars['Boolean']>;
  bankCertificateRequired?: InputMaybe<Scalars['Boolean']>;
  paymentFrequencyRequired?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type BudgetCenterDtoCollectionSegment = {
  __typename?: 'BudgetCenterDtoCollectionSegment';
  items?: Maybe<Array<Maybe<BudgetCenterDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AdminCommercialAgreementDtoCollectionSegment = {
  __typename?: 'AdminCommercialAgreementDtoCollectionSegment';
  items?: Maybe<Array<Maybe<AdminCommercialAgreementDto>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AdminCommercialAgreementFilterRequestModelInput = {
  caFromDate?: InputMaybe<Scalars['DateTime']>;
  caToDate?: InputMaybe<Scalars['DateTime']>;
  caStatus?: InputMaybe<DocumentStatusEnum>;
  caZone?: InputMaybe<Scalars['String']>;
  salesRepName?: InputMaybe<Scalars['String']>;
  pocHermesId?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AgreementTypeEnum>;
  reason?: InputMaybe<AgreementReasonEnum>;
  subject?: InputMaybe<AgreementSubjectEnum>;
};

export type UserResetPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordCode: Scalars['String'];
};

export type UserChangePasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UserSendResetPasswordCodeInput = {
  email: Scalars['String'];
};

export type UserVerifyResetPasswordCodeInput = {
  email: Scalars['String'];
  resetPasswordCode: Scalars['String'];
};

export type CompleteProtocolInput = {
  protocolId: Scalars['Long'];
  customerEmail?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  pocPersonnelName: Scalars['String'];
  srSignature: Scalars['String'];
  pocPersonnelSignature: Scalars['String'];
  protocolMaterials?: InputMaybe<Array<InputMaybe<UpdateProtocolMaterialsInput>>>;
};

export type SingleProtocolShareInput = {
  protocolId: Scalars['Long'];
  srEmail: Scalars['String'];
};

export type MultipleProtocolsShareInput = {
  protocolIds: Array<Scalars['Long']>;
  srEmail: Scalars['String'];
};

export type CreateContractInput = {
  customerId: Scalars['Long'];
  models?: InputMaybe<Array<InputMaybe<CreateContractModelInput>>>;
};

export type CompleteContractInput = {
  contractId: Scalars['Long'];
  customerEmail?: InputMaybe<Scalars['String']>;
  customerPersonnelName: Scalars['String'];
  customerPersonnelRole: Scalars['String'];
  customerPersonnelSignatures: Array<Scalars['String']>;
  srSignatures: Array<Scalars['String']>;
  models?: InputMaybe<Array<InputMaybe<UpdateContractModelInput>>>;
};

export type CreateUserInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  role: UserRoleEnum;
  zoneId?: InputMaybe<Scalars['Long']>;
  regionId?: InputMaybe<Scalars['Long']>;
  isActive: Scalars['Boolean'];
};

export type AdminMaterialPriceUpdateInput = {
  modelId: Scalars['Long'];
  price: Scalars['Decimal'];
};

export type CompleteAgreementInput = {
  agreementId: Scalars['Long'];
  customerEmail?: InputMaybe<Scalars['String']>;
  pocPersonnelName: Scalars['String'];
  srSignature: Scalars['String'];
  pocPersonnelSignature: Scalars['String'];
  srSignatureProtocol?: InputMaybe<Scalars['String']>;
  pocEmployeeSignatureProtocol?: InputMaybe<Scalars['String']>;
};

export type CreateAgreementInput = {
  pocId: Scalars['Long'];
  agreementDefinitionId: Scalars['Long'];
  agreementDate: Scalars['DateTime'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  attributeValues?: InputMaybe<Array<InputMaybe<AttributeValuesInput>>>;
};

export type UpdateAgreementInput = {
  id: Scalars['Long'];
  agreementDefinitionId?: InputMaybe<Scalars['Long']>;
  agreementDate?: InputMaybe<Scalars['DateTime']>;
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  attributeValues?: InputMaybe<Array<InputMaybe<AttributeValuesInput>>>;
};

export type CreateBrandInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CreateProductInput = {
  name: Scalars['String'];
  price: Scalars['Decimal'];
  isActive: Scalars['Boolean'];
};

export type CreateTargetSkuInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CreatePaymentFrequencyInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CreateThirdPartyInput = {
  name: Scalars['String'];
  sapNumber: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CreateDistributorInput = {
  name: Scalars['String'];
  zones?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  isActive: Scalars['Boolean'];
};

export type UpdateDistributorInput = {
  distributorId: Scalars['Long'];
  name?: InputMaybe<Scalars['String']>;
  zones?: InputMaybe<Array<Scalars['Long']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type CreateRegionInput = {
  name: Scalars['String'];
  zones?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  costCenter: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateRegionInput = {
  regionId: Scalars['Long'];
  name?: InputMaybe<Scalars['String']>;
  zones?: InputMaybe<Array<Scalars['Long']>>;
  rcmcId?: InputMaybe<Scalars['Long']>;
  costCenter?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateZoneInput = {
  zoneId: Scalars['Long'];
  supervisorId?: InputMaybe<Scalars['Long']>;
};

export type CreateBudgetCenterInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type UpdateBudgetCenterInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Long'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  eq?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  contains?: InputMaybe<Scalars['String']>;
  ncontains?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
};

export type DocumentStatusEnumOperationFilterInput = {
  eq?: InputMaybe<DocumentStatusEnum>;
  neq?: InputMaybe<DocumentStatusEnum>;
  in?: InputMaybe<Array<DocumentStatusEnum>>;
  nin?: InputMaybe<Array<DocumentStatusEnum>>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type AssetDto = {
  __typename?: 'AssetDto';
  assetId: Scalars['Long'];
  status: DocumentStatusEnum;
  assetType: AssetTypeEnum;
  customerId: Scalars['Long'];
  customerName: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  pocPersonnelName?: Maybe<Scalars['String']>;
  pocName?: Maybe<Scalars['String']>;
  hermesPocId?: Maybe<Scalars['String']>;
  declarationScanned: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  contractNumber?: Maybe<Scalars['String']>;
  contracted: Scalars['String'];
  contractedQuantity: Scalars['Int'];
  protocolType?: Maybe<ProtocolTypeEnum>;
  orderIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isShared: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type AssetsEdge = {
  __typename?: 'AssetsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AssetDto>;
};

export type ScannedDocumentDto = {
  __typename?: 'ScannedDocumentDto';
  scannedDocumentType?: Maybe<ScannedDocumentTypeEnum>;
  scannedDocumentName?: Maybe<Scalars['String']>;
};

export type ProtocolMaterialDto = {
  __typename?: 'ProtocolMaterialDto';
  id: Scalars['Long'];
  orderId: Scalars['String'];
  quantity: Scalars['Int'];
  name: Scalars['String'];
  brand: Scalars['String'];
  type: Scalars['String'];
  materialSn?: Maybe<Scalars['String']>;
};

export type ContractModelDto = {
  __typename?: 'ContractModelDto';
  id: Scalars['Long'];
  type: Scalars['String'];
  model: Scalars['String'];
  price: Scalars['Decimal'];
  quantity: Scalars['Int'];
};

export type AdminAssetDto = {
  __typename?: 'AdminAssetDto';
  assetId: Scalars['Long'];
  customer: Scalars['String'];
  pocName?: Maybe<Scalars['String']>;
  contractNumber?: Maybe<Scalars['String']>;
  pocId?: Maybe<Scalars['String']>;
  declaration: Scalars['String'];
  contracted: Scalars['String'];
  contractedQuantity: Scalars['Int'];
  assetType: AssetTypeEnum;
  status: Scalars['String'];
  employee: Scalars['String'];
  materialType: Scalars['String'];
  dateSigned?: Maybe<Scalars['DateTime']>;
};

export type AdminMaterialDto = {
  __typename?: 'AdminMaterialDto';
  modelId: Scalars['Long'];
  hermesModelId: Scalars['Int'];
  modelName: Scalars['String'];
  type: Scalars['String'];
  price?: Maybe<Scalars['Decimal']>;
};

export type AssetRawDataReportModel = {
  __typename?: 'AssetRawDataReportModel';
  assetNo: Scalars['Long'];
  contractNo?: Maybe<Scalars['String']>;
  assetType: AssetTypeEnum;
  status: DocumentStatusEnum;
  dateSigned?: Maybe<Scalars['DateTime']>;
  dateCreated?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  customerName: Scalars['String'];
  pocId: Scalars['String'];
  pocName: Scalars['String'];
  employeeName: Scalars['String'];
  deliveryType?: Maybe<Scalars['String']>;
  materialType?: Maybe<Scalars['String']>;
  materialModel: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  serialNo?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type AgreementDto = {
  __typename?: 'AgreementDto';
  agreementId: Scalars['Long'];
  pocName: Scalars['String'];
  status: DocumentStatusEnum;
  amount: Scalars['Decimal'];
  type: AgreementTypeEnum;
  reason: AgreementReasonEnum;
  subject: AgreementSubjectEnum;
  customerName: Scalars['String'];
  number: Scalars['String'];
  pocPersonnelName?: Maybe<Scalars['String']>;
  hermesPocId?: Maybe<Scalars['String']>;
  declarationScanned: Scalars['Boolean'];
  date: Scalars['DateTime'];
};

/** An edge in a connection. */
export type AgreementsEdge = {
  __typename?: 'AgreementsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgreementDto>;
};

export type AgreementAttributeValueDto = {
  __typename?: 'AgreementAttributeValueDto';
  attributeId: Scalars['Long'];
  type: AttributeTypeEnum;
  label: Scalars['String'];
  intValue?: Maybe<Scalars['Int']>;
  stringValue?: Maybe<Scalars['String']>;
  decimalValue?: Maybe<Scalars['Decimal']>;
  boolValue?: Maybe<Scalars['Boolean']>;
  enumValue?: Maybe<AttributeEnumValueDto>;
  order: Scalars['Int'];
  requiresCurrency?: Maybe<Scalars['Boolean']>;
};

export type AgreementAdditionalDocumentDto = {
  __typename?: 'AgreementAdditionalDocumentDto';
  additionalDocumentId: Scalars['Long'];
  pdfName: Scalars['String'];
  dateCreated: Scalars['DateTime'];
};

export type AgreementSubjectDto = {
  __typename?: 'AgreementSubjectDto';
  subject: AgreementSubjectEnum;
  reasons: Array<Maybe<AgreementReasonDto>>;
};

export type AgreementDefinitionAttributeDto = {
  __typename?: 'AgreementDefinitionAttributeDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  type: AttributeTypeEnum;
  order: Scalars['Int'];
  values?: Maybe<Array<Maybe<AttributeEnumValueDto>>>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['Long'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['Long'];
  name: Scalars['String'];
  price: Scalars['Decimal'];
  isActive: Scalars['Boolean'];
};

export type PaymentFrequency = {
  __typename?: 'PaymentFrequency';
  id: Scalars['Long'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type TargetSku = {
  __typename?: 'TargetSku';
  id: Scalars['Long'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type ThirdParty = {
  __typename?: 'ThirdParty';
  id: Scalars['Long'];
  name: Scalars['String'];
  sapNumber: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type DistributorDto = {
  __typename?: 'DistributorDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  zones: Array<ZoneDto>;
  isActive: Scalars['Boolean'];
};

export type RegionDto = {
  __typename?: 'RegionDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  zones: Array<ZoneDto>;
  rcmcId?: Maybe<Scalars['Long']>;
  rcmcEmail?: Maybe<Scalars['String']>;
  costCenter: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type AgreementDefinition = {
  __typename?: 'AgreementDefinition';
  id: Scalars['Long'];
  type: AgreementTypeEnum;
  reason: AgreementReasonEnum;
  subject: AgreementSubjectEnum;
  validationBy: UserRoleEnum;
  budgetCenterId: Scalars['Long'];
  additionalFormula: AdditionalFormulaEnum;
  transmissionProtocolType: TransmissionProtocolTypeEnum;
  includeInLimit: Scalars['Boolean'];
  invoiceRequired: Scalars['Boolean'];
  bankCertificateRequired: Scalars['Boolean'];
  paymentFrequencyRequired: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  attributes: Array<Maybe<AgreementDefinitionAttribute>>;
};

export type BudgetCenterDto = {
  __typename?: 'BudgetCenterDto';
  id: Scalars['Long'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type AdminCommercialAgreementDto = {
  __typename?: 'AdminCommercialAgreementDto';
  status: DocumentStatusEnum;
  caDate: Scalars['DateTime'];
  caFromDate: Scalars['DateTime'];
  caToDate: Scalars['DateTime'];
  type: AgreementTypeEnum;
  subject: AgreementSubjectEnum;
  reason: AgreementReasonEnum;
  pocHermesId: Scalars['String'];
  pocName: Scalars['String'];
  customerName: Scalars['String'];
  salesRepName: Scalars['String'];
  zone: Scalars['String'];
  ttlCostInvestmentAmount: Scalars['Decimal'];
  documentSequenceNo: Scalars['Int'];
  caId: Scalars['Long'];
  caName?: Maybe<Scalars['String']>;
  protocolName?: Maybe<Scalars['String']>;
  invoice?: Maybe<ScannedDocumentDto>;
  bankCertificate?: Maybe<ScannedDocumentDto>;
};

export type UpdateProtocolMaterialsInput = {
  protocolMaterialId: Scalars['Long'];
  quantity: Scalars['Int'];
};

export type CreateContractModelInput = {
  modelId: Scalars['Long'];
  quantity: Scalars['Int'];
};

export type UpdateContractModelInput = {
  contractModelId: Scalars['Long'];
  quantity: Scalars['Int'];
};

export type AttributeValuesInput = {
  attributeId: Scalars['Long'];
  type: AttributeTypeEnum;
  intValue?: InputMaybe<Scalars['Int']>;
  decimalValue?: InputMaybe<Scalars['Decimal']>;
  stringValue?: InputMaybe<Scalars['String']>;
  boolValue?: InputMaybe<Scalars['Boolean']>;
  enumValue?: InputMaybe<Scalars['Long']>;
};

export type AttributeEnumValueDto = {
  __typename?: 'AttributeEnumValueDto';
  enumId: Scalars['Long'];
  value: Scalars['String'];
};

export type AgreementReasonDto = {
  __typename?: 'AgreementReasonDto';
  reason: AgreementReasonEnum;
};

export type AgreementDefinitionAttribute = {
  __typename?: 'AgreementDefinitionAttribute';
  attributeId: Scalars['Long'];
};

export type CancelAgreementMutationVariables = Exact<{
  agreementId: Scalars['Long'];
}>;


export type CancelAgreementMutation = { __typename?: 'mutations', cancelAgreement: boolean };

export type CancelAssetMutationVariables = Exact<{
  assetType: AssetTypeEnum;
  assetId: Scalars['Long'];
}>;


export type CancelAssetMutation = { __typename?: 'mutations', cancelAsset: boolean };

export type CancelShareProtocolMutationVariables = Exact<{
  input: Scalars['Long'];
}>;


export type CancelShareProtocolMutation = { __typename?: 'mutations', cancelProtocolShare: boolean };

export type ChangeBrandIsActiveMutationVariables = Exact<{
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangeBrandIsActiveMutation = { __typename?: 'mutations', changeBrandIsActive: boolean };

export type ChangePasswordMutationVariables = Exact<{
  input: UserChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'mutations', changePassword: boolean };

export type ChangeProductIsActiveMutationVariables = Exact<{
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangeProductIsActiveMutation = { __typename?: 'mutations', changeProductIsActive: boolean };

export type ChangeUserIsActiveMutationVariables = Exact<{
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangeUserIsActiveMutation = { __typename?: 'mutations', changeUserIsActive: boolean };

export type ChangeTargetSkuIsActiveMutationVariables = Exact<{
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangeTargetSkuIsActiveMutation = { __typename?: 'mutations', changeTargetSkuIsActive: boolean };

export type CreateTargetSkuMutationVariables = Exact<{
  input: CreateTargetSkuInput;
}>;


export type CreateTargetSkuMutation = { __typename?: 'mutations', createTargetSku: boolean };

export type ChangeThirdPartyIsActiveMutationVariables = Exact<{
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangeThirdPartyIsActiveMutation = { __typename?: 'mutations', changeThirdPartyIsActive: boolean };

export type CreateThirdPartyMutationVariables = Exact<{
  input: CreateThirdPartyInput;
}>;


export type CreateThirdPartyMutation = { __typename?: 'mutations', createThirdParty: boolean };

export type CreatePaymentFrequencyMutationVariables = Exact<{
  input: CreatePaymentFrequencyInput;
}>;


export type CreatePaymentFrequencyMutation = { __typename?: 'mutations', createPaymentFrequency: boolean };

export type ChangePaymentFrequencyIsActiveMutationVariables = Exact<{
  id: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangePaymentFrequencyIsActiveMutation = { __typename?: 'mutations', changePaymentFrequencyIsActive: boolean };

export type UpdateZoneMutationVariables = Exact<{
  input: UpdateZoneInput;
}>;


export type UpdateZoneMutation = { __typename?: 'mutations', updateZone: boolean };

export type CreateRegionMutationVariables = Exact<{
  input: CreateRegionInput;
}>;


export type CreateRegionMutation = { __typename?: 'mutations', createRegion: boolean };

export type UpdateRegionMutationVariables = Exact<{
  input: UpdateRegionInput;
}>;


export type UpdateRegionMutation = { __typename?: 'mutations', updateRegion: boolean };

export type UpdateDistributorMutationVariables = Exact<{
  input: UpdateDistributorInput;
}>;


export type UpdateDistributorMutation = { __typename?: 'mutations', updateDistributor: boolean };

export type CreateDistributorMutationVariables = Exact<{
  input: CreateDistributorInput;
}>;


export type CreateDistributorMutation = { __typename?: 'mutations', createDistributor: boolean };

export type UpdateSpendLimitMutationVariables = Exact<{
  limit: Scalars['Decimal'];
}>;


export type UpdateSpendLimitMutation = { __typename?: 'mutations', updateSpendLimit: boolean };

export type ChangeAdminDefinitionIsActiveMutationVariables = Exact<{
  definitionId: Scalars['Long'];
  isActive: Scalars['Boolean'];
}>;


export type ChangeAdminDefinitionIsActiveMutation = { __typename?: 'mutations', changeDefinitionIsActive: boolean };

export type CompleteAgreementMutationVariables = Exact<{
  input: CompleteAgreementInput;
}>;


export type CompleteAgreementMutation = { __typename?: 'mutations', completeAgreement?: { __typename?: 'AgreementDetailsDto', agreementId: any } | null };

export type CompleteContractMutationVariables = Exact<{
  input: CompleteContractInput;
}>;


export type CompleteContractMutation = { __typename?: 'mutations', completeContract: boolean };

export type CompleteProtocolMutationVariables = Exact<{
  input: CompleteProtocolInput;
}>;


export type CompleteProtocolMutation = { __typename?: 'mutations', completeProtocol?: { __typename?: 'ProtocolDto', id: any } | null };

export type CreateAgreementMutationVariables = Exact<{
  input: CreateAgreementInput;
}>;


export type CreateAgreementMutation = { __typename?: 'mutations', createAgreement: any };

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = { __typename?: 'mutations', createBrand: boolean };

export type CreateContractMutationVariables = Exact<{
  input: CreateContractInput;
}>;


export type CreateContractMutation = { __typename?: 'mutations', createContract?: any | null };

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'mutations', createProduct: boolean };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'mutations', createUser: boolean };

export type ResendAdditionalDocumentEmailMutationVariables = Exact<{
  documentId: Scalars['Long'];
}>;


export type ResendAdditionalDocumentEmailMutation = { __typename?: 'mutations', resendAdditionalDocumentEmail: boolean };

export type ResendDocumentEmailMutationVariables = Exact<{
  documentId: Scalars['Long'];
  documentType: DocumentTypeEnum;
}>;


export type ResendDocumentEmailMutation = { __typename?: 'mutations', resendDocumentEmail: boolean };

export type ResetPasswordMutationVariables = Exact<{
  input: UserResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'mutations', resetPassword: boolean };

export type ReturnAgreementToSrMutationVariables = Exact<{
  agreementId: Scalars['Long'];
}>;


export type ReturnAgreementToSrMutation = { __typename?: 'mutations', returnAgreementToSr: boolean };

export type SendAgreementForValidationMutationVariables = Exact<{
  agreementId: Scalars['Long'];
}>;


export type SendAgreementForValidationMutation = { __typename?: 'mutations', sendAgreementForValidation: boolean };

export type SendUserResetPasswordMutationVariables = Exact<{
  input: UserSendResetPasswordCodeInput;
}>;


export type SendUserResetPasswordMutation = { __typename?: 'mutations', sendResetPasswordCode: boolean };

export type SetAgreementToStatusDoneMutationVariables = Exact<{
  agreementId: Scalars['Long'];
}>;


export type SetAgreementToStatusDoneMutation = { __typename?: 'mutations', setAgreementToStatusDone: boolean };

export type ShareProtocolsMutationVariables = Exact<{
  input: MultipleProtocolsShareInput;
}>;


export type ShareProtocolsMutation = { __typename?: 'mutations', multipleProtocolsShare: boolean };

export type SoftDeleteAgreementMutationVariables = Exact<{
  agreementId: Scalars['Long'];
}>;


export type SoftDeleteAgreementMutation = { __typename?: 'mutations', softDeleteAgreement: boolean };

export type UpdateAdminModelsPricesMutationVariables = Exact<{
  inputModels: Array<AdminMaterialPriceUpdateInput> | AdminMaterialPriceUpdateInput;
}>;


export type UpdateAdminModelsPricesMutation = { __typename?: 'mutations', updateAdminModelsPrices: boolean };

export type UpdateAgreementMutationVariables = Exact<{
  input: UpdateAgreementInput;
}>;


export type UpdateAgreementMutation = { __typename?: 'mutations', updateAgreement: boolean };

export type ValidateAgreementMutationVariables = Exact<{
  agreementId: Scalars['Long'];
  validatorSignature: Scalars['String'];
}>;


export type ValidateAgreementMutation = { __typename?: 'mutations', validateAgreement: boolean };

export type VerifyResetPasswordCodeMutationVariables = Exact<{
  input: UserVerifyResetPasswordCodeInput;
}>;


export type VerifyResetPasswordCodeMutation = { __typename?: 'mutations', verifyResetPasswordCode: boolean };

export type GetTargetSkusQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AttributeModelBaseSortInput> | AttributeModelBaseSortInput>;
}>;


export type GetTargetSkusQuery = { __typename?: 'queries', targetSkus?: { __typename?: 'TargetSkuCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'TargetSku', id: any, name: string, isActive: boolean } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetBrandsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AttributeModelBaseSortInput> | AttributeModelBaseSortInput>;
}>;


export type GetBrandsQuery = { __typename?: 'queries', brands?: { __typename?: 'BrandCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Brand', id: any, name: string, isActive: boolean } | null> | null } | null };

export type GetProductsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ProductSortInput> | ProductSortInput>;
}>;


export type GetProductsQuery = { __typename?: 'queries', products?: { __typename?: 'ProductCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Product', id: any, name: string, price: any, isActive: boolean } | null> | null } | null };

export type GetThirdPartiesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ThirdPartySortInput> | ThirdPartySortInput>;
}>;


export type GetThirdPartiesQuery = { __typename?: 'queries', thirdParties?: { __typename?: 'ThirdPartyCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'ThirdParty', id: any, name: string, isActive: boolean, sapNumber: string } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetPaymentFrequenciesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AttributeModelBaseSortInput> | AttributeModelBaseSortInput>;
}>;


export type GetPaymentFrequenciesQuery = { __typename?: 'queries', paymentFrequencies?: { __typename?: 'PaymentFrequencyCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'PaymentFrequency', id: any, name: string, isActive: boolean } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetZonesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ZoneDtoSortInput> | ZoneDtoSortInput>;
}>;


export type GetZonesQuery = { __typename?: 'queries', zones?: { __typename?: 'ZoneDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'ZoneDto', id: any, name: string, supervisorEmail?: string | null, supervisorId?: any | null } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetZonesWithSupervisorsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetZonesWithSupervisorsQuery = { __typename?: 'queries', zonesWithSupervisors: Array<{ __typename?: 'ZoneDto', id: any, name: string, supervisorEmail?: string | null, supervisorId?: any | null }> };

export type GetRegionsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<RegionDtoSortInput> | RegionDtoSortInput>;
}>;


export type GetRegionsQuery = { __typename?: 'queries', regions?: { __typename?: 'RegionDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'RegionDto', id: any, isActive: boolean, name: string, rcmcEmail?: string | null, rcmcId?: any | null, costCenter: string, zones: Array<{ __typename?: 'ZoneDto', id: any, name: string }> } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetDistributorsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<DistributorDtoSortInput> | DistributorDtoSortInput>;
}>;


export type GetDistributorsQuery = { __typename?: 'queries', distributors?: { __typename?: 'DistributorDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DistributorDto', id: any, isActive: boolean, name: string, zones: Array<{ __typename?: 'ZoneDto', id: any, name: string }> } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetAgreementSpendLimitQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAgreementSpendLimitQuery = { __typename?: 'queries', agreementSpendLimit: any };

export type GetAdminDefinitionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AgreementDefinitionSortInput> | AgreementDefinitionSortInput>;
  request: AgreementDefinitionsFilterRequestModelInput;
}>;


export type GetAdminDefinitionsQuery = { __typename?: 'queries', adminAgreementDefinitions?: { __typename?: 'AgreementDefinitionCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AgreementDefinition', additionalFormula: AdditionalFormulaEnum, bankCertificateRequired: boolean, id: any, includeInLimit: boolean, invoiceRequired: boolean, isActive: boolean, paymentFrequencyRequired: boolean, reason: AgreementReasonEnum, subject: AgreementSubjectEnum, transmissionProtocolType: TransmissionProtocolTypeEnum, type: AgreementTypeEnum, validationBy: UserRoleEnum } | null> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type GetAdminAssetStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminAssetStatusesQuery = { __typename?: 'queries', adminAssetStatuses: Array<DocumentStatusEnum> };

export type GetAdminAssetsQueryVariables = Exact<{
  input: AdminAssetFilterRequestModelInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AdminAssetDtoSortInput> | AdminAssetDtoSortInput>;
}>;


export type GetAdminAssetsQuery = { __typename?: 'queries', adminAssets?: { __typename?: 'AdminAssetDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AdminAssetDto', assetId: any, customer: string, pocName?: string | null, contractNumber?: string | null, pocId?: string | null, declaration: string, contracted: string, contractedQuantity: number, assetType: AssetTypeEnum, status: string, employee: string, materialType: string, dateSigned?: any | null } | null> | null } | null };

export type GetAdminCommercialAgreementsDocumentQueryVariables = Exact<{
  input: AdminCommercialAgreementFilterRequestModelInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AdminCommercialAgreementDtoSortInput> | AdminCommercialAgreementDtoSortInput>;
}>;


export type GetAdminCommercialAgreementsDocumentQuery = { __typename?: 'queries', adminCommercialAgreements?: { __typename?: 'AdminCommercialAgreementDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AdminCommercialAgreementDto', caDate: any, caFromDate: any, caId: any, caName?: string | null, caToDate: any, customerName: string, documentSequenceNo: number, pocHermesId: string, pocName: string, protocolName?: string | null, reason: AgreementReasonEnum, salesRepName: string, status: DocumentStatusEnum, subject: AgreementSubjectEnum, ttlCostInvestmentAmount: any, type: AgreementTypeEnum, zone: string, invoice?: { __typename?: 'ScannedDocumentDto', scannedDocumentType?: ScannedDocumentTypeEnum | null, scannedDocumentName?: string | null } | null, bankCertificate?: { __typename?: 'ScannedDocumentDto', scannedDocumentType?: ScannedDocumentTypeEnum | null, scannedDocumentName?: string | null } | null } | null> | null } | null };

export type GetAdminMaterialsQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  order?: InputMaybe<Array<AdminMaterialDtoSortInput> | AdminMaterialDtoSortInput>;
}>;


export type GetAdminMaterialsQuery = { __typename?: 'queries', adminMaterials?: { __typename?: 'AdminMaterialDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AdminMaterialDto', modelId: any, hermesModelId: number, modelName: string, type: string, price?: any | null } | null> | null } | null };

export type GetAgreementDefinitionQueryVariables = Exact<{
  type: AgreementTypeEnum;
  subject: AgreementSubjectEnum;
  reason: AgreementReasonEnum;
}>;


export type GetAgreementDefinitionQuery = { __typename?: 'queries', agreementDefinition: { __typename?: 'AgreementDefinitionDto', id: any, attributes: Array<{ __typename?: 'AgreementDefinitionAttributeDto', id: any, name: string, type: AttributeTypeEnum, order: number, values?: Array<{ __typename?: 'AttributeEnumValueDto', enumId: any, value: string } | null> | null } | null> } };

export type GetAgreementDetailsQueryVariables = Exact<{
  agreementId: Scalars['Long'];
}>;


export type GetAgreementDetailsQuery = { __typename?: 'queries', agreementDetails: { __typename?: 'AgreementDetailsDto', agreementId: any, customerName: string, customerVat: string, customerAddress: string, customerEmail?: string | null, pocEmployee?: string | null, pocName: string, pocCity: string, pocAddress: string, pocRegion: string, pocZone: string, pocChannel: string, hermesPocId: string, invoicesAmount?: any | null, freeBeerQuantity?: number | null, agreementNumber: string, multipleAdditionalDocumentsAllowed?: boolean | null, investmentAmount: any, globalCustomerLimit: any, customerAgreementsSpentAmount: any, paymentFrequency: string, status: DocumentStatusEnum, type: AgreementTypeEnum, reason: AgreementReasonEnum, subject: AgreementSubjectEnum, agreementDate: any, dateFrom: any, dateTo: any, period: string, srName: string, transmissionProtocolType: TransmissionProtocolTypeEnum, invoiceRequired: boolean, bankCertificateRequired: boolean, budgetCenter: string, costCenter: string, validatorName?: string | null, validatorSignature?: string | null, declaration?: { __typename?: 'ScannedDocumentDto', scannedDocumentName?: string | null, scannedDocumentType?: ScannedDocumentTypeEnum | null } | null, bankCertificate?: { __typename?: 'ScannedDocumentDto', scannedDocumentName?: string | null, scannedDocumentType?: ScannedDocumentTypeEnum | null } | null, additionalDocuments?: Array<{ __typename?: 'AgreementAdditionalDocumentDto', pdfName: string, additionalDocumentId: any, dateCreated: any } | null> | null, attributeValues: Array<{ __typename?: 'AgreementAttributeValueDto', attributeId: any, type: AttributeTypeEnum, label: string, order: number, intValue?: number | null, stringValue?: string | null, decimalValue?: any | null, boolValue?: boolean | null, requiresCurrency?: boolean | null, enumValue?: { __typename?: 'AttributeEnumValueDto', enumId: any, value: string } | null }> } };

export type GetAgreementStatusesPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetAgreementStatusesPerUserQuery = { __typename?: 'queries', agreementStatusesPerUser: { __typename?: 'DocumentsStatusesCount', statuses?: any | null } };

export type GetAgreementTypeTreeQueryVariables = Exact<{
  type: AgreementTypeEnum;
}>;


export type GetAgreementTypeTreeQuery = { __typename?: 'queries', agreementTypeTree: { __typename?: 'AgreementTypeDto', subjects: Array<{ __typename?: 'AgreementSubjectDto', subject: AgreementSubjectEnum, reasons: Array<{ __typename?: 'AgreementReasonDto', reason: AgreementReasonEnum } | null> } | null> } };

export type GetAgreementsQueryVariables = Exact<{
  filters?: InputMaybe<Array<AgreementDtoFilterInput> | AgreementDtoFilterInput>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAgreementsQuery = { __typename?: 'queries', agreements?: { __typename?: 'AgreementsConnection', totalCount: number, edges?: Array<{ __typename?: 'AgreementsEdge', node?: { __typename?: 'AgreementDto', agreementId: any, hermesPocId?: string | null, customerName: string, pocName: string, status: DocumentStatusEnum, declarationScanned: boolean, date: any, number: string, type: AgreementTypeEnum, reason: AgreementReasonEnum, subject: AgreementSubjectEnum, amount: any } | null }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetAssetOrderIdsPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetAssetOrderIdsPerUserQuery = { __typename?: 'queries', assetOrderIdsPerUser: Array<string> };

export type GetAssetStatusesPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetAssetStatusesPerUserQuery = { __typename?: 'queries', assetStatusesPerUser: { __typename?: 'DocumentsStatusesCount', statuses?: any | null } };

export type GetAssetsQueryVariables = Exact<{
  filters?: InputMaybe<Array<AssetDtoFilterInput> | AssetDtoFilterInput>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetAssetsQuery = { __typename?: 'queries', assets?: { __typename?: 'AssetsConnection', edges?: Array<{ __typename?: 'AssetsEdge', node?: { __typename?: 'AssetDto', assetId: any, hermesPocId?: string | null, customerName: string, pocName?: string | null, status: DocumentStatusEnum, assetType: AssetTypeEnum, declarationScanned: boolean, lastModified: any, contracted: string, contractedQuantity: number, protocolType?: ProtocolTypeEnum | null, isShared: boolean, contractNumber?: string | null, comment?: string | null } | null }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetCombinedPocsAndSpendLimitQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCombinedPocsAndSpendLimitQuery = { __typename?: 'queries', agreementSpendLimit: any, customerPocs: Array<{ __typename?: 'Poc', id: any, pocName: string }> };

export type GetContractByIdQueryVariables = Exact<{
  input: Scalars['Long'];
}>;


export type GetContractByIdQuery = { __typename?: 'queries', contract: { __typename?: 'ContractDto', id: any, status: DocumentStatusEnum, assetType: AssetTypeEnum, declarationGuid?: string | null, customerName: string, customerVat: string, srCyrillicName: string, srEmail: string, contracted: string, contractedQuantity: number, customerEmail?: string | null, customerCity: string, customerStreet: string, customerStreetNumber: string, contractNumber?: string | null, customerPersonnelName?: string | null, customerPersonnelRole?: string | null, signaturesCount: number, lastModified: any, models: Array<{ __typename?: 'ContractModelDto', id: any, type: string, model: string, price: any, quantity: number }> } };

export type GetUserCustomerByNameQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetUserCustomerByNameQuery = { __typename?: 'queries', userCustomersByName: Array<{ __typename?: 'CustomerDto', id: any, name: string, customerVat: string, customerAddress?: string | null, agreementSpentAmount: any, declaration?: { __typename?: 'ScannedDocumentDto', scannedDocumentType?: ScannedDocumentTypeEnum | null, scannedDocumentName?: string | null } | null }> };

export type GetCustomerPerProtocolIdQueryVariables = Exact<{
  input: Scalars['Long'];
}>;


export type GetCustomerPerProtocolIdQuery = { __typename?: 'queries', customerPerProtocolId: { __typename?: 'CustomerDto', id: any, name: string, email?: string | null, customerAddress?: string | null, customerVat: string } };

export type GetCustomersWithAgreementsPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetCustomersWithAgreementsPerUserQuery = { __typename?: 'queries', customersWithAgreementsPerUser: Array<{ __typename: 'CustomerDto', id: any, name: string, email?: string | null }> };

export type GetCustomersWithAssetsPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetCustomersWithAssetsPerUserQuery = { __typename?: 'queries', customersWithAssetsPerUser: Array<{ __typename: 'CustomerDto', id: any, name: string, email?: string | null }> };

export type GetCustomersNamesQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetCustomersNamesQuery = { __typename?: 'queries', customersNames: Array<string> };

export type GetDocumentEmailHistoryQueryVariables = Exact<{
  documentId: Scalars['Long'];
  documentType: DocumentTypeEnum;
  assetType?: InputMaybe<AssetTypeEnum>;
}>;


export type GetDocumentEmailHistoryQuery = { __typename?: 'queries', documentEmailHistory: { __typename?: 'EmailHistory', dateUpdated: any, recipientEmail: string, recipientName: string, cc: string, subject: string, pdfFileName: string } };

export type GetExistingAgreementTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExistingAgreementTypesQuery = { __typename?: 'queries', existingAgreementTypes: Array<AgreementTypeEnum | null> };

export type GetMaterialModelsQueryVariables = Exact<{
  type: MaterialTypeEnum;
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetMaterialModelsQuery = { __typename?: 'queries', materialModels: Array<{ __typename?: 'Model', id: any, name: string }> };

export type GetMaterialTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaterialTypesQuery = { __typename?: 'queries', createContractMaterialTypes: Array<MaterialTypeEnum> };

export type GetPocsWithAgreementsPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetPocsWithAgreementsPerUserQuery = { __typename?: 'queries', pocsWithAgreementsPerUser: Array<{ __typename: 'Poc', hermesPocId?: string | null, pocName: string, userId?: any | null }> };

export type GetPocsWithAssetsPerUserQueryVariables = Exact<{
  input: FilterRequestModelInput;
}>;


export type GetPocsWithAssetsPerUserQuery = { __typename?: 'queries', pocsWithAssetsPerUser: Array<{ __typename: 'Poc', hermesPocId?: string | null, pocName: string, userId?: any | null }> };

export type GetPocsHermesIdsQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetPocsHermesIdsQuery = { __typename?: 'queries', pocsHermesIds: Array<string> };

export type GetProtocolByIdQueryVariables = Exact<{
  input: Scalars['Long'];
}>;


export type GetProtocolByIdQuery = { __typename?: 'queries', protocol: { __typename?: 'ProtocolDto', id: any, status: DocumentStatusEnum, assetType: AssetTypeEnum, customerName: string, customerEmail?: string | null, customerVat: string, customerAddress: string, pocName: string, hermesPocId: string, pocPersonnelName?: string | null, pocAddress: string, srCyrillicName: string, srEmail: string, contractNumber?: string | null, contracted: string, contractedQuantity: number, protocolType: ProtocolTypeEnum, isShared: boolean, lastModified: any, declaration?: { __typename?: 'ScannedDocumentDto', scannedDocumentName?: string | null, scannedDocumentType?: ScannedDocumentTypeEnum | null } | null, materials: Array<{ __typename?: 'ProtocolMaterialDto', id: any, orderId: string, quantity: number, name: string, brand: string, type: string }> } };

export type GetAssetsRawDataReportQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  request: AssetsRawDataReportRequestModelInput;
}>;


export type GetAssetsRawDataReportQuery = { __typename?: 'queries', assetsRawDataReport?: { __typename?: 'AssetRawDataReportModelCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AssetRawDataReportModel', assetNo: any, contractNo?: string | null, assetType: AssetTypeEnum, status: DocumentStatusEnum, dateSigned?: any | null, customerId: string, customerName: string, pocId: string, pocName: string, employeeName: string, deliveryType?: string | null, materialType?: string | null, materialModel: string, brand?: string | null, serialNo?: string | null, quantity: number } | null> | null } | null };

export type GetRcmcsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetRcmcsQuery = { __typename?: 'queries', rcmcs: Array<{ __typename?: 'UserDto', email: string, id: any }> };

export type GetSupervisorsQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type GetSupervisorsQuery = { __typename?: 'queries', supervisors: Array<{ __typename?: 'UserDto', email: string, id: any }> };

export type GetSupervisorsWithoutZonesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupervisorsWithoutZonesQuery = { __typename?: 'queries', supervisorsWithoutZone: Array<{ __typename?: 'UserDto', email: string, id: any }> };

export type GetUsersNamesQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetUsersNamesQuery = { __typename?: 'queries', usersNames: Array<string> };

export type GetUsersQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<UserDtoSortInput> | UserDtoSortInput>;
}>;


export type GetUsersQuery = { __typename?: 'queries', users?: { __typename?: 'UserDtoCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'UserDto', id: any, name: string, role: UserRoleEnum, email: string, isActive: boolean } | null> | null } | null };

export type SearchEmployeeEmailsQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type SearchEmployeeEmailsQuery = { __typename?: 'queries', srEmailSearch: Array<{ __typename?: 'UserDto', id: any, name: string, role: UserRoleEnum, email: string }> };


export const CancelAgreementDocument = gql`
    mutation CancelAgreement($agreementId: Long!) {
  cancelAgreement(agreementId: $agreementId)
}
    `;
export type CancelAgreementMutationFn = Apollo.MutationFunction<CancelAgreementMutation, CancelAgreementMutationVariables>;

/**
 * __useCancelAgreementMutation__
 *
 * To run a mutation, you first call `useCancelAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAgreementMutation, { data, loading, error }] = useCancelAgreementMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useCancelAgreementMutation(baseOptions?: Apollo.MutationHookOptions<CancelAgreementMutation, CancelAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAgreementMutation, CancelAgreementMutationVariables>(CancelAgreementDocument, options);
      }
export type CancelAgreementMutationHookResult = ReturnType<typeof useCancelAgreementMutation>;
export type CancelAgreementMutationResult = Apollo.MutationResult<CancelAgreementMutation>;
export type CancelAgreementMutationOptions = Apollo.BaseMutationOptions<CancelAgreementMutation, CancelAgreementMutationVariables>;
export const CancelAssetDocument = gql`
    mutation CancelAsset($assetType: AssetTypeEnum!, $assetId: Long!) {
  cancelAsset(assetType: $assetType, assetId: $assetId)
}
    `;
export type CancelAssetMutationFn = Apollo.MutationFunction<CancelAssetMutation, CancelAssetMutationVariables>;

/**
 * __useCancelAssetMutation__
 *
 * To run a mutation, you first call `useCancelAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAssetMutation, { data, loading, error }] = useCancelAssetMutation({
 *   variables: {
 *      assetType: // value for 'assetType'
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useCancelAssetMutation(baseOptions?: Apollo.MutationHookOptions<CancelAssetMutation, CancelAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAssetMutation, CancelAssetMutationVariables>(CancelAssetDocument, options);
      }
export type CancelAssetMutationHookResult = ReturnType<typeof useCancelAssetMutation>;
export type CancelAssetMutationResult = Apollo.MutationResult<CancelAssetMutation>;
export type CancelAssetMutationOptions = Apollo.BaseMutationOptions<CancelAssetMutation, CancelAssetMutationVariables>;
export const CancelShareProtocolDocument = gql`
    mutation CancelShareProtocol($input: Long!) {
  cancelProtocolShare(protocolId: $input)
}
    `;
export type CancelShareProtocolMutationFn = Apollo.MutationFunction<CancelShareProtocolMutation, CancelShareProtocolMutationVariables>;

/**
 * __useCancelShareProtocolMutation__
 *
 * To run a mutation, you first call `useCancelShareProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelShareProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelShareProtocolMutation, { data, loading, error }] = useCancelShareProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelShareProtocolMutation(baseOptions?: Apollo.MutationHookOptions<CancelShareProtocolMutation, CancelShareProtocolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelShareProtocolMutation, CancelShareProtocolMutationVariables>(CancelShareProtocolDocument, options);
      }
export type CancelShareProtocolMutationHookResult = ReturnType<typeof useCancelShareProtocolMutation>;
export type CancelShareProtocolMutationResult = Apollo.MutationResult<CancelShareProtocolMutation>;
export type CancelShareProtocolMutationOptions = Apollo.BaseMutationOptions<CancelShareProtocolMutation, CancelShareProtocolMutationVariables>;
export const ChangeBrandIsActiveDocument = gql`
    mutation ChangeBrandIsActive($id: Long!, $isActive: Boolean!) {
  changeBrandIsActive(id: $id, isActive: $isActive)
}
    `;
export type ChangeBrandIsActiveMutationFn = Apollo.MutationFunction<ChangeBrandIsActiveMutation, ChangeBrandIsActiveMutationVariables>;

/**
 * __useChangeBrandIsActiveMutation__
 *
 * To run a mutation, you first call `useChangeBrandIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBrandIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBrandIsActiveMutation, { data, loading, error }] = useChangeBrandIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangeBrandIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBrandIsActiveMutation, ChangeBrandIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBrandIsActiveMutation, ChangeBrandIsActiveMutationVariables>(ChangeBrandIsActiveDocument, options);
      }
export type ChangeBrandIsActiveMutationHookResult = ReturnType<typeof useChangeBrandIsActiveMutation>;
export type ChangeBrandIsActiveMutationResult = Apollo.MutationResult<ChangeBrandIsActiveMutation>;
export type ChangeBrandIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangeBrandIsActiveMutation, ChangeBrandIsActiveMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: UserChangePasswordInput!) {
  changePassword(input: $input)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeProductIsActiveDocument = gql`
    mutation ChangeProductIsActive($id: Long!, $isActive: Boolean!) {
  changeProductIsActive(id: $id, isActive: $isActive)
}
    `;
export type ChangeProductIsActiveMutationFn = Apollo.MutationFunction<ChangeProductIsActiveMutation, ChangeProductIsActiveMutationVariables>;

/**
 * __useChangeProductIsActiveMutation__
 *
 * To run a mutation, you first call `useChangeProductIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProductIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProductIsActiveMutation, { data, loading, error }] = useChangeProductIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangeProductIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProductIsActiveMutation, ChangeProductIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProductIsActiveMutation, ChangeProductIsActiveMutationVariables>(ChangeProductIsActiveDocument, options);
      }
export type ChangeProductIsActiveMutationHookResult = ReturnType<typeof useChangeProductIsActiveMutation>;
export type ChangeProductIsActiveMutationResult = Apollo.MutationResult<ChangeProductIsActiveMutation>;
export type ChangeProductIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangeProductIsActiveMutation, ChangeProductIsActiveMutationVariables>;
export const ChangeUserIsActiveDocument = gql`
    mutation ChangeUserIsActive($id: Long!, $isActive: Boolean!) {
  changeUserIsActive(id: $id, isActive: $isActive)
}
    `;
export type ChangeUserIsActiveMutationFn = Apollo.MutationFunction<ChangeUserIsActiveMutation, ChangeUserIsActiveMutationVariables>;

/**
 * __useChangeUserIsActiveMutation__
 *
 * To run a mutation, you first call `useChangeUserIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserIsActiveMutation, { data, loading, error }] = useChangeUserIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangeUserIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserIsActiveMutation, ChangeUserIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserIsActiveMutation, ChangeUserIsActiveMutationVariables>(ChangeUserIsActiveDocument, options);
      }
export type ChangeUserIsActiveMutationHookResult = ReturnType<typeof useChangeUserIsActiveMutation>;
export type ChangeUserIsActiveMutationResult = Apollo.MutationResult<ChangeUserIsActiveMutation>;
export type ChangeUserIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangeUserIsActiveMutation, ChangeUserIsActiveMutationVariables>;
export const ChangeTargetSkuIsActiveDocument = gql`
    mutation ChangeTargetSkuIsActive($id: Long!, $isActive: Boolean!) {
  changeTargetSkuIsActive(id: $id, isActive: $isActive)
}
    `;
export type ChangeTargetSkuIsActiveMutationFn = Apollo.MutationFunction<ChangeTargetSkuIsActiveMutation, ChangeTargetSkuIsActiveMutationVariables>;

/**
 * __useChangeTargetSkuIsActiveMutation__
 *
 * To run a mutation, you first call `useChangeTargetSkuIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTargetSkuIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTargetSkuIsActiveMutation, { data, loading, error }] = useChangeTargetSkuIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangeTargetSkuIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTargetSkuIsActiveMutation, ChangeTargetSkuIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTargetSkuIsActiveMutation, ChangeTargetSkuIsActiveMutationVariables>(ChangeTargetSkuIsActiveDocument, options);
      }
export type ChangeTargetSkuIsActiveMutationHookResult = ReturnType<typeof useChangeTargetSkuIsActiveMutation>;
export type ChangeTargetSkuIsActiveMutationResult = Apollo.MutationResult<ChangeTargetSkuIsActiveMutation>;
export type ChangeTargetSkuIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangeTargetSkuIsActiveMutation, ChangeTargetSkuIsActiveMutationVariables>;
export const CreateTargetSkuDocument = gql`
    mutation CreateTargetSku($input: CreateTargetSkuInput!) {
  createTargetSku(input: $input)
}
    `;
export type CreateTargetSkuMutationFn = Apollo.MutationFunction<CreateTargetSkuMutation, CreateTargetSkuMutationVariables>;

/**
 * __useCreateTargetSkuMutation__
 *
 * To run a mutation, you first call `useCreateTargetSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTargetSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTargetSkuMutation, { data, loading, error }] = useCreateTargetSkuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTargetSkuMutation(baseOptions?: Apollo.MutationHookOptions<CreateTargetSkuMutation, CreateTargetSkuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTargetSkuMutation, CreateTargetSkuMutationVariables>(CreateTargetSkuDocument, options);
      }
export type CreateTargetSkuMutationHookResult = ReturnType<typeof useCreateTargetSkuMutation>;
export type CreateTargetSkuMutationResult = Apollo.MutationResult<CreateTargetSkuMutation>;
export type CreateTargetSkuMutationOptions = Apollo.BaseMutationOptions<CreateTargetSkuMutation, CreateTargetSkuMutationVariables>;
export const ChangeThirdPartyIsActiveDocument = gql`
    mutation ChangeThirdPartyIsActive($id: Long!, $isActive: Boolean!) {
  changeThirdPartyIsActive(id: $id, isActive: $isActive)
}
    `;
export type ChangeThirdPartyIsActiveMutationFn = Apollo.MutationFunction<ChangeThirdPartyIsActiveMutation, ChangeThirdPartyIsActiveMutationVariables>;

/**
 * __useChangeThirdPartyIsActiveMutation__
 *
 * To run a mutation, you first call `useChangeThirdPartyIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeThirdPartyIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeThirdPartyIsActiveMutation, { data, loading, error }] = useChangeThirdPartyIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangeThirdPartyIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeThirdPartyIsActiveMutation, ChangeThirdPartyIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeThirdPartyIsActiveMutation, ChangeThirdPartyIsActiveMutationVariables>(ChangeThirdPartyIsActiveDocument, options);
      }
export type ChangeThirdPartyIsActiveMutationHookResult = ReturnType<typeof useChangeThirdPartyIsActiveMutation>;
export type ChangeThirdPartyIsActiveMutationResult = Apollo.MutationResult<ChangeThirdPartyIsActiveMutation>;
export type ChangeThirdPartyIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangeThirdPartyIsActiveMutation, ChangeThirdPartyIsActiveMutationVariables>;
export const CreateThirdPartyDocument = gql`
    mutation CreateThirdParty($input: CreateThirdPartyInput!) {
  createThirdParty(input: $input)
}
    `;
export type CreateThirdPartyMutationFn = Apollo.MutationFunction<CreateThirdPartyMutation, CreateThirdPartyMutationVariables>;

/**
 * __useCreateThirdPartyMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyMutation, { data, loading, error }] = useCreateThirdPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThirdPartyMutation(baseOptions?: Apollo.MutationHookOptions<CreateThirdPartyMutation, CreateThirdPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThirdPartyMutation, CreateThirdPartyMutationVariables>(CreateThirdPartyDocument, options);
      }
export type CreateThirdPartyMutationHookResult = ReturnType<typeof useCreateThirdPartyMutation>;
export type CreateThirdPartyMutationResult = Apollo.MutationResult<CreateThirdPartyMutation>;
export type CreateThirdPartyMutationOptions = Apollo.BaseMutationOptions<CreateThirdPartyMutation, CreateThirdPartyMutationVariables>;
export const CreatePaymentFrequencyDocument = gql`
    mutation CreatePaymentFrequency($input: CreatePaymentFrequencyInput!) {
  createPaymentFrequency(input: $input)
}
    `;
export type CreatePaymentFrequencyMutationFn = Apollo.MutationFunction<CreatePaymentFrequencyMutation, CreatePaymentFrequencyMutationVariables>;

/**
 * __useCreatePaymentFrequencyMutation__
 *
 * To run a mutation, you first call `useCreatePaymentFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentFrequencyMutation, { data, loading, error }] = useCreatePaymentFrequencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentFrequencyMutation, CreatePaymentFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentFrequencyMutation, CreatePaymentFrequencyMutationVariables>(CreatePaymentFrequencyDocument, options);
      }
export type CreatePaymentFrequencyMutationHookResult = ReturnType<typeof useCreatePaymentFrequencyMutation>;
export type CreatePaymentFrequencyMutationResult = Apollo.MutationResult<CreatePaymentFrequencyMutation>;
export type CreatePaymentFrequencyMutationOptions = Apollo.BaseMutationOptions<CreatePaymentFrequencyMutation, CreatePaymentFrequencyMutationVariables>;
export const ChangePaymentFrequencyIsActiveDocument = gql`
    mutation ChangePaymentFrequencyIsActive($id: Long!, $isActive: Boolean!) {
  changePaymentFrequencyIsActive(id: $id, isActive: $isActive)
}
    `;
export type ChangePaymentFrequencyIsActiveMutationFn = Apollo.MutationFunction<ChangePaymentFrequencyIsActiveMutation, ChangePaymentFrequencyIsActiveMutationVariables>;

/**
 * __useChangePaymentFrequencyIsActiveMutation__
 *
 * To run a mutation, you first call `useChangePaymentFrequencyIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePaymentFrequencyIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePaymentFrequencyIsActiveMutation, { data, loading, error }] = useChangePaymentFrequencyIsActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangePaymentFrequencyIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangePaymentFrequencyIsActiveMutation, ChangePaymentFrequencyIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePaymentFrequencyIsActiveMutation, ChangePaymentFrequencyIsActiveMutationVariables>(ChangePaymentFrequencyIsActiveDocument, options);
      }
export type ChangePaymentFrequencyIsActiveMutationHookResult = ReturnType<typeof useChangePaymentFrequencyIsActiveMutation>;
export type ChangePaymentFrequencyIsActiveMutationResult = Apollo.MutationResult<ChangePaymentFrequencyIsActiveMutation>;
export type ChangePaymentFrequencyIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangePaymentFrequencyIsActiveMutation, ChangePaymentFrequencyIsActiveMutationVariables>;
export const UpdateZoneDocument = gql`
    mutation UpdateZone($input: UpdateZoneInput!) {
  updateZone(input: $input)
}
    `;
export type UpdateZoneMutationFn = Apollo.MutationFunction<UpdateZoneMutation, UpdateZoneMutationVariables>;

/**
 * __useUpdateZoneMutation__
 *
 * To run a mutation, you first call `useUpdateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoneMutation, { data, loading, error }] = useUpdateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateZoneMutation, UpdateZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(UpdateZoneDocument, options);
      }
export type UpdateZoneMutationHookResult = ReturnType<typeof useUpdateZoneMutation>;
export type UpdateZoneMutationResult = Apollo.MutationResult<UpdateZoneMutation>;
export type UpdateZoneMutationOptions = Apollo.BaseMutationOptions<UpdateZoneMutation, UpdateZoneMutationVariables>;
export const CreateRegionDocument = gql`
    mutation CreateRegion($input: CreateRegionInput!) {
  createRegion(input: $input)
}
    `;
export type CreateRegionMutationFn = Apollo.MutationFunction<CreateRegionMutation, CreateRegionMutationVariables>;

/**
 * __useCreateRegionMutation__
 *
 * To run a mutation, you first call `useCreateRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegionMutation, { data, loading, error }] = useCreateRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegionMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegionMutation, CreateRegionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegionMutation, CreateRegionMutationVariables>(CreateRegionDocument, options);
      }
export type CreateRegionMutationHookResult = ReturnType<typeof useCreateRegionMutation>;
export type CreateRegionMutationResult = Apollo.MutationResult<CreateRegionMutation>;
export type CreateRegionMutationOptions = Apollo.BaseMutationOptions<CreateRegionMutation, CreateRegionMutationVariables>;
export const UpdateRegionDocument = gql`
    mutation UpdateRegion($input: UpdateRegionInput!) {
  updateRegion(input: $input)
}
    `;
export type UpdateRegionMutationFn = Apollo.MutationFunction<UpdateRegionMutation, UpdateRegionMutationVariables>;

/**
 * __useUpdateRegionMutation__
 *
 * To run a mutation, you first call `useUpdateRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegionMutation, { data, loading, error }] = useUpdateRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegionMutation, UpdateRegionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegionMutation, UpdateRegionMutationVariables>(UpdateRegionDocument, options);
      }
export type UpdateRegionMutationHookResult = ReturnType<typeof useUpdateRegionMutation>;
export type UpdateRegionMutationResult = Apollo.MutationResult<UpdateRegionMutation>;
export type UpdateRegionMutationOptions = Apollo.BaseMutationOptions<UpdateRegionMutation, UpdateRegionMutationVariables>;
export const UpdateDistributorDocument = gql`
    mutation UpdateDistributor($input: UpdateDistributorInput!) {
  updateDistributor(input: $input)
}
    `;
export type UpdateDistributorMutationFn = Apollo.MutationFunction<UpdateDistributorMutation, UpdateDistributorMutationVariables>;

/**
 * __useUpdateDistributorMutation__
 *
 * To run a mutation, you first call `useUpdateDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDistributorMutation, { data, loading, error }] = useUpdateDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDistributorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDistributorMutation, UpdateDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDistributorMutation, UpdateDistributorMutationVariables>(UpdateDistributorDocument, options);
      }
export type UpdateDistributorMutationHookResult = ReturnType<typeof useUpdateDistributorMutation>;
export type UpdateDistributorMutationResult = Apollo.MutationResult<UpdateDistributorMutation>;
export type UpdateDistributorMutationOptions = Apollo.BaseMutationOptions<UpdateDistributorMutation, UpdateDistributorMutationVariables>;
export const CreateDistributorDocument = gql`
    mutation CreateDistributor($input: CreateDistributorInput!) {
  createDistributor(input: $input)
}
    `;
export type CreateDistributorMutationFn = Apollo.MutationFunction<CreateDistributorMutation, CreateDistributorMutationVariables>;

/**
 * __useCreateDistributorMutation__
 *
 * To run a mutation, you first call `useCreateDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDistributorMutation, { data, loading, error }] = useCreateDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDistributorMutation(baseOptions?: Apollo.MutationHookOptions<CreateDistributorMutation, CreateDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDistributorMutation, CreateDistributorMutationVariables>(CreateDistributorDocument, options);
      }
export type CreateDistributorMutationHookResult = ReturnType<typeof useCreateDistributorMutation>;
export type CreateDistributorMutationResult = Apollo.MutationResult<CreateDistributorMutation>;
export type CreateDistributorMutationOptions = Apollo.BaseMutationOptions<CreateDistributorMutation, CreateDistributorMutationVariables>;
export const UpdateSpendLimitDocument = gql`
    mutation UpdateSpendLimit($limit: Decimal!) {
  updateSpendLimit(limit: $limit)
}
    `;
export type UpdateSpendLimitMutationFn = Apollo.MutationFunction<UpdateSpendLimitMutation, UpdateSpendLimitMutationVariables>;

/**
 * __useUpdateSpendLimitMutation__
 *
 * To run a mutation, you first call `useUpdateSpendLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpendLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpendLimitMutation, { data, loading, error }] = useUpdateSpendLimitMutation({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUpdateSpendLimitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpendLimitMutation, UpdateSpendLimitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpendLimitMutation, UpdateSpendLimitMutationVariables>(UpdateSpendLimitDocument, options);
      }
export type UpdateSpendLimitMutationHookResult = ReturnType<typeof useUpdateSpendLimitMutation>;
export type UpdateSpendLimitMutationResult = Apollo.MutationResult<UpdateSpendLimitMutation>;
export type UpdateSpendLimitMutationOptions = Apollo.BaseMutationOptions<UpdateSpendLimitMutation, UpdateSpendLimitMutationVariables>;
export const ChangeAdminDefinitionIsActiveDocument = gql`
    mutation ChangeAdminDefinitionIsActive($definitionId: Long!, $isActive: Boolean!) {
  changeDefinitionIsActive(definitionId: $definitionId, isActive: $isActive)
}
    `;
export type ChangeAdminDefinitionIsActiveMutationFn = Apollo.MutationFunction<ChangeAdminDefinitionIsActiveMutation, ChangeAdminDefinitionIsActiveMutationVariables>;

/**
 * __useChangeAdminDefinitionIsActiveMutation__
 *
 * To run a mutation, you first call `useChangeAdminDefinitionIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdminDefinitionIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdminDefinitionIsActiveMutation, { data, loading, error }] = useChangeAdminDefinitionIsActiveMutation({
 *   variables: {
 *      definitionId: // value for 'definitionId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useChangeAdminDefinitionIsActiveMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAdminDefinitionIsActiveMutation, ChangeAdminDefinitionIsActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAdminDefinitionIsActiveMutation, ChangeAdminDefinitionIsActiveMutationVariables>(ChangeAdminDefinitionIsActiveDocument, options);
      }
export type ChangeAdminDefinitionIsActiveMutationHookResult = ReturnType<typeof useChangeAdminDefinitionIsActiveMutation>;
export type ChangeAdminDefinitionIsActiveMutationResult = Apollo.MutationResult<ChangeAdminDefinitionIsActiveMutation>;
export type ChangeAdminDefinitionIsActiveMutationOptions = Apollo.BaseMutationOptions<ChangeAdminDefinitionIsActiveMutation, ChangeAdminDefinitionIsActiveMutationVariables>;
export const CompleteAgreementDocument = gql`
    mutation CompleteAgreement($input: CompleteAgreementInput!) {
  completeAgreement(input: $input) {
    agreementId
  }
}
    `;
export type CompleteAgreementMutationFn = Apollo.MutationFunction<CompleteAgreementMutation, CompleteAgreementMutationVariables>;

/**
 * __useCompleteAgreementMutation__
 *
 * To run a mutation, you first call `useCompleteAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAgreementMutation, { data, loading, error }] = useCompleteAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAgreementMutation(baseOptions?: Apollo.MutationHookOptions<CompleteAgreementMutation, CompleteAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteAgreementMutation, CompleteAgreementMutationVariables>(CompleteAgreementDocument, options);
      }
export type CompleteAgreementMutationHookResult = ReturnType<typeof useCompleteAgreementMutation>;
export type CompleteAgreementMutationResult = Apollo.MutationResult<CompleteAgreementMutation>;
export type CompleteAgreementMutationOptions = Apollo.BaseMutationOptions<CompleteAgreementMutation, CompleteAgreementMutationVariables>;
export const CompleteContractDocument = gql`
    mutation CompleteContract($input: CompleteContractInput!) {
  completeContract(input: $input)
}
    `;
export type CompleteContractMutationFn = Apollo.MutationFunction<CompleteContractMutation, CompleteContractMutationVariables>;

/**
 * __useCompleteContractMutation__
 *
 * To run a mutation, you first call `useCompleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeContractMutation, { data, loading, error }] = useCompleteContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteContractMutation(baseOptions?: Apollo.MutationHookOptions<CompleteContractMutation, CompleteContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteContractMutation, CompleteContractMutationVariables>(CompleteContractDocument, options);
      }
export type CompleteContractMutationHookResult = ReturnType<typeof useCompleteContractMutation>;
export type CompleteContractMutationResult = Apollo.MutationResult<CompleteContractMutation>;
export type CompleteContractMutationOptions = Apollo.BaseMutationOptions<CompleteContractMutation, CompleteContractMutationVariables>;
export const CompleteProtocolDocument = gql`
    mutation CompleteProtocol($input: CompleteProtocolInput!) {
  completeProtocol(input: $input) {
    id
  }
}
    `;
export type CompleteProtocolMutationFn = Apollo.MutationFunction<CompleteProtocolMutation, CompleteProtocolMutationVariables>;

/**
 * __useCompleteProtocolMutation__
 *
 * To run a mutation, you first call `useCompleteProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProtocolMutation, { data, loading, error }] = useCompleteProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteProtocolMutation(baseOptions?: Apollo.MutationHookOptions<CompleteProtocolMutation, CompleteProtocolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteProtocolMutation, CompleteProtocolMutationVariables>(CompleteProtocolDocument, options);
      }
export type CompleteProtocolMutationHookResult = ReturnType<typeof useCompleteProtocolMutation>;
export type CompleteProtocolMutationResult = Apollo.MutationResult<CompleteProtocolMutation>;
export type CompleteProtocolMutationOptions = Apollo.BaseMutationOptions<CompleteProtocolMutation, CompleteProtocolMutationVariables>;
export const CreateAgreementDocument = gql`
    mutation CreateAgreement($input: CreateAgreementInput!) {
  createAgreement(input: $input)
}
    `;
export type CreateAgreementMutationFn = Apollo.MutationFunction<CreateAgreementMutation, CreateAgreementMutationVariables>;

/**
 * __useCreateAgreementMutation__
 *
 * To run a mutation, you first call `useCreateAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgreementMutation, { data, loading, error }] = useCreateAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAgreementMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgreementMutation, CreateAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgreementMutation, CreateAgreementMutationVariables>(CreateAgreementDocument, options);
      }
export type CreateAgreementMutationHookResult = ReturnType<typeof useCreateAgreementMutation>;
export type CreateAgreementMutationResult = Apollo.MutationResult<CreateAgreementMutation>;
export type CreateAgreementMutationOptions = Apollo.BaseMutationOptions<CreateAgreementMutation, CreateAgreementMutationVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input)
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateContractDocument = gql`
    mutation CreateContract($input: CreateContractInput!) {
  createContract(input: $input)
}
    `;
export type CreateContractMutationFn = Apollo.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, options);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input)
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ResendAdditionalDocumentEmailDocument = gql`
    mutation ResendAdditionalDocumentEmail($documentId: Long!) {
  resendAdditionalDocumentEmail(documentId: $documentId)
}
    `;
export type ResendAdditionalDocumentEmailMutationFn = Apollo.MutationFunction<ResendAdditionalDocumentEmailMutation, ResendAdditionalDocumentEmailMutationVariables>;

/**
 * __useResendAdditionalDocumentEmailMutation__
 *
 * To run a mutation, you first call `useResendAdditionalDocumentEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendAdditionalDocumentEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendAdditionalDocumentEmailMutation, { data, loading, error }] = useResendAdditionalDocumentEmailMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useResendAdditionalDocumentEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendAdditionalDocumentEmailMutation, ResendAdditionalDocumentEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendAdditionalDocumentEmailMutation, ResendAdditionalDocumentEmailMutationVariables>(ResendAdditionalDocumentEmailDocument, options);
      }
export type ResendAdditionalDocumentEmailMutationHookResult = ReturnType<typeof useResendAdditionalDocumentEmailMutation>;
export type ResendAdditionalDocumentEmailMutationResult = Apollo.MutationResult<ResendAdditionalDocumentEmailMutation>;
export type ResendAdditionalDocumentEmailMutationOptions = Apollo.BaseMutationOptions<ResendAdditionalDocumentEmailMutation, ResendAdditionalDocumentEmailMutationVariables>;
export const ResendDocumentEmailDocument = gql`
    mutation ResendDocumentEmail($documentId: Long!, $documentType: DocumentTypeEnum!) {
  resendDocumentEmail(documentId: $documentId, documentType: $documentType)
}
    `;
export type ResendDocumentEmailMutationFn = Apollo.MutationFunction<ResendDocumentEmailMutation, ResendDocumentEmailMutationVariables>;

/**
 * __useResendDocumentEmailMutation__
 *
 * To run a mutation, you first call `useResendDocumentEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendDocumentEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendDocumentEmailMutation, { data, loading, error }] = useResendDocumentEmailMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useResendDocumentEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendDocumentEmailMutation, ResendDocumentEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendDocumentEmailMutation, ResendDocumentEmailMutationVariables>(ResendDocumentEmailDocument, options);
      }
export type ResendDocumentEmailMutationHookResult = ReturnType<typeof useResendDocumentEmailMutation>;
export type ResendDocumentEmailMutationResult = Apollo.MutationResult<ResendDocumentEmailMutation>;
export type ResendDocumentEmailMutationOptions = Apollo.BaseMutationOptions<ResendDocumentEmailMutation, ResendDocumentEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: UserResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ReturnAgreementToSrDocument = gql`
    mutation ReturnAgreementToSr($agreementId: Long!) {
  returnAgreementToSr(agreementId: $agreementId)
}
    `;
export type ReturnAgreementToSrMutationFn = Apollo.MutationFunction<ReturnAgreementToSrMutation, ReturnAgreementToSrMutationVariables>;

/**
 * __useReturnAgreementToSrMutation__
 *
 * To run a mutation, you first call `useReturnAgreementToSrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnAgreementToSrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnAgreementToSrMutation, { data, loading, error }] = useReturnAgreementToSrMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useReturnAgreementToSrMutation(baseOptions?: Apollo.MutationHookOptions<ReturnAgreementToSrMutation, ReturnAgreementToSrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReturnAgreementToSrMutation, ReturnAgreementToSrMutationVariables>(ReturnAgreementToSrDocument, options);
      }
export type ReturnAgreementToSrMutationHookResult = ReturnType<typeof useReturnAgreementToSrMutation>;
export type ReturnAgreementToSrMutationResult = Apollo.MutationResult<ReturnAgreementToSrMutation>;
export type ReturnAgreementToSrMutationOptions = Apollo.BaseMutationOptions<ReturnAgreementToSrMutation, ReturnAgreementToSrMutationVariables>;
export const SendAgreementForValidationDocument = gql`
    mutation SendAgreementForValidation($agreementId: Long!) {
  sendAgreementForValidation(agreementId: $agreementId)
}
    `;
export type SendAgreementForValidationMutationFn = Apollo.MutationFunction<SendAgreementForValidationMutation, SendAgreementForValidationMutationVariables>;

/**
 * __useSendAgreementForValidationMutation__
 *
 * To run a mutation, you first call `useSendAgreementForValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAgreementForValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAgreementForValidationMutation, { data, loading, error }] = useSendAgreementForValidationMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useSendAgreementForValidationMutation(baseOptions?: Apollo.MutationHookOptions<SendAgreementForValidationMutation, SendAgreementForValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAgreementForValidationMutation, SendAgreementForValidationMutationVariables>(SendAgreementForValidationDocument, options);
      }
export type SendAgreementForValidationMutationHookResult = ReturnType<typeof useSendAgreementForValidationMutation>;
export type SendAgreementForValidationMutationResult = Apollo.MutationResult<SendAgreementForValidationMutation>;
export type SendAgreementForValidationMutationOptions = Apollo.BaseMutationOptions<SendAgreementForValidationMutation, SendAgreementForValidationMutationVariables>;
export const SendUserResetPasswordDocument = gql`
    mutation SendUserResetPassword($input: UserSendResetPasswordCodeInput!) {
  sendResetPasswordCode(input: $input)
}
    `;
export type SendUserResetPasswordMutationFn = Apollo.MutationFunction<SendUserResetPasswordMutation, SendUserResetPasswordMutationVariables>;

/**
 * __useSendUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserResetPasswordMutation, { data, loading, error }] = useSendUserResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SendUserResetPasswordMutation, SendUserResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendUserResetPasswordMutation, SendUserResetPasswordMutationVariables>(SendUserResetPasswordDocument, options);
      }
export type SendUserResetPasswordMutationHookResult = ReturnType<typeof useSendUserResetPasswordMutation>;
export type SendUserResetPasswordMutationResult = Apollo.MutationResult<SendUserResetPasswordMutation>;
export type SendUserResetPasswordMutationOptions = Apollo.BaseMutationOptions<SendUserResetPasswordMutation, SendUserResetPasswordMutationVariables>;
export const SetAgreementToStatusDoneDocument = gql`
    mutation SetAgreementToStatusDone($agreementId: Long!) {
  setAgreementToStatusDone(agreementId: $agreementId)
}
    `;
export type SetAgreementToStatusDoneMutationFn = Apollo.MutationFunction<SetAgreementToStatusDoneMutation, SetAgreementToStatusDoneMutationVariables>;

/**
 * __useSetAgreementToStatusDoneMutation__
 *
 * To run a mutation, you first call `useSetAgreementToStatusDoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAgreementToStatusDoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAgreementToStatusDoneMutation, { data, loading, error }] = useSetAgreementToStatusDoneMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useSetAgreementToStatusDoneMutation(baseOptions?: Apollo.MutationHookOptions<SetAgreementToStatusDoneMutation, SetAgreementToStatusDoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAgreementToStatusDoneMutation, SetAgreementToStatusDoneMutationVariables>(SetAgreementToStatusDoneDocument, options);
      }
export type SetAgreementToStatusDoneMutationHookResult = ReturnType<typeof useSetAgreementToStatusDoneMutation>;
export type SetAgreementToStatusDoneMutationResult = Apollo.MutationResult<SetAgreementToStatusDoneMutation>;
export type SetAgreementToStatusDoneMutationOptions = Apollo.BaseMutationOptions<SetAgreementToStatusDoneMutation, SetAgreementToStatusDoneMutationVariables>;
export const ShareProtocolsDocument = gql`
    mutation ShareProtocols($input: MultipleProtocolsShareInput!) {
  multipleProtocolsShare(input: $input)
}
    `;
export type ShareProtocolsMutationFn = Apollo.MutationFunction<ShareProtocolsMutation, ShareProtocolsMutationVariables>;

/**
 * __useShareProtocolsMutation__
 *
 * To run a mutation, you first call `useShareProtocolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareProtocolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareProtocolsMutation, { data, loading, error }] = useShareProtocolsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareProtocolsMutation(baseOptions?: Apollo.MutationHookOptions<ShareProtocolsMutation, ShareProtocolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareProtocolsMutation, ShareProtocolsMutationVariables>(ShareProtocolsDocument, options);
      }
export type ShareProtocolsMutationHookResult = ReturnType<typeof useShareProtocolsMutation>;
export type ShareProtocolsMutationResult = Apollo.MutationResult<ShareProtocolsMutation>;
export type ShareProtocolsMutationOptions = Apollo.BaseMutationOptions<ShareProtocolsMutation, ShareProtocolsMutationVariables>;
export const SoftDeleteAgreementDocument = gql`
    mutation SoftDeleteAgreement($agreementId: Long!) {
  softDeleteAgreement(agreementId: $agreementId)
}
    `;
export type SoftDeleteAgreementMutationFn = Apollo.MutationFunction<SoftDeleteAgreementMutation, SoftDeleteAgreementMutationVariables>;

/**
 * __useSoftDeleteAgreementMutation__
 *
 * To run a mutation, you first call `useSoftDeleteAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteAgreementMutation, { data, loading, error }] = useSoftDeleteAgreementMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useSoftDeleteAgreementMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteAgreementMutation, SoftDeleteAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteAgreementMutation, SoftDeleteAgreementMutationVariables>(SoftDeleteAgreementDocument, options);
      }
export type SoftDeleteAgreementMutationHookResult = ReturnType<typeof useSoftDeleteAgreementMutation>;
export type SoftDeleteAgreementMutationResult = Apollo.MutationResult<SoftDeleteAgreementMutation>;
export type SoftDeleteAgreementMutationOptions = Apollo.BaseMutationOptions<SoftDeleteAgreementMutation, SoftDeleteAgreementMutationVariables>;
export const UpdateAdminModelsPricesDocument = gql`
    mutation UpdateAdminModelsPrices($inputModels: [AdminMaterialPriceUpdateInput!]!) {
  updateAdminModelsPrices(inputModels: $inputModels)
}
    `;
export type UpdateAdminModelsPricesMutationFn = Apollo.MutationFunction<UpdateAdminModelsPricesMutation, UpdateAdminModelsPricesMutationVariables>;

/**
 * __useUpdateAdminModelsPricesMutation__
 *
 * To run a mutation, you first call `useUpdateAdminModelsPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminModelsPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminModelsPricesMutation, { data, loading, error }] = useUpdateAdminModelsPricesMutation({
 *   variables: {
 *      inputModels: // value for 'inputModels'
 *   },
 * });
 */
export function useUpdateAdminModelsPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminModelsPricesMutation, UpdateAdminModelsPricesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminModelsPricesMutation, UpdateAdminModelsPricesMutationVariables>(UpdateAdminModelsPricesDocument, options);
      }
export type UpdateAdminModelsPricesMutationHookResult = ReturnType<typeof useUpdateAdminModelsPricesMutation>;
export type UpdateAdminModelsPricesMutationResult = Apollo.MutationResult<UpdateAdminModelsPricesMutation>;
export type UpdateAdminModelsPricesMutationOptions = Apollo.BaseMutationOptions<UpdateAdminModelsPricesMutation, UpdateAdminModelsPricesMutationVariables>;
export const UpdateAgreementDocument = gql`
    mutation UpdateAgreement($input: UpdateAgreementInput!) {
  updateAgreement(input: $input)
}
    `;
export type UpdateAgreementMutationFn = Apollo.MutationFunction<UpdateAgreementMutation, UpdateAgreementMutationVariables>;

/**
 * __useUpdateAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgreementMutation, { data, loading, error }] = useUpdateAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgreementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgreementMutation, UpdateAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgreementMutation, UpdateAgreementMutationVariables>(UpdateAgreementDocument, options);
      }
export type UpdateAgreementMutationHookResult = ReturnType<typeof useUpdateAgreementMutation>;
export type UpdateAgreementMutationResult = Apollo.MutationResult<UpdateAgreementMutation>;
export type UpdateAgreementMutationOptions = Apollo.BaseMutationOptions<UpdateAgreementMutation, UpdateAgreementMutationVariables>;
export const ValidateAgreementDocument = gql`
    mutation ValidateAgreement($agreementId: Long!, $validatorSignature: String!) {
  validateAgreement(
    agreementId: $agreementId
    validatorSignature: $validatorSignature
  )
}
    `;
export type ValidateAgreementMutationFn = Apollo.MutationFunction<ValidateAgreementMutation, ValidateAgreementMutationVariables>;

/**
 * __useValidateAgreementMutation__
 *
 * To run a mutation, you first call `useValidateAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAgreementMutation, { data, loading, error }] = useValidateAgreementMutation({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *      validatorSignature: // value for 'validatorSignature'
 *   },
 * });
 */
export function useValidateAgreementMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAgreementMutation, ValidateAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateAgreementMutation, ValidateAgreementMutationVariables>(ValidateAgreementDocument, options);
      }
export type ValidateAgreementMutationHookResult = ReturnType<typeof useValidateAgreementMutation>;
export type ValidateAgreementMutationResult = Apollo.MutationResult<ValidateAgreementMutation>;
export type ValidateAgreementMutationOptions = Apollo.BaseMutationOptions<ValidateAgreementMutation, ValidateAgreementMutationVariables>;
export const VerifyResetPasswordCodeDocument = gql`
    mutation VerifyResetPasswordCode($input: UserVerifyResetPasswordCodeInput!) {
  verifyResetPasswordCode(input: $input)
}
    `;
export type VerifyResetPasswordCodeMutationFn = Apollo.MutationFunction<VerifyResetPasswordCodeMutation, VerifyResetPasswordCodeMutationVariables>;

/**
 * __useVerifyResetPasswordCodeMutation__
 *
 * To run a mutation, you first call `useVerifyResetPasswordCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyResetPasswordCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyResetPasswordCodeMutation, { data, loading, error }] = useVerifyResetPasswordCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyResetPasswordCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyResetPasswordCodeMutation, VerifyResetPasswordCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyResetPasswordCodeMutation, VerifyResetPasswordCodeMutationVariables>(VerifyResetPasswordCodeDocument, options);
      }
export type VerifyResetPasswordCodeMutationHookResult = ReturnType<typeof useVerifyResetPasswordCodeMutation>;
export type VerifyResetPasswordCodeMutationResult = Apollo.MutationResult<VerifyResetPasswordCodeMutation>;
export type VerifyResetPasswordCodeMutationOptions = Apollo.BaseMutationOptions<VerifyResetPasswordCodeMutation, VerifyResetPasswordCodeMutationVariables>;
export const GetTargetSkusDocument = gql`
    query GetTargetSkus($searchText: String, $take: Int, $skip: Int, $order: [AttributeModelBaseSortInput!]) {
  targetSkus(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      isActive
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetTargetSkusQuery__
 *
 * To run a query within a React component, call `useGetTargetSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetSkusQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetTargetSkusQuery(baseOptions?: Apollo.QueryHookOptions<GetTargetSkusQuery, GetTargetSkusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetSkusQuery, GetTargetSkusQueryVariables>(GetTargetSkusDocument, options);
      }
export function useGetTargetSkusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetSkusQuery, GetTargetSkusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetSkusQuery, GetTargetSkusQueryVariables>(GetTargetSkusDocument, options);
        }
export type GetTargetSkusQueryHookResult = ReturnType<typeof useGetTargetSkusQuery>;
export type GetTargetSkusLazyQueryHookResult = ReturnType<typeof useGetTargetSkusLazyQuery>;
export type GetTargetSkusQueryResult = Apollo.QueryResult<GetTargetSkusQuery, GetTargetSkusQueryVariables>;
export const GetBrandsDocument = gql`
    query GetBrands($searchText: String, $take: Int, $skip: Int, $order: [AttributeModelBaseSortInput!]) {
  brands(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      isActive
    }
    totalCount
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts($searchText: String, $take: Int, $skip: Int, $order: [ProductSortInput!]) {
  products(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      price
      isActive
    }
    totalCount
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetThirdPartiesDocument = gql`
    query GetThirdParties($searchText: String, $take: Int, $skip: Int, $order: [ThirdPartySortInput!]) {
  thirdParties(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      isActive
      sapNumber
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetThirdPartiesQuery__
 *
 * To run a query within a React component, call `useGetThirdPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThirdPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThirdPartiesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetThirdPartiesQuery(baseOptions?: Apollo.QueryHookOptions<GetThirdPartiesQuery, GetThirdPartiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThirdPartiesQuery, GetThirdPartiesQueryVariables>(GetThirdPartiesDocument, options);
      }
export function useGetThirdPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThirdPartiesQuery, GetThirdPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThirdPartiesQuery, GetThirdPartiesQueryVariables>(GetThirdPartiesDocument, options);
        }
export type GetThirdPartiesQueryHookResult = ReturnType<typeof useGetThirdPartiesQuery>;
export type GetThirdPartiesLazyQueryHookResult = ReturnType<typeof useGetThirdPartiesLazyQuery>;
export type GetThirdPartiesQueryResult = Apollo.QueryResult<GetThirdPartiesQuery, GetThirdPartiesQueryVariables>;
export const GetPaymentFrequenciesDocument = gql`
    query GetPaymentFrequencies($searchText: String, $take: Int, $skip: Int, $order: [AttributeModelBaseSortInput!]) {
  paymentFrequencies(
    searchText: $searchText
    take: $take
    skip: $skip
    order: $order
  ) {
    items {
      id
      name
      isActive
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetPaymentFrequenciesQuery__
 *
 * To run a query within a React component, call `useGetPaymentFrequenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentFrequenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentFrequenciesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPaymentFrequenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentFrequenciesQuery, GetPaymentFrequenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentFrequenciesQuery, GetPaymentFrequenciesQueryVariables>(GetPaymentFrequenciesDocument, options);
      }
export function useGetPaymentFrequenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentFrequenciesQuery, GetPaymentFrequenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentFrequenciesQuery, GetPaymentFrequenciesQueryVariables>(GetPaymentFrequenciesDocument, options);
        }
export type GetPaymentFrequenciesQueryHookResult = ReturnType<typeof useGetPaymentFrequenciesQuery>;
export type GetPaymentFrequenciesLazyQueryHookResult = ReturnType<typeof useGetPaymentFrequenciesLazyQuery>;
export type GetPaymentFrequenciesQueryResult = Apollo.QueryResult<GetPaymentFrequenciesQuery, GetPaymentFrequenciesQueryVariables>;
export const GetZonesDocument = gql`
    query GetZones($searchText: String, $take: Int, $skip: Int, $order: [ZoneDtoSortInput!]) {
  zones(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      name
      supervisorEmail
      supervisorId
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetZonesQuery__
 *
 * To run a query within a React component, call `useGetZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZonesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetZonesQuery(baseOptions?: Apollo.QueryHookOptions<GetZonesQuery, GetZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZonesQuery, GetZonesQueryVariables>(GetZonesDocument, options);
      }
export function useGetZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZonesQuery, GetZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZonesQuery, GetZonesQueryVariables>(GetZonesDocument, options);
        }
export type GetZonesQueryHookResult = ReturnType<typeof useGetZonesQuery>;
export type GetZonesLazyQueryHookResult = ReturnType<typeof useGetZonesLazyQuery>;
export type GetZonesQueryResult = Apollo.QueryResult<GetZonesQuery, GetZonesQueryVariables>;
export const GetZonesWithSupervisorsDocument = gql`
    query GetZonesWithSupervisors {
  zonesWithSupervisors {
    id
    name
    supervisorEmail
    supervisorId
  }
}
    `;

/**
 * __useGetZonesWithSupervisorsQuery__
 *
 * To run a query within a React component, call `useGetZonesWithSupervisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZonesWithSupervisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZonesWithSupervisorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetZonesWithSupervisorsQuery(baseOptions?: Apollo.QueryHookOptions<GetZonesWithSupervisorsQuery, GetZonesWithSupervisorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZonesWithSupervisorsQuery, GetZonesWithSupervisorsQueryVariables>(GetZonesWithSupervisorsDocument, options);
      }
export function useGetZonesWithSupervisorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZonesWithSupervisorsQuery, GetZonesWithSupervisorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZonesWithSupervisorsQuery, GetZonesWithSupervisorsQueryVariables>(GetZonesWithSupervisorsDocument, options);
        }
export type GetZonesWithSupervisorsQueryHookResult = ReturnType<typeof useGetZonesWithSupervisorsQuery>;
export type GetZonesWithSupervisorsLazyQueryHookResult = ReturnType<typeof useGetZonesWithSupervisorsLazyQuery>;
export type GetZonesWithSupervisorsQueryResult = Apollo.QueryResult<GetZonesWithSupervisorsQuery, GetZonesWithSupervisorsQueryVariables>;
export const GetRegionsDocument = gql`
    query GetRegions($searchText: String, $take: Int, $skip: Int, $order: [RegionDtoSortInput!]) {
  regions(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      isActive
      name
      rcmcEmail
      rcmcId
      costCenter
      zones {
        id
        name
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
      }
export function useGetRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
        }
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const GetDistributorsDocument = gql`
    query GetDistributors($searchText: String, $take: Int, $skip: Int, $order: [DistributorDtoSortInput!]) {
  distributors(searchText: $searchText, take: $take, skip: $skip, order: $order) {
    items {
      id
      isActive
      name
      zones {
        id
        name
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetDistributorsQuery__
 *
 * To run a query within a React component, call `useGetDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributorsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<GetDistributorsQuery, GetDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributorsQuery, GetDistributorsQueryVariables>(GetDistributorsDocument, options);
      }
export function useGetDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributorsQuery, GetDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributorsQuery, GetDistributorsQueryVariables>(GetDistributorsDocument, options);
        }
export type GetDistributorsQueryHookResult = ReturnType<typeof useGetDistributorsQuery>;
export type GetDistributorsLazyQueryHookResult = ReturnType<typeof useGetDistributorsLazyQuery>;
export type GetDistributorsQueryResult = Apollo.QueryResult<GetDistributorsQuery, GetDistributorsQueryVariables>;
export const GetAgreementSpendLimitDocument = gql`
    query GetAgreementSpendLimit {
  agreementSpendLimit
}
    `;

/**
 * __useGetAgreementSpendLimitQuery__
 *
 * To run a query within a React component, call `useGetAgreementSpendLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementSpendLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementSpendLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAgreementSpendLimitQuery(baseOptions?: Apollo.QueryHookOptions<GetAgreementSpendLimitQuery, GetAgreementSpendLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementSpendLimitQuery, GetAgreementSpendLimitQueryVariables>(GetAgreementSpendLimitDocument, options);
      }
export function useGetAgreementSpendLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementSpendLimitQuery, GetAgreementSpendLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementSpendLimitQuery, GetAgreementSpendLimitQueryVariables>(GetAgreementSpendLimitDocument, options);
        }
export type GetAgreementSpendLimitQueryHookResult = ReturnType<typeof useGetAgreementSpendLimitQuery>;
export type GetAgreementSpendLimitLazyQueryHookResult = ReturnType<typeof useGetAgreementSpendLimitLazyQuery>;
export type GetAgreementSpendLimitQueryResult = Apollo.QueryResult<GetAgreementSpendLimitQuery, GetAgreementSpendLimitQueryVariables>;
export const GetAdminDefinitionsDocument = gql`
    query GetAdminDefinitions($skip: Int, $take: Int, $order: [AgreementDefinitionSortInput!], $request: AgreementDefinitionsFilterRequestModelInput!) {
  adminAgreementDefinitions(
    skip: $skip
    take: $take
    order: $order
    request: $request
  ) {
    items {
      additionalFormula
      bankCertificateRequired
      id
      includeInLimit
      invoiceRequired
      isActive
      paymentFrequencyRequired
      reason
      subject
      transmissionProtocolType
      type
      validationBy
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useGetAdminDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetAdminDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDefinitionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAdminDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminDefinitionsQuery, GetAdminDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminDefinitionsQuery, GetAdminDefinitionsQueryVariables>(GetAdminDefinitionsDocument, options);
      }
export function useGetAdminDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDefinitionsQuery, GetAdminDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminDefinitionsQuery, GetAdminDefinitionsQueryVariables>(GetAdminDefinitionsDocument, options);
        }
export type GetAdminDefinitionsQueryHookResult = ReturnType<typeof useGetAdminDefinitionsQuery>;
export type GetAdminDefinitionsLazyQueryHookResult = ReturnType<typeof useGetAdminDefinitionsLazyQuery>;
export type GetAdminDefinitionsQueryResult = Apollo.QueryResult<GetAdminDefinitionsQuery, GetAdminDefinitionsQueryVariables>;
export const GetAdminAssetStatusesDocument = gql`
    query GetAdminAssetStatuses {
  adminAssetStatuses
}
    `;

/**
 * __useGetAdminAssetStatusesQuery__
 *
 * To run a query within a React component, call `useGetAdminAssetStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAssetStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAssetStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminAssetStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminAssetStatusesQuery, GetAdminAssetStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminAssetStatusesQuery, GetAdminAssetStatusesQueryVariables>(GetAdminAssetStatusesDocument, options);
      }
export function useGetAdminAssetStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminAssetStatusesQuery, GetAdminAssetStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminAssetStatusesQuery, GetAdminAssetStatusesQueryVariables>(GetAdminAssetStatusesDocument, options);
        }
export type GetAdminAssetStatusesQueryHookResult = ReturnType<typeof useGetAdminAssetStatusesQuery>;
export type GetAdminAssetStatusesLazyQueryHookResult = ReturnType<typeof useGetAdminAssetStatusesLazyQuery>;
export type GetAdminAssetStatusesQueryResult = Apollo.QueryResult<GetAdminAssetStatusesQuery, GetAdminAssetStatusesQueryVariables>;
export const GetAdminAssetsDocument = gql`
    query GetAdminAssets($input: AdminAssetFilterRequestModelInput!, $skip: Int, $take: Int, $order: [AdminAssetDtoSortInput!]) {
  adminAssets(request: $input, skip: $skip, take: $take, order: $order) {
    items {
      assetId
      customer
      pocName
      contractNumber
      pocId
      declaration
      contracted
      contractedQuantity
      assetType
      status
      employee
      materialType
      dateSigned
    }
    totalCount
  }
}
    `;

/**
 * __useGetAdminAssetsQuery__
 *
 * To run a query within a React component, call `useGetAdminAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAdminAssetsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminAssetsQuery, GetAdminAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminAssetsQuery, GetAdminAssetsQueryVariables>(GetAdminAssetsDocument, options);
      }
export function useGetAdminAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminAssetsQuery, GetAdminAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminAssetsQuery, GetAdminAssetsQueryVariables>(GetAdminAssetsDocument, options);
        }
export type GetAdminAssetsQueryHookResult = ReturnType<typeof useGetAdminAssetsQuery>;
export type GetAdminAssetsLazyQueryHookResult = ReturnType<typeof useGetAdminAssetsLazyQuery>;
export type GetAdminAssetsQueryResult = Apollo.QueryResult<GetAdminAssetsQuery, GetAdminAssetsQueryVariables>;
export const GetAdminCommercialAgreementsDocumentDocument = gql`
    query GetAdminCommercialAgreementsDocument($input: AdminCommercialAgreementFilterRequestModelInput!, $skip: Int, $take: Int, $order: [AdminCommercialAgreementDtoSortInput!]) {
  adminCommercialAgreements(
    request: $input
    skip: $skip
    take: $take
    order: $order
  ) {
    items {
      caDate
      caFromDate
      caId
      caName
      caToDate
      customerName
      documentSequenceNo
      invoice {
        scannedDocumentType
        scannedDocumentName
      }
      bankCertificate {
        scannedDocumentType
        scannedDocumentName
      }
      pocHermesId
      pocName
      protocolName
      reason
      salesRepName
      status
      subject
      ttlCostInvestmentAmount
      type
      zone
    }
    totalCount
  }
}
    `;

/**
 * __useGetAdminCommercialAgreementsDocumentQuery__
 *
 * To run a query within a React component, call `useGetAdminCommercialAgreementsDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminCommercialAgreementsDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminCommercialAgreementsDocumentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAdminCommercialAgreementsDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetAdminCommercialAgreementsDocumentQuery, GetAdminCommercialAgreementsDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminCommercialAgreementsDocumentQuery, GetAdminCommercialAgreementsDocumentQueryVariables>(GetAdminCommercialAgreementsDocumentDocument, options);
      }
export function useGetAdminCommercialAgreementsDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminCommercialAgreementsDocumentQuery, GetAdminCommercialAgreementsDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminCommercialAgreementsDocumentQuery, GetAdminCommercialAgreementsDocumentQueryVariables>(GetAdminCommercialAgreementsDocumentDocument, options);
        }
export type GetAdminCommercialAgreementsDocumentQueryHookResult = ReturnType<typeof useGetAdminCommercialAgreementsDocumentQuery>;
export type GetAdminCommercialAgreementsDocumentLazyQueryHookResult = ReturnType<typeof useGetAdminCommercialAgreementsDocumentLazyQuery>;
export type GetAdminCommercialAgreementsDocumentQueryResult = Apollo.QueryResult<GetAdminCommercialAgreementsDocumentQuery, GetAdminCommercialAgreementsDocumentQueryVariables>;
export const GetAdminMaterialsDocument = gql`
    query GetAdminMaterials($skip: Int!, $take: Int!, $order: [AdminMaterialDtoSortInput!]) {
  adminMaterials(order: $order, skip: $skip, take: $take) {
    items {
      modelId
      hermesModelId
      modelName
      type
      price
    }
    totalCount
  }
}
    `;

/**
 * __useGetAdminMaterialsQuery__
 *
 * To run a query within a React component, call `useGetAdminMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminMaterialsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAdminMaterialsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminMaterialsQuery, GetAdminMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminMaterialsQuery, GetAdminMaterialsQueryVariables>(GetAdminMaterialsDocument, options);
      }
export function useGetAdminMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminMaterialsQuery, GetAdminMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminMaterialsQuery, GetAdminMaterialsQueryVariables>(GetAdminMaterialsDocument, options);
        }
export type GetAdminMaterialsQueryHookResult = ReturnType<typeof useGetAdminMaterialsQuery>;
export type GetAdminMaterialsLazyQueryHookResult = ReturnType<typeof useGetAdminMaterialsLazyQuery>;
export type GetAdminMaterialsQueryResult = Apollo.QueryResult<GetAdminMaterialsQuery, GetAdminMaterialsQueryVariables>;
export const GetAgreementDefinitionDocument = gql`
    query GetAgreementDefinition($type: AgreementTypeEnum!, $subject: AgreementSubjectEnum!, $reason: AgreementReasonEnum!) {
  agreementDefinition(type: $type, subject: $subject, reason: $reason) {
    id
    attributes {
      id
      name
      type
      order
      values {
        enumId
        value
      }
    }
  }
}
    `;

/**
 * __useGetAgreementDefinitionQuery__
 *
 * To run a query within a React component, call `useGetAgreementDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementDefinitionQuery({
 *   variables: {
 *      type: // value for 'type'
 *      subject: // value for 'subject'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useGetAgreementDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetAgreementDefinitionQuery, GetAgreementDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementDefinitionQuery, GetAgreementDefinitionQueryVariables>(GetAgreementDefinitionDocument, options);
      }
export function useGetAgreementDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementDefinitionQuery, GetAgreementDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementDefinitionQuery, GetAgreementDefinitionQueryVariables>(GetAgreementDefinitionDocument, options);
        }
export type GetAgreementDefinitionQueryHookResult = ReturnType<typeof useGetAgreementDefinitionQuery>;
export type GetAgreementDefinitionLazyQueryHookResult = ReturnType<typeof useGetAgreementDefinitionLazyQuery>;
export type GetAgreementDefinitionQueryResult = Apollo.QueryResult<GetAgreementDefinitionQuery, GetAgreementDefinitionQueryVariables>;
export const GetAgreementDetailsDocument = gql`
    query GetAgreementDetails($agreementId: Long!) {
  agreementDetails(agreementId: $agreementId) {
    agreementId
    customerName
    customerVat
    customerAddress
    customerEmail
    pocEmployee
    pocName
    pocCity
    pocAddress
    pocRegion
    pocZone
    pocChannel
    hermesPocId
    invoicesAmount
    freeBeerQuantity
    agreementNumber
    multipleAdditionalDocumentsAllowed
    investmentAmount
    globalCustomerLimit
    customerAgreementsSpentAmount
    paymentFrequency
    declaration {
      scannedDocumentName
      scannedDocumentType
    }
    bankCertificate {
      scannedDocumentName
      scannedDocumentType
    }
    status
    type
    reason
    subject
    agreementDate
    dateFrom
    dateTo
    period
    dateFrom
    dateTo
    srName
    transmissionProtocolType
    invoiceRequired
    bankCertificateRequired
    budgetCenter
    costCenter
    validatorName
    validatorSignature
    additionalDocuments {
      pdfName
      additionalDocumentId
      dateCreated
    }
    attributeValues {
      attributeId
      type
      label
      order
      intValue
      stringValue
      decimalValue
      boolValue
      requiresCurrency
      enumValue {
        enumId
        value
      }
    }
  }
}
    `;

/**
 * __useGetAgreementDetailsQuery__
 *
 * To run a query within a React component, call `useGetAgreementDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementDetailsQuery({
 *   variables: {
 *      agreementId: // value for 'agreementId'
 *   },
 * });
 */
export function useGetAgreementDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAgreementDetailsQuery, GetAgreementDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementDetailsQuery, GetAgreementDetailsQueryVariables>(GetAgreementDetailsDocument, options);
      }
export function useGetAgreementDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementDetailsQuery, GetAgreementDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementDetailsQuery, GetAgreementDetailsQueryVariables>(GetAgreementDetailsDocument, options);
        }
export type GetAgreementDetailsQueryHookResult = ReturnType<typeof useGetAgreementDetailsQuery>;
export type GetAgreementDetailsLazyQueryHookResult = ReturnType<typeof useGetAgreementDetailsLazyQuery>;
export type GetAgreementDetailsQueryResult = Apollo.QueryResult<GetAgreementDetailsQuery, GetAgreementDetailsQueryVariables>;
export const GetAgreementStatusesPerUserDocument = gql`
    query GetAgreementStatusesPerUser($input: FilterRequestModelInput!) {
  agreementStatusesPerUser(request: $input) {
    statuses
  }
}
    `;

/**
 * __useGetAgreementStatusesPerUserQuery__
 *
 * To run a query within a React component, call `useGetAgreementStatusesPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementStatusesPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementStatusesPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAgreementStatusesPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetAgreementStatusesPerUserQuery, GetAgreementStatusesPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementStatusesPerUserQuery, GetAgreementStatusesPerUserQueryVariables>(GetAgreementStatusesPerUserDocument, options);
      }
export function useGetAgreementStatusesPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementStatusesPerUserQuery, GetAgreementStatusesPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementStatusesPerUserQuery, GetAgreementStatusesPerUserQueryVariables>(GetAgreementStatusesPerUserDocument, options);
        }
export type GetAgreementStatusesPerUserQueryHookResult = ReturnType<typeof useGetAgreementStatusesPerUserQuery>;
export type GetAgreementStatusesPerUserLazyQueryHookResult = ReturnType<typeof useGetAgreementStatusesPerUserLazyQuery>;
export type GetAgreementStatusesPerUserQueryResult = Apollo.QueryResult<GetAgreementStatusesPerUserQuery, GetAgreementStatusesPerUserQueryVariables>;
export const GetAgreementTypeTreeDocument = gql`
    query GetAgreementTypeTree($type: AgreementTypeEnum!) {
  agreementTypeTree(type: $type) {
    subjects {
      subject
      reasons {
        reason
      }
    }
  }
}
    `;

/**
 * __useGetAgreementTypeTreeQuery__
 *
 * To run a query within a React component, call `useGetAgreementTypeTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementTypeTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementTypeTreeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAgreementTypeTreeQuery(baseOptions: Apollo.QueryHookOptions<GetAgreementTypeTreeQuery, GetAgreementTypeTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementTypeTreeQuery, GetAgreementTypeTreeQueryVariables>(GetAgreementTypeTreeDocument, options);
      }
export function useGetAgreementTypeTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementTypeTreeQuery, GetAgreementTypeTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementTypeTreeQuery, GetAgreementTypeTreeQueryVariables>(GetAgreementTypeTreeDocument, options);
        }
export type GetAgreementTypeTreeQueryHookResult = ReturnType<typeof useGetAgreementTypeTreeQuery>;
export type GetAgreementTypeTreeLazyQueryHookResult = ReturnType<typeof useGetAgreementTypeTreeLazyQuery>;
export type GetAgreementTypeTreeQueryResult = Apollo.QueryResult<GetAgreementTypeTreeQuery, GetAgreementTypeTreeQueryVariables>;
export const GetAgreementsDocument = gql`
    query GetAgreements($filters: [AgreementDtoFilterInput!], $after: String) {
  agreements(where: {and: $filters}, after: $after) {
    edges {
      node {
        agreementId
        hermesPocId
        customerName
        pocName
        status
        declarationScanned
        date
        number
        type
        reason
        subject
        amount
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    `;

/**
 * __useGetAgreementsQuery__
 *
 * To run a query within a React component, call `useGetAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgreementsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAgreementsQuery(baseOptions?: Apollo.QueryHookOptions<GetAgreementsQuery, GetAgreementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgreementsQuery, GetAgreementsQueryVariables>(GetAgreementsDocument, options);
      }
export function useGetAgreementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgreementsQuery, GetAgreementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgreementsQuery, GetAgreementsQueryVariables>(GetAgreementsDocument, options);
        }
export type GetAgreementsQueryHookResult = ReturnType<typeof useGetAgreementsQuery>;
export type GetAgreementsLazyQueryHookResult = ReturnType<typeof useGetAgreementsLazyQuery>;
export type GetAgreementsQueryResult = Apollo.QueryResult<GetAgreementsQuery, GetAgreementsQueryVariables>;
export const GetAssetOrderIdsPerUserDocument = gql`
    query GetAssetOrderIdsPerUser($input: FilterRequestModelInput!) {
  assetOrderIdsPerUser(request: $input)
}
    `;

/**
 * __useGetAssetOrderIdsPerUserQuery__
 *
 * To run a query within a React component, call `useGetAssetOrderIdsPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetOrderIdsPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetOrderIdsPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssetOrderIdsPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetAssetOrderIdsPerUserQuery, GetAssetOrderIdsPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetOrderIdsPerUserQuery, GetAssetOrderIdsPerUserQueryVariables>(GetAssetOrderIdsPerUserDocument, options);
      }
export function useGetAssetOrderIdsPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetOrderIdsPerUserQuery, GetAssetOrderIdsPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetOrderIdsPerUserQuery, GetAssetOrderIdsPerUserQueryVariables>(GetAssetOrderIdsPerUserDocument, options);
        }
export type GetAssetOrderIdsPerUserQueryHookResult = ReturnType<typeof useGetAssetOrderIdsPerUserQuery>;
export type GetAssetOrderIdsPerUserLazyQueryHookResult = ReturnType<typeof useGetAssetOrderIdsPerUserLazyQuery>;
export type GetAssetOrderIdsPerUserQueryResult = Apollo.QueryResult<GetAssetOrderIdsPerUserQuery, GetAssetOrderIdsPerUserQueryVariables>;
export const GetAssetStatusesPerUserDocument = gql`
    query GetAssetStatusesPerUser($input: FilterRequestModelInput!) {
  assetStatusesPerUser(request: $input) {
    statuses
  }
}
    `;

/**
 * __useGetAssetStatusesPerUserQuery__
 *
 * To run a query within a React component, call `useGetAssetStatusesPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetStatusesPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetStatusesPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssetStatusesPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetAssetStatusesPerUserQuery, GetAssetStatusesPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetStatusesPerUserQuery, GetAssetStatusesPerUserQueryVariables>(GetAssetStatusesPerUserDocument, options);
      }
export function useGetAssetStatusesPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetStatusesPerUserQuery, GetAssetStatusesPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetStatusesPerUserQuery, GetAssetStatusesPerUserQueryVariables>(GetAssetStatusesPerUserDocument, options);
        }
export type GetAssetStatusesPerUserQueryHookResult = ReturnType<typeof useGetAssetStatusesPerUserQuery>;
export type GetAssetStatusesPerUserLazyQueryHookResult = ReturnType<typeof useGetAssetStatusesPerUserLazyQuery>;
export type GetAssetStatusesPerUserQueryResult = Apollo.QueryResult<GetAssetStatusesPerUserQuery, GetAssetStatusesPerUserQueryVariables>;
export const GetAssetsDocument = gql`
    query GetAssets($filters: [AssetDtoFilterInput!], $after: String) {
  assets(where: {and: $filters}, after: $after) {
    edges {
      node {
        assetId
        hermesPocId
        customerName
        pocName
        status
        assetType
        declarationScanned
        lastModified
        contracted
        contractedQuantity
        protocolType
        isShared
        contractNumber
        comment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a React component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument, options);
      }
export function useGetAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument, options);
        }
export type GetAssetsQueryHookResult = ReturnType<typeof useGetAssetsQuery>;
export type GetAssetsLazyQueryHookResult = ReturnType<typeof useGetAssetsLazyQuery>;
export type GetAssetsQueryResult = Apollo.QueryResult<GetAssetsQuery, GetAssetsQueryVariables>;
export const GetCombinedPocsAndSpendLimitDocument = gql`
    query GetCombinedPocsAndSpendLimit($customerId: Long!) {
  customerPocs(customerId: $customerId) {
    id
    pocName
  }
  agreementSpendLimit
}
    `;

/**
 * __useGetCombinedPocsAndSpendLimitQuery__
 *
 * To run a query within a React component, call `useGetCombinedPocsAndSpendLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCombinedPocsAndSpendLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCombinedPocsAndSpendLimitQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCombinedPocsAndSpendLimitQuery(baseOptions: Apollo.QueryHookOptions<GetCombinedPocsAndSpendLimitQuery, GetCombinedPocsAndSpendLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCombinedPocsAndSpendLimitQuery, GetCombinedPocsAndSpendLimitQueryVariables>(GetCombinedPocsAndSpendLimitDocument, options);
      }
export function useGetCombinedPocsAndSpendLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCombinedPocsAndSpendLimitQuery, GetCombinedPocsAndSpendLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCombinedPocsAndSpendLimitQuery, GetCombinedPocsAndSpendLimitQueryVariables>(GetCombinedPocsAndSpendLimitDocument, options);
        }
export type GetCombinedPocsAndSpendLimitQueryHookResult = ReturnType<typeof useGetCombinedPocsAndSpendLimitQuery>;
export type GetCombinedPocsAndSpendLimitLazyQueryHookResult = ReturnType<typeof useGetCombinedPocsAndSpendLimitLazyQuery>;
export type GetCombinedPocsAndSpendLimitQueryResult = Apollo.QueryResult<GetCombinedPocsAndSpendLimitQuery, GetCombinedPocsAndSpendLimitQueryVariables>;
export const GetContractByIdDocument = gql`
    query GetContractById($input: Long!) {
  contract(contractId: $input) {
    id
    status
    assetType
    declarationGuid
    customerName
    customerVat
    srCyrillicName
    srEmail
    contracted
    contractedQuantity
    customerEmail
    customerCity
    customerStreet
    customerStreetNumber
    contractNumber
    customerPersonnelName
    customerPersonnelRole
    contractNumber
    signaturesCount
    lastModified
    models {
      id
      type
      model
      price
      quantity
    }
  }
}
    `;

/**
 * __useGetContractByIdQuery__
 *
 * To run a query within a React component, call `useGetContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContractByIdQuery, GetContractByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractByIdQuery, GetContractByIdQueryVariables>(GetContractByIdDocument, options);
      }
export function useGetContractByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractByIdQuery, GetContractByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractByIdQuery, GetContractByIdQueryVariables>(GetContractByIdDocument, options);
        }
export type GetContractByIdQueryHookResult = ReturnType<typeof useGetContractByIdQuery>;
export type GetContractByIdLazyQueryHookResult = ReturnType<typeof useGetContractByIdLazyQuery>;
export type GetContractByIdQueryResult = Apollo.QueryResult<GetContractByIdQuery, GetContractByIdQueryVariables>;
export const GetUserCustomerByNameDocument = gql`
    query GetUserCustomerByName($input: String!) {
  userCustomersByName(searchText: $input) {
    id
    name
    customerVat
    customerAddress
    agreementSpentAmount
    declaration {
      scannedDocumentType
      scannedDocumentName
    }
  }
}
    `;

/**
 * __useGetUserCustomerByNameQuery__
 *
 * To run a query within a React component, call `useGetUserCustomerByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCustomerByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCustomerByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserCustomerByNameQuery(baseOptions: Apollo.QueryHookOptions<GetUserCustomerByNameQuery, GetUserCustomerByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCustomerByNameQuery, GetUserCustomerByNameQueryVariables>(GetUserCustomerByNameDocument, options);
      }
export function useGetUserCustomerByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCustomerByNameQuery, GetUserCustomerByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCustomerByNameQuery, GetUserCustomerByNameQueryVariables>(GetUserCustomerByNameDocument, options);
        }
export type GetUserCustomerByNameQueryHookResult = ReturnType<typeof useGetUserCustomerByNameQuery>;
export type GetUserCustomerByNameLazyQueryHookResult = ReturnType<typeof useGetUserCustomerByNameLazyQuery>;
export type GetUserCustomerByNameQueryResult = Apollo.QueryResult<GetUserCustomerByNameQuery, GetUserCustomerByNameQueryVariables>;
export const GetCustomerPerProtocolIdDocument = gql`
    query GetCustomerPerProtocolId($input: Long!) {
  customerPerProtocolId(protocolId: $input) {
    id
    name
    email
    customerAddress
    customerVat
  }
}
    `;

/**
 * __useGetCustomerPerProtocolIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerPerProtocolIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPerProtocolIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPerProtocolIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerPerProtocolIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerPerProtocolIdQuery, GetCustomerPerProtocolIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerPerProtocolIdQuery, GetCustomerPerProtocolIdQueryVariables>(GetCustomerPerProtocolIdDocument, options);
      }
export function useGetCustomerPerProtocolIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerPerProtocolIdQuery, GetCustomerPerProtocolIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerPerProtocolIdQuery, GetCustomerPerProtocolIdQueryVariables>(GetCustomerPerProtocolIdDocument, options);
        }
export type GetCustomerPerProtocolIdQueryHookResult = ReturnType<typeof useGetCustomerPerProtocolIdQuery>;
export type GetCustomerPerProtocolIdLazyQueryHookResult = ReturnType<typeof useGetCustomerPerProtocolIdLazyQuery>;
export type GetCustomerPerProtocolIdQueryResult = Apollo.QueryResult<GetCustomerPerProtocolIdQuery, GetCustomerPerProtocolIdQueryVariables>;
export const GetCustomersWithAgreementsPerUserDocument = gql`
    query GetCustomersWithAgreementsPerUser($input: FilterRequestModelInput!) {
  customersWithAgreementsPerUser(request: $input) {
    id
    name
    email
    __typename
  }
}
    `;

/**
 * __useGetCustomersWithAgreementsPerUserQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithAgreementsPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithAgreementsPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithAgreementsPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomersWithAgreementsPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersWithAgreementsPerUserQuery, GetCustomersWithAgreementsPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersWithAgreementsPerUserQuery, GetCustomersWithAgreementsPerUserQueryVariables>(GetCustomersWithAgreementsPerUserDocument, options);
      }
export function useGetCustomersWithAgreementsPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersWithAgreementsPerUserQuery, GetCustomersWithAgreementsPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersWithAgreementsPerUserQuery, GetCustomersWithAgreementsPerUserQueryVariables>(GetCustomersWithAgreementsPerUserDocument, options);
        }
export type GetCustomersWithAgreementsPerUserQueryHookResult = ReturnType<typeof useGetCustomersWithAgreementsPerUserQuery>;
export type GetCustomersWithAgreementsPerUserLazyQueryHookResult = ReturnType<typeof useGetCustomersWithAgreementsPerUserLazyQuery>;
export type GetCustomersWithAgreementsPerUserQueryResult = Apollo.QueryResult<GetCustomersWithAgreementsPerUserQuery, GetCustomersWithAgreementsPerUserQueryVariables>;
export const GetCustomersWithAssetsPerUserDocument = gql`
    query GetCustomersWithAssetsPerUser($input: FilterRequestModelInput!) {
  customersWithAssetsPerUser(request: $input) {
    id
    name
    email
    __typename
  }
}
    `;

/**
 * __useGetCustomersWithAssetsPerUserQuery__
 *
 * To run a query within a React component, call `useGetCustomersWithAssetsPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersWithAssetsPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersWithAssetsPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomersWithAssetsPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersWithAssetsPerUserQuery, GetCustomersWithAssetsPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersWithAssetsPerUserQuery, GetCustomersWithAssetsPerUserQueryVariables>(GetCustomersWithAssetsPerUserDocument, options);
      }
export function useGetCustomersWithAssetsPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersWithAssetsPerUserQuery, GetCustomersWithAssetsPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersWithAssetsPerUserQuery, GetCustomersWithAssetsPerUserQueryVariables>(GetCustomersWithAssetsPerUserDocument, options);
        }
export type GetCustomersWithAssetsPerUserQueryHookResult = ReturnType<typeof useGetCustomersWithAssetsPerUserQuery>;
export type GetCustomersWithAssetsPerUserLazyQueryHookResult = ReturnType<typeof useGetCustomersWithAssetsPerUserLazyQuery>;
export type GetCustomersWithAssetsPerUserQueryResult = Apollo.QueryResult<GetCustomersWithAssetsPerUserQuery, GetCustomersWithAssetsPerUserQueryVariables>;
export const GetCustomersNamesDocument = gql`
    query GetCustomersNames($input: String!) {
  customersNames(searchText: $input)
}
    `;

/**
 * __useGetCustomersNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomersNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersNamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomersNamesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>(GetCustomersNamesDocument, options);
      }
export function useGetCustomersNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>(GetCustomersNamesDocument, options);
        }
export type GetCustomersNamesQueryHookResult = ReturnType<typeof useGetCustomersNamesQuery>;
export type GetCustomersNamesLazyQueryHookResult = ReturnType<typeof useGetCustomersNamesLazyQuery>;
export type GetCustomersNamesQueryResult = Apollo.QueryResult<GetCustomersNamesQuery, GetCustomersNamesQueryVariables>;
export const GetDocumentEmailHistoryDocument = gql`
    query GetDocumentEmailHistory($documentId: Long!, $documentType: DocumentTypeEnum!, $assetType: AssetTypeEnum) {
  documentEmailHistory(
    documentId: $documentId
    documentType: $documentType
    assetType: $assetType
  ) {
    dateUpdated
    recipientEmail
    recipientName
    cc
    subject
    pdfFileName
  }
}
    `;

/**
 * __useGetDocumentEmailHistoryQuery__
 *
 * To run a query within a React component, call `useGetDocumentEmailHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentEmailHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentEmailHistoryQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      documentType: // value for 'documentType'
 *      assetType: // value for 'assetType'
 *   },
 * });
 */
export function useGetDocumentEmailHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentEmailHistoryQuery, GetDocumentEmailHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentEmailHistoryQuery, GetDocumentEmailHistoryQueryVariables>(GetDocumentEmailHistoryDocument, options);
      }
export function useGetDocumentEmailHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentEmailHistoryQuery, GetDocumentEmailHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentEmailHistoryQuery, GetDocumentEmailHistoryQueryVariables>(GetDocumentEmailHistoryDocument, options);
        }
export type GetDocumentEmailHistoryQueryHookResult = ReturnType<typeof useGetDocumentEmailHistoryQuery>;
export type GetDocumentEmailHistoryLazyQueryHookResult = ReturnType<typeof useGetDocumentEmailHistoryLazyQuery>;
export type GetDocumentEmailHistoryQueryResult = Apollo.QueryResult<GetDocumentEmailHistoryQuery, GetDocumentEmailHistoryQueryVariables>;
export const GetExistingAgreementTypesDocument = gql`
    query GetExistingAgreementTypes {
  existingAgreementTypes
}
    `;

/**
 * __useGetExistingAgreementTypesQuery__
 *
 * To run a query within a React component, call `useGetExistingAgreementTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingAgreementTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingAgreementTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExistingAgreementTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetExistingAgreementTypesQuery, GetExistingAgreementTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExistingAgreementTypesQuery, GetExistingAgreementTypesQueryVariables>(GetExistingAgreementTypesDocument, options);
      }
export function useGetExistingAgreementTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExistingAgreementTypesQuery, GetExistingAgreementTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExistingAgreementTypesQuery, GetExistingAgreementTypesQueryVariables>(GetExistingAgreementTypesDocument, options);
        }
export type GetExistingAgreementTypesQueryHookResult = ReturnType<typeof useGetExistingAgreementTypesQuery>;
export type GetExistingAgreementTypesLazyQueryHookResult = ReturnType<typeof useGetExistingAgreementTypesLazyQuery>;
export type GetExistingAgreementTypesQueryResult = Apollo.QueryResult<GetExistingAgreementTypesQuery, GetExistingAgreementTypesQueryVariables>;
export const GetMaterialModelsDocument = gql`
    query GetMaterialModels($type: MaterialTypeEnum!, $searchText: String) {
  materialModels(type: $type, searchText: $searchText) {
    id
    name
  }
}
    `;

/**
 * __useGetMaterialModelsQuery__
 *
 * To run a query within a React component, call `useGetMaterialModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialModelsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetMaterialModelsQuery(baseOptions: Apollo.QueryHookOptions<GetMaterialModelsQuery, GetMaterialModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaterialModelsQuery, GetMaterialModelsQueryVariables>(GetMaterialModelsDocument, options);
      }
export function useGetMaterialModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaterialModelsQuery, GetMaterialModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaterialModelsQuery, GetMaterialModelsQueryVariables>(GetMaterialModelsDocument, options);
        }
export type GetMaterialModelsQueryHookResult = ReturnType<typeof useGetMaterialModelsQuery>;
export type GetMaterialModelsLazyQueryHookResult = ReturnType<typeof useGetMaterialModelsLazyQuery>;
export type GetMaterialModelsQueryResult = Apollo.QueryResult<GetMaterialModelsQuery, GetMaterialModelsQueryVariables>;
export const GetMaterialTypesDocument = gql`
    query GetMaterialTypes {
  createContractMaterialTypes
}
    `;

/**
 * __useGetMaterialTypesQuery__
 *
 * To run a query within a React component, call `useGetMaterialTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaterialTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaterialTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaterialTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetMaterialTypesQuery, GetMaterialTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaterialTypesQuery, GetMaterialTypesQueryVariables>(GetMaterialTypesDocument, options);
      }
export function useGetMaterialTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaterialTypesQuery, GetMaterialTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaterialTypesQuery, GetMaterialTypesQueryVariables>(GetMaterialTypesDocument, options);
        }
export type GetMaterialTypesQueryHookResult = ReturnType<typeof useGetMaterialTypesQuery>;
export type GetMaterialTypesLazyQueryHookResult = ReturnType<typeof useGetMaterialTypesLazyQuery>;
export type GetMaterialTypesQueryResult = Apollo.QueryResult<GetMaterialTypesQuery, GetMaterialTypesQueryVariables>;
export const GetPocsWithAgreementsPerUserDocument = gql`
    query GetPocsWithAgreementsPerUser($input: FilterRequestModelInput!) {
  pocsWithAgreementsPerUser(request: $input) {
    hermesPocId
    pocName
    userId
    __typename
  }
}
    `;

/**
 * __useGetPocsWithAgreementsPerUserQuery__
 *
 * To run a query within a React component, call `useGetPocsWithAgreementsPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPocsWithAgreementsPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPocsWithAgreementsPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPocsWithAgreementsPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetPocsWithAgreementsPerUserQuery, GetPocsWithAgreementsPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPocsWithAgreementsPerUserQuery, GetPocsWithAgreementsPerUserQueryVariables>(GetPocsWithAgreementsPerUserDocument, options);
      }
export function useGetPocsWithAgreementsPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPocsWithAgreementsPerUserQuery, GetPocsWithAgreementsPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPocsWithAgreementsPerUserQuery, GetPocsWithAgreementsPerUserQueryVariables>(GetPocsWithAgreementsPerUserDocument, options);
        }
export type GetPocsWithAgreementsPerUserQueryHookResult = ReturnType<typeof useGetPocsWithAgreementsPerUserQuery>;
export type GetPocsWithAgreementsPerUserLazyQueryHookResult = ReturnType<typeof useGetPocsWithAgreementsPerUserLazyQuery>;
export type GetPocsWithAgreementsPerUserQueryResult = Apollo.QueryResult<GetPocsWithAgreementsPerUserQuery, GetPocsWithAgreementsPerUserQueryVariables>;
export const GetPocsWithAssetsPerUserDocument = gql`
    query GetPocsWithAssetsPerUser($input: FilterRequestModelInput!) {
  pocsWithAssetsPerUser(request: $input) {
    hermesPocId
    pocName
    userId
    __typename
  }
}
    `;

/**
 * __useGetPocsWithAssetsPerUserQuery__
 *
 * To run a query within a React component, call `useGetPocsWithAssetsPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPocsWithAssetsPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPocsWithAssetsPerUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPocsWithAssetsPerUserQuery(baseOptions: Apollo.QueryHookOptions<GetPocsWithAssetsPerUserQuery, GetPocsWithAssetsPerUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPocsWithAssetsPerUserQuery, GetPocsWithAssetsPerUserQueryVariables>(GetPocsWithAssetsPerUserDocument, options);
      }
export function useGetPocsWithAssetsPerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPocsWithAssetsPerUserQuery, GetPocsWithAssetsPerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPocsWithAssetsPerUserQuery, GetPocsWithAssetsPerUserQueryVariables>(GetPocsWithAssetsPerUserDocument, options);
        }
export type GetPocsWithAssetsPerUserQueryHookResult = ReturnType<typeof useGetPocsWithAssetsPerUserQuery>;
export type GetPocsWithAssetsPerUserLazyQueryHookResult = ReturnType<typeof useGetPocsWithAssetsPerUserLazyQuery>;
export type GetPocsWithAssetsPerUserQueryResult = Apollo.QueryResult<GetPocsWithAssetsPerUserQuery, GetPocsWithAssetsPerUserQueryVariables>;
export const GetPocsHermesIdsDocument = gql`
    query GetPocsHermesIds($input: String!) {
  pocsHermesIds(searchText: $input)
}
    `;

/**
 * __useGetPocsHermesIdsQuery__
 *
 * To run a query within a React component, call `useGetPocsHermesIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPocsHermesIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPocsHermesIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPocsHermesIdsQuery(baseOptions: Apollo.QueryHookOptions<GetPocsHermesIdsQuery, GetPocsHermesIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPocsHermesIdsQuery, GetPocsHermesIdsQueryVariables>(GetPocsHermesIdsDocument, options);
      }
export function useGetPocsHermesIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPocsHermesIdsQuery, GetPocsHermesIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPocsHermesIdsQuery, GetPocsHermesIdsQueryVariables>(GetPocsHermesIdsDocument, options);
        }
export type GetPocsHermesIdsQueryHookResult = ReturnType<typeof useGetPocsHermesIdsQuery>;
export type GetPocsHermesIdsLazyQueryHookResult = ReturnType<typeof useGetPocsHermesIdsLazyQuery>;
export type GetPocsHermesIdsQueryResult = Apollo.QueryResult<GetPocsHermesIdsQuery, GetPocsHermesIdsQueryVariables>;
export const GetProtocolByIdDocument = gql`
    query GetProtocolById($input: Long!) {
  protocol(protocolId: $input) {
    id
    status
    assetType
    declaration {
      scannedDocumentName
      scannedDocumentType
    }
    customerName
    customerEmail
    customerVat
    customerAddress
    pocName
    hermesPocId
    pocPersonnelName
    pocAddress
    srCyrillicName
    srEmail
    contractNumber
    contracted
    contractedQuantity
    protocolType
    isShared
    lastModified
    materials {
      id
      orderId
      quantity
      name
      brand
      type
    }
  }
}
    `;

/**
 * __useGetProtocolByIdQuery__
 *
 * To run a query within a React component, call `useGetProtocolByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProtocolByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProtocolByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProtocolByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProtocolByIdQuery, GetProtocolByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProtocolByIdQuery, GetProtocolByIdQueryVariables>(GetProtocolByIdDocument, options);
      }
export function useGetProtocolByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProtocolByIdQuery, GetProtocolByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProtocolByIdQuery, GetProtocolByIdQueryVariables>(GetProtocolByIdDocument, options);
        }
export type GetProtocolByIdQueryHookResult = ReturnType<typeof useGetProtocolByIdQuery>;
export type GetProtocolByIdLazyQueryHookResult = ReturnType<typeof useGetProtocolByIdLazyQuery>;
export type GetProtocolByIdQueryResult = Apollo.QueryResult<GetProtocolByIdQuery, GetProtocolByIdQueryVariables>;
export const GetAssetsRawDataReportDocument = gql`
    query GetAssetsRawDataReport($skip: Int, $take: Int, $request: AssetsRawDataReportRequestModelInput!) {
  assetsRawDataReport(skip: $skip, take: $take, request: $request) {
    items {
      assetNo
      contractNo
      assetType
      status
      dateSigned
      customerId
      customerName
      pocId
      pocName
      employeeName
      deliveryType
      materialType
      materialModel
      brand
      serialNo
      quantity
    }
    totalCount
  }
}
    `;

/**
 * __useGetAssetsRawDataReportQuery__
 *
 * To run a query within a React component, call `useGetAssetsRawDataReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsRawDataReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsRawDataReportQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetAssetsRawDataReportQuery(baseOptions: Apollo.QueryHookOptions<GetAssetsRawDataReportQuery, GetAssetsRawDataReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsRawDataReportQuery, GetAssetsRawDataReportQueryVariables>(GetAssetsRawDataReportDocument, options);
      }
export function useGetAssetsRawDataReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsRawDataReportQuery, GetAssetsRawDataReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsRawDataReportQuery, GetAssetsRawDataReportQueryVariables>(GetAssetsRawDataReportDocument, options);
        }
export type GetAssetsRawDataReportQueryHookResult = ReturnType<typeof useGetAssetsRawDataReportQuery>;
export type GetAssetsRawDataReportLazyQueryHookResult = ReturnType<typeof useGetAssetsRawDataReportLazyQuery>;
export type GetAssetsRawDataReportQueryResult = Apollo.QueryResult<GetAssetsRawDataReportQuery, GetAssetsRawDataReportQueryVariables>;
export const GetRcmcsDocument = gql`
    query GetRcmcs($searchText: String) {
  rcmcs(searchText: $searchText) {
    email
    id
  }
}
    `;

/**
 * __useGetRcmcsQuery__
 *
 * To run a query within a React component, call `useGetRcmcsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRcmcsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRcmcsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetRcmcsQuery(baseOptions?: Apollo.QueryHookOptions<GetRcmcsQuery, GetRcmcsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRcmcsQuery, GetRcmcsQueryVariables>(GetRcmcsDocument, options);
      }
export function useGetRcmcsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRcmcsQuery, GetRcmcsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRcmcsQuery, GetRcmcsQueryVariables>(GetRcmcsDocument, options);
        }
export type GetRcmcsQueryHookResult = ReturnType<typeof useGetRcmcsQuery>;
export type GetRcmcsLazyQueryHookResult = ReturnType<typeof useGetRcmcsLazyQuery>;
export type GetRcmcsQueryResult = Apollo.QueryResult<GetRcmcsQuery, GetRcmcsQueryVariables>;
export const GetSupervisorsDocument = gql`
    query GetSupervisors($searchText: String) {
  supervisors(searchText: $searchText) {
    email
    id
  }
}
    `;

/**
 * __useGetSupervisorsQuery__
 *
 * To run a query within a React component, call `useGetSupervisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetSupervisorsQuery(baseOptions?: Apollo.QueryHookOptions<GetSupervisorsQuery, GetSupervisorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupervisorsQuery, GetSupervisorsQueryVariables>(GetSupervisorsDocument, options);
      }
export function useGetSupervisorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupervisorsQuery, GetSupervisorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupervisorsQuery, GetSupervisorsQueryVariables>(GetSupervisorsDocument, options);
        }
export type GetSupervisorsQueryHookResult = ReturnType<typeof useGetSupervisorsQuery>;
export type GetSupervisorsLazyQueryHookResult = ReturnType<typeof useGetSupervisorsLazyQuery>;
export type GetSupervisorsQueryResult = Apollo.QueryResult<GetSupervisorsQuery, GetSupervisorsQueryVariables>;
export const GetSupervisorsWithoutZonesDocument = gql`
    query GetSupervisorsWithoutZones {
  supervisorsWithoutZone {
    email
    id
  }
}
    `;

/**
 * __useGetSupervisorsWithoutZonesQuery__
 *
 * To run a query within a React component, call `useGetSupervisorsWithoutZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorsWithoutZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorsWithoutZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupervisorsWithoutZonesQuery(baseOptions?: Apollo.QueryHookOptions<GetSupervisorsWithoutZonesQuery, GetSupervisorsWithoutZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupervisorsWithoutZonesQuery, GetSupervisorsWithoutZonesQueryVariables>(GetSupervisorsWithoutZonesDocument, options);
      }
export function useGetSupervisorsWithoutZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupervisorsWithoutZonesQuery, GetSupervisorsWithoutZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupervisorsWithoutZonesQuery, GetSupervisorsWithoutZonesQueryVariables>(GetSupervisorsWithoutZonesDocument, options);
        }
export type GetSupervisorsWithoutZonesQueryHookResult = ReturnType<typeof useGetSupervisorsWithoutZonesQuery>;
export type GetSupervisorsWithoutZonesLazyQueryHookResult = ReturnType<typeof useGetSupervisorsWithoutZonesLazyQuery>;
export type GetSupervisorsWithoutZonesQueryResult = Apollo.QueryResult<GetSupervisorsWithoutZonesQuery, GetSupervisorsWithoutZonesQueryVariables>;
export const GetUsersNamesDocument = gql`
    query GetUsersNames($input: String!) {
  usersNames(searchText: $input)
}
    `;

/**
 * __useGetUsersNamesQuery__
 *
 * To run a query within a React component, call `useGetUsersNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersNamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersNamesQuery(baseOptions: Apollo.QueryHookOptions<GetUsersNamesQuery, GetUsersNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersNamesQuery, GetUsersNamesQueryVariables>(GetUsersNamesDocument, options);
      }
export function useGetUsersNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersNamesQuery, GetUsersNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersNamesQuery, GetUsersNamesQueryVariables>(GetUsersNamesDocument, options);
        }
export type GetUsersNamesQueryHookResult = ReturnType<typeof useGetUsersNamesQuery>;
export type GetUsersNamesLazyQueryHookResult = ReturnType<typeof useGetUsersNamesLazyQuery>;
export type GetUsersNamesQueryResult = Apollo.QueryResult<GetUsersNamesQuery, GetUsersNamesQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($searchText: String, $take: Int, $skip: Int, $order: [UserDtoSortInput!]) {
  users(searchText: $searchText, order: $order, take: $take, skip: $skip) {
    items {
      id
      name
      role
      email
      isActive
    }
    totalCount
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const SearchEmployeeEmailsDocument = gql`
    query SearchEmployeeEmails($input: String!) {
  srEmailSearch(emailText: $input) {
    id
    name
    role
    email
  }
}
    `;

/**
 * __useSearchEmployeeEmailsQuery__
 *
 * To run a query within a React component, call `useSearchEmployeeEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEmployeeEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEmployeeEmailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchEmployeeEmailsQuery(baseOptions: Apollo.QueryHookOptions<SearchEmployeeEmailsQuery, SearchEmployeeEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchEmployeeEmailsQuery, SearchEmployeeEmailsQueryVariables>(SearchEmployeeEmailsDocument, options);
      }
export function useSearchEmployeeEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchEmployeeEmailsQuery, SearchEmployeeEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchEmployeeEmailsQuery, SearchEmployeeEmailsQueryVariables>(SearchEmployeeEmailsDocument, options);
        }
export type SearchEmployeeEmailsQueryHookResult = ReturnType<typeof useSearchEmployeeEmailsQuery>;
export type SearchEmployeeEmailsLazyQueryHookResult = ReturnType<typeof useSearchEmployeeEmailsLazyQuery>;
export type SearchEmployeeEmailsQueryResult = Apollo.QueryResult<SearchEmployeeEmailsQuery, SearchEmployeeEmailsQueryVariables>;