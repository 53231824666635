import React, { useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import { LogoTypeEnum } from '@/enums/logo-type.enum';
import { isLoggedIn } from '@/utils/authorizationUtils';

import { LogoContainer, LogoImage, LogoLabel } from './styles';

type Props = {
    path: string;
    alt?: string;
    classes?: string[];
    labelSize?: string;
    logoType: LogoTypeEnum;
};

const AppLogo: React.FC<Props> = ({ path, alt, labelSize = '16px', logoType }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const logoTypeClass =
        logoType === LogoTypeEnum.NavbarLogo
            ? 'navbar-logo'
            : logoType === LogoTypeEnum.MenuLogo
            ? 'menu-logo'
            : 'logo';
    const logoTitle = 'by Kamenitza';

    const isOnAdminRoute = useMemo(
        () => location.pathname.includes(ROUTES.Admin.Path),
        [location.pathname]
    );

    const switchToHomepage = () => {
        if (isLoggedIn() && !isOnAdminRoute) {
            if (location.pathname.includes(ROUTES.AssetList.Path)) {
                navigate(ROUTES.FullPaths.AssetList);
            }

            if (location.pathname.includes(ROUTES.AgreementList.Path)) {
                navigate(ROUTES.FullPaths.AgreementList);
            }
        }
    };

    return (
        <LogoContainer onClick={switchToHomepage}>
            <LogoImage alt={alt} src={path} className={`${logoTypeClass}`} />
            <LogoLabel $labelSize={labelSize}>{logoTitle}</LogoLabel>
        </LogoContainer>
    );
};

export default AppLogo;
