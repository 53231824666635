import { Dropdown } from 'antd';
import styled from 'styled-components';

const ShareModalDropdown = styled(Dropdown)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${({ theme }) => theme.space[40]};
    margin-bottom: ${({ theme }) => theme.space[20]};
    font-size: ${({ theme }) => theme.fontSize.xxlg};
    font-weight: 600;
    color: ${({ theme }) => theme.color.grey[40]};
    border: 1px solid ${({ theme }) => theme.color.grey[90]};
    border-radius: 4px;
`;

export const ShareModalDropdownMenu = styled(ShareModalDropdown)`
    text-align: left;

    & > div > input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
    }

    & > div > ul > li {
        &:hover {
            background-color: ${({ theme }) => theme.color.grey[10]};
        }
    }
`;

export const ShareModalSearchContainer = styled.div`
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: ${({ theme }) => theme.color.default.white};

    & > ul {
        max-height: 270px;
        overflow-y: scroll;
    }
`;

export const ShareModalNoContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: ${({ theme }) => theme.color.default.white};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const ShareModalNoContentLabel = styled.p`
    margin-top: 0;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.h2};
    color: ${({ theme }) => theme.color.grey[40]};
`;
