import { TableDataProvider } from '@/hooks/admin/useTableData';
import DefinitionsTable from '@/pages/admin/CommercialAgreements/Definitions/partials/DefinitionsTable';

const Zones = () => (
    <TableDataProvider>
        <DefinitionsTable />
    </TableDataProvider>
);

export default Zones;
