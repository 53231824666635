import React from 'react';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import App from '@/App';
import ROUTES from '@/constants/routes/routes';

import AdminRoutes from './admin/AdminRoutes';
import AgreementRoutes from './agreement/AgreementRoutes';
import AssetRoutes from './asset/AssetRoutes';
import AuthRoutes from './authentication/AuthRoutes';
import NotFound from './misc/NotFound';

const RoutesWrapper: React.FC = () => {
    return (
        <>
            <AuthRoutes />
            <AgreementRoutes />
            <AdminRoutes />
            <AssetRoutes />
        </>
    );
};

const Routing = () => (
    <BrowserRouter>
        <Routes>
            <Route path={ROUTES.Default} element={<App />}>
                <Route path="/" index element={<Navigate to={ROUTES.FullPaths.AdminUsers} />} />
                <Route path="*" element={<RoutesWrapper />} />
                <Route path={ROUTES.None} element={<NotFound />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default Routing;
