import React, { useState } from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorMessage from '@/components/shared/message/ErrorMessage';
import { StyledSubtitle } from '@/components/shared/UI/CustomSubtitle/styles';
import ERROR_CODES from '@/constants/errors/error-codes';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { useVerifyResetPasswordCodeMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';

import {
    AuthButton,
    AuthButtonsContainer,
    AuthForm,
    AuthFormContainer,
    AuthHeader,
    AuthHeaderContainer
} from '../../shared/styles';

import { RecoveryInput, RecoveryInputContainer } from './styles';

let code = '';

const Recovery = () => {
    const { t } = useTranslation('translation');
    const { setError, clearError, errorMessage } = useError();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loading, setLoading] = useState<boolean>(false);

    const [verifyResetPasswordCode] = useVerifyResetPasswordCodeMutation({
        onError: (error) => {
            setLoading(false);
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: () => {
            setLoading(false);
            clearError();
            navigate(ROUTES.FullPaths.ResetPassword, {
                state: { ...state, code }
            });
        }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFinish = async (values: any) => {
        code = '';
        for (const valuesKey in values) {
            if (values[valuesKey]) {
                // @todo
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                code += values[valuesKey];
            }
        }
        // TODO (5): Remove That.
        if (code === '') {
            setError(ERROR_CODES.CodeIsRequired, ErrorTypeEnum.FrontEndError);

            return;
        }

        setLoading(true);

        await verifyResetPasswordCode({
            variables: {
                input: {
                    email: state.email,
                    resetPasswordCode: code
                }
            }
        });
    };

    const validateInput = (event: React.FormEvent<HTMLInputElement>, position: number) => {
        const inputs = document.getElementsByTagName('input');
        event.currentTarget.value = event.currentTarget.value.toUpperCase();

        if (event.currentTarget.value !== '' && position < inputs.length - 1) {
            const inputs = document.getElementsByTagName('input')[position + 1];
            inputs.focus();
        }
    };

    return (
        <AuthFormContainer>
            <AuthHeaderContainer>
                <AuthHeader>{t('RECOVERY_TITLE')}</AuthHeader>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </AuthHeaderContainer>
            <AuthForm
                name="basic"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onClick={clearError}
                autoComplete="off">
                <RecoveryInputContainer>
                    <Form.Item name="fist-digit">
                        <RecoveryInput
                            type="text"
                            maxLength={1}
                            onInput={(e) => validateInput(e, 0)}
                            size="large"
                            autoFocus
                        />
                    </Form.Item>
                    <Form.Item name="second-digit">
                        <RecoveryInput
                            type="text"
                            maxLength={1}
                            onInput={(e) => validateInput(e, 1)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="third-digit">
                        <RecoveryInput
                            type="text"
                            maxLength={1}
                            onInput={(e) => validateInput(e, 2)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="fourth-digit">
                        <RecoveryInput
                            type="text"
                            maxLength={1}
                            onInput={(e) => validateInput(e, 3)}
                            size="large"
                        />
                    </Form.Item>
                </RecoveryInputContainer>
                <StyledSubtitle>{t('RECOVERY_SUBTITLE')}</StyledSubtitle>
                <AuthButtonsContainer>
                    <AuthButton
                        label={t('VERIFY_AND_PROCEED')}
                        buttonType={ButtonsTypesEnum.Main}
                        functionType={'submit'}
                        loading={loading}
                    />
                </AuthButtonsContainer>
            </AuthForm>
        </AuthFormContainer>
    );
};

export default Recovery;
