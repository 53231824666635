import React, { useState } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import DownloadButton from '@/components/admin/AdminButtons/DownloadButton';
import SectionHeading from '@/components/admin/SectionHeading';
import DateRangePicker from '@/components/shared/UI/DateRangePicker';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import useError from '@/hooks/error/useError';
import { downloadAgreementsRawDataReportApiCall } from '@/services/admin/downloadRawDataReportApiCall';

const AgreementRawDataReport = () => {
    const { t } = useTranslation();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const { setError } = useError();

    const handleDownload = async () => {
        if (fromDate && toDate) {
            try {
                setDownloadLoading(true);
                fromDate.setUTCHours(0, 0, 0, 0);
                toDate.setUTCHours(23, 59, 59, 999);

                const res = await downloadAgreementsRawDataReportApiCall(
                    fromDate.toISOString(),
                    toDate.toISOString()
                );
                if (res?.ok) {
                    const filename = res.headers
                        .get('content-disposition')
                        ?.split(';')
                        ?.find((n) => n.includes("filename*=UTF-8''"))
                        ?.replace("filename*=UTF-8''", '')
                        ?.trim();
                    const decodedFileName = decodeURI(filename ?? 'excelSheet');
                    const blob = await res.blob();
                    const url = URL.createObjectURL(new Blob([blob], { type: 'application/xlsx' }));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = decodedFileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else message.error(t('NO_AGREEMENTS_FOUND'));
            } catch (error) {
                setError(error, ErrorTypeEnum.Error);
            }
        }
        setDownloadLoading(false);
    };

    return (
        <>
            <SectionHeading heading={t('RAW_DATA_REPORT_AGREEMENTS')} />
            <p>{t('SELECT_PERIOD')}</p>
            <DateRangePicker setFromDate={setFromDate} setToDate={setToDate} />

            <DownloadButton
                loading={downloadLoading}
                style={{ marginLeft: '16px' }}
                onClick={handleDownload}
                title={!fromDate || !toDate ? t('SELECT_PERIOD') : undefined}
                disabled={!fromDate || !toDate}
            />
        </>
    );
};

export default AgreementRawDataReport;
