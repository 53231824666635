import { DownloadOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';

const DownloadButton = ({ ...rest }: ButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button type="primary" icon={<DownloadOutlined />} {...rest}>
            {t('DOWNLOAD')}
        </Button>
    );
};

export default DownloadButton;
