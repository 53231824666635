import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { AdminCommercialAgreementDto, DocumentTypeEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import {
    downloadAdditionalDocumentProtocolPdf,
    downloadBankStatementAndInvoicePdf,
    downloadPdf
} from '@/pages/asset/shared/utils/download-pdf';
import { FlexContainer } from '@/styles/shared/container/styles';

type Props = {
    caId: AdminCommercialAgreementDto['caId'];
    protocolName: AdminCommercialAgreementDto['protocolName'];
    invoiceData: AdminCommercialAgreementDto['invoice'];
    bankCertificateData: AdminCommercialAgreementDto['bankCertificate'];
};

const ActionButtons: React.FC<Props> = ({
    caId,
    protocolName,
    invoiceData,
    bankCertificateData
}) => {
    const { setError } = useError();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <FlexContainer $justify="center" $gap={theme?.space[12]}>
            <Button
                disabled={!caId}
                title={t('DOWNLOAD_AGREEMENT')}
                onClick={() =>
                    downloadPdf(caId, DocumentTypeEnum.CommercialAgreement, setError, undefined)
                }>
                <DownloadOutlined />
            </Button>
            <Button
                disabled={!protocolName}
                title={t('DOWNLOAD_PROTOCOL')}
                onClick={() =>
                    protocolName && downloadAdditionalDocumentProtocolPdf(protocolName, setError)
                }>
                <DownloadOutlined />
            </Button>
            <Button
                disabled={!invoiceData?.scannedDocumentName}
                title={t('DOWNLOAD_INVOICE')}
                onClick={() =>
                    invoiceData?.scannedDocumentName &&
                    downloadBankStatementAndInvoicePdf(invoiceData, setError)
                }>
                <DownloadOutlined />
            </Button>
            <Button
                disabled={!bankCertificateData?.scannedDocumentName}
                title={t('DOWNLOAD_BANK_CERTIFICATE')}
                onClick={() =>
                    bankCertificateData?.scannedDocumentName &&
                    downloadBankStatementAndInvoicePdf(bankCertificateData, setError)
                }>
                <DownloadOutlined />
            </Button>
        </FlexContainer>
    );
};

export default ActionButtons;
