import React from 'react';

import { StyledModalContent, StyledModalOverlay } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
};

const ModalOverlay: React.FC<Props> = ({ children, className }) => {
    return (
        <StyledModalOverlay className={className}>
            <StyledModalContent>{children}</StyledModalContent>
        </StyledModalOverlay>
    );
};

export default ModalOverlay;
