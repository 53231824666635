import { useEffect, useState } from 'react';

import { MenuProps } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomDropdown from '@/components/shared/UI/CustomDropdown';
import { dateFormat } from '@/constants/dates/dates';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AgreementSubjectDto,
    AgreementTypeEnum,
    useGetAgreementTypeTreeLazyQuery,
    useGetExistingAgreementTypesQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { FormStateType } from '@/pages/agreement/CreateAgreement';
import {
    Container,
    FlexAlignCenter,
    FlexContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { ListItemCardLabel } from '@/styles/shared/list-item/styles';

import { CustomDatePicker } from '../../styles';

enum DateTypes {
    DATE = 'agreementDate',
    START = 'dateFrom',
    END = 'dateTo'
}

export type DropdownValuesType = {
    label: string;
    key: string;
};

type ModalStepOneProps = {
    handleFormState: (values: Pick<FormStateType, 'firstStep'>) => void;
    disableNextButton: (value: boolean) => void;
    isDefinitionChanged?: () => boolean;
    currentState: FormStateType['firstStep'];
    oldState?: FormStateType['firstStep'];
    isEditMode?: boolean;
};

dayjs.extend(utc);

// First step of the modal is always the same
const ModalStepOne: React.FC<ModalStepOneProps> = ({
    isEditMode = false,
    handleFormState,
    isDefinitionChanged,
    disableNextButton,
    currentState,
    oldState
}) => {
    const theme = useTheme();
    const { t } = useTranslation('translation');
    const { setError, clearError, errorMessage } = useError();

    const [agreementTypeTree] = useGetAgreementTypeTreeLazyQuery();
    const [types, setTypes] = useState<DropdownValuesType[]>([]);
    const [subjects, setSubjects] = useState<DropdownValuesType[]>(currentState.subjectList);
    const [reasons, setReasons] = useState<DropdownValuesType[]>(currentState.reasonList);
    const [agreementTree, setAgreementTree] = useState<Array<AgreementSubjectDto | null>>([]);
    const [selectedAgreementDetails, setSelectedAgreementDetails] =
        useState<FormStateType['firstStep']>(currentState);

    useEffect(() => {
        !agreementTree.length &&
            selectedAgreementDetails.agreementTree.length &&
            setAgreementTree(selectedAgreementDetails.agreementTree);

        isEditMode &&
            !subjects.length &&
            !agreementTree.length &&
            fetchSubjectsAndReasons(selectedAgreementDetails.type);

        agreementTree.length &&
            !selectedAgreementDetails.reasonList.length &&
            isEditMode &&
            getReasonListBySubject(selectedAgreementDetails.subject);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreementTree.length]);

    const transformValues = <T,>(dataToTransform: T[]) => {
        return dataToTransform.map((type) => ({
            label: t(`${type}`) ?? '',
            key: type ?? ''
        }));
    };

    useGetExistingAgreementTypesQuery({
        onCompleted: ({ existingAgreementTypes }) => {
            setTypes(transformValues(existingAgreementTypes));
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    const fetchSubjectsAndReasons = (currSelectedType: string) => {
        const toAgreementTypeEnum = (value: string): AgreementTypeEnum => {
            if (Object.values(AgreementTypeEnum).includes(value as AgreementTypeEnum)) {
                return value as AgreementTypeEnum;
            }
            throw new Error(`"${value}" is not a valid AgreementTypeEnum value`);
        };

        agreementTypeTree({
            variables: {
                type: toAgreementTypeEnum(currSelectedType)
            },
            onCompleted: ({ agreementTypeTree }) => {
                setAgreementTree(agreementTypeTree?.subjects);

                const subjects = agreementTypeTree.subjects.map((data) => data?.subject);
                setSubjects(transformValues(subjects));
                setSelectedAgreementDetails((prev) => ({
                    ...prev,
                    subject:
                        isEditMode && !isDefinitionChanged?.() && oldState?.type === prev.type
                            ? prev.subject
                            : '',
                    subjectList: transformValues(subjects),
                    reason:
                        isEditMode &&
                        !isDefinitionChanged?.() &&
                        oldState?.subject === prev.subject &&
                        oldState?.type === prev.type
                            ? prev.reason
                            : '',
                    agreementTree: agreementTypeTree?.subjects
                }));
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const onTypeSelection: MenuProps['onClick'] = ({ key: currSelectedType }) => {
        setSelectedAgreementDetails((prev) => ({
            ...prev,
            type: currSelectedType
        }));

        fetchSubjectsAndReasons(currSelectedType);
    };

    const onSubjectSelection: MenuProps['onClick'] = ({ key: currSelectedSubject }) => {
        const selectedSubject = agreementTree.find(
            (s) => s?.subject === currSelectedSubject
        )?.reasons;

        const reasonsBySelectedSubject = transformValues(
            selectedSubject?.map((data) => data?.reason) ?? []
        );

        setSelectedAgreementDetails((prev) => ({
            ...prev,
            subject: currSelectedSubject,
            reason: '',
            reasonList: reasonsBySelectedSubject
        }));

        setReasons(reasonsBySelectedSubject);
    };

    const getReasonListBySubject = (currentSelectedSubject: string) => {
        const selectedSubject = agreementTree.find(
            (s) => s?.subject === currentSelectedSubject
        )?.reasons;

        const reasonsBySelectedSubject = transformValues(
            selectedSubject?.map((data) => data?.reason) ?? []
        );

        setSelectedAgreementDetails((prev) => ({
            ...prev,
            reasonList: reasonsBySelectedSubject
        }));

        setReasons(reasonsBySelectedSubject);
    };

    const onReasonSelection: MenuProps['onClick'] = ({ key: currSelectedReason }) => {
        setSelectedAgreementDetails((prev) => ({
            ...prev,
            reason: currSelectedReason
        }));
    };

    const areAllFieldsFilled = (obj: FormStateType['firstStep']) => {
        return Object.values(obj).every((value) => value);
    };

    useEffect(() => {
        handleFormState({ firstStep: selectedAgreementDetails });

        // Disable the next step if not all fields are filled
        if (areAllFieldsFilled(selectedAgreementDetails)) {
            disableNextButton(false);
        } else {
            disableNextButton(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAgreementDetails]);

    const handleDateChange = (date: dayjs.Dayjs | null, type: DateTypes) => {
        const utcDate = dayjs(date).utc().toISOString();

        setSelectedAgreementDetails((prev) => ({
            ...prev,
            [type]: utcDate
        }));
    };

    const disabledStartDate = (currentDate: dayjs.Dayjs | null): boolean => {
        const endDate = selectedAgreementDetails.dateTo;

        if (!currentDate || !endDate) {
            return false;
        }

        return (
            currentDate.isAfter(dayjs(endDate), 'day') || currentDate.isSame(dayjs(endDate), 'day')
        );
    };

    const disabledEndDate = (currentDate: dayjs.Dayjs | null): boolean => {
        const startDate = selectedAgreementDetails.dateFrom;

        if (!currentDate || !selectedAgreementDetails.dateFrom) {
            return false;
        }

        return (
            currentDate.isBefore(dayjs(startDate), 'day') ||
            currentDate.isSame(dayjs(startDate), 'day')
        );
    };

    const handleDateDefaultValue = (date: string) => {
        return date ? dayjs(date, dateFormat) : undefined;
    };

    return (
        <>
            <ScrollableContainer $paddingBottom="0">
                <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[52]}>
                    <FlexContainer $flexDirection="column" $marginBottom={theme?.space[16]}>
                        <ListItemCardLabel
                            $marginBottom={theme?.space[8]}
                            direction={'row'}
                            value={t('TYPE')}
                        />
                        <CustomDropdown
                            placeholder="CHOOSE_TYPE"
                            items={types ?? []}
                            menuProps={{ selectable: true }}
                            value={t(`${selectedAgreementDetails.type}`)}
                            onClick={onTypeSelection}
                        />
                    </FlexContainer>
                    <FlexContainer $flexDirection="column" $marginBottom={theme?.space[16]}>
                        <ListItemCardLabel
                            $marginBottom={theme?.space[8]}
                            direction={'row'}
                            value={t('SUBJECT')}
                        />
                        <CustomDropdown
                            placeholder="CHOOSE_SUBJECT"
                            items={subjects ?? []}
                            menuProps={{ selectable: true }}
                            value={t(`${selectedAgreementDetails.subject}`)}
                            disabled={!selectedAgreementDetails.type}
                            onClick={onSubjectSelection}
                        />
                    </FlexContainer>

                    <FlexContainer $flexDirection="column" $marginBottom={theme?.space[16]}>
                        <ListItemCardLabel
                            $marginBottom={theme?.space[8]}
                            direction={'row'}
                            value={t('REASON')}
                        />
                        <CustomDropdown
                            placeholder="CHOOSE_REASON"
                            items={reasons ?? []}
                            menuProps={{ selectable: true }}
                            value={t(`${selectedAgreementDetails.reason}`)}
                            disabled={!selectedAgreementDetails.subject}
                            onClick={onReasonSelection}
                        />
                    </FlexContainer>
                </Container>

                <Container $paddingX={theme?.space[0]}>
                    <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[16]}>
                        <ListItemCardLabel
                            $marginBottom={theme?.space[8]}
                            direction={'row'}
                            value={t('DATE')}
                        />
                        <CustomDatePicker
                            inputReadOnly
                            onChange={(date) => handleDateChange(date, DateTypes.DATE)}
                            placeholder={t('PICK_DATE_FROM_CALENDAR')}
                            format={dateFormat}
                            size="large"
                            defaultValue={handleDateDefaultValue(
                                selectedAgreementDetails.agreementDate
                            )}
                        />
                    </Container>

                    <div>
                        <ListItemCardLabel
                            $marginBottom={theme?.space[8]}
                            direction={'row'}
                            label={t('PERIOD')}
                        />
                        <FlexAlignCenter $gap={theme?.space[8]}>
                            <CustomDatePicker
                                inputReadOnly
                                onChange={(date) => handleDateChange(date, DateTypes.START)}
                                placeholder={t('START_DATE')}
                                format={dateFormat}
                                size="large"
                                disabledDate={disabledStartDate}
                                defaultValue={handleDateDefaultValue(
                                    selectedAgreementDetails.dateFrom
                                )}
                            />

                            <span>to</span>

                            <CustomDatePicker
                                inputReadOnly
                                onChange={(date) => handleDateChange(date, DateTypes.END)}
                                placeholder={t('END_DATE')}
                                format={dateFormat}
                                size="large"
                                disabledDate={disabledEndDate}
                                defaultValue={handleDateDefaultValue(
                                    selectedAgreementDetails.dateTo
                                )}
                            />
                        </FlexAlignCenter>
                    </div>
                </Container>
            </ScrollableContainer>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </>
    );
};

export default ModalStepOne;
