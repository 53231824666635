import { Header, Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: ${({ theme }) => theme.color.default.bg};
`;

export const ListHeader = styled(Header)`
    padding: ${({ theme }) => theme.space[16]};
    height: unset;
    padding-top: ${({ theme }) => theme.space[32]};
    background-color: ${({ theme }) => theme.color.default.white};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: ${({ theme }) => theme.shadow.heavyShadow};
`;

export const ListHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[16]};
`;

export const ListContent = styled(Content)`
    height: 58%;
    margin-top: ${({ theme }) => theme.space[8]};
    padding: 0 ${({ theme }) => theme.space[16]};
    border: 2px solid transparent;
`;

export const ListScrollableContainer = styled.div`
    display: flex;
    margin-top: ${({ theme }) => theme.space[8]};
    overflow-y: auto;
    height: 100%;
    padding-bottom: 100px;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[8]};

    .list-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: ${({ theme }) => theme.space[8]};
    }
`;

export const ListNoContentLabel = styled.p`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.h2};
    color: ${({ theme }) => theme.color.grey[40]};
    padding: ${({ theme }) => theme.space[24]};
`;
