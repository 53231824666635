import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

type TextInputProps = {
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
};

const TextInput = ({ inputValue, setInputValue }: TextInputProps) => {
    const { t } = useTranslation('translation');

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleClearSearch = () => {
        setInputValue('');
    };

    return (
        <Input
            placeholder={t('FILTER')}
            value={inputValue}
            onChange={handleSearch}
            style={{
                width: 300,
                marginLeft: 0
            }}
            suffix={
                <CloseCircleOutlined
                    onClick={handleClearSearch}
                    style={{ visibility: inputValue ? 'visible' : 'hidden' }}
                />
            }
        />
    );
};

export default TextInput;
