import { TableDataProvider } from '@/hooks/admin/useTableData';
import RegionsTable from '@/pages/admin/CommercialAgreements/Regions/partials/RegionsTable';

const Regions = () => (
    <TableDataProvider>
        <RegionsTable />
    </TableDataProvider>
);

export default Regions;
