import ActionsPanel from './partials/ActionsPanel';
import Logo from './partials/Logo';
import NavigationLinks from './partials/NavigationLinks';
import { StyledWrapper } from './styles';

const AdminNavBar = () => {
    return (
        <StyledWrapper>
            <div>
                <Logo />
                <NavigationLinks />
            </div>
            <ActionsPanel />
        </StyledWrapper>
    );
};

export default AdminNavBar;
