import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import { ReactComponent as ChevronIcon } from '@/assets/svg-icons/chevron-icon.svg';
import { ReactComponent as CurrencyIcon } from '@/assets/svg-icons/currency-icon.svg';
import { ReactComponent as EmailIcon } from '@/assets/svg-icons/email-icon.svg';
import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import { ReactComponent as ViewDetailsIcon } from '@/assets/svg-icons/view-details-icon.svg';
import { ReactComponent as XIcon } from '@/assets/svg-icons/x-icon.svg';
import EmailHistoryModal from '@/components/shared/modal/EmailHistoryModal';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import IconContainer from '@/components/shared/UI/IconContainer';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { AgreementDto, DocumentStatusEnum, DocumentTypeEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useStatus from '@/hooks/status/useStatus';
import { setCacheVal } from '@/services/utils/cacheUtils';
import AppContext from '@/store/app-context/AppContext';
import { FlexAlignCenter, FlexContainer } from '@/styles/shared/container/styles';
import { ChevronArrowIconContainer } from '@/styles/shared/icon/styles';
import {
    ListItemCardCustomerName,
    ListItemCardDate,
    ListItemCardFooter,
    ListItemCardFooterWrapper,
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardRowHeader,
    ListItemCardValue,
    ListItemRightCol
} from '@/styles/shared/list-item/styles';
import { formatDate } from '@/utils/formatDate';

import {
    AgreementCardContainer,
    DeclarationIcon,
    StyledAgreementCardBtnContainer,
    StyledAgreementCardStatus,
    StyledAgreementCardStatusLine
} from './styles';

type Props = {
    agreement: AgreementDto;
};

const AgreementCard: React.FC<Props> = ({ agreement }) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { getStatusLabel, getStatusStyle } = useStatus();
    const { errorMessage, clearError } = useError();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isResendModalOpened, setIsResendModalOpened] = useState(false);
    const { agreement: ctxAgreement } = useContext(AppContext);
    const {
        agreementId,
        status,
        customerName,
        pocName,
        hermesPocId,
        number,
        date,
        amount,
        type,
        subject,
        reason,
        declarationScanned
    } = agreement;
    const agreementType = type;

    // TODO: to be used later on
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleAssetSign = () => {
        navigate(ROUTES.AgreementList.Agreement.DynamicPath(agreementId));
    };

    const viewAgreementDetails = () => {
        if (agreementId === agreement.agreementId) {
            ctxAgreement.setAgreement(agreement);
            setCacheVal(LOCAL_STORAGE_KEYS.CommercialAgreement, agreement);
        }

        navigate(ROUTES.AgreementList.Agreement.DynamicPath(agreementId));
    };

    return (
        <>
            <AgreementCardContainer>
                <ListItemCardRowHeader>
                    <ListItemCardCustomerName direction="row" value={customerName} bolder={true} />
                    {!isExpanded && (
                        <ChevronArrowIconContainer
                            onClick={() => setIsExpanded(true)}
                            $isRotated={isExpanded}>
                            <ChevronIcon />
                        </ChevronArrowIconContainer>
                    )}
                    {isExpanded && (
                        <ChevronArrowIconContainer
                            onClick={() => setIsExpanded(false)}
                            $isRotated={isExpanded}>
                            <ChevronIcon />
                        </ChevronArrowIconContainer>
                    )}
                </ListItemCardRowHeader>

                <ListItemCardRow>
                    <div>
                        <ListItemCardLabel direction="column" label={t('POC_NAME')} />
                        <ListItemCardValue direction="column" value={pocName ?? undefined} />
                    </div>
                    <ListItemRightCol>
                        <ListItemCardLabel direction="column" label={t('POC_ID')} />
                        <ListItemCardValue direction="column" value={hermesPocId ?? undefined} />
                    </ListItemRightCol>
                </ListItemCardRow>

                {isExpanded && (
                    <div>
                        <ListItemCardRow>
                            <FlexAlignCenter $gap={theme?.space[12]}>
                                <ListItemCardLabel
                                    direction="column"
                                    $marginBottom={theme?.space[0]}
                                    label={t('COMMERCIAL_AGREEMENT_NO')}
                                />
                                <ListItemCardValue
                                    direction="column"
                                    value={number ?? undefined}
                                    $fontWeight={600}
                                />
                            </FlexAlignCenter>
                            <ListItemRightCol>
                                <ListItemCardLabel direction="column" label={t('DECLARATION')} />
                                <FlexContainer $gap={theme?.space[4]}>
                                    <>
                                        <DeclarationIcon $color={theme?.color.default.black}>
                                            {declarationScanned ? <TickIcon /> : <XIcon />}
                                        </DeclarationIcon>

                                        <ListItemCardValue
                                            $fontSize={theme?.fontSize.xlg}
                                            $fontWeight={600}
                                            direction="column"
                                            value={declarationScanned ? t('YES') : t('NO')}
                                        />
                                    </>
                                </FlexContainer>
                            </ListItemRightCol>
                        </ListItemCardRow>

                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel
                                    direction="column"
                                    label={t('INVESTMENT_AMOUNT')}
                                />
                                <FlexAlignCenter $gap={theme?.space[4]}>
                                    <CurrencyIcon />
                                    <ListItemCardValue
                                        direction="column"
                                        value={`${amount} ${t('LV')}` ?? undefined}
                                    />
                                </FlexAlignCenter>
                            </div>
                        </ListItemCardRow>

                        <FlexContainer $flexDirection="column" $gap={theme?.space[12]}>
                            <div>
                                <ListItemCardLabel direction="column" label={t('AGREEMENT_TYPE')} />
                                <ListItemCardValue
                                    direction="column"
                                    value={t(agreementType) ?? undefined}
                                />
                            </div>
                            <div>
                                <ListItemCardLabel
                                    direction="column"
                                    label={t('AGREEMENT_SUBJECT')}
                                />
                                <ListItemCardValue direction="column" value={t(subject)} />
                            </div>
                            <div>
                                <ListItemCardLabel
                                    direction="column"
                                    label={t('AGREEMENT_REASON')}
                                />
                                <ListItemCardValue
                                    direction="column"
                                    value={t(reason) ?? undefined}
                                />
                            </div>
                        </FlexContainer>

                        <StyledAgreementCardBtnContainer>
                            <ListItemCardRow>
                                {(status === DocumentStatusEnum.Signed ||
                                    status === DocumentStatusEnum.Done) && (
                                    <CustomButton
                                        onClick={() => setIsResendModalOpened(true)}
                                        label={t('PREVIEW_EMAIL')}
                                        buttonType={ButtonsTypesEnum.Secondary}
                                        icon={<EmailIcon />}
                                        style={{ whiteSpace: 'nowrap' }}
                                    />
                                )}

                                <CustomButton
                                    onClick={viewAgreementDetails}
                                    label={t('VIEW_DETAILS')}
                                    buttonType={ButtonsTypesEnum.Main}
                                    icon={<ViewDetailsIcon />}
                                />
                            </ListItemCardRow>
                        </StyledAgreementCardBtnContainer>
                    </div>
                )}

                <ListItemCardFooter>
                    <ListItemCardFooterWrapper>
                        <StyledAgreementCardStatus $style={getStatusStyle(status)}>
                            {getStatusLabel(status)}
                        </StyledAgreementCardStatus>
                        <ListItemCardDate>
                            <IconContainer>
                                <CalendarIcon />
                            </IconContainer>
                            <span>{formatDate(date)}</span>
                        </ListItemCardDate>
                    </ListItemCardFooterWrapper>
                </ListItemCardFooter>

                <StyledAgreementCardStatusLine $style={getStatusStyle(status)} />
            </AgreementCardContainer>

            {isResendModalOpened && (
                <EmailHistoryModal
                    close={() => setIsResendModalOpened(false)}
                    documentId={agreementId}
                    documentType={DocumentTypeEnum.CommercialAgreement}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </>
    );
};

export default AgreementCard;
