import { Fragment, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import attachment from '@/assets/svg-icons/attachment.svg';
import previewEmail from '@/assets/svg-icons/preview-email.svg';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';
import {
    AssetTypeEnum,
    DocumentTypeEnum,
    EmailHistory,
    useGetDocumentEmailHistoryQuery,
    useResendDocumentEmailMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { downloadPdf } from '@/pages/asset/shared/utils/download-pdf';
import {
    EmailModalBodyContainer,
    EmailModalContainer,
    EmailModalFooter,
    EmailModalLabel,
    EmailModalPdfContainer,
    EmailModalWrapper,
    StyledDownloadPdfButton
} from '@/styles/shared/email-modal/styles';
import { formatDateTime } from '@/utils/formatDate';

import CustomButton from '../../UI/CustomButton';
import ErrorModal from '../ErrorModal';
import CloseModalBar from '../partials/CloseModalBar';
import ModalBody from '../partials/ModalBody';
import ModalHeader from '../partials/ModalHeader';
import SuccessModal from '../SuccessModal';

type Props = {
    close: (closeReason?: ModalCloseReasonEnum) => void;
    documentId: number;
    documentType: DocumentTypeEnum;
    assetType?: AssetTypeEnum;
};

const EmailHistoryModal: React.FC<Props> = ({ close, documentId, documentType, assetType }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [emailInfo, setEmailInfo] = useState<EmailHistory>({
        recipientEmail: '',
        cc: '',
        subject: '',
        pdfFileName: '',
        dateUpdated: '',
        recipientName: ''
    });

    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const { errorMessage, setError, clearError } = useError();
    const { pdfFileName, dateUpdated, recipientEmail, cc, subject } = emailInfo;
    const [resendDocumentEmail, { loading: resendLoading }] = useResendDocumentEmailMutation();

    useGetDocumentEmailHistoryQuery({
        variables: {
            documentId,
            documentType,
            assetType
        },
        onCompleted: (data) => {
            setEmailInfo(data?.documentEmailHistory);
            clearError();
            setDataLoading(false);
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
            setDataLoading(false);
        }
    });

    const handleResend = () => {
        resendDocumentEmail({
            variables: {
                documentId,
                documentType
            },
            onCompleted: () => {
                setIsSuccessModalOpened(true);
            },
            onError: (error) => {
                setError(error, ErrorTypeEnum.ApolloError);
                setDataLoading(false);
            }
        });
    };

    const handleDownloadPdf = () => {
        downloadPdf(documentId, documentType, setError, assetType);
    };

    return (
        <Fragment>
            {!isSuccessModalOpened && !errorMessage && (
                <EmailModalContainer>
                    <SpinnerContainer loading={dataLoading}>
                        <CloseModalBar
                            onClick={close}
                            xColor={theme?.color.default.black}
                            backgroundColor={theme?.color.default.white}
                        />

                        <ModalHeader backgroundColor={theme?.color.default.white}>
                            <img
                                src={previewEmail}
                                alt="Preview Email"
                                onClick={handleDownloadPdf}
                            />
                            <StyledPageTitle>{t('PREVIEW_EMAIL')}</StyledPageTitle>
                        </ModalHeader>

                        <ModalBody backgroundColor={theme?.color.default.white}>
                            <EmailModalBodyContainer>
                                <EmailModalWrapper>
                                    <EmailModalLabel
                                        direction={'column'}
                                        label={t('DATE')}
                                        value={formatDateTime(dateUpdated)}
                                    />
                                    <EmailModalLabel
                                        direction={'column'}
                                        label={t('RECIPIENT')}
                                        value={recipientEmail}
                                    />
                                    <EmailModalLabel
                                        direction={'column'}
                                        label={t('CC')}
                                        value={cc}
                                    />
                                </EmailModalWrapper>

                                <EmailModalLabel
                                    direction={'column'}
                                    label={t('EMAIL_SUBJECT')}
                                    value={subject}
                                />

                                <EmailModalPdfContainer>
                                    <EmailModalLabel
                                        direction={'row'}
                                        onClickValue={handleDownloadPdf}
                                        label={t('ATTACHMENT')}
                                    />
                                    <StyledDownloadPdfButton onClick={handleDownloadPdf}>
                                        <img
                                            src={attachment}
                                            alt="Attachment"
                                            onClick={handleDownloadPdf}
                                        />
                                        {`${pdfFileName.substring(0, 8)}...`}
                                    </StyledDownloadPdfButton>
                                </EmailModalPdfContainer>
                            </EmailModalBodyContainer>
                        </ModalBody>

                        <EmailModalFooter backgroundColor={theme?.color.default.white}>
                            <CustomButton
                                loading={resendLoading}
                                onClick={handleResend}
                                label={t('RESEND_EMAIL')}
                                buttonType={ButtonsTypesEnum.Main}
                            />
                        </EmailModalFooter>
                    </SpinnerContainer>
                </EmailModalContainer>
            )}

            {errorMessage && (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => close(ModalCloseReasonEnum.Error)}
                />
            )}

            {isSuccessModalOpened && (
                <SuccessModal
                    message={t('EMAIL_SUCCESSFULLY_SENT')}
                    onConfirm={() => close(ModalCloseReasonEnum.Success)}
                />
            )}
        </Fragment>
    );
};

export default EmailHistoryModal;
