import React, { CSSProperties } from 'react';

import { StyledErrorMessage } from './styles';

type Props = {
    message: string;
    style?: CSSProperties;
};

const ErrorMessage: React.FC<Props> = ({ message, style }) => {
    return <StyledErrorMessage style={style}>{message}</StyledErrorMessage>;
};

export default ErrorMessage;
