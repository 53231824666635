export const color = {
    default: {
        white: '#fff',
        black: '#181818',
        bg: '#f8f8f8'
    },
    grey: {
        10: '#ebebeb',
        20: '#c8c8c8',
        30: '#9f9f9f',
        40: '#626262',
        50: '#555555',
        60: '#f5f5f5',
        70: '#8a8a8a',
        80: '#5c5c5c',
        90: '#d9d9d9',
        100: '#7c7b7b',
        110: '#747474',
        120: '#efefef'
    },
    red: {
        10: '#ffe7e3',
        20: '#ffccc5',
        30: '#ffa296',
        40: '#fb725f',
        50: '#FF3030',
        60: '#ffecec',
        70: '#ffc0c0'
    },
    green: {
        10: '#c8f8e6',
        20: '#90e5c7',
        30: '#55dcab',
        40: '#15d490',
        50: '#3ac922',
        60: '#5ba296'
    },
    blue: {
        5: '#e0f2ff',
        10: '#f2f5ff',
        15: '#f0f9ff',
        20: '#e3e8ff',
        30: '#bfcbff',
        40: '#8ca1ff',
        50: '#4f6ffd',
        60: '#374cac'
    },
    yellow: {
        10: '#ffc700'
    },
    orange: {
        10: '#ffad32'
    },
    assetStatus: {
        all: '#bcc8ff',
        prepared: '#ffef98',
        signed: '#b2ffa6',
        canceled: '#ffc0c0'
    },
    agreementStatus: {
        validation: '#ffef98',
        signed: '#b2ffa6',
        prepared: '#ff9900',
        canceled: '#ff6666',
        done: '#6d9ae3',
        draft: '#9f9f9f'
    },
    other: {
        dark: '#333333',
        violet: '#424bb2',
        lavender: '#917ac2'
    }
};
