import { Dropdown, Select } from 'antd';
import styled from 'styled-components';

type StyledDropdownProps = {
    $paddingX?: string;
    $paddingY?: string;
    $color?: string;
};

export const DropdownContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[8]};
`;

export const StyledDropdown = styled(Dropdown)<StyledDropdownProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${({ theme, $paddingY }) => ($paddingY !== undefined ? $paddingY : theme.space[20])}
        ${({ theme, $paddingX }) => ($paddingX !== undefined ? $paddingX : theme.space[20])};
    font-size: ${({ theme }) => theme.fontSize.h6};
    color: ${({ theme, $color }) => ($color !== undefined ? $color : theme.color.grey[30])};
    border: 1px solid ${({ theme }) => theme.color.grey[90]};
    border-radius: 4px;
`;

export const StyledSelect = styled(Select)`
    .ant-select-selection-placeholder {
        color: ${({ theme }) => theme.color.grey[70]};
        font-size: ${({ theme }) => theme.fontSize.h6};
    }

    .ant-select-selector {
        padding: ${({ theme }) => theme.space[20]} !important;
        align-items: center !important;
        border-radius: 4px;
    }

    .ant-select-selection-search-input {
        padding: ${({ theme }) => theme.space[20]} ${({ theme }) => theme.space[8]} !important;
    }
`;
