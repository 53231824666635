import { ScannedDocumentDto } from '@/graphql/main';

export default {
    apiAddress: `${process.env.REACT_APP_BACKEND_URI!}/api/`,
    apolloClientAddress: `${process.env.REACT_APP_BACKEND_URI!}/graphql/`,
    authenticationUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/Login/Login`,
    downloadDocument: `${process.env.REACT_APP_BACKEND_URI!}/api/Document/DownloadDocument`,
    downloadAdditionalDocument: `${process.env
        .REACT_APP_BACKEND_URI!}/api/Document/DownloadAdditionalDocument`,
    downloadCommercialAgreementProtocol: `${process.env
        .REACT_APP_BACKEND_URI!}/api/Document/DownloadCommercialAgreementProtocol`,
    uploadDeclarationUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/Document/UploadDeclaration`,
    downloadScannedDocument: (
        fileName: ScannedDocumentDto['scannedDocumentName'],
        documentType: ScannedDocumentDto['scannedDocumentType']
    ) =>
        `${process.env
            .REACT_APP_BACKEND_URI!}/api/Document/DownloadScannedDocument/${fileName}/${documentType}`,
    previewAgreementUrl: `PdfGenerator/Agreement?isPreview=true`,
    previewAgreementProtocolUrl: `PdfGenerator/AgreementProtocol`,
    scanAgreementProtocolUrl: `PdfGenerator/AdditionalDocuments`,
    previewContractUrl: `PdfGenerator/Contract?isPreview=true`,
    previewInstallProtocolUrl: `PdfGenerator/InstallProtocol?isPreview=true`,
    previewUninstallProtocolUrl: `PdfGenerator/UninstallProtocol?isPreview=true`,
    previewFrDiInstallProtocolUrl: `PdfGenerator/FrDiInstallProtocol?isPreview=true`,
    previewFrDiUninstallProtocolUrl: `PdfGenerator/FrDiUninstallProtocol?isPreview=true`,
    uploadAgreementAdditionalDocuments: `${process.env
        .REACT_APP_BACKEND_URI!}/api/Document/UploadAgreementAdditionalDocuments`,

    refreshTokenConfigs: {
        refreshTokenUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/Login/RefreshToken`,
        closeToTokenExpirationTime: 300000 // Near to expire is if the remaining time is under 5 minutes (300000 milliseconds)
    }
};
