import { Content, Footer, Header } from 'antd/es/layout/layout';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/svg-icons/arrow-icon.svg';
import { ReactComponent as DefaultProfileIcon } from '@/assets/svgs/profile-image-default.svg';

import AppLogo from '../../logo/AppLogo';
import CustomButton from '../../UI/CustomButton';

const slideSideways = keyframes`
    from {
        opacity: 0;
        transform: translateX(-3rem);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1100;
    background-color: white;
    width: 80%;
    height: 100%;
    left: 0;
    max-width: 400px;
    box-shadow: ${({ theme }) => theme.shadow.mediumHover};
    animation: ${slideSideways} 200ms ease-out;
`;

export const SidebarHeader = styled(Header)`
    display: flex;
    flex-direction: column;
    height: auto;
    padding: ${({ theme }) => theme.space[48]} ${({ theme }) => theme.space[72]};
    background-color: ${({ theme }) => theme.color.blue[50]};

    span {
        color: ${({ theme }) => theme.color.default.white};
    }
`;

export const SidebarLogo = styled(AppLogo)`
    position: relative;
    height: 100%;
`;

export const SidebarContent = styled(Content)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: ${({ theme }) => theme.color.default.white};
`;

export const SidebarFooter = styled(Footer)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: ${({ theme }) => theme.space[24]};
`;

export const SidebarButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.space[12]};

    button {
        width: 100%;
    }
`;

export const SidebarButton = styled(CustomButton)<{ $fill?: string }>`
    height: auto;
    padding: ${({ theme }) => theme.space[16]} ${({ theme }) => theme.space[24]};
    color: inherit;
    justify-content: start;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    transition: 0.2s all;

    svg > path {
        fill: ${(props) => props.$fill} !important;
    }

    &:hover {
        color: ${({ theme }) => theme.color.blue[50]};
        background-color: ${({ theme }) => theme.color.blue[5]};
        transition: 0.2s all;

        svg > path {
            fill: ${({ theme }) => theme.color.blue[50]};
        }
    }
`;

export const SidebarUsernameLabel = styled.span`
    font-size: ${({ theme }) => theme.fontSize.h6};
    font-weight: 700;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: ${({ theme }) => theme.space[12]};
    right: ${({ theme }) => theme.space[12]};
`;

export const CloseArrowIcon = styled(ArrowIcon)`
    width: ${({ theme }) => theme.space[24]};
    height: ${({ theme }) => theme.space[24]};

    path {
        fill: white;
    }
`;

export const ProfileIcon = styled(DefaultProfileIcon)`
    width: 50px;
    height: 50px;
    fill: ${({ theme }) => theme.color.grey[30]};
    margin-right: ${({ theme }) => theme.space[20]};
`;

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space[24]};
`;
