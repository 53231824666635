import { Header } from 'antd/es/layout/layout';
import styled from 'styled-components';

type FlexContainerProps = {
    $gap?: string;
    $flexDirection?: string;
    $justify?: string;
    $marginTop?: string;
    $marginBottom?: string;
    $paddingX?: string;
    $paddingY?: string;
    $width?: number;
};

type HeaderContainerProps = {
    $padding?: string;
    $marginBottom?: string;
    $marginTop?: string;
};

type ContainerProps = {
    $marginTop?: string;
    $marginBottom?: string;
    $paddingX?: string;
    $paddingY?: string;
    $bgColor?: string;
    $height?: number;
    $position?: string;
};

type ScrollableContainerProps = ContainerProps & {
    $paddingBottom?: string;
};

export const Container = styled.div<ContainerProps>`
    padding: ${({ $paddingY }) => ($paddingY !== undefined ? $paddingY : 0)}
        ${({ theme, $paddingX }) => ($paddingX !== undefined ? $paddingX : theme.space[16])};
    background-color: ${({ $bgColor }) => ($bgColor !== undefined ? $bgColor : 'transparent')};
    height: ${({ $height }) => ($height !== undefined ? `${$height}%` : 'auto')};
    margin-top: ${({ $marginTop }) => $marginTop};
    margin-bottom: ${({ $marginBottom }) => $marginBottom};
    position: ${({ $position }) => ($position !== undefined ? $position : 'static')};
`;

export const ScrollableContainer = styled(Container)<ScrollableContainerProps>`
    overflow: auto;
    padding-bottom: ${({ theme, $paddingBottom }) =>
        $paddingBottom !== undefined ? $paddingBottom : theme.space[32]};
    height: ${({ $height }) => ($height !== undefined ? `${$height}%` : '100%')};
`;

export const FlexContainer = styled.div<FlexContainerProps>`
    display: flex;
    flex-direction: ${({ $flexDirection }) => $flexDirection};
    gap: ${({ $gap }) => $gap};
    justify-content: ${({ $justify }) => $justify ?? 'unset'};
    margin-top: ${({ $marginTop }) => $marginTop};
    margin-bottom: ${({ $marginBottom }) => $marginBottom};
    padding: ${({ theme, $paddingY }) => ($paddingY !== undefined ? $paddingY : theme.space[0])}
        ${({ theme, $paddingX }) => ($paddingX !== undefined ? $paddingX : theme.space[0])};
    width: ${({ $width }) => ($width !== undefined ? `${$width}%` : 'auto')};
`;

export const FlexAlignCenter = styled(FlexContainer)`
    align-items: center;
`;

export const HeaderContainer = styled(Header)<HeaderContainerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme, $marginTop }) =>
        $marginTop !== undefined ? $marginTop : theme.space[32]};
    margin-bottom: ${({ theme, $marginBottom }) =>
        $marginBottom !== undefined ? $marginBottom : theme.space[20]};
    padding: ${({ theme, $padding }) => ($padding !== undefined ? $padding : theme.space[16])};
    background-color: transparent;
`;
