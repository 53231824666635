import {
    LogoutOutlined,
    SyncOutlined,
    SettingOutlined,
    TranslationOutlined
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import useLanguage from '@/hooks/useLanguage';
import { useUserContext } from '@/store/user/useUserContext';
import { logout } from '@/utils/authorizationUtils';

import { StyledActions } from '../../styles';

const ActionsPanel = () => {
    const { t } = useTranslation('translation');
    const { setLanguage } = useLanguage();
    const navigate = useNavigate();

    const {
        permissions: { canAccessSRRoutes }
    } = useUserContext();

    const switchPortal = () => {
        if (canAccessSRRoutes) {
            navigate(ROUTES.AssetList.Path);
        }
    };

    const items: MenuProps['items'] = [
        ...(canAccessSRRoutes
            ? [
                  {
                      key: '1',
                      label: (
                          <button style={{ padding: '12px' }} onClick={switchPortal}>
                              {t('SWITCH_TO_MOBILE')}
                          </button>
                      ),
                      icon: <SyncOutlined />
                  }
              ]
            : []),
        {
            key: '2',
            label: (
                <button style={{ padding: '12px' }} onClick={setLanguage}>
                    {t('CHANGE_LANGUAGE')}
                </button>
            ),
            icon: <TranslationOutlined />
        },
        {
            key: '3',
            label: (
                <button style={{ padding: '12px' }} onClick={logout}>
                    {t('LOGOUT')}
                </button>
            ),
            icon: <LogoutOutlined />
        }
    ];

    return (
        <StyledActions $hasMoreThanOneItem={canAccessSRRoutes}>
            <Dropdown menu={{ items }}>
                <Button
                    onClick={() => null}
                    icon={<SettingOutlined />}
                    shape="circle"
                    size="large"
                />
            </Dropdown>
        </StyledActions>
    );
};

export default ActionsPanel;
