import styled from 'styled-components';

import TextLabelValue from '@/components/shared/UI/TextLabelValue';

type ListItemCardRowProps = {
    $marginBottom?: string;
    $justify?: string;
};

type ValueProps = {
    $fontSize?: string;
    $fontWeight?: number;
    $color?: string;
};

type LabelProps = {
    $marginBottom?: string;
    $fontWeight?: number;
    $fontSize?: string;
    $color?: string;
};

type ListItemCardFooterProps = {
    $gap?: string;
    $justify?: string;
};

export const ListItem = styled.div`
    position: relative;
    padding: ${({ theme }) => theme.space[12]} ${({ theme }) => theme.space[16]};
    width: 100%;
    border-radius: 4px;
    box-shadow: 1px 5px 4px rgba(0, 0, 0, 0.05);
    background-color: ${({ theme }) => theme.color.default.white};
`;

export const ListItemCardRowHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[12]};
`;

export const ListItemCardRow = styled.div<ListItemCardRowProps>`
    display: flex;
    align-items: center;
    justify-content: ${({ $justify }) => ($justify !== undefined ? $justify : 'space-between')};
    margin-bottom: ${({ theme, $marginBottom }) =>
        $marginBottom !== undefined ? $marginBottom : theme.space[16]};
    gap: ${({ theme }) => theme.space[12]};

    button {
        width: 100%;
    }
`;

export const ListItemRightCol = styled.div`
    width: 23%;
`;

export const ListItemCardCustomerName = styled(TextLabelValue)`
    font-size: ${({ theme }) => theme.fontSize.h6};
`;

export const ListItemCardLabel = styled(TextLabelValue)<LabelProps>`
    span {
        font-family: ${({ theme }) => theme.fontFamily.interNormal};
        font-weight: ${({ $fontWeight }) => ($fontWeight !== undefined ? $fontWeight : 400)};
        font-size: ${({ $fontSize, theme }) =>
            $fontSize !== undefined ? $fontSize : theme.fontSize.xlg};
        color: ${({ $color, theme }) => ($color !== undefined ? $color : theme.color.grey[80])};
    }

    margin-bottom: ${({ $marginBottom, theme }) =>
        $marginBottom !== undefined ? $marginBottom : theme.space[4]};
`;

export const ListItemCardValue = styled(TextLabelValue)<ValueProps>`
    span {
        font-size: ${({ $fontSize, theme }) =>
            $fontSize !== undefined ? $fontSize : theme.fontSize.h6};
        font-weight: ${({ $fontWeight }) => $fontWeight};
        color: ${({ $color }) => $color};
    }
`;

export const ListItemCardFooterWrapper = styled.div<ListItemCardFooterProps>`
    display: flex;
    align-items: baseline;
    justify-content: ${({ $justify }) => ($justify !== undefined ? $justify : 'space-between')};
    width: 100%;
    gap: ${({ theme }) => theme.space[16]};
`;

export const ListItemCardFooter = styled.div<ListItemCardFooterProps>`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: ${({ $gap }) => $gap};
`;

export const ListItemCardDate = styled.div`
    display: flex;
    align-items: center;

    span {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSize.xlg};
        color: ${({ theme }) => theme.color.grey[110]};
    }
`;
