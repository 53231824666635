import { useContext, useEffect, useState } from 'react';

import { FREE_BEER_QUANTITY_FORM_KEY } from '@constants/keys/form-data-keys';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import ErrorMessage from '@/components/shared/message/ErrorMessage';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import envconfig from '@/config/envconfig';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { TransmissionProtocolTypeEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import AGREEMENT_PROTOCOL_PREVIEW_STYLE from '@/pages/agreement/AgreementDetails/partials/AdditionalDocuments/partials/GenerateAgreementProtocol/preview-style';
import { StyledDeclarationPreviewFooter } from '@/pages/shared/declaration/DeclarationPreview/styles';
import AppContext from '@/store/app-context/AppContext';
import { HeaderContainer, ScrollableContainer } from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';
import { getToken } from '@/utils/authorizationUtils';

const GenerateAgreementProtocol: React.FC = () => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const navigate = useNavigate();
    const {
        navbar,
        agreementDetails: { currentAgreementDetails }
    } = useContext(AppContext);
    const { setError, clearError, errorMessage } = useError();

    const [data, setData] = useState<any>(); // eslint-disable-line
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isGeneratedServiceProtocol, setIsGeneratedServiceProtocol] = useState<boolean>();

    const { getFormData } = useFormData();
    const freeBeerQuantity = getFormData(FREE_BEER_QUANTITY_FORM_KEY);
    const sanitizedHTML = DOMPurify.sanitize(data);
    const currentAgreement = currentAgreementDetails ?? getCurrentContractData<any>();

    document.body.style.width = 'unset';
    document.body.style.height = 'unset';

    const handleSign = () => {
        navigate(ROUTES.AgreementList.AgreementPreview.Sign.Path);
    };

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        setIsGeneratedServiceProtocol(
            currentAgreementDetails?.transmissionProtocolType ===
                TransmissionProtocolTypeEnum.GeneratedServiceProtocol
        );
    }, [currentAgreementDetails?.transmissionProtocolType]);

    useEffect(() => {
        if (
            currentAgreementDetails?.transmissionProtocolType !==
            TransmissionProtocolTypeEnum.GeneratedServiceProtocol
        ) {
            navigate(ROUTES.AgreementList.Agreement.AdditionalDocuments.AgreementProtocol.Scan);
        }
    }, []);

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        const requestUrl =
            currentAgreementDetails?.transmissionProtocolType ===
            TransmissionProtocolTypeEnum.GeneratedServiceProtocol
                ? envconfig.apiAddress + envconfig.previewAgreementProtocolUrl
                : envconfig.apiAddress + envconfig.scanAgreementProtocolUrl;

        fetch(requestUrl, {
            headers: {
                Authorization: `bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ ...currentAgreement, freeBeerQuantity })
        })
            .then(async (res) => {
                const data = await res.text();
                setData(data);
                setIsLoading(false);
                clearError();
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error, ErrorTypeEnum.Error);
            });

        return () => {
            navbar.disposeNavbar();
        };
    }, [freeBeerQuantity]); // eslint-disable-line

    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}

            <ScrollableContainer $height={90}>
                <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[0]}>
                    <StyledPageTitle>{t('PREVIEW_PROTOCOL')}</StyledPageTitle>
                </HeaderContainer>

                {isGeneratedServiceProtocol && (
                    <SpinnerContainer loading={isLoading}>
                        {data && <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />}
                    </SpinnerContainer>
                )}
            </ScrollableContainer>

            <StyledDeclarationPreviewFooter>
                <CustomButton
                    onClick={handleBack}
                    label={t('BACK')}
                    buttonType={ButtonsTypesEnum.Secondary}
                    style={{ marginRight: theme?.space[12] }}
                />
                <CustomButton
                    icon={<SignIcon />}
                    onClick={handleSign}
                    label={t('SIGN')}
                    buttonType={ButtonsTypesEnum.Main}
                    style={{ width: '100%' }}
                />
            </StyledDeclarationPreviewFooter>

            <style id="front-end-style">{AGREEMENT_PROTOCOL_PREVIEW_STYLE}</style>
        </>
    );
};

export default GenerateAgreementProtocol;
