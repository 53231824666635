import Title from 'antd/es/typography/Title';
import styled from 'styled-components';

import CustomCard from '@/components/shared/UI/CustomCard';

export const SignSectionTitle = styled(Title)`
    font-size: ${({ theme }) => theme.fontSize.h6} !important;
`;

export const SignSectionCard = styled(CustomCard)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[12]};
    padding: ${({ theme }) => theme.space[24]} ${({ theme }) => theme.space[16]};
`;

export const SignPreviewContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 216px;
    height: ${({ theme }) => theme.space[88]};
    border: 1px solid ${({ theme }) => theme.color.grey[70]};
`;

export const SignSectionLabel = styled.span`
    display: block;
    width: 216px;
    font-size: ${({ theme }) => theme.fontSize.m};
    color: ${({ theme }) => theme.color.grey[30]};
    text-align: end;
`;
