export function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;

        return v.toString(16);
    });
}

export function generateLargeRandomNumber(): number {
    const min = BigInt(1);
    const max = BigInt(10 ** 12); // 1 trillion

    const range = max - min;
    const randomBigInt = BigInt(Math.floor(Math.random() * Number(range + BigInt(1)))) + min;

    return Number(randomBigInt);
}
