import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTheme } from 'styled-components';

import { AssetTypeEnum, DocumentStatusEnum, DocumentTypeEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { downloadPdf } from '@/pages/asset/shared/utils/download-pdf';
import { previewPdf } from '@/pages/asset/shared/utils/preview-pdf';
import { FlexContainer } from '@/styles/shared/container/styles';

type Props = {
    assetId: number;
    assetType: AssetTypeEnum;
    assetStatus: string;
};

const ActionButtons: React.FC<Props> = ({ assetId, assetType, assetStatus }) => {
    const { setError } = useError();
    const theme = useTheme();

    return (
        <FlexContainer $justify="center" $gap={theme?.space[12]}>
            <Button
                disabled={assetStatus !== DocumentStatusEnum.Signed}
                onClick={() => previewPdf(assetId, DocumentTypeEnum.Asset, setError, assetType)}>
                <SearchOutlined />
            </Button>
            <Button
                disabled={assetStatus !== DocumentStatusEnum.Signed}
                onClick={() => downloadPdf(assetId, DocumentTypeEnum.Asset, setError, assetType)}>
                <DownloadOutlined />
            </Button>
        </FlexContainer>
    );
};

export default ActionButtons;
