import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomInput from '@/components/shared/UI/CustomInput';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { FREE_BEER_QUANTITY_FORM_KEY } from '@/constants/keys/form-data-keys';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { AgreementReasonEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import AppContext from '@/store/app-context/AppContext';
import {
    Container,
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { ListItemCardLabel } from '@/styles/shared/list-item/styles';
import { isInvalidNumber } from '@/utils/validations';

type FlexWrapperProps = {
    children: JSX.Element[];
};

const FlexWrapper: React.FC<FlexWrapperProps> = ({ children }) => {
    const theme = useTheme();

    return (
        <FlexContainer
            $flexDirection="column"
            $marginBottom={theme?.space[16]}
            $gap={theme?.space[2]}>
            {children}
        </FlexContainer>
    );
};

const AddFreeBeerQuantity: React.FC = () => {
    const theme = useTheme();
    const {
        agreementDetails: { currentAgreementDetails }
    } = useContext(AppContext);
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const { clearError, errorMessage } = useError();
    const { getFormData, setFormData } = useFormData();
    const [isValidAmount, setIsValidAmount] = useState(false);
    const isReasonFreeBeer = currentAgreementDetails?.reason === AgreementReasonEnum.FreeBeer;

    const navigateBack = () => {
        navigate(-1);
    };

    const handleInvoiceAmount = (num: string) => {
        setIsValidAmount(!isInvalidNumber(num));
        setFormData(FREE_BEER_QUANTITY_FORM_KEY, num);
    };

    const handleInvoiceNumberDefaultValue = () => {
        return getFormData(FREE_BEER_QUANTITY_FORM_KEY)?.toString() ?? '';
    };

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });
    }, []); // eslint-disable-line

    return (
        <ScrollableContainer $position="relative">
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[40]}>
                <StyledPageTitle $marginY={theme?.space[0]}>
                    {t('SELECT_FREE_BEER_QUANTITY')}
                </StyledPageTitle>
            </HeaderContainer>

            <FlexWrapper>
                <ListItemCardLabel
                    value={
                        isReasonFreeBeer ? t('QUANTITY_CRATE_STACK_KEG_FREE_BEER') : t('QUANTITY')
                    }
                />
                <CustomInput
                    key="free-beer-quantity"
                    inputType="number"
                    onChange={handleInvoiceAmount}
                    placeholder={t('SELECT_FREE_BEER_QUANTITY')}
                    step={1}
                    min={1}
                    invalid={isValidAmount}
                    invalidMsg={isValidAmount ? t('AMOUNT_VALIDATION_MESSAGE') : ''}
                    defaultValue={handleInvoiceNumberDefaultValue()}
                />
            </FlexWrapper>
            <Container
                $paddingX={theme?.space[0]}
                $position="absolute"
                style={{ bottom: '2vh', width: '92%' }}>
                <CustomButton
                    onClick={navigateBack}
                    label={t('BACK')}
                    buttonType={ButtonsTypesEnum.Main}
                    style={{ width: '100%' }}
                />
            </Container>
            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </ScrollableContainer>
    );
};

export default AddFreeBeerQuantity;
