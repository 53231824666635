import styled from 'styled-components';

export const StyledScanBtnContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 92%;
    margin-bottom: ${({ theme }) => theme.space[28]};

    button {
        width: 100%;
        cursor: pointer;
    }

    input {
        visibility: hidden;
    }
`;
