import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import envconfig from '@/config/envconfig';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { AssetDto, CustomerDto, DocumentStatusEnum, ScannedDocumentDto } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { HeaderContainer } from '@/styles/shared/container/styles';
import { getToken } from '@/utils/authorizationUtils';

import {
    StyledDeclarationPreviewContainer,
    StyledDeclarationPreviewContent,
    StyledDeclarationPreviewFooter
} from './styles';

const AssetDeclarationPreview: React.FC = () => {
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as {
        declaration?: ScannedDocumentDto;
        status?: DocumentStatusEnum;
        selectedCustomer?: CustomerDto;
    } | null;
    const theme = useTheme();
    const params = useParams<{ assetId: AssetDto['assetId'] }>();
    const [image, setImage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const { setError, clearError, errorMessage } = useError();
    const isInCreateContractProcess = !!state?.selectedCustomer;

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallOverrideBackFunctionality: () => handleBack()
        });
        fetch(
            envconfig.downloadScannedDocument(
                state?.declaration?.scannedDocumentName,
                state?.declaration?.scannedDocumentType
            ),
            {
                headers: {
                    Authorization: `bearer ${getToken()}`
                },
                method: 'GET'
            }
        )
            .then(async (response) => await response.blob())
            .then(async (imageBlob) => {
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImage(imageObjectURL);
                setLoading(false);
                clearError();
            })
            .catch((error) => {
                setLoading(false);
                setError(error, ErrorTypeEnum.Error);
            });

        return () => {
            navbar.disposeNavbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBack = () => {
        isInCreateContractProcess
            ? navigate(ROUTES.FullPaths.CreateContract, {
                  state: { selectedCustomer: state?.selectedCustomer }
              })
            : navigate(-1);
    };

    const handleScanDeclaration = () => {
        navigate(
            isInCreateContractProcess
                ? ROUTES.FullPaths.CreateContractDeclarationScan
                : ROUTES.FullPaths.ProtocolDeclarationScan(params.assetId),
            {
                state: { status: state?.status, selectedCustomer: state?.selectedCustomer }
            }
        );
    };

    return (
        <StyledDeclarationPreviewContainer>
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[12]}>
                <StyledPageTitle>{t('DECLARATION_PREVIEW')}</StyledPageTitle>
            </HeaderContainer>

            <StyledDeclarationPreviewContent>
                <SpinnerContainer loading={loading}>
                    <img src={image} alt="Declaration" />
                </SpinnerContainer>
            </StyledDeclarationPreviewContent>

            <StyledDeclarationPreviewFooter>
                {state?.status !== DocumentStatusEnum.Canceled &&
                    state?.status !== DocumentStatusEnum.Done && (
                        <CustomButton
                            onClick={handleScanDeclaration}
                            label={t('RETAKE')}
                            buttonType={ButtonsTypesEnum.Secondary}
                        />
                    )}

                <CustomButton
                    onClick={handleBack}
                    label={t('BACK')}
                    buttonType={ButtonsTypesEnum.Main}
                />
            </StyledDeclarationPreviewFooter>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </StyledDeclarationPreviewContainer>
    );
};

export default AssetDeclarationPreview;
