import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import successIcon from 'src/assets/svg-icons/success-icon.svg';

import { StyledActionMessage, StyledConfirmEmailContainer, StyledSuccessMessage } from './styles';

const ConfirmEmailSent = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            navigate(ROUTES.FullPaths.Recovery, { state: { ...state } });
        }, 3000);
    }, []); // eslint-disable-line

    return (
        <StyledConfirmEmailContainer>
            <img src={successIcon} alt="Success icon" />
            <StyledSuccessMessage>{t('CONFIRM_EMAIL_MESSAGE_SENT')}</StyledSuccessMessage>
            <StyledActionMessage>{t('CONFIRM_EMAIL_MESSAGE_SENT_SUBTITLE')}</StyledActionMessage>
        </StyledConfirmEmailContainer>
    );
};

export default ConfirmEmailSent;
