import { useEffect, useState } from 'react';

import { AGREEMENT_OPTIONAL_FIELD_KEYS } from '@constants/keys/create-agreement-optional-field-keys';
import { Checkbox, MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import CustomDropdown from '@/components/shared/UI/CustomDropdown';
import CustomInput from '@/components/shared/UI/CustomInput';
import {
    AgreementDefinitionAttributeDto,
    AttributeEnumValueDto,
    AttributeTypeEnum
} from '@/graphql/main';
import { FormStateType } from '@/pages/agreement/CreateAgreement';
import { FlexContainer, ScrollableContainer } from '@/styles/shared/container/styles';
import { ListItemCardLabel } from '@/styles/shared/list-item/styles';

import { StyledCheckboxLabel } from '../../styles';

type FieldComponentProps = {
    value: string | undefined;
    onChange: (value: string) => void;
    placeholder: string;
};

type CheckboxFieldProps = {
    value: boolean;
    onChange: (value: boolean) => void;
    placeholder: string;
};

type EnumFieldProps = {
    items: AttributeEnumValueDto[];
    onChange: MenuProps['onClick'];
    value: string | undefined;
    placeholder: string;
};

const EnumField: React.FC<EnumFieldProps> = ({ items, onChange, value, placeholder }) => {
    const transformedValues = items.map(({ value }) => ({
        label: value ?? '',
        key: value ?? ''
    }));

    return (
        <CustomDropdown
            placeholder={placeholder}
            items={transformedValues}
            menuProps={{ selectable: true }}
            value={value}
            onClick={onChange}
        />
    );
};

const CheckboxField: React.FC<CheckboxFieldProps> = ({ value, onChange, placeholder }) => (
    <FlexContainer $marginTop={'8px'}>
        <Checkbox defaultChecked={value} onChange={(value) => onChange(value.target.checked)}>
            <StyledCheckboxLabel>{placeholder}</StyledCheckboxLabel>
        </Checkbox>
    </FlexContainer>
);

const StringField: React.FC<FieldComponentProps> = ({ value, onChange, placeholder }) => (
    <CustomInput inputType="text" placeholder={placeholder} value={value} onChange={onChange} />
);

const IntegerField: React.FC<FieldComponentProps> = ({ value, onChange, placeholder }) => {
    const isValueInvalid = value !== '' ? Number(value) < 1 : false;

    return (
        <CustomInput
            inputType="number"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            step={1}
            min={1}
            isTypeInteger
            invalid={isValueInvalid}
            invalidMsg={isValueInvalid ? 'Cannot be lower than 1' : ''}
        />
    );
};

const DecimalField: React.FC<FieldComponentProps> = ({ value, onChange, placeholder }) => {
    const isValueInvalid = value !== '' ? Number(value) < 0.01 : false;

    return (
        <CustomInput
            inputType="number"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            step={0.01}
            min={0.01}
            invalid={isValueInvalid}
            invalidMsg={isValueInvalid ? 'Cannot be lower than 0,01' : ''}
        />
    );
};

type ModalStepTwoProps = {
    handleFormState: (values: Pick<FormStateType, 'secondStep'>) => void;
    disableFinishButton: (value: boolean) => void;
    attributesData: Array<AgreementDefinitionAttributeDto | null>;
    currentState: FormStateType['secondStep'];
};

const ModalStepTwo: React.FC<ModalStepTwoProps> = ({
    handleFormState,
    disableFinishButton,
    attributesData,
    currentState
}) => {
    const theme = useTheme();
    const { t } = useTranslation('translation');

    const [attributeFormData, setAttributeFormData] =
        useState<FormStateType['secondStep']>(currentState);

    useEffect(() => {
        if (attributesData.length && !attributeFormData.length) {
            const attributesArray = attributesData.map((item) => {
                return {
                    key: item?.name ?? '',
                    attributeId: item?.id ?? null,
                    type: item?.type ?? AttributeTypeEnum.String,
                    label: item?.name ?? '',
                    order: item?.order ?? 0,
                    ...(item?.type === AttributeTypeEnum.Boolean
                        ? { booleanValue: false }
                        : { value: '' })
                };
            });
            setAttributeFormData(attributesArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const areAllFieldsFilled = (arr: FormStateType['secondStep']) => {
        return arr.every((item) => {
            if (
                item.type === AttributeTypeEnum.Boolean ||
                AGREEMENT_OPTIONAL_FIELD_KEYS.includes(item.key)
            )
                return true;

            return item.value;
        });
    };

    useEffect(() => {
        handleFormState({ secondStep: attributeFormData });

        // // Disable the next step if not all fields are filled
        if (areAllFieldsFilled(attributeFormData)) {
            disableFinishButton(false);
        } else {
            disableFinishButton(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeFormData]);

    const onTypeSelection = (items: MenuInfo, key: string, values: AttributeEnumValueDto[]) => {
        const getId = values.find((item) => item.value === items?.key)?.enumId;

        setAttributeFormData((prev) => {
            return prev.map((item) => {
                if (item.key === key) {
                    return {
                        ...item,
                        value: items?.key,
                        id: getId
                    };
                }

                return item;
            });
        });
    };

    const onInputChange = (value: string, key: string) => {
        setAttributeFormData((prev) => {
            return prev.map((item) => {
                if (item.key === key) {
                    return {
                        ...item,
                        value
                    };
                }

                return item;
            });
        });
    };

    const onCheckboxChange = (value: boolean, key: string) => {
        setAttributeFormData((prev) => {
            return prev.map((item) => {
                if (item.key === key) {
                    return {
                        ...item,
                        booleanValue: value
                    };
                }

                return item;
            });
        });
    };

    const getFieldValue = (key: string) => {
        return attributeFormData.find((item) => item?.key === key)?.value;
    };

    return (
        <ScrollableContainer $paddingBottom="0">
            {attributesData.map((attributes) => {
                const label = t(`${attributes?.name ?? ''}`);
                const inputPlaceholder = `${t('ENTER')} ${label.toLocaleLowerCase()}`;

                return (
                    <FlexContainer
                        key={`step-two-attribute-${attributes?.id}`}
                        $flexDirection="column"
                        $marginBottom={theme?.space[16]}>
                        {attributes?.type !== AttributeTypeEnum.Boolean && (
                            <ListItemCardLabel
                                $marginBottom={theme?.space[8]}
                                direction={'row'}
                                value={label}
                            />
                        )}

                        {attributes?.type === AttributeTypeEnum.Enum && attributes?.values && (
                            <EnumField
                                items={attributes?.values as AttributeEnumValueDto[]}
                                onChange={(items) =>
                                    // @ts-expect-error TODO: fix this
                                    onTypeSelection(items, attributes?.name, attributes?.values)
                                }
                                value={getFieldValue(attributes?.name)}
                                placeholder={`${t('CHOOSE')} ${label.toLocaleLowerCase()}`}
                            />
                        )}
                        {attributes?.type === AttributeTypeEnum.Boolean && (
                            <CheckboxField
                                onChange={(value) => onCheckboxChange(value, attributes?.name)}
                                value={
                                    attributeFormData.find((item) => item?.key === attributes?.name)
                                        ?.booleanValue as boolean
                                }
                                placeholder={label}
                            />
                        )}

                        {attributes?.type === AttributeTypeEnum.String && (
                            <StringField
                                onChange={(value) => onInputChange(value, attributes?.name)}
                                value={getFieldValue(attributes?.name)}
                                placeholder={inputPlaceholder}
                            />
                        )}

                        {attributes?.type === AttributeTypeEnum.Int && (
                            <IntegerField
                                onChange={(value) => onInputChange(value, attributes?.name)}
                                value={getFieldValue(attributes?.name)}
                                placeholder={inputPlaceholder}
                            />
                        )}

                        {attributes?.type === AttributeTypeEnum.Decimal && (
                            <DecimalField
                                onChange={(value) => onInputChange(value, attributes?.name)}
                                value={getFieldValue(attributes?.name)}
                                placeholder={inputPlaceholder}
                            />
                        )}
                    </FlexContainer>
                );
            })}
        </ScrollableContainer>
    );
};

export default ModalStepTwo;
