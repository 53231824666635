import { useState } from 'react';

import { Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import CreateUserModal from '@/components/admin/CreateUserModal';
import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import TextInput from '@/components/admin/TextInput';
import TABLE_CONSTANTS from '@/constants/admin/tables';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SortEnumType, useGetUsersQuery, UserDto } from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import useError from '@/hooks/error/useError';
import { useUserContext } from '@/store/user/useUserContext';

import useColDefs from '../../hooks/useColDefs';

const UsersTable = () => {
    const {
        permissions: { canCreateNewUsers }
    } = useUserContext();
    const {
        handlePageChange,
        pageSize,
        itemsToSkip,
        setTotalItems,
        totalItems,
        sortingOrder,
        setSortingOrder
    } = usePaginate();
    const { setError, clearError } = useError();
    const [tableData, setTableData] = useState<UserDto[]>([]);
    const { t } = useTranslation('translation');
    const [searchText, setSearchText] = useState('');
    const { TableSize } = TABLE_CONSTANTS;

    const { loading, refetch } = useGetUsersQuery({
        variables: {
            searchText,
            take: pageSize,
            skip: itemsToSkip,
            order: sortingOrder
        },
        onCompleted: (data) => {
            if (data?.users?.items) {
                const nodes = data.users.items.map((item) => item).filter(Boolean) as UserDto[];
                setTableData(nodes);
                setTotalItems(data.users.totalCount);
            }
            clearError();
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const { colDef } = useColDefs({
        onChangeSuccess: async () => await refetch()
    });
    const onSortChange = (sorterResult: SorterResult<UserDto>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <>
            <SectionHeading heading={t('USERS_TABLE_TITLE')} />

            <Row justify={'space-between'} align={'bottom'} style={{ marginBottom: 18 }}>
                <TextInput inputValue={searchText} setInputValue={setSearchText} />
                {canCreateNewUsers && <CreateUserModal successCallback={refetch} />}
            </Row>

            <ResizableTable
                colDefs={colDef}
                tableData={tableData}
                emptyText={t('NO_RESULTS')}
                loading={loading}
                onPageChange={handlePageChange}
                totalPaginationItems={totalItems}
                size={TableSize}
                onChange={(_, __, sorterResult) =>
                    onSortChange(sorterResult as SorterResult<UserDto>)
                }
            />
        </>
    );
};

export default UsersTable;
