export const INVOICE_FORM_KEYS = {
    SCANNED_INVOICE: 'ScannedInvoice',
    INVOICE_DATE: 'InvoiceDate',
    INVOICE_NUMBER: 'InvoiceNumber',
    INVOICE_AMOUNT: 'InvoiceAmount'
};
export const FREE_BEER_QUANTITY_FORM_KEY = 'freeBeerQuantity';

export const BANK_CERTIFICATE_FORM_KEYS = {
    SCANNED_BANK_CERTIFICATE: 'scannedBankCertificate'
};

export const GENERATE_PROTOCOL_FORM_KEYS = {
    SCANNED_PROTOCOL: 'ScannedProtocol',
    SR_SIGNATURE: 'SrSignature',
    POC_EMPLOYEE_SIGNATURE: 'PocEmployeeSignature'
};
