import { useState } from 'react';

import { ServerError } from '@apollo/client';
import { GraphQLError } from 'graphql/error';
import { useTranslation } from 'react-i18next';

import ACTION_ERRORS from '@/constants/errors/action-errors';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import useErrorHandler from '@/hooks/error/useErrorHandler';

type ErrorSetter = {
    setError: (err: any, type: ErrorTypeEnum) => void;
    clearError: () => void;
    errorMessage: string | undefined;
};

const useError = (): ErrorSetter => {
    const { handleActionError } = useErrorHandler();
    const [errorMessage, setErrorMessage] = useState<string>();
    const { t } = useTranslation('translation');

    const setError = (err: any, type: ErrorTypeEnum) => {
        // TODO: This whole logic should be rewritten and secured with type!
        if (type === ErrorTypeEnum.Error) {
            for (const errKey in err.errors) {
                if (ACTION_ERRORS.includes(errKey)) {
                    handleActionError({ key: errKey, value: err.errors[errKey] });
                } else {
                    setErrorMessage(t(`${errKey}`));
                }
            }
        } else if (type === ErrorTypeEnum.ApolloError) {
            if (err.graphQLErrors?.length > 0) {
                const messageCode = (err.graphQLErrors[0] as GraphQLError).extensions
                    .code as string;
                const message = err.graphQLErrors[0].message;
                const isActionError = ACTION_ERRORS.includes(messageCode);

                if (!isActionError) {
                    setErrorMessage(`${messageCode}: ${message}`);
                } else {
                    handleActionError({ key: messageCode, value: [] });
                }
            } else if (err.clientErrors?.length > 0) {
                // For the moment we do not have such errors
            } else if (err.networkError) {
                // I take first because not sure if we can get more than one error
                const firstError = (err as ServerError).message;
                setErrorMessage(firstError);
            }
        } else if (type === ErrorTypeEnum.FrontEndError) {
            setErrorMessage(t(`${err}`));
        } else if (type === ErrorTypeEnum.LoginError) {
            setErrorMessage(t(`WRONG_EMAIL_OR_PASSWORD`));
        }
    };
    const clearError = () => {
        setErrorMessage(undefined);
    };

    return {
        setError,
        clearError,
        errorMessage
    };
};

export default useError;
