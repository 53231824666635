import React from 'react';

import logo from '@/assets/svgs/plana-logo.svg';

import { LogoContainer, LogoDescription, LogoImage } from './styles';

const CompanyLogo: React.FC = () => {
    return (
        <LogoContainer>
            <LogoDescription>Developed by</LogoDescription>
            <LogoImage alt="Plan A Logo" src={logo} />
        </LogoContainer>
    );
};

export default CompanyLogo;
