import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import AssetStatusTag from '@/components/admin/AssetStatusTag';
import { AdminAssetDto } from '@/graphql/main';

import ActionButtons from '../partials/ActionButtons';

const useColDefs = () => {
    const { t } = useTranslation();

    const colDefs: ColumnsType<AdminAssetDto> = [
        {
            key: 'employee',
            title: t('EMPLOYEE'),
            dataIndex: 'employee',
            width: 100,
            sorter: true
        },
        {
            key: 'customer',
            title: t('CUSTOMER_NAME'),
            dataIndex: 'customer',
            width: 250,
            sorter: true
        },
        {
            key: 'pocName',
            title: t('POC_NAME'),
            dataIndex: 'pocName',
            width: 250,
            sorter: true
        },
        {
            key: 'assetType',
            title: t('ASSET_TYPE'),
            dataIndex: 'assetType',
            width: 100,
            sorter: true,
            render: (_, { assetType }) => t(assetType)
        },
        {
            key: 'status',
            title: t('ASSET_STATUS'),
            dataIndex: 'status',
            width: 100,
            render: (_, { status }: AdminAssetDto) => <AssetStatusTag status={status} />,
            sorter: true
        },
        {
            key: 'contractNumber',
            title: t('CONTRACT_NUMBER'),
            dataIndex: 'contractNumber',
            sorter: true,
            width: 50
        },
        {
            key: 'pocId',
            title: t('POC_ID'),
            dataIndex: 'pocId',
            sorter: true,
            width: 50
        },
        {
            title: t('DECLARATION_SCANNED'),
            key: 'declarationScanned',
            dataIndex: 'declarationScanned',
            width: 50,
            render: (_, { declaration }: AdminAssetDto) => (declaration ? 'Yes' : 'No')
        },
        {
            key: 'contracted',
            title: t('CONTRACTED'),
            dataIndex: 'contracted',
            sorter: true,
            width: 50,
            render: (_, { contracted }) => t(contracted)
        },
        {
            key: 'contractedQuantity',
            title: t('CONTRACTED_QUANTITY'),
            dataIndex: 'contractedQuantity',
            sorter: true,
            width: 50
        },
        {
            title: t('ACTIONS'),
            key: 'actions',
            dataIndex: 'actions',
            width: 200,
            render: (_, { assetId, status, assetType }) => (
                <ActionButtons assetId={assetId} assetType={assetType} assetStatus={status} />
            )
        }
    ];

    return { colDefs };
};

export default useColDefs;
