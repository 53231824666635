import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { getCurrentLanguage } from '@/utils/languageStorage';
import translationBG from 'src/locales/bg/translation.json';
import translationEN from 'src/locales/en/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    bg: {
        translation: translationBG
    }
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: false,
        // @todo - change to 'bg'
        // @todo - before pushing to prod, we want to make sure that we have all the Bulgarian copies
        lng: getCurrentLanguage(),
        // @todo - when translations are done - add a fallback
        // fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: 'src/locales/{{lng}}/{{ns}}.json'
        },
        returnNull: false
        // @todo - remove all .toString() from tFunc
    });

export default i18n;
