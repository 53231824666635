import { ReactComponent as Icon } from '@/assets/svg-icons/contract-icon.svg';

import { StyledContractIcon } from './styles';

const ContractIcon = () => {
    return (
        <StyledContractIcon>
            <Icon />
        </StyledContractIcon>
    );
};

export default ContractIcon;
