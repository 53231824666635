import styled from 'styled-components';

export const StyledModalBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    white-space: pre-line;
    margin-top: -1px;
`;
