import styled from 'styled-components';

export const StyledCloseContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
`;
