import { useState } from 'react';

import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import SectionHeading from '@/components/admin/SectionHeading';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AssetRawDataReportModel,
    AssetsRawDataReportRequestModelInput,
    DateFilterTypeEnum,
    useGetAssetsRawDataReportQuery
} from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import { TableDataProvider } from '@/hooks/admin/useTableData';
import useError from '@/hooks/error/useError';

import RawDataReportActions from './partials/RawDataReportActions';
import RawDataReportFilters from './partials/RawDataReportFilters';
import ReportTable from './partials/ReportTable';

const RawDataReport = () => {
    const { handlePageChange, pageSize, itemsToSkip, setTotalItems, totalItems } = usePaginate();
    const [tableData, setTableData] = useState<AssetRawDataReportModel[]>([]);
    const { clearError, setError } = useError();
    const { t } = useTranslation();

    const [reportDates, setReportDates] = useState<AssetsRawDataReportRequestModelInput>({
        fromDate: undefined,
        toDate: undefined,
        filterBy: DateFilterTypeEnum.DateCreated
    });

    const { loading } = useGetAssetsRawDataReportQuery({
        variables: {
            request: reportDates,
            skip: itemsToSkip,
            take: pageSize
        },
        onCompleted: (data) => {
            if (data.assetsRawDataReport?.items) {
                setTableData(data.assetsRawDataReport?.items as AssetRawDataReportModel[]);
                clearError();
                setTotalItems(data.assetsRawDataReport.totalCount);
            }
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const handleSearch = async (reportDates: AssetsRawDataReportRequestModelInput) => {
        setReportDates(reportDates);
    };

    return (
        <TableDataProvider>
            <SectionHeading heading={t('RAW_DATA_REPORT_ASSETS')} />
            <Row justify="space-between" align="bottom">
                <RawDataReportFilters onSearch={handleSearch} />
                <RawDataReportActions dates={reportDates} hasResults={tableData.length > 0} />
            </Row>
            <ReportTable
                loading={loading}
                tableData={tableData}
                handlePageChange={handlePageChange}
                totalItems={totalItems}
            />
        </TableDataProvider>
    );
};

export default RawDataReport;
