import envconfig from '@/config/envconfig';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { AssetTypeEnum, DocumentTypeEnum } from '@/graphql/main';
import { getToken } from '@/utils/authorizationUtils';

export const previewPdf = (
    id: number,
    documentType: DocumentTypeEnum,
    setError: (error: any, errorType: ErrorTypeEnum) => void,
    assetType?: AssetTypeEnum
) => {
    fetch(
        documentType === DocumentTypeEnum.Asset
            ? `${envconfig.downloadDocument}/${id}/${documentType}/${assetType}`
            : `${envconfig.downloadDocument}/${id}/${documentType}`,
        {
            headers: {
                Authorization: `bearer ${getToken()}`
            },
            method: 'GET'
        }
    )
        .then(async (res) => {
            const blob = await res.blob();
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));

            // Open the PDF in a new tab for preview
            window.open(url, '_blank');
        })
        .catch((error) => {
            setError(error, ErrorTypeEnum.Error);
        });
};
export const previewAdditionalDocumentPdf = (
    documentName: string,
    setError: (error: any, errorType: ErrorTypeEnum) => void
) => {
    fetch(`${envconfig.downloadAdditionalDocument}/${documentName}`, {
        headers: {
            Authorization: `bearer ${getToken()}`
        },
        method: 'GET'
    })
        .then(async (res) => {
            const blob = await res.blob();
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));

            // Open the PDF in a new tab for preview
            window.open(url, '_blank');
        })
        .catch((error) => {
            setError(error, ErrorTypeEnum.Error);
        });
};
