import React, { useEffect, useState } from 'react';

import { Button, message, Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import TABLE_CONSTANTS from '@/constants/admin/tables';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AdminMaterialDto,
    AdminMaterialPriceUpdateInput,
    GetAdminMaterialsQuery,
    SortEnumType,
    useGetAdminMaterialsQuery,
    useUpdateAdminModelsPricesMutation
} from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import { useTableData } from '@/hooks/admin/useTableData';
import useError from '@/hooks/error/useError';

import useColDefs from '../../hooks/useColDefs';

const MaterialManagement: React.FC = () => {
    const { clearError, setError } = useError();
    const {
        pageSize,
        itemsToSkip,
        handlePageChange,
        setTotalItems,
        totalItems,
        sortingOrder,
        setSortingOrder
    } = usePaginate();
    const { t } = useTranslation('translation');
    const colDefs = useColDefs();

    const [initialDataState, setInitialDataState] = useState<AdminMaterialDto[]>([]);
    const [dataChanged, setDataChanged] = useState(false);
    const { dataState } = useTableData<AdminMaterialDto>();
    const { TableSize } = TABLE_CONSTANTS;

    const { loading, refetch } = useGetAdminMaterialsQuery({
        variables: {
            take: pageSize,
            skip: itemsToSkip,
            order: sortingOrder
        },
        onCompleted: (data: GetAdminMaterialsQuery) => {
            const nodes = data?.adminMaterials?.items;
            if (nodes) {
                setInitialDataState(
                    nodes.filter((node): node is AdminMaterialDto => node !== null)
                );
                setTotalItems(data?.adminMaterials?.totalCount ?? 0);
            }

            clearError();
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    const [updateModelPrice] = useUpdateAdminModelsPricesMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await refetch();
        }
    });

    const handleUpdateModelPrices = async (
        materialPriceUpdateInput: AdminMaterialPriceUpdateInput[]
    ) => {
        const inputModels: AdminMaterialPriceUpdateInput[] = materialPriceUpdateInput
            .filter((material) => material.price !== null)
            .map((material) => ({
                modelId: material.modelId,
                price: material.price
            }));

        try {
            if (dataChanged) {
                setInitialDataState(dataState);
            }

            await updateModelPrice({
                variables: {
                    inputModels
                }
            });

            void message.success(t('TABLE_UPDATE_SUCCESS'));
        } catch (e) {
            void message.error(t('TABLE_UPDATE_FAIL'));
        }
    };

    useEffect(() => {
        setDataChanged(JSON.stringify(dataState) !== JSON.stringify(initialDataState));
    }, [dataState, initialDataState]);
    const onSortChange = (sorterResult: SorterResult<AdminMaterialDto>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <>
            <SectionHeading heading={t('MATERIALS_TABLE_TITLE')} />

            <Row justify="end" style={{ marginBottom: 18 }}>
                <Button
                    type="primary"
                    size="middle"
                    disabled={dataState.length === 0 || !dataChanged}
                    onClick={async () =>
                        await handleUpdateModelPrices(dataState as AdminMaterialPriceUpdateInput[])
                    }>
                    {t('SAVE')}
                </Button>
            </Row>
            <ResizableTable
                emptyText={t('NO_RESULTS')}
                tableData={initialDataState}
                colDefs={colDefs}
                hasSearchBar={false}
                loading={loading}
                onPageChange={(page, pageSize) => handlePageChange(page, pageSize)}
                totalPaginationItems={totalItems}
                size={TableSize}
                editableCellsType="number"
                onChange={(_, __, sorterResult) =>
                    onSortChange(sorterResult as SorterResult<AdminMaterialDto>)
                }
            />
        </>
    );
};

export default MaterialManagement;
