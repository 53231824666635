import ROUTES from './routes';

const PUBLIC_ROUTES: string[] = [
    ROUTES.FullPaths.Login,
    ROUTES.FullPaths.ForgottenPassword,
    ROUTES.FullPaths.ConfirmEmailSent,
    ROUTES.FullPaths.ResetPassword,
    ROUTES.FullPaths.Recovery
];

export default PUBLIC_ROUTES;
