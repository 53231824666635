import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as EmailIcon } from '@/assets/svg-icons/email-icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/svg-icons/profile-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import WarningModal from '@/components/shared/modal/WarningModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomInput from '@/components/shared/UI/CustomInput';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import { AgreementDetailsDto, useCancelAgreementMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { FlexContainer } from '@/styles/shared/container/styles';
import { ListItemCardLabel, ListItemCardRow } from '@/styles/shared/list-item/styles';
import { saveCurrentContractData } from '@/utils/assetStorage';
import { isValidEmail } from '@/utils/validations';

import CancelAgreement from '../CancelAgreement';

type Props = {
    customerEmail: string | undefined;
    pocEmployee: string | undefined;
    agreementId: string;
};

const AgreementSign: React.FC<Props> = ({ customerEmail, pocEmployee, agreementId }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const navigate = useNavigate();
    const { agreementDetails: ctxAgreementDetails } = useContext(AppContext);
    const [canProceed, setCanProceed] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>();
    const [isWarning, setIsWarning] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { errorMessage, setError, clearError } = useError();

    const [cancelAgreement] = useCancelAgreementMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: () => {
            setIsWarning(false);
            setIsSuccess(true);
            clearError();
        }
    });

    const handleCancelAgreement = () => {
        cancelAgreement({ variables: { agreementId } });
    };

    const onCustomerMailChange = (email: string) => {
        const newAgreementDetails = {
            ...ctxAgreementDetails.currentAgreementDetails
        } as AgreementDetailsDto;
        newAgreementDetails.customerEmail = email;
        ctxAgreementDetails.setAgreementDetails(newAgreementDetails);
    };

    const onPocEmployeeChange = (employeeName: string) => {
        const newAgreementDetails = {
            ...ctxAgreementDetails.currentAgreementDetails
        } as AgreementDetailsDto;
        newAgreementDetails.pocEmployee = employeeName;
        ctxAgreementDetails.setAgreementDetails(newAgreementDetails);
    };

    useEffect(() => {
        const hasScannedDeclaration =
            ctxAgreementDetails.currentAgreementDetails?.declaration ?? false;
        const customerEmail = ctxAgreementDetails?.currentAgreementDetails?.customerEmail;
        const hasInputValidEmail = isValidEmail(customerEmail ?? '');
        setIsEmailValid(hasInputValidEmail);

        const hasInputPocEmployee =
            ctxAgreementDetails?.currentAgreementDetails?.pocEmployee ?? false;

        if (hasScannedDeclaration && hasInputValidEmail && hasInputPocEmployee) {
            setCanProceed(true);

            return;
        }

        setCanProceed(false);
    }, [ctxAgreementDetails.currentAgreementDetails]);

    const navigateToDashboard = () => {
        navigate(ROUTES.FullPaths.AgreementList);
    };

    const handlePreview = () => {
        if (ctxAgreementDetails.currentAgreementDetails) {
            saveCurrentContractData<AgreementDetailsDto>(
                ctxAgreementDetails.currentAgreementDetails
            );
        }

        navigate(ROUTES.AgreementList.AgreementPreview.Path);
    };

    return (
        <>
            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[12]}>
                <ListItemCardLabel direction={'column'} label={t('CUSTOMER_EMAIL')} />
                <CustomInput
                    icon={<EmailIcon />}
                    inputType="email"
                    onChange={onCustomerMailChange}
                    placeholder={t('ENTER_CUSTOMER_EMAIL')}
                    defaultValue={customerEmail}
                    invalid={!isEmailValid}
                    invalidMsg={t('INVALID_EMAIL')}
                />
            </FlexContainer>

            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[12]}>
                <ListItemCardLabel direction={'column'} label={t('POC_EMPLOYEE')} />
                <CustomInput
                    icon={<ProfileIcon />}
                    onChange={onPocEmployeeChange}
                    isLettersOnly
                    placeholder={t('ENTER_POC_EMPLOYEE_NAME')}
                    defaultValue={pocEmployee}
                    value={ctxAgreementDetails.currentAgreementDetails?.pocEmployee ?? pocEmployee}
                />
            </FlexContainer>

            <ListItemCardRow $marginBottom={theme?.space[4]}>
                <CustomButton
                    onClick={handlePreview}
                    label={t('SIGN')}
                    buttonType={canProceed ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                    icon={<SignIcon />}
                />
            </ListItemCardRow>

            {!canProceed && (
                <ListItemCardRow $marginBottom={theme?.space[8]}>
                    <InfoMessage message={t('SIGN_AGREEMENT_INFO_MSG')} />
                </ListItemCardRow>
            )}

            <CancelAgreement agreementId={agreementId} />

            {isWarning && (
                <WarningModal
                    accept={handleCancelAgreement}
                    deny={() => setIsWarning(false)}
                    title={t('CANCEL_AGREEMENT')}
                    message={t('CANCEL_AGREEMENT_WARNING_MESSAGE')}
                    subMessage={t('CANCEL_AGREEMENT_WARNING_SUBMESSAGE')}
                />
            )}

            {isSuccess && (
                <SuccessModal
                    title={t('CANCEL_AGREEMENT')}
                    message={t('CANCEL_AGREEMENT_SUCCESS_MESSAGE')}
                    modalType={SuccessModalTypeEnum.Draft}
                    onConfirm={navigateToDashboard}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} />}
        </>
    );
};

export default AgreementSign;
