import { Content, Header } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const StyledAuthHeader = styled(Header)`
    display: flex;
    justify-content: center;
    height: 35%;
    background-color: ${({ theme }) => theme.color.default.bg};
`;

export const StyledAuthContent = styled(Content)`
    display: flex;
    justify-content: center;
    height: 65%;
`;
