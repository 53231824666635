import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import jwtDecode, { JwtPayload } from 'jwt-decode';

import { UserRoleEnum } from '@/graphql/main';
import { getToken, isLoggedIn } from '@/utils/authorizationUtils';

import { rolesEligibleForAdminRoutes, rolesEligibleForSRRoutes, type Permissions } from './helpers';

type ContextState = {
    token: string | null;
    setToken: React.Dispatch<React.SetStateAction<string | null>>;
    role: User['role'] | null;
    permissions: Permissions;
    clearUserData: () => void;
    isF2RCMC: boolean;
    isAdmin: boolean;
    isSuperAdmin: boolean;
};

type Props = {
    children: React.ReactNode;
};

const UserContext = createContext({} as ContextState);

function UserProvider({ ...props }: Props) {
    const [token, setToken] = useState<ContextState['token']>(null);
    const [role, setRole] = useState<ContextState['role']>(null);
    const [canCreateNewUsers, setCanCreateNewUsers] =
        useState<Permissions['canCreateNewUsers']>(false);

    const canAccessSRRoutes = useMemo(() => rolesEligibleForSRRoutes.includes(role), [role]);
    const canAccessAdminRoutes = useMemo(() => rolesEligibleForAdminRoutes.includes(role), [role]);

    useEffect(() => {
        if (token) {
            const decodedRole = jwtDecode<JwtPayload>(token)[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' as keyof JwtPayload
            ]?.toString() as User['role'];

            setRole(decodedRole);
        } else {
            setRole(null);
        }
    }, [token]);

    useEffect(() => {
        const tokenFromStore = getToken();
        if (isLoggedIn() && !token && tokenFromStore) {
            setToken(getToken());
        }
    }, [token]);

    useEffect(() => {
        setCanCreateNewUsers(role === UserRoleEnum.SuperAdmin);
    }, [role]);

    const clearUserData = () => {
        setToken(null);
        setRole(null);
        setCanCreateNewUsers(false);
    };

    const permissions = {
        canCreateNewUsers,
        canAccessSRRoutes,
        canAccessAdminRoutes
    };

    const value = {
        token,
        setToken,
        role,
        permissions,
        clearUserData,
        isF2RCMC: role === UserRoleEnum.Rcmc || role === UserRoleEnum.Supervisor,
        isAdmin: role === UserRoleEnum.Admin,
        isSuperAdmin: role === UserRoleEnum.SuperAdmin
    };

    return <UserContext.Provider value={value} {...props} />;
}

function useUserContext() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error(`useUserContext must be used within a UserProvider`);
    }

    return context;
}

export { UserProvider, useUserContext };
