import { SizeType } from 'antd/es/config-provider/SizeContext';

const TABLE_CONSTANTS: {
    TableSize: SizeType;
    InitialPageSize: number;
    PageSizeOptions: string[] | number[];
} = {
    TableSize: 'small',
    InitialPageSize: 10,
    PageSizeOptions: ['10', '20', '30', '50']
};

export default TABLE_CONSTANTS;
