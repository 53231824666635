import { StyledCard } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
};

const CustomCard: React.FC<Props> = ({ children, className }) => {
    return <StyledCard className={className}>{children}</StyledCard>;
};

export default CustomCard;
