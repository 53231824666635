import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as AttachmentIcon } from '@/assets/svg-icons/attachment.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import { ReactComponent as ScanIcon } from '@/assets/svg-icons/scan-icon.svg';
import { ReactComponent as SquarePlusIcon } from '@/assets/svg-icons/square-plus-icon.svg';
import { ReactComponent as TickIconGrey } from '@/assets/svg-icons/tick-icon-grey.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CloseModalBar from '@/components/shared/modal/partials/CloseModalBar';
import ModalBody from '@/components/shared/modal/partials/ModalBody';
import ModalContainer from '@/components/shared/modal/partials/ModalContainer';
import ModalFooter from '@/components/shared/modal/partials/ModalFooter';
import ModalHeader from '@/components/shared/modal/partials/ModalHeader';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import envconfig from '@/config/envconfig';
import {
    BANK_CERTIFICATE_FORM_KEYS,
    FREE_BEER_QUANTITY_FORM_KEY,
    GENERATE_PROTOCOL_FORM_KEYS,
    INVOICE_FORM_KEYS
} from '@/constants/keys/form-data-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import { AgreementReasonEnum, TransmissionProtocolTypeEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import AppContext from '@/store/app-context/AppContext';
import { FlexAlignCenter, FlexContainer } from '@/styles/shared/container/styles';
import { ListItemCardLabel } from '@/styles/shared/list-item/styles';
import { getToken } from '@/utils/authorizationUtils';
import { disableBackFunctionality } from '@/utils/windowUtils';

type Props = {
    isModalOpened: boolean;
    closeModal: () => void;
};

type FlexWrapperProps = {
    children: JSX.Element[];
};

const FlexWrapper: React.FC<FlexWrapperProps> = ({ children }) => {
    const theme = useTheme();

    return (
        <FlexAlignCenter $justify="space-between" $paddingX={theme?.space[24]}>
            {children}
        </FlexAlignCenter>
    );
};

const AdditionalDocumentsModal: React.FC<Props> = ({ closeModal }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const navigate = useNavigate();
    const { getFormData } = useFormData();
    const {
        additionalDocsFormData: { data },
        agreementDetails: { currentAgreementDetails }
    } = useContext(AppContext);

    const { setError, clearError, errorMessage } = useError();

    const [scannedInvoiceFile, setScannedInvoiceFile] = useState<File>();
    const [scannedBankCertificate, setScannedBankCertificate] = useState<File>();
    const [srSignature, setSrSignature] = useState<string>();
    const [pocSignature, setPocSignature] = useState<string>();
    const [canProceed, setCanProceed] = useState(false);
    const [isSuccessModalShown, setIsSuccessModalOpened] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceReqsMet, setInvoiceReqsMet] = useState<boolean>(
        !currentAgreementDetails?.invoiceRequired
    );

    const [bankCertificateReqsMet, setBankCertificateReqsMet] = useState<boolean>(
        !currentAgreementDetails?.bankCertificateRequired
    );
    const isReasonFreeBeer = currentAgreementDetails?.reason === AgreementReasonEnum.FreeBeer;
    const freeBeerQuantity = getFormData(FREE_BEER_QUANTITY_FORM_KEY);

    const invoiceValue = `1. ${t('INVOICE')}`;

    const bankCertificateValue = currentAgreementDetails?.invoiceRequired
        ? `2. ${t('BANK_CERTIFICATE')}`
        : `1. ${t('BANK_CERTIFICATE')}`;

    const generateProtocolValue =
        currentAgreementDetails?.invoiceRequired && currentAgreementDetails.bankCertificateRequired
            ? `3. ${t('PROTOCOL')}`
            : currentAgreementDetails?.bankCertificateRequired ??
              currentAgreementDetails?.invoiceRequired
            ? `2. ${t('PROTOCOL')}`
            : isReasonFreeBeer
            ? `2. ${t('PROTOCOL')}`
            : `1. ${t('PROTOCOL')}`;

    const navigateToInvoice = () => {
        navigate(ROUTES.AgreementList.Agreement.AdditionalDocuments.Invoice.AddInvoice);
    };

    const navigateToFreeBeerQuantity = () => {
        navigate(ROUTES.AgreementList.Agreement.AdditionalDocuments.FreeBeerQuantity);
    };

    const navigateToBankCertificate = () => {
        navigate(ROUTES.AgreementList.Agreement.AdditionalDocuments.ScanBankCertificate, {
            state: { bankCertificate: currentAgreementDetails?.bankCertificate }
        });
    };

    const navigateToGenerateProtocol = () => {
        navigate(ROUTES.AgreementList.Agreement.AdditionalDocuments.AgreementProtocol.Generate);
    };

    const handleSaveAndForward = async () => {
        if (!canProceed) {
            return;
        }

        setIsLoading(true);

        await fetch(
            `${envconfig.uploadAgreementAdditionalDocuments}?${new URLSearchParams({
                agreementId: currentAgreementDetails?.agreementId
            })}`,
            {
                headers: {
                    Authorization: `bearer ${getToken()}`
                },
                method: 'POST',
                body: data
            }
        )
            .then(async (res) => {
                if (!res.ok) {
                    setIsLoading(false);
                    setError(
                        new Error(`Something went wrong! Status: ${res.status} ${res.statusText}`),
                        ErrorTypeEnum.FrontEndError
                    );
                } else {
                    setIsLoading(false);
                    setIsSuccessModalOpened(true);
                }

                return await res.json();
            })
            .then((res) => {
                if (res.ok) {
                    setIsLoading(false);
                    setIsSuccessModalOpened(true);
                    closeModal();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error, ErrorTypeEnum.ApolloError);
            });
    };

    const handleConfirmSuccess = () => {
        closeModal();
        setIsSuccessModalOpened(false);
        disableBackFunctionality();
    };

    useEffect(() => {
        const invoiceAmount = getFormData(INVOICE_FORM_KEYS.INVOICE_AMOUNT);
        const invoiceNumber = getFormData(INVOICE_FORM_KEYS.INVOICE_NUMBER);
        const invoiceDate = getFormData(INVOICE_FORM_KEYS.INVOICE_DATE);
        const scannedInvoice = getFormData(INVOICE_FORM_KEYS.SCANNED_INVOICE);

        if (
            currentAgreementDetails?.invoiceRequired &&
            invoiceAmount &&
            invoiceNumber &&
            invoiceDate &&
            scannedInvoice
        ) {
            setInvoiceReqsMet(true);
        }

        const scannedBankCertificate = getFormData(
            BANK_CERTIFICATE_FORM_KEYS.SCANNED_BANK_CERTIFICATE
        );

        if (currentAgreementDetails?.bankCertificateRequired && scannedBankCertificate) {
            setBankCertificateReqsMet(true);
        }

        const srSignature = getFormData(GENERATE_PROTOCOL_FORM_KEYS.SR_SIGNATURE);
        const pocSignature = getFormData(GENERATE_PROTOCOL_FORM_KEYS.POC_EMPLOYEE_SIGNATURE);

        setScannedInvoiceFile(scannedInvoice as File);
        setScannedBankCertificate(scannedBankCertificate as File);
        setSrSignature(srSignature as string);
        setPocSignature(pocSignature as string);
        if (
            invoiceReqsMet &&
            bankCertificateReqsMet &&
            currentAgreementDetails?.transmissionProtocolType ===
                TransmissionProtocolTypeEnum.ScannedServiceProtocol
                ? true
                : srSignature && pocSignature
        ) {
            setCanProceed(true);
        }
    }, [
        data,
        getFormData,
        currentAgreementDetails?.invoiceRequired,
        currentAgreementDetails?.bankCertificate,
        currentAgreementDetails?.transmissionProtocolType,
        currentAgreementDetails?.bankCertificateRequired,
        invoiceReqsMet,
        bankCertificateReqsMet
    ]);

    return (
        <>
            <ModalContainer>
                <CloseModalBar backgroundColor={theme?.color.default.white} onClick={closeModal} />

                <ModalHeader backgroundColor={theme?.color.default.white}>
                    <StyledPageTitle $marginY={theme?.space[24]}>
                        {t('ADD_ADDITIONAL_DOCUMENTS')}
                    </StyledPageTitle>
                </ModalHeader>

                <ModalBody backgroundColor={theme?.color.default.white}>
                    <FlexContainer
                        $flexDirection="column"
                        $width={100}
                        $gap={theme?.space[20]}
                        $marginBottom={theme?.space[100]}>
                        {currentAgreementDetails?.invoiceRequired && (
                            <FlexWrapper>
                                <ListItemCardLabel value={invoiceValue} />

                                {scannedInvoiceFile?.name ? (
                                    <CustomButton
                                        label={scannedInvoiceFile?.name}
                                        buttonType={ButtonsTypesEnum.Tertiary}
                                        maxWidth={200}
                                        icon={<AttachmentIcon />}
                                        onClick={navigateToInvoice}
                                        style={{
                                            color: theme?.color.blue[50],
                                            height: theme?.space[28],
                                            backgroundColor: theme?.color.grey[60]
                                        }}
                                    />
                                ) : (
                                    <CustomButton
                                        label={t('ADD')}
                                        buttonType={ButtonsTypesEnum.Secondary}
                                        icon={<PlusIcon />}
                                        onClick={navigateToInvoice}
                                    />
                                )}
                            </FlexWrapper>
                        )}
                        {isReasonFreeBeer && (
                            <FlexWrapper>
                                <ListItemCardLabel value={`1. ${t('SELECT_FREE_BEER_QUANTITY')}`} />

                                {getFormData(FREE_BEER_QUANTITY_FORM_KEY) ? (
                                    <CustomButton
                                        label={getFormData(FREE_BEER_QUANTITY_FORM_KEY)?.toString()}
                                        buttonType={ButtonsTypesEnum.Tertiary}
                                        maxWidth={200}
                                        icon={<AttachmentIcon />}
                                        onClick={navigateToFreeBeerQuantity}
                                        style={{
                                            color: theme?.color.blue[50],
                                            height: theme?.space[28],
                                            backgroundColor: theme?.color.grey[60]
                                        }}
                                    />
                                ) : (
                                    <CustomButton
                                        label={t('ADD')}
                                        buttonType={ButtonsTypesEnum.Secondary}
                                        icon={<PlusIcon />}
                                        onClick={navigateToFreeBeerQuantity}
                                    />
                                )}
                            </FlexWrapper>
                        )}

                        {currentAgreementDetails?.bankCertificateRequired && (
                            <FlexWrapper>
                                <ListItemCardLabel value={bankCertificateValue} />

                                {currentAgreementDetails?.bankCertificate?.scannedDocumentName ??
                                scannedBankCertificate?.name ? (
                                    <CustomButton
                                        label={
                                            scannedBankCertificate?.name ??
                                            currentAgreementDetails?.bankCertificate
                                                ?.scannedDocumentName ??
                                            ''
                                        }
                                        maxWidth={144}
                                        buttonType={ButtonsTypesEnum.Tertiary}
                                        icon={<AttachmentIcon />}
                                        onClick={navigateToBankCertificate}
                                        style={{
                                            color: theme?.color.blue[50],
                                            height: theme?.space[28],
                                            backgroundColor: theme?.color.grey[60]
                                        }}
                                    />
                                ) : (
                                    <CustomButton
                                        label={t('SCAN')}
                                        buttonType={ButtonsTypesEnum.Secondary}
                                        icon={<ScanIcon />}
                                        onClick={navigateToBankCertificate}
                                    />
                                )}
                            </FlexWrapper>
                        )}

                        <FlexWrapper>
                            <ListItemCardLabel value={generateProtocolValue} />
                            {srSignature && pocSignature ? (
                                <CustomButton
                                    onClick={navigateToGenerateProtocol}
                                    label={t('GENERATED_AND_SIGNED')}
                                    buttonType={ButtonsTypesEnum.Disabled}
                                    icon={<TickIconGrey />}
                                />
                            ) : (
                                <CustomButton
                                    onClick={navigateToGenerateProtocol}
                                    label={
                                        currentAgreementDetails?.transmissionProtocolType ===
                                        TransmissionProtocolTypeEnum.ScannedServiceProtocol
                                            ? t('SCAN_AGREEMENT_PROTOCOL')
                                            : t('GENERATE_AND_SIGN')
                                    }
                                    buttonType={
                                        isReasonFreeBeer && !freeBeerQuantity
                                            ? ButtonsTypesEnum.Disabled
                                            : ButtonsTypesEnum.Secondary
                                    }
                                    icon={<SquarePlusIcon />}
                                />
                            )}
                        </FlexWrapper>
                    </FlexContainer>
                </ModalBody>

                <ModalFooter backgroundColor={theme?.color.default.white}>
                    <CustomButton
                        onClick={handleSaveAndForward}
                        label={t('SAVE_AND_FORWARD')}
                        buttonType={canProceed ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                        loading={isLoading}
                        style={{ width: '100%' }}
                    />
                </ModalFooter>
            </ModalContainer>

            {isSuccessModalShown && (
                <SuccessModal
                    title={t('ADDITIONAL_DOCUMENTS_ADDED_TITLE')}
                    message={t('ADDITIONAL_DOCUMENTS_ADDED_MESSAGE')}
                    onConfirm={handleConfirmSuccess}
                    modalType={SuccessModalTypeEnum.AdditionalDocuments}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </>
    );
};

export default AdditionalDocumentsModal;
