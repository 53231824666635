import { useState } from 'react';

import TABLE_CONSTANTS from '@constants/admin/tables';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AdminAssetDto,
    AdminAssetFilterRequestModelInput,
    SortEnumType,
    useGetAdminAssetsQuery
} from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import { TableDataProvider } from '@/hooks/admin/useTableData';
import useError from '@/hooks/error/useError';
import useColDefs from '@/pages/admin/Assets/hooks/useColDefs';

import AssetsFilters from './partials/AssetsFilters';

const AssetsArchiveTable: React.FC = () => {
    const {
        handlePageChange,
        pageSize,
        itemsToSkip,
        setTotalItems,
        totalItems,
        setSortingOrder,
        sortingOrder
    } = usePaginate();
    const [currentSearchTerms, setCurrentSearchTerms] =
        useState<AdminAssetFilterRequestModelInput>();
    const [tableData, setTableData] = useState<AdminAssetDto[]>([]);
    const { clearError, setError } = useError();
    const { t } = useTranslation();

    const { loading } = useGetAdminAssetsQuery({
        variables: {
            input: {
                ...currentSearchTerms
            },
            skip: itemsToSkip,
            take: pageSize,
            order: sortingOrder
        },
        onCompleted: (data) => {
            if (data.adminAssets?.items) {
                setTableData(data.adminAssets?.items as AdminAssetDto[]);
                clearError();
                setTotalItems(data.adminAssets.totalCount);
            }
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const handleSearch = async (searchTerms: AdminAssetFilterRequestModelInput) => {
        if (searchTerms !== currentSearchTerms) {
            setCurrentSearchTerms(searchTerms);
        }
    };
    const { colDefs } = useColDefs();
    const onSortChange = (sorterResult: SorterResult<AdminAssetDto>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <TableDataProvider>
            <SectionHeading heading={t('ARCHIVE')} />
            <AssetsFilters onSearch={handleSearch} />
            <ResizableTable
                tableData={tableData}
                colDefs={colDefs}
                emptyText={t('NO_FILTERS_HINT')}
                hasSearchBar={false}
                loading={loading}
                onPageChange={handlePageChange}
                totalPaginationItems={totalItems}
                onChange={(_, __, sorterResult) =>
                    onSortChange(sorterResult as SorterResult<AdminAssetDto>)
                }
                size={TABLE_CONSTANTS.TableSize}
            />
        </TableDataProvider>
    );
};

export default AssetsArchiveTable;
