import { useContext, useEffect, useState } from 'react';

import { AutoComplete, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as FloppyIcon } from '@/assets/svg-icons/floppy-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomDropdown from '@/components/shared/UI/CustomDropdown';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    AgreementReasonEnum,
    AgreementSubjectDto,
    AgreementSubjectEnum,
    AgreementTypeEnum,
    AttributeTypeEnum,
    AttributeValuesInput,
    CustomerDto,
    DocumentStatusEnum,
    Poc,
    useCreateAgreementMutation,
    useGetCombinedPocsAndSpendLimitLazyQuery,
    useGetUserCustomerByNameLazyQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import {
    Container,
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import {
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardValue
} from '@/styles/shared/list-item/styles';
import { formatDate } from '@/utils/formatDate';

import AgreementDetailsSummary from '../AgreementDetails/partials/AgreementDetailsSummary';

import AddAgreementDetailsModal from './partials/AddAgreementDetailsModal';
import { DropdownValuesType } from './partials/AddAgreementDetailsModal/partials/ModalStepOne';

const initialFormState = {
    firstStep: {
        type: '',
        subject: '',
        subjectList: [],
        reason: '',
        reasonList: [],
        agreementTree: [],
        agreementDate: '',
        dateTo: '',
        dateFrom: ''
    },
    secondStep: []
};

type TransformDataProps = {
    isSummary?: boolean;
};

export enum AttributeValueTypesEnum {
    Enum = 'enumValue',
    Int = 'intValue',
    String = 'stringValue',
    Decimal = 'decimalValue',
    Boolean = 'boolValue'
}

export type FormStateType = {
    firstStep: {
        type: string;
        subject: string;
        subjectList: DropdownValuesType[];
        reason: string;
        reasonList: DropdownValuesType[];
        agreementTree: Array<AgreementSubjectDto | null>;
        agreementDate: string;
        dateTo: string;
        dateFrom: string;
    };
    secondStep: Array<
        Pick<AttributeValuesInput, 'attributeId' | 'type'> & {
            key: string;
            value?: string;
            booleanValue?: boolean;
            id?: number;
            order: number;
            label: string;
        }
    >;
};

type CustomerNameOptionType = {
    label: string;
    value: string;
};

type FinancialDataType = {
    spendBalance: number;
    total: number;
};

const CreateAgreement: React.FC = () => {
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const { setError, clearError, errorMessage } = useError();

    const [customers, setCustomers] = useState<CustomerDto[]>([]);
    const [customerNames, setCustomerNames] = useState<CustomerNameOptionType[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDto>();
    const [pocs, setPocs] = useState<Poc[]>([]);
    const [selectedPoc, setSelectedPoc] = useState<string | null>(null);
    const [financialData, setFinancialData] = useState<FinancialDataType>({
        spendBalance: 0,
        total: 0
    });
    const [formState, setFormState] = useState<FormStateType>(initialFormState);
    const [agreementDefinitionId, setAgreementDefinitionId] = useState<number | null>(null);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);

    const [customersByName] = useGetUserCustomerByNameLazyQuery();
    const [combinedPocsAndSpendLimit] = useGetCombinedPocsAndSpendLimitLazyQuery();
    const [createAgreement] = useCreateAgreementMutation();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [createAgreementId, setCreateAgreementId] = useState<number | null>(null);

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });
    }, []); // eslint-disable-line

    const getCombinedPocsAndSpendLimit = (customerId: string) => {
        combinedPocsAndSpendLimit({
            variables: {
                customerId
            },
            onCompleted: ({ agreementSpendLimit, customerPocs }) => {
                setPocs(customerPocs);
                setFinancialData((prevState) => ({
                    ...prevState,
                    total: agreementSpendLimit
                }));
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const searchCustomerName = (searchText: string) => {
        customersByName({
            variables: {
                input: searchText
            },
            onCompleted: ({ userCustomersByName }) => {
                setCustomerNames(
                    () =>
                        userCustomersByName.map(({ name }) => ({
                            label: name,
                            value: name
                        })) as CustomerNameOptionType[]
                );

                setCustomers(userCustomersByName);
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const handelCustomerSelection = (selectedName: string) => {
        const chosenCustomer = customers.find(
            (customer: CustomerDto) => customer.name === selectedName
        );

        setSelectedCustomer(chosenCustomer);
        setFinancialData((prevState) => ({
            ...prevState,
            spendBalance: Number(chosenCustomer?.agreementSpentAmount)
        }));
        getCombinedPocsAndSpendLimit(chosenCustomer?.id);
    };

    const handleDisableSaveButton = (value: boolean) => setDisableSaveButton(value);
    const handleAgreementDefinitionId = (id: number) => setAgreementDefinitionId(id);

    const handleFormState = (
        values: Pick<FormStateType, 'firstStep'> | Pick<FormStateType, 'secondStep'>
    ) => {
        setFormState((prev) => ({ ...prev, ...values }));
    };

    const transformCreateAgreementData = ({ isSummary = false }: TransformDataProps) => {
        const { agreementDate, dateFrom, dateTo } = formState.firstStep;
        const filteredSecondStep = formState.secondStep.filter((prop) => prop.value !== '');
        const transformedAttributes = filteredSecondStep.map((item) => {
            const { key, value, id: enumId, booleanValue, ...rest } = item;
            let attributeValueType;
            let attributeValue;

            switch (item.type) {
                case AttributeTypeEnum.Int:
                    attributeValueType = AttributeValueTypesEnum.Int;
                    attributeValue = value && parseInt(value, 10);
                    break;
                case AttributeTypeEnum.Decimal:
                    attributeValueType = AttributeValueTypesEnum.Decimal;
                    attributeValue = value && parseFloat(parseFloat(value).toFixed(2));
                    break;
                case AttributeTypeEnum.Enum:
                    attributeValueType = AttributeValueTypesEnum.Enum;
                    attributeValue = isSummary ? { enumId, value } : enumId;
                    break;
                case AttributeTypeEnum.String:
                    attributeValueType = AttributeValueTypesEnum.String;
                    attributeValue = value;
                    break;
                case AttributeTypeEnum.Boolean:
                    attributeValueType = AttributeValueTypesEnum.Boolean;
                    attributeValue = booleanValue;
                    break;
                default:
                    throw new Error(`Unexpected type: ${item.type}`);
            }

            return {
                ...rest,
                [attributeValueType]: attributeValue
            };
        });

        const pocId = pocs.find((poc) => poc.pocName === selectedPoc)?.id;

        return {
            pocId,
            agreementDefinitionId,
            agreementDate,
            dateFrom,
            dateTo,
            attributeValues: transformedAttributes
        };
    };

    const getPeriodDates = () =>
        `${formatDate(formState.firstStep.dateFrom)} - ${formatDate(formState.firstStep.dateTo)}`;

    const handleAgreementSubmit = () => {
        createAgreement({
            variables: {
                input: transformCreateAgreementData({})
            },
            onCompleted: (data) => {
                setIsSuccess(true);
                setCreateAgreementId(data.createAgreement);
                handleDisableSaveButton(true);
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const pocItems = pocs.map(({ pocName }) => ({
        key: pocName,
        label: pocName
    }));

    const onPocSelection: MenuProps['onClick'] = ({ key }) => setSelectedPoc(key);
    const onSuccessModalConfirm = () => {
        navigate(`/agreement-list/agreement/${createAgreementId}`);
    };

    return (
        <ScrollableContainer $position="relative">
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[40]}>
                <StyledPageTitle $marginY={theme?.space[0]}>
                    {t('CREATE_NEW_AGREEMENT')}
                </StyledPageTitle>
            </HeaderContainer>

            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[32]}>
                <ListItemCardLabel
                    direction={'row'}
                    $fontWeight={700}
                    $fontSize={theme?.fontSize.h6}
                    $marginBottom={theme?.space[12]}
                    $color={theme?.color.default.black}
                    value={t('CUSTOMER_NAME')}
                />
                <AutoComplete
                    allowClear
                    dropdownClassName={'dropdown-overlay'}
                    onSelect={handelCustomerSelection}
                    options={customerNames}
                    onSearch={searchCustomerName}
                    value={selectedCustomer?.name}
                    disabled={!!selectedCustomer}
                />
            </FlexContainer>

            {!!selectedCustomer && (
                <>
                    <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[24]}>
                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel
                                    direction="column"
                                    label={t('CUSTOMER_ADDRESS')}
                                />
                                <ListItemCardValue
                                    direction="column"
                                    value={selectedCustomer.customerAddress!}
                                />
                            </div>
                        </ListItemCardRow>

                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="column" label={t('CUSTOMER_VAT')} />
                                <ListItemCardValue
                                    direction="column"
                                    value={selectedCustomer?.customerVat}
                                />
                            </div>
                        </ListItemCardRow>
                    </Container>

                    <FlexContainer $flexDirection="column" $marginBottom={theme?.space[16]}>
                        <ListItemCardLabel
                            $marginBottom={theme?.space[8]}
                            direction={'row'}
                            value={t('POC_NAME')}
                        />

                        <CustomDropdown
                            placeholder="CHOOSE_POC_NAME"
                            items={pocItems}
                            menuProps={{ selectable: true }}
                            value={selectedPoc ?? ''}
                            onClick={onPocSelection}
                        />
                    </FlexContainer>

                    <ListItemCardRow $justify="flex-start">
                        <div>
                            <ListItemCardLabel direction="column" label={t('SPEND_BALANCE')} />
                            <ListItemCardValue
                                direction="column"
                                value={`${financialData.spendBalance} lv`}
                            />
                        </div>
                        <div>
                            <ListItemCardLabel direction="column" label={t('TOTAL')} />
                            <ListItemCardValue
                                direction="column"
                                value={`/ ${financialData.total} lv`}
                                $color={theme?.color.grey[100]}
                            />
                        </div>
                    </ListItemCardRow>
                </>
            )}

            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[0]}>
                <h3>{t('CA_DETAILS')}</h3>

                <AddAgreementDetailsModal
                    disabled={!!(!selectedCustomer || !selectedPoc)}
                    formState={formState}
                    handleFormState={handleFormState}
                    handleAgreementDefinitionId={handleAgreementDefinitionId}
                    disableSaveButton={handleDisableSaveButton}
                />
            </HeaderContainer>

            {!disableSaveButton && (
                <AgreementDetailsSummary
                    showHeader={false}
                    agreementDetails={{
                        type: formState.firstStep.type as AgreementTypeEnum,
                        subject: formState.firstStep.subject as AgreementSubjectEnum,
                        reason: formState.firstStep.reason as AgreementReasonEnum,
                        dateFrom: formState.firstStep.dateFrom,
                        dateTo: formState.firstStep.dateTo,
                        agreementDate: formState.firstStep.agreementDate,
                        period: getPeriodDates(),
                        attributeValues: transformCreateAgreementData({ isSummary: true })
                            .attributeValues,
                        status: DocumentStatusEnum.Done
                    }}
                />
            )}
            <Container $paddingX={theme?.space[0]} $marginTop={theme?.space[20]}>
                <CustomButton
                    onClick={handleAgreementSubmit}
                    label={t('SAVE_AS_DRAFT')}
                    buttonType={
                        disableSaveButton ? ButtonsTypesEnum.Disabled : ButtonsTypesEnum.Main
                    }
                    icon={<FloppyIcon />}
                    style={{ width: '100%' }}
                />
            </Container>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
            {isSuccess && (
                <SuccessModal
                    title={t('DRAFT_CREATED')}
                    message={t('DRAFT_CREATED_MESSAGE')}
                    onConfirm={onSuccessModalConfirm}
                />
            )}
        </ScrollableContainer>
    );
};

export default CreateAgreement;
