import React from 'react';

import { useTheme } from 'styled-components';

import { ReactComponent as InfoIcon } from '@/assets/svg-icons/info-icon.svg';
import { FlexAlignCenter } from '@/styles/shared/container/styles';

import { InfoMessageText } from './styles';

type Props = {
    message: string;
    showMessage?: boolean;
};

const InfoMessage: React.FC<Props> = ({ message, showMessage = true }) => {
    const theme = useTheme();

    if (!showMessage) return null;

    return (
        showMessage && (
            <FlexAlignCenter $gap={theme?.space[4]}>
                <InfoIcon width={theme?.space[16]} height={theme?.space[16]} />
                <InfoMessageText>{message}</InfoMessageText>
            </FlexAlignCenter>
        )
    );
};

export default InfoMessage;
