import { useTranslation } from 'react-i18next';

import { DocumentStatusEnum, ProtocolTypeEnum } from '@/graphql/main';

const useStatus = () => {
    const { t } = useTranslation();

    const getNormalizedProtocolType = (type: ProtocolTypeEnum): string => {
        switch (type) {
            case ProtocolTypeEnum.PosmInstall:
                return 'Install';
            case ProtocolTypeEnum.FrdiInstall:
                return 'Install';
            case ProtocolTypeEnum.PosmUninstall:
                return 'Uninstall';
            case ProtocolTypeEnum.FrdiUninstall:
                return 'Uninstall';
        }
    };

    const getStatusStyle = (status: DocumentStatusEnum) => {
        switch (status) {
            case DocumentStatusEnum.Prepared:
                return 'prepared';
            case DocumentStatusEnum.Signed:
                return 'signed';
            case DocumentStatusEnum.Canceled:
                return 'canceled';
            case DocumentStatusEnum.Draft:
                return 'draft';
            case DocumentStatusEnum.ForValidation:
                return 'validation';
            case DocumentStatusEnum.Done:
                return 'done';
        }
    };

    const getStatusLabel = (status: DocumentStatusEnum) => {
        switch (status) {
            case DocumentStatusEnum.Prepared:
                return t('PREPARED_SIMPLIFIED');
            case DocumentStatusEnum.Signed:
                return t('SIGNED_SIMPLIFIED');
            case DocumentStatusEnum.Canceled:
                return t('CANCELED');
            case DocumentStatusEnum.Draft:
                return t('DRAFT');
            case DocumentStatusEnum.ForValidation:
                return t('FOR_VALIDATION');
            case DocumentStatusEnum.Done:
                return t('STATUS_DONE');
        }
    };

    return {
        getStatusLabel,
        getStatusStyle,
        getNormalizedProtocolType
    };
};

export default useStatus;
