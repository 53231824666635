import styled from 'styled-components';

import ModalContainer from '@/components/shared/modal/partials/ModalContainer';
import ModalFooter from '@/components/shared/modal/partials/ModalFooter';
import TextLabelValue from '@/components/shared/UI/TextLabelValue';

export const EmailModalContainer = styled(ModalContainer)`
    top: 15%;
`;

export const EmailModalBodyContainer = styled.div`
    width: 85%;
`;

export const EmailModalWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.space[52]};
`;

export const EmailModalLabel = styled(TextLabelValue)`
    display: flex;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space[12]};
    font-size: ${({ theme }) => theme.fontSize.h6};
    text-align: left;
`;

export const EmailModalPdfContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.space[20]};
`;

export const EmailModalFooter = styled(ModalFooter)`
    padding-top: ${({ theme }) => theme.space[40]};

    button {
        width: 90%;
    }
`;

export const StyledDownloadPdfButton = styled.button`
    display: flex;
    padding: ${({ theme }) => theme.space[8]};
    gap: ${({ theme }) => theme.space[8]};
    font-size: ${({ theme }) => theme.fontSize.lg};
    background: ${({ theme }) => theme.color.grey[60]};
    color: ${({ theme }) => theme.color.blue[50]};
    border-radius: 4px;
`;
