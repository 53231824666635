import { styled } from 'styled-components';

export const Wrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.default.white};
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.color.default.white};

    & > div {
        height: 100%;
        overflow: auto;
        width: 100%;
        margin: 0 50px;
    }
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    background-color: ${({ theme }) => theme.color.default.white};
`;
