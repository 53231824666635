import { CSSProperties } from 'react';

import { Select, SelectProps } from 'antd';

import { StyledSelect } from '@/styles/shared/dropdown/styles';
import { SelectChevronIcon } from '@/styles/shared/icon/styles';
import { SelectItem } from '@/types/SelectItem';

type Props = {
    placeholder: string | undefined;
    items: SelectItem[] | undefined;
    onChangeHandler: (value: any, option: any) => void;
    onClear: () => void;
    value: string | null;
    style?: CSSProperties;
};

const DropdownWithSearch: React.FC<Props> = ({
    placeholder,
    items,
    onChangeHandler,
    onClear,
    value,
    style
}) => {
    const { Option } = Select;

    return (
        <StyledSelect
            showSearch
            allowClear
            suffixIcon={<SelectChevronIcon />}
            onChange={onChangeHandler}
            placeholder={placeholder}
            onClear={onClear}
            value={value}
            style={style}>
            {items?.map((item) => (
                <Option key={item.key}>{item.label}</Option>
            ))}
        </StyledSelect>
    );
};

export default DropdownWithSearch;
