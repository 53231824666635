import { LoadingOutlined } from '@ant-design/icons';

import { StyledSpin } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    loading?: boolean;
    spinnerSize?: string;
    spinnerColor?: string;
    styles?: { fontSize?: string; marginTop?: string };
};

const SpinnerContainer: React.FC<Props> = ({
    children,
    loading,
    spinnerSize,
    spinnerColor,
    styles
}) => {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: spinnerSize,
                color: spinnerColor ?? '#1677ff',
                ...styles
            }}
            spin
        />
    );

    if (loading) {
        return <StyledSpin $defaultSize={!spinnerSize} indicator={antIcon} />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default SpinnerContainer;
