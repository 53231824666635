import { Form, FormProps } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from '@/components/shared/UI/CustomButton';
import PageTitle from '@/components/shared/UI/PageTitle';

export const AuthFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 ${({ theme }) => theme.space[40]};
`;

export const AuthHeaderContainer = styled.div`
    text-align: center;
    min-height: ${({ theme }) => theme.space[100]};
    padding: 0 ${({ theme }) => theme.space[20]};
`;

export const AuthHeader = styled(PageTitle)`
    margin-bottom: ${({ theme }) => theme.space[16]};
`;

export const AuthForm: React.FunctionComponent<FormProps> = styled(Form)`
    width: 100%;
`;

export const AuthLink = styled(Link)`
    display: block;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.h6};
    color: ${({ theme }) => theme.color.grey[40]};
`;

export const AuthButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 7vh;
    left: 50%;
    transform: translate(-50%, 0);
`;

export const AuthButton = styled(CustomButton)`
    width: 200px;
    margin-bottom: ${({ theme }) => theme.space[12]};
`;

export const AuthCheckboxLabel = styled.span`
    color: ${({ theme }) => theme.color.grey[30]};
`;
