import styled from 'styled-components';

export const AgreementListHeaderContainer = styled.div`
    margin-top: ${({ theme }) => theme.space[24]};
    margin-bottom: ${({ theme }) => theme.space[20]};
    font-size: ${({ theme }) => theme.fontSize.h6};
    font-weight: 700;
`;

export const AgreementListHeader = styled.span`
    margin-right: ${({ theme }) => theme.space[4]};
`;

export const AgreementListLength = styled.span`
    margin-bottom: ${({ theme }) => theme.space[20]};
    color: ${({ theme }) => theme.color.grey[100]};
`;
