import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import TABLE_CONSTANTS from '@/constants/admin/tables';
import { AssetRawDataReportModel } from '@/graphql/main';

import useColDefs from '../../hooks/useColDefs';

type Props = {
    loading: boolean;
    tableData: AssetRawDataReportModel[];
    handlePageChange: (page: number, pageSize: number) => void;
    totalItems: number;
};

const ReportTable = ({ loading, tableData, handlePageChange, totalItems }: Props) => {
    const { t } = useTranslation('translation');
    const { colDefs } = useColDefs();
    const { TableSize } = TABLE_CONSTANTS;

    return (
        <ResizableTable
            tableData={tableData}
            colDefs={colDefs}
            emptyText={t('NO_FILTERS_HINT')}
            hasSearchBar={false}
            loading={loading}
            onPageChange={handlePageChange}
            totalPaginationItems={totalItems}
            size={TableSize}
        />
    );
};

export default ReportTable;
