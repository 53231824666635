import styled from 'styled-components';

export const StyledNavBar = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.color.default.bg};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${({ theme }) => theme.space[16]};
`;

export const Button = styled.button`
    background: none;
    border: none;
    padding: 0;
`;

export const BackButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BackButtonLabel = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.xxlg};
    color: ${({ theme }) => theme.color.grey[40]};
`;

export const RightSide = styled.div`
    display: flex;
`;
