import { TableDataProvider } from '@/hooks/admin/useTableData';
import ProductsTable from '@/pages/admin/CommercialAgreements/Products/partials/ProductsTable';

const Products = () => (
    <TableDataProvider>
        <ProductsTable />
    </TableDataProvider>
);

export default Products;
