import styled, { css } from 'styled-components';

type StyledInputProps = {
    $withIcon?: boolean;
    $invalid?: boolean;
    $isDisabled?: boolean;
};

export const StyledInputContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledInput = styled.input<StyledInputProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${({ theme }) => theme.space[4]} 0;
    gap: ${({ theme: space }) => space[8]};
    padding: ${({ theme: { space } }) => `${space[8]} ${space[12]}`};
    width: 100%;
    height: ${({ theme: space }) => space[40]};
    background: ${({ theme: { color } }) => color.default.white};
    border: 1px solid ${({ theme: { color } }) => color.grey[90]};
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.h6};
    line-height: ${({ theme }) => theme.lineHeight[22]};
    color: ${({ theme }) => theme.color.grey[80]};

    ${({ $withIcon }) =>
        $withIcon &&
        css`
            padding-left: 30px !important;
        `}

    ${({ $invalid }) =>
        $invalid &&
        css`
            border: 1px solid red;
        `}

    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            color: ${({ theme }) => theme.color.grey[70]};
            background-color: ${({ theme }) => theme.color.grey[120]};
            cursor: not-allowed;
        `}
`;

export const StyledInputIcon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: ${({ theme }) => theme.space[12]};
    margin-left: ${({ theme }) => theme.space[12]};

    & > svg {
        width: 100%;

        & > path {
            fill: #7c7b7b;
        }
    }
`;

export const StyledTextarea = styled.textarea`
    padding: ${({ theme }) => theme.space[4]};
    border: 1px solid ${({ theme }) => theme.color.grey[40]};
    margin-bottom: ${({ theme }) => theme.space[12]};
    font-size: ${({ theme }) => theme.fontSize.h6};
    border-radius: 5px;
    max-width: 90%;
    min-width: 90%;
    max-height: 180px;
    min-height: ${({ theme }) => theme.space[88]};
    overflow-y: scroll;
`;
