const PREVIEW_STYLE = `
        .contract {
          width: 100%;
          display: flex;
          flex-direction: column;
          border: 1px solid gray;
          padding: 5px
        }
        #contract-header-container {
          display: flex;
          margin: 0;
        }
        .contract-header {
          height: 30px;
        }
        #contract-logo {
          background-color: white;
          width: 3.4rem;
          height: 2rem;
          position: absolute;
          bottom: 12px;
          left: -1px;
        }
        #table-header > th {
          vertical-align: inherit;
        }
        #price-subtitle {
          margin-top: 0px;
          font-size: 0.5rem;
        }
        
        #contract-sr-sign, #contract-customer-personnel-sign {
          display: none; 
        }
        
        #signs-container { 
          display: flex;
        }
        #contract-sr-container, #contract-customer-personnel-container {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        
        .contract-body {
          display: flex;
          flex-direction: column;
        }
        br {
          display: none;
        }
        p {
          white-space: pre-line;
        }
        .contract-body > table {
          font-size: 0.7rem;
        }
         
        
        .document {
          width: 100%;
          display: flex;
          flex-direction: column;
          border: 1px solid gray;
          padding: 5px;
          margin-top: 5px;
          align-items: center;
        }
        .document > .header-container {
          display: flex; 
          flex-direction: column;
        }
        .document > ol, ul {
          white-space: normal;
          padding: 5%;
        }
        
        .document > p {
          margin: 0
          
        }
        .document > .sing-container {
          width: 100%;
          margin-bottom: 40px;
        }     
        .sing-container > .left-side {
          display: flex;
          flex-direction: column;
        }
        .left-side,
        .right-side{
          display: flex;
          flex-direction: column;
        }
        .document > .header-container > h6 {
          font-size: 1rem;
          margin-bottom: 0;
        }
        .document > .body-container {
          display: flex;
          flex-direction: column;
        }
        .sr-sign, .c-sign {
          display: none;
        }
        .body-container > p {
          margin: 0;
        }
        .body-container > .sign-container {
          margin: 0;
          margin-top: 50px;
          display: flex;
          flex-direction: column;
        }
        .c-row {
          display: flex;
          gap: 25px;
        }
        .c-row > .sign-info {
          display: flex;
          flex-direction: column;
          width: 60%;
          
        }
         .c-row > .sign {
           display: flex;
         }
         
         .sr-row {
           display: flex;
           gap: 25px;
         }
         .sr-row > .sign-info {
            width: 60%;
            display: flex;
         }
         .sr-row > .sign {
           display: flex; 
         }
         .document-info {
           display: flex;
           flex-direction: column;
         }

`;

export default PREVIEW_STYLE;
