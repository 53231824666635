import { ApolloQueryResult } from '@apollo/client';
import { Button, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { Product, GetProductsQuery, useChangeProductIsActiveMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetProductsQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();
    const [changeProductIsActive] = useChangeProductIsActiveMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await onChangeSuccess();
        }
    });

    const handleToggleProductStatus = async ({
        id,
        isActive
    }: {
        id: string;
        isActive: boolean;
    }) => {
        try {
            await changeProductIsActive({
                variables: {
                    id,
                    isActive: !isActive
                }
            });

            message.success(t(isActive ? 'DEACTIVATED_SUCCESS' : 'ACTIVATED_SUCCESS'));
        } catch (error) {
            setError(error, ErrorTypeEnum.ApolloError);
            await message.error(t(isActive ? 'DEACTIVATE_FAIL' : 'ACTIVATED_FAIL'));
        }
    };

    const colDef: ColumnsType<Product> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            width: 200,
            sorter: true,
            sortDirections: ['descend', 'ascend']
        },
        {
            key: 'price',
            title: t('PRICE'),
            dataIndex: 'price',
            width: 200,
            sorter: true,
            sortDirections: ['descend', 'ascend']
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            dataIndex: 'active',
            sorter: true,
            width: 100,
            render: (_, { id, isActive }: Product) => (
                <Button
                    key={`deactivate-${id as string}`}
                    danger={isActive}
                    onClick={async () => await handleToggleProductStatus({ id, isActive })}>
                    {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                </Button>
            )
        }
    ];

    return { colDef };
};

export default useColDefs;
