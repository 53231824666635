import { ReactElement } from 'react';

import { FlexContainer } from '@/styles/shared/container/styles';

import ErrorMessage from '../../message/ErrorMessage';

import { StyledInput, StyledInputContainer, StyledInputIcon } from './styles';

type Props = {
    placeholder?: string;
    onInput?: (value: string) => void;
    onKeyDown?: (value: string) => void;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    icon?: ReactElement;
    defaultValue?: string | number;
    inputType?: React.HTMLInputTypeAttribute;
    invalid?: boolean;
    invalidMsg?: string;
    isDisabled?: boolean;
    isLettersOnly?: boolean;
    isTypeInteger?: boolean;
    step?: number;
    value?: string | number;
    min?: number;
    max?: number;
};

const CustomInput: React.FC<Props> = ({
    placeholder,
    onInput,
    onKeyDown,
    onBlur,
    onChange,
    icon,
    defaultValue,
    inputType,
    invalid,
    invalidMsg,
    isDisabled,
    isTypeInteger,
    isLettersOnly,
    step,
    value,
    min,
    max
}) => {
    const onInputHandler = (
        e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (onInput) {
            onInput(e.currentTarget.value);
        }
    };

    const onKeyDownHandler = (
        e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (onKeyDown) {
            onKeyDown(e.currentTarget.value);
        }

        if (isTypeInteger) {
            const key = (e as React.KeyboardEvent<HTMLInputElement>).key;
            if (
                !/[0-9]/.test(key) &&
                key !== 'Backspace' &&
                key !== 'ArrowLeft' &&
                key !== 'ArrowRight' &&
                key !== 'Delete'
            ) {
                e.preventDefault();
            }
        }
    };

    const onBlurHandler = (
        e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (onBlur) {
            onBlur(e.currentTarget.value);
        }
    };

    const onChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
    ) => {
        const { value } = e.currentTarget;

        if (isLettersOnly && /[0-9]/.test(value)) return;

        onChange?.(value);
    };

    return (
        <FlexContainer $flexDirection="column">
            <StyledInputContainer>
                <StyledInput
                    $withIcon={!!icon}
                    $invalid={invalid}
                    $isDisabled={isDisabled}
                    onInput={onInputHandler}
                    onKeyDown={onKeyDownHandler}
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler}
                    type={inputType ?? 'text'}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    disabled={isDisabled}
                    step={step}
                    value={value}
                    min={min}
                    max={max}
                />
                {icon && <StyledInputIcon>{icon}</StyledInputIcon>}
            </StyledInputContainer>

            {invalid && invalidMsg && <ErrorMessage message={invalidMsg} />}
        </FlexContainer>
    );
};

export default CustomInput;
