import React, { useState } from 'react';

import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

import SectionHeading from '@/components/admin/SectionHeading';
import CustomInput from '@/components/shared/UI/CustomInput';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { useGetAgreementSpendLimitQuery, useUpdateSpendLimitMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { FlexContainer } from '@/styles/shared/container/styles';

const SpendLimit = () => {
    const { t } = useTranslation();
    const [spendLimit, setSpendLimit] = useState<number | undefined>();
    const [spendLimitUpdated, setSpendLimitUpdated] = useState(false);

    const { setError } = useError();

    const handleSave = async () => {
        await updateSpendLimitMutation({ variables: { limit: spendLimit } });
    };
    const { refetch } = useGetAgreementSpendLimitQuery({
        onCompleted: (data) => {
            if (data?.agreementSpendLimit) {
                setSpendLimit(data.agreementSpendLimit);
            }
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });
    const [updateSpendLimitMutation, { loading }] = useUpdateSpendLimitMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await refetch();
            setSpendLimitUpdated(false);
            message.success(t('SPEND_LIMIT_UPDATED_SUCCESSFULLY'));
        }
    });

    return (
        <>
            <SectionHeading heading={t('SPEND_LIMIT')} />
            <FlexContainer>
                <CustomInput
                    inputType="number"
                    step={50}
                    value={spendLimit}
                    onChange={(e) => {
                        setSpendLimit(Number(e));
                        setSpendLimitUpdated(!!Number(e));
                    }}
                />
                <Button
                    style={{ margin: '8px 0 0 20px', height: '100%' }}
                    type="primary"
                    onClick={handleSave}
                    loading={loading}
                    disabled={!spendLimitUpdated}>
                    {t('SAVE')}
                </Button>
            </FlexContainer>
        </>
    );
};

export default SpendLimit;
