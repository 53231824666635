import { Fragment, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import { ReactComponent as CommentIcon } from '@/assets/svg-icons/comment-icon.svg';
import { ReactComponent as DotIcon } from '@/assets/svg-icons/dot-icon.svg';
import { ReactComponent as EmailIcon } from '@/assets/svg-icons/email-icon.svg';
import { ReactComponent as InstallIcon } from '@/assets/svg-icons/install-icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import { ReactComponent as ViewDeclarationIcon } from '@/assets/svg-icons/preview-declaration-icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/svg-icons/profile-icon.svg';
import { ReactComponent as ScanIcon } from '@/assets/svg-icons/scan-icon.svg';
import { ReactComponent as ShareIcon } from '@/assets/svg-icons/share-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import { ReactComponent as UninstallIcon } from '@/assets/svg-icons/uninstall-icon.svg';
import ProtocolIcon from '@/components/shared/asset-icons/ProtocolIcon';
import CommentModal from '@/components/shared/modal/CommentModal';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import ShareModal from '@/components/shared/modal/ShareModal';
import WarningModal from '@/components/shared/modal/WarningModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomInput from '@/components/shared/UI/CustomInput';
import IconContainer from '@/components/shared/UI/IconContainer';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import ERROR_CODES from '@/constants/errors/error-codes';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    ProtocolDto,
    useCancelShareProtocolMutation,
    useGetProtocolByIdQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useStatus from '@/hooks/status/useStatus';
import { setCacheVal } from '@/services/utils/cacheUtils';
import AppContext from '@/store/app-context/AppContext';
import {
    Container,
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { StyledNotScannedErrorLabel } from '@/styles/shared/label/styles';
import {
    ListItemCardCustomerName,
    ListItemCardDate,
    ListItemCardFooter,
    ListItemCardFooterWrapper,
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardRowHeader,
    ListItemCardValue,
    ListItemRightCol
} from '@/styles/shared/list-item/styles';
import {
    getCommentFromStorage,
    saveCommentToStorage,
    saveCurrentContractData
} from '@/utils/assetStorage';
import { formatDate } from '@/utils/formatDate';

import MaterialsTable from '../../shared/components/MaterialsTable';
import {
    StyledAssetCard,
    StyledAssetContent,
    StyledCommentButton
} from '../../shared/styles/styles';
import {
    AssetCardStatusLabel,
    AssetCardStatusLine,
    StyledAssetCardProtocolType,
    StyledProtocolTypeLabel
} from '../asset-list/partials/AssetCard/styles';

const Protocol: React.FC = () => {
    const { t } = useTranslation('translation');
    const { setError, clearError, errorMessage } = useError();
    const { navbar, protocol: ctxProtocol } = useContext(AppContext);
    const navigate = useNavigate();
    const { getStatusLabel, getStatusStyle, getNormalizedProtocolType } = useStatus();
    const { assetId } = useParams();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isRemoveShareModalOpened, setIsRemoveShareModalOpened] = useState<boolean>(false);
    const [isShareModalOpened, setIsShareModalOpened] = useState<boolean>(false);
    const [isCommentModalOpened, setIsCommentModalOpened] = useState<boolean>(false);
    const theme = useTheme();

    const comment = getCommentFromStorage(assetId);

    const { loading: loadingProtocol } = useGetProtocolByIdQuery({
        variables: {
            input: +assetId!
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            ctxProtocol.setProtocol(data.protocol);
            clearError();
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        skip: ctxProtocol.currentProtocol && ctxProtocol.currentProtocol.id === assetId
    });

    const [cancelProtocolShare] = useCancelShareProtocolMutation();

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });
    }, []);

    useEffect(() => {
        checkValidity();
    }, [
        ctxProtocol.currentProtocol?.customerEmail,
        ctxProtocol.currentProtocol?.pocPersonnelName,
        ctxProtocol.currentProtocol?.declaration?.scannedDocumentName,
        ctxProtocol.currentProtocol?.materials
    ]);

    const handlePreview = () => {
        if (ctxProtocol.currentProtocol) {
            saveCurrentContractData<ProtocolDto>(ctxProtocol.currentProtocol);
        }

        navigate(ROUTES.AssetList.Protocol.ProtocolPreview.Path);
    };

    const handleMaterialChange = (id: number, quantity: number) => {
        const newProtocol = { ...ctxProtocol.currentProtocol } || {};

        if (newProtocol.materials) {
            const materialIndex = newProtocol.materials.findIndex((material) => material.id === id);

            if (materialIndex !== -1) {
                const updatedMaterial = {
                    ...newProtocol.materials[materialIndex],
                    quantity
                };
                const updatedMaterials = [...newProtocol.materials];
                updatedMaterials[materialIndex] = updatedMaterial;
                newProtocol.materials = updatedMaterials;
                newProtocol.contractedQuantity = newProtocol.materials.reduce(
                    (total, obj) => total + obj.quantity,
                    0
                );
                ctxProtocol.setProtocol(newProtocol as ProtocolDto);
            }
        }
    };

    const onCustomerMailChange = (email: string) => {
        const newProtocol = { ...ctxProtocol.currentProtocol } as ProtocolDto;
        newProtocol.customerEmail = email;
        ctxProtocol.setProtocol(newProtocol);
    };

    const onPocEmployeeChangeChange = (employeeName: string) => {
        const newProtocol = { ...ctxProtocol.currentProtocol } as ProtocolDto;
        newProtocol.pocPersonnelName = employeeName;
        ctxProtocol.setProtocol(newProtocol);
    };
    const handleViewDeclaration = () =>
        navigate(ROUTES.AssetList.Protocol.Declaration.Preview, {
            state: {
                declaration: ctxProtocol.currentProtocol?.declaration,
                status: ctxProtocol.currentProtocol?.status
            }
        });

    const handleScanDeclaration = () =>
        navigate(ROUTES.AssetList.Protocol.Declaration.Scan, {
            state: { status: ctxProtocol.currentProtocol?.status }
        });

    const openCloseCancelShareModal = () => {
        setIsRemoveShareModalOpened((prevState) => !prevState);
    };

    const handleCancelShare = () => {
        cancelProtocolShare({
            variables: {
                input: ctxProtocol.currentProtocol?.id
            }
        })
            .then(() => {
                setIsRemoveShareModalOpened(false);
                navigate(ROUTES.FullPaths.AssetList);
            })
            .catch((error) => {
                setError(error, ErrorTypeEnum.ApolloError);
                setIsRemoveShareModalOpened(false);
            });
    };

    const checkValidity = () => {
        setIsValid(
            !!ctxProtocol.currentProtocol?.customerEmail &&
                !!ctxProtocol.currentProtocol.pocPersonnelName &&
                !!ctxProtocol.currentProtocol.declaration?.scannedDocumentName &&
                !ctxProtocol.currentProtocol.materials.some((m) => m.quantity < 1)
        );
    };

    const handleFinishSend = (closeReason?: ModalCloseReasonEnum) => {
        setIsShareModalOpened(false);
        if (closeReason !== ModalCloseReasonEnum.Close) {
            navigate(ROUTES.FullPaths.AssetList);
        }
    };

    const handleFinishComment = (comment: string, closeReason?: ModalCloseReasonEnum) => {
        setIsCommentModalOpened(false);
        if (closeReason !== ModalCloseReasonEnum.Close && assetId) {
            saveCommentToStorage({ comment, assetId });
            ctxProtocol.setProtocol({
                ...ctxProtocol.currentProtocol,
                comment
            } as ProtocolDto);
        }
    };

    useEffect(() => {
        setCacheVal(LOCAL_STORAGE_KEYS.CustomerName, ctxProtocol.currentProtocol?.customerName);
    }, [ctxProtocol.currentProtocol]);

    return (
        <Fragment>
            <ScrollableContainer $paddingX={theme?.space[0]}>
                <SpinnerContainer loading={loadingProtocol}>
                    <HeaderContainer $marginBottom={theme?.space[8]}>
                        <StyledPageTitle $marginY={theme?.space[0]}>
                            {t('SIGN_PROTOCOL')}
                        </StyledPageTitle>
                        {ctxProtocol.currentProtocol?.isShared && (
                            <CustomButton
                                onClick={openCloseCancelShareModal}
                                buttonType={ButtonsTypesEnum.DestructiveMain}
                                icon={<ShareIcon />}
                                label={t('CANCEL')}></CustomButton>
                        )}
                        {!ctxProtocol.currentProtocol?.isShared && (
                            <CustomButton
                                onClick={() => setIsShareModalOpened(true)}
                                buttonType={ButtonsTypesEnum.Secondary}
                                icon={<ShareIcon />}
                                label={t('SHARE')}
                            />
                        )}
                    </HeaderContainer>

                    <Fragment>
                        {!ctxProtocol.currentProtocol?.declaration?.scannedDocumentName && (
                            <StyledNotScannedErrorLabel>
                                {t(ERROR_CODES.DeclarationNotScanned)}
                            </StyledNotScannedErrorLabel>
                        )}
                        {ctxProtocol.currentProtocol && (
                            <Fragment>
                                <StyledAssetContent>
                                    <StyledAssetCard>
                                        <ListItemCardRowHeader>
                                            <ListItemCardCustomerName
                                                direction="row"
                                                value={ctxProtocol.currentProtocol.customerName}
                                                bolder={true}
                                            />
                                        </ListItemCardRowHeader>

                                        <ListItemCardRow>
                                            <div>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('POC_NAME')}
                                                />
                                                <ListItemCardValue
                                                    direction="column"
                                                    value={ctxProtocol.currentProtocol.pocName}
                                                />
                                            </div>
                                            <ListItemRightCol>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('POC_ID')}
                                                />
                                                <ListItemCardValue
                                                    direction="column"
                                                    value={
                                                        ctxProtocol.currentProtocol.hermesPocId ??
                                                        undefined
                                                    }
                                                />
                                            </ListItemRightCol>
                                        </ListItemCardRow>

                                        <ListItemCardRow>
                                            <div>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('STATUS')}
                                                />
                                                <AssetCardStatusLabel
                                                    $style={getStatusStyle(
                                                        ctxProtocol.currentProtocol.status
                                                    )}>
                                                    {getStatusLabel(
                                                        ctxProtocol.currentProtocol.status
                                                    )}
                                                </AssetCardStatusLabel>
                                            </div>
                                            <ListItemRightCol>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('CONTRACT_NO')}
                                                />
                                                <ListItemCardValue
                                                    direction="column"
                                                    value={
                                                        ctxProtocol.currentProtocol
                                                            .contractNumber ?? undefined
                                                    }
                                                />
                                            </ListItemRightCol>
                                        </ListItemCardRow>

                                        <ListItemCardRow>
                                            <div>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('CONTRACTED')}
                                                />

                                                <ListItemCardValue
                                                    direction="column"
                                                    value={
                                                        ctxProtocol.currentProtocol.contracted ??
                                                        undefined
                                                    }
                                                />
                                            </div>
                                            <ListItemRightCol>
                                                <ListItemCardLabel
                                                    direction="column"
                                                    label={t('CONT_QUANTITY')}
                                                />
                                                <ListItemCardValue
                                                    direction="column"
                                                    value={
                                                        ctxProtocol.currentProtocol.contractedQuantity.toString() ??
                                                        undefined
                                                    }
                                                />
                                            </ListItemRightCol>
                                        </ListItemCardRow>

                                        <ListItemCardRow>
                                            <ListItemCardLabel
                                                direction="column"
                                                label={t('DECLARATION')}
                                            />

                                            <div>
                                                {ctxProtocol.currentProtocol?.declaration
                                                    ?.scannedDocumentName ? (
                                                    <CustomButton
                                                        label={t('VIEW')}
                                                        buttonType={ButtonsTypesEnum.Secondary}
                                                        icon={<ViewDeclarationIcon />}
                                                        onClick={handleViewDeclaration}
                                                    />
                                                ) : (
                                                    <CustomButton
                                                        label={t('SCAN')}
                                                        buttonType={ButtonsTypesEnum.Secondary}
                                                        icon={<ScanIcon />}
                                                        onClick={handleScanDeclaration}
                                                    />
                                                )}
                                            </div>
                                        </ListItemCardRow>

                                        {getNormalizedProtocolType(
                                            ctxProtocol.currentProtocol.protocolType
                                        ) === 'Uninstall' && (
                                            <ListItemCardRow>
                                                <ListItemCardLabel
                                                    direction={'column'}
                                                    label={t('COMMENT')}
                                                />

                                                <div>
                                                    {!comment ? (
                                                        <CustomButton
                                                            label={t('ADD_COMMENT')}
                                                            buttonType={ButtonsTypesEnum.Secondary}
                                                            icon={<PlusIcon />}
                                                            onClick={() =>
                                                                setIsCommentModalOpened(true)
                                                            }
                                                        />
                                                    ) : (
                                                        <StyledCommentButton
                                                            onClick={() =>
                                                                setIsCommentModalOpened(true)
                                                            }>
                                                            <CommentIcon />
                                                            {t('COMMENT')}
                                                            <DotIcon />
                                                        </StyledCommentButton>
                                                    )}
                                                </div>
                                            </ListItemCardRow>
                                        )}

                                        <FlexContainer
                                            $flexDirection="column"
                                            $marginBottom={theme?.space[16]}>
                                            <ListItemCardLabel
                                                direction={'column'}
                                                label={t('CUSTOMER_EMAIL')}
                                            />
                                            <CustomInput
                                                icon={<EmailIcon />}
                                                inputType={'email'}
                                                onChange={onCustomerMailChange}
                                                placeholder={t('ENTER_CUSTOMER_EMAIL')}
                                                defaultValue={
                                                    ctxProtocol.currentProtocol.customerEmail
                                                        ? ctxProtocol.currentProtocol.customerEmail
                                                        : ''
                                                }
                                            />
                                        </FlexContainer>

                                        <FlexContainer
                                            $flexDirection="column"
                                            $marginBottom={theme?.space[16]}>
                                            <ListItemCardLabel
                                                direction={'column'}
                                                label={t('POC_EMPLOYEE')}
                                            />

                                            <CustomInput
                                                icon={<ProfileIcon />}
                                                onChange={onPocEmployeeChangeChange}
                                                placeholder={t('ENTER_POC_EMPLOYEE_NAME')}
                                                isLettersOnly
                                                defaultValue={
                                                    ctxProtocol.currentProtocol.pocPersonnelName
                                                        ? ctxProtocol.currentProtocol
                                                              .pocPersonnelName
                                                        : ''
                                                }
                                                value={
                                                    ctxProtocol.currentProtocol.pocPersonnelName ??
                                                    ''
                                                }
                                            />
                                        </FlexContainer>

                                        <FlexContainer $marginBottom={theme?.space[12]}>
                                            <CustomButton
                                                onClick={handlePreview}
                                                label={t('SIGN')}
                                                buttonType={
                                                    isValid
                                                        ? ButtonsTypesEnum.Main
                                                        : ButtonsTypesEnum.Disabled
                                                }
                                                icon={<SignIcon />}
                                                style={{ width: '100%' }}
                                            />
                                        </FlexContainer>

                                        <ListItemCardFooterWrapper>
                                            <ProtocolIcon />

                                            <ListItemCardFooter $gap={theme?.space[12]}>
                                                <ListItemCardDate>
                                                    <IconContainer>
                                                        <CalendarIcon />
                                                    </IconContainer>
                                                    <span>
                                                        {formatDate(
                                                            ctxProtocol.currentProtocol.lastModified
                                                        )}
                                                    </span>
                                                </ListItemCardDate>
                                                <StyledAssetCardProtocolType>
                                                    {getNormalizedProtocolType(
                                                        ctxProtocol.currentProtocol.protocolType
                                                    ) === 'Install' && (
                                                        <IconContainer>
                                                            <InstallIcon />
                                                        </IconContainer>
                                                    )}
                                                    {getNormalizedProtocolType(
                                                        ctxProtocol.currentProtocol.protocolType
                                                    ) === 'Uninstall' && (
                                                        <IconContainer>
                                                            <UninstallIcon />
                                                        </IconContainer>
                                                    )}
                                                    <StyledProtocolTypeLabel>
                                                        {getNormalizedProtocolType(
                                                            ctxProtocol.currentProtocol.protocolType
                                                        )}
                                                    </StyledProtocolTypeLabel>
                                                </StyledAssetCardProtocolType>
                                            </ListItemCardFooter>
                                        </ListItemCardFooterWrapper>

                                        <AssetCardStatusLine
                                            $style={getStatusStyle(
                                                ctxProtocol.currentProtocol.status
                                            )}
                                        />
                                    </StyledAssetCard>
                                </StyledAssetContent>

                                <Container $paddingY={theme?.space[16]}>
                                    <HeaderContainer
                                        $padding={theme?.space[0]}
                                        $marginTop={theme?.space[0]}
                                        $marginBottom={theme?.space[0]}>
                                        <h3>{t('ARTICLES_LIST')}</h3>
                                    </HeaderContainer>

                                    <MaterialsTable
                                        materials={ctxProtocol.currentProtocol.materials}
                                        onArticlesChange={handleMaterialChange}
                                        noMaterialsMessage={t('NO_MATERIALS_IN_PROTOCOL')}
                                    />
                                </Container>
                            </Fragment>
                        )}
                    </Fragment>
                </SpinnerContainer>
            </ScrollableContainer>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}

            {isRemoveShareModalOpened && (
                <WarningModal
                    isDestructive={true}
                    accept={handleCancelShare}
                    deny={openCloseCancelShareModal}
                    title={t('REMOVE_SHARE')}
                    message={t('REMOVE_SHARE_MESSAGE')}
                />
            )}

            {isShareModalOpened && (
                <ShareModal
                    close={handleFinishSend}
                    assetsIds={[ctxProtocol?.currentProtocol?.id] || []}
                />
            )}

            {isCommentModalOpened && (
                <CommentModal
                    initialComment={comment}
                    viewOnly={false}
                    close={handleFinishComment}
                />
            )}
        </Fragment>
    );
};

export default Protocol;
