import { TableDataProvider } from '@/hooks/admin/useTableData';
import BrandsTable from '@/pages/admin/CommercialAgreements/Brands/partials/BrandsTable';

const Brands = () => (
    <TableDataProvider>
        <BrandsTable />
    </TableDataProvider>
);

export default Brands;
