import React, { useEffect, useState } from 'react';

import { Checkbox, Form, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from '@/components/shared/message/ErrorMessage';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import useLogin from '@/hooks/authentication/useLogin';
import useError from '@/hooks/error/useError';
import { useUserContext } from '@/store/user/useUserContext';
import { login, passExpiredHandler } from '@/utils/authorizationUtils';

import {
    AuthButton,
    AuthButtonsContainer,
    AuthCheckboxLabel,
    AuthForm,
    AuthFormContainer,
    AuthHeader,
    AuthHeaderContainer,
    AuthLink
} from '../../shared/styles';

const Login: React.FC = () => {
    const {
        token,
        role,
        isF2RCMC,
        setToken,
        permissions: { canAccessAdminRoutes, canAccessSRRoutes }
    } = useUserContext();
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { dispatchLogin } = useLogin();
    const { setError, clearError, errorMessage } = useError();
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    type FormValues = {
        email: string;
        password: string;
    };

    const onFinish = (values: FormValues) => {
        setLoading(true);
        dispatchLogin({
            email: values.email,
            password: values.password,
            rememberMe
        })
            .then(async (response) => {
                const data = await response.json();

                if (!response.ok) {
                    return await Promise.reject(data);
                }

                const token = data.token;
                setToken(token);
                const refreshToken = data.refreshToken;

                login(token, refreshToken);
                clearError();
            })
            .catch((err) => {
                setLoading(false);
                setError(err, ErrorTypeEnum.LoginError);

                if (err?.errors?.PASSWORD_EXPIRED) {
                    const token = err?.errors?.PASSWORD_EXPIRED[0];
                    const refreshToken = err?.errors?.PASSWORD_EXPIRED[1];
                    setToken(token);
                    passExpiredHandler(true);
                    login(token, refreshToken);
                    clearError();
                }
            });
    };

    useEffect(() => {
        if (token && isF2RCMC && canAccessSRRoutes) {
            navigate(ROUTES.FullPaths.AgreementList);

            return;
        }

        if (token && role && (canAccessAdminRoutes || canAccessSRRoutes)) {
            setLoading(false);
            navigate(ROUTES.Default);
        }
    }, [token, role]); // eslint-disable-line

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        setRememberMe(e.target.checked);
    };

    return (
        <AuthFormContainer>
            <AuthHeaderContainer>
                <AuthHeader>{t('LOGIN_TITLE')}</AuthHeader>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </AuthHeaderContainer>
            <AuthForm
                name="basic"
                labelCol={{ span: 20 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onClick={clearError}
                autoComplete="off">
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: t('INVALID_EMAIL')
                        }
                    ]}>
                    <Input
                        type="email"
                        placeholder={t('USER_EMAIL')}
                        size="large"
                        autoComplete="username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('INVALID_PASSWORD')
                        }
                    ]}>
                    <Input.Password
                        placeholder={t('PASSWORD')}
                        size="large"
                        autoComplete="current-password"
                    />
                </Form.Item>

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{ offset: 7, span: 12 }}>
                    <Checkbox defaultChecked={false} onChange={handleCheckboxChange}>
                        <AuthCheckboxLabel>{t('REMEMBER_ME')}</AuthCheckboxLabel>
                    </Checkbox>
                </Form.Item>
                <AuthButtonsContainer>
                    <AuthButton
                        label={t('LOGIN')}
                        buttonType={ButtonsTypesEnum.Main}
                        functionType={'submit'}
                        loading={loading}
                    />
                    <AuthLink to={ROUTES.FullPaths.ForgottenPassword}>
                        {`${t('FORGOT_PASSWORD')}?`}
                    </AuthLink>
                </AuthButtonsContainer>
            </AuthForm>
        </AuthFormContainer>
    );
};

export default Login;
