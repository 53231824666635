import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import CustomButton from '@/components/shared/UI/CustomButton';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { Container } from '@/styles/shared/container/styles';
import {
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardValue
} from '@/styles/shared/list-item/styles';

import {
    SignPreviewContainer,
    SignSectionCard,
    SignSectionLabel,
    SignSectionTitle
} from './styles';

type Props = {
    sign?: string;
    signatureIndex: number;
    signer: string;
    name: string;
    email: string;
    sectionTitle: string;
};

const SignSection: React.FC<Props> = ({
    sign,
    signatureIndex,
    signer,
    name,
    email,
    sectionTitle
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation('translation');

    const handleClick = () => {
        navigate(ROUTES.AssetList.Protocol.ProtocolPreview.Sign.SigningArea, {
            state: {
                signer,
                signatureIndex,
                email,
                signerName: name,
                title: sectionTitle
            }
        });
    };

    return (
        <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[32]}>
            <SignSectionTitle>{sectionTitle}</SignSectionTitle>
            <SignSectionCard>
                <>
                    <ListItemCardRow>
                        <ListItemCardValue direction={'row'} value={name} />
                    </ListItemCardRow>
                    <div>
                        <ListItemCardLabel direction={'column'} value={t('EMAIL')} />
                        <ListItemCardValue
                            $fontSize={theme?.fontSize.xlg}
                            $color={theme?.color.grey[40]}
                            direction={'row'}
                            value={email}
                        />
                    </div>

                    {sign && (
                        <>
                            <div>
                                <SignPreviewContainer>
                                    {sign && <img alt="Sign Protocol Preview" src={sign} />}
                                </SignPreviewContainer>
                                <SignSectionLabel>{sectionTitle} Sign</SignSectionLabel>
                            </div>
                            <CustomButton
                                onClick={handleClick}
                                label={t('RE_SIGN')}
                                buttonType={ButtonsTypesEnum.Secondary}
                                icon={<SignIcon />}
                            />
                        </>
                    )}

                    {!sign && (
                        <CustomButton
                            onClick={handleClick}
                            label={t('ADD_SIGNATURE')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<SignIcon />}
                        />
                    )}
                </>
            </SignSectionCard>
        </Container>
    );
};

export default SignSection;
