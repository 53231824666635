import {
    AdminAssetFilterRequestModelInput,
    DocumentStatusEnum,
    AssetTypeEnum,
    MaterialTypeEnum
} from '@/graphql/main';

export const assetTypes = Object.values(AssetTypeEnum);
export const assetStatuses = Object.values(DocumentStatusEnum);
export const materialTypes = Object.values(MaterialTypeEnum);

export const defaultFilterState: AdminAssetFilterRequestModelInput = {
    assetType: undefined,
    status: undefined,
    customer: undefined,
    pocId: undefined,
    employee: undefined,
    materialType: undefined,
    fromDate: undefined,
    toDate: undefined
};
