import styled from 'styled-components';

export const StyledConfirmEmailContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-self: flex-end;
    width: 300px;
    height: 200px;
    margin-bottom: 15%;
    border: 2px solid ${({ theme }) => theme.color.green[40]};
    border-radius: 8px;
`;

export const StyledSuccessMessage = styled.p`
    color: ${({ theme }) => theme.color.grey[40]};
    font-size: ${({ theme }) => theme.fontSize.h4};
    font-weight: 700;
    text-align: center;
    max-width: 85%;
`;

export const StyledActionMessage = styled.span`
    color: ${({ theme }) => theme.color.grey[30]};
    font-size: ${({ theme }) => theme.fontSize.h6};
`;
