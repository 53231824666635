import { useRef, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input, InputRef, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as LeftArrowIcon } from '@/assets/svg-icons/left-arrow-icon.svg';
import { ReactComponent as RightArrowIcon } from '@/assets/svg-icons/right-arrow-icon.svg';
import CloseModalBar from '@/components/shared/modal/partials/CloseModalBar';
import ModalBody from '@/components/shared/modal/partials/ModalBody';
import ModalContainer from '@/components/shared/modal/partials/ModalContainer';
import ModalFooter from '@/components/shared/modal/partials/ModalFooter';
import ModalHeader from '@/components/shared/modal/partials/ModalHeader';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';
import {
    MaterialTypeEnum,
    useGetMaterialModelsLazyQuery,
    useGetMaterialTypesQuery
} from '@/graphql/main';
import { FlexContainer } from '@/styles/shared/container/styles';
import { StyledDropdown } from '@/styles/shared/dropdown/styles';
import { ListItemCardLabel } from '@/styles/shared/list-item/styles';
import { generateLargeRandomNumber } from '@/utils/functions';

import {
    CreateMaterialQuantityContainer,
    StyledCreateMaterialButton,
    StyledCreateMaterialLeftArrow,
    StyledCreateMaterialRightArrow
} from './styles';

type MaterialWithId = {
    id: number;
    type: string;
    model: { id: number; name: string };
    quantity: number;
};

const defaultMaterial: MaterialWithId = {
    id: 0,
    type: '',
    model: { id: 0, name: '' },
    quantity: 0
};

type Props = {
    onMaterialCreation: (material: MaterialWithId, closeReason: ModalCloseReasonEnum) => void;
    close: (closeReason: ModalCloseReasonEnum) => void;
};

const CreateMaterialModal: React.FC<Props> = ({ onMaterialCreation, close }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [material, setMaterial] = useState<MaterialWithId>({
        ...defaultMaterial,
        id: generateLargeRandomNumber()
    });

    const quantityRef = useRef<InputRef>(null);

    const [isQuantityValid, setIsQuantityValid] = useState<boolean>(true);
    const [materialTypes, setMaterialTypes] = useState<MaterialTypeEnum[]>();
    const [loadedMaterialModels, setLoadedMaterialModels] =
        useState<Array<{ id: number; name: string }>>();

    useGetMaterialTypesQuery({
        onCompleted: (data) => {
            setMaterialTypes(data.createContractMaterialTypes);
        }
    });
    const [materialModels] = useGetMaterialModelsLazyQuery();

    const materialTypesItems: MenuProps['items'] = materialTypes?.map((type) => {
        return { label: type, key: type };
    });

    const materialModelsItems = loadedMaterialModels?.map((model) => {
        return { label: model.name, value: model.name };
    });

    const handleTypeSelection: MenuProps['onClick'] = ({ key, domEvent }) => {
        const label = domEvent.currentTarget.innerText;

        updateMaterialType(label);
        fetchMaterialModels(label);
    };

    function updateMaterialType(label: string) {
        setMaterial(
            (prevState) =>
                ({
                    ...prevState,
                    type: label
                } as MaterialWithId)
        );
    }

    function fetchMaterialModels(label: string) {
        materialModels({
            variables: {
                type: label as MaterialTypeEnum,
                searchText: ''
            },
            onCompleted: (data) => {
                setLoadedMaterialModels(data.materialModels);
            }
        });
    }

    const handleModelSelection = (_: any, model: { label: string; value: string }) => {
        const materialId = loadedMaterialModels?.find(
            (materialModel) => materialModel.name === model.label
        )?.id;

        if (materialId) {
            setMaterial((prevState) => {
                return {
                    ...prevState,
                    model: { id: materialId, name: model.label }
                } as MaterialWithId;
            });
        }
    };

    const handleQuantityInput = (e: any) => {
        const quantity = +e.currentTarget.value;

        setMaterial((prevState) => {
            return { ...prevState, quantity } as MaterialWithId;
        });
    };

    const isFormValid = (): boolean => {
        return material.quantity > 0 && !!material.model && !!material.type && !!material.quantity;
    };

    const handleCreateMaterial = () => {
        if (isFormValid()) {
            onMaterialCreation(material, ModalCloseReasonEnum.Success);
        }
    };

    const handleSearchModel = (searchText: string) => {
        materialModels({
            variables: {
                type: material.type as MaterialTypeEnum,
                searchText
            },
            onCompleted: (data) => {
                setLoadedMaterialModels(data.materialModels);
            }
        });
    };

    const handleQuantityIncrease = () => {
        setMaterial((material) => {
            return { ...material, quantity: material.quantity + 1 };
        });

        setIsQuantityValid(true);
    };

    const handleQuantityDecrease = () => {
        if (material.quantity > 1) {
            setMaterial((material) => {
                return { ...material, quantity: material.quantity - 1 };
            });

            setIsQuantityValid(true);
        }
    };

    return (
        <ModalContainer>
            <CloseModalBar
                backgroundColor={theme?.color.default.white}
                onClick={() => close(ModalCloseReasonEnum.Close)}
            />
            <ModalHeader backgroundColor={theme?.color.default.white}>
                <StyledPageTitle>{t('CREATE_MATERIAL')}</StyledPageTitle>
            </ModalHeader>

            <ModalBody backgroundColor={theme?.color.default.white}>
                <FlexContainer $flexDirection="column" $width={100} $gap={theme?.space[20]}>
                    <div>
                        <ListItemCardLabel direction="row" label={t('CHOOSE_TYPE')} />
                        <StyledDropdown
                            $paddingY={theme?.space[0]}
                            $paddingX={theme?.space[12]}
                            menu={{
                                items: materialTypesItems,
                                onClick: handleTypeSelection
                            }}
                            disabled={material.model.name !== ''}>
                            <Button>
                                {material.type || t('TYPE')}
                                <DownOutlined />
                            </Button>
                        </StyledDropdown>
                    </div>

                    <div>
                        <ListItemCardLabel direction="row" label={t('CHOOSE_MODEL')} />
                        <AutoComplete
                            onSelect={handleModelSelection}
                            placeholder={t('MODEL')}
                            options={materialModelsItems}
                            onClick={() => handleSearchModel('')}
                            onSearch={handleSearchModel}
                            disabled={!material.type || !!material.model.name}
                            style={{ width: '100%' }}
                        />
                    </div>

                    <div>
                        <ListItemCardLabel direction="row" label={t('QUANTITY')} />
                        <CreateMaterialQuantityContainer>
                            {material.model.name && (
                                <StyledCreateMaterialLeftArrow onClick={handleQuantityDecrease}>
                                    <LeftArrowIcon />
                                </StyledCreateMaterialLeftArrow>
                            )}

                            <Input
                                ref={quantityRef}
                                type="number"
                                value={material.quantity}
                                status={isQuantityValid ? '' : 'error'}
                                disabled={!material.model.name}
                                onInput={handleQuantityInput}
                            />
                            {material.model.name && (
                                <StyledCreateMaterialRightArrow onClick={handleQuantityIncrease}>
                                    <RightArrowIcon />
                                </StyledCreateMaterialRightArrow>
                            )}
                        </CreateMaterialQuantityContainer>
                    </div>
                </FlexContainer>
            </ModalBody>

            <ModalFooter backgroundColor={theme?.color.default.white}>
                <StyledCreateMaterialButton
                    label={t('CREATE_MATERIAL')}
                    buttonType={isFormValid() ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                    onClick={handleCreateMaterial}
                />
            </ModalFooter>
        </ModalContainer>
    );
};

export default CreateMaterialModal;
