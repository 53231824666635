import React from 'react';

import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { AgreementDto, DocumentStatusEnum } from '@/graphql/main';
import { useUserContext } from '@/store/user/useUserContext';
import { ListNoContentLabel, ListScrollableContainer } from '@/styles/shared/list/styles';

import AgreementCard from '../AgreementCard';
import { FilterWithType } from '../AgreementFilters';

type Pros = {
    agreements: AgreementDto[];
    fetchNext: () => void;
    hasMore: boolean;
};

const AgreementList: React.FC<Pros> = ({ agreements, fetchNext, hasMore }) => {
    const { t } = useTranslation('translation');
    const { isF2RCMC } = useUserContext();

    const getIsProtocolPreparedFilter = () => {
        const filters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AgreementFilters)!);

        if (!filters) {
            return;
        }

        return filters.some((filter: FilterWithType) => {
            return filter.statusKey === DocumentStatusEnum.Prepared;
        });
    };

    return (
        <ListScrollableContainer id="scrollTarget">
            <InfiniteScroll
                className="list-container"
                dataLength={agreements?.length ?? 0}
                next={fetchNext}
                hasMore={hasMore}
                loader={<Spin />}
                scrollableTarget="scrollTarget">
                {agreements?.map((agreement: AgreementDto, index) => {
                    return (
                        <AgreementCard
                            key={`${agreement.agreementId as string}_${index}`}
                            agreement={agreement}
                        />
                    );
                })}
            </InfiniteScroll>

            {agreements?.length === 0 && !getIsProtocolPreparedFilter() && (
                <ListNoContentLabel>
                    {isF2RCMC ? t('NO_AGREEMENTS_LEFT_FOR_VALIDATION') : t('NO_AGREEMENTS_FOUND')}
                </ListNoContentLabel>
            )}
        </ListScrollableContainer>
    );
};

export default AgreementList;
