import { useTranslation } from 'react-i18next';

import { saveCurrentLanguage, getCurrentLanguage } from '@/utils/languageStorage';

const useLanguage = () => {
    const { i18n } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    const setLanguage = () => {
        const lang = currentLanguage === 'en' ? 'bg' : 'en';
        saveCurrentLanguage(lang);
        i18n.changeLanguage(lang);
    };

    return { setLanguage, currentLanguage };
};

export default useLanguage;
