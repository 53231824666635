import React, { useContext, useEffect, useRef, useState } from 'react';

import { Form, Input, Table, InputRef } from 'antd';
import type { FormInstance } from 'antd/es/form';

import { Model } from '@/graphql/main';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

type EditableRowProps = {
    index: number;
};

type EditableCellProps = {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Model;
    record: Model;
    handleSave: (record: Model) => void;
};

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableNumberCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            let values = await form.validateFields();

            const numericValue = parseFloat(values[dataIndex]);

            // Check if the numericValue is not a number (i.e., if the input was empty),
            // in which case set it to an empty string
            if (!isNaN(numericValue)) {
                values = { ...values, [dataIndex]: numericValue };
            }

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {}
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setInputValue(value);
        form.setFieldsValue({ [dataIndex]: value });
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        // this RegExp matches n > 0, where n can be a float
                        pattern: new RegExp(/^(0\.[0-9]*[1-9][0-9]*|[1-9][0-9]*(\.[0-9]+)?)$/),
                        message: `${title as string} must be a positive number`
                    }
                ]}>
                <Input
                    ref={inputRef}
                    value={inputValue}
                    onPressEnter={() => {
                        void save();
                    }}
                    onBlur={() => {
                        void save();
                    }}
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                        const keyValue = event.key;
                        if (!/\d/.test(keyValue) && keyValue !== '.' && keyValue !== 'Backspace')
                            event.preventDefault();
                    }}
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};
const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {}
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setInputValue(value);
        form.setFieldsValue({ [dataIndex]: value });
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
                <Input
                    ref={inputRef}
                    value={inputValue}
                    onPressEnter={() => {
                        void save();
                    }}
                    onBlur={() => {
                        void save();
                    }}
                    onChange={handleInputChange}
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export { EditableRow, EditableNumberCell, EditableCell };
