import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as ReturnIcon } from '@/assets/svg-icons/return-icon.svg';
import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import WarningModal from '@/components/shared/modal/WarningModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import {
    AgreementDetailsDto,
    DocumentStatusEnum,
    useReturnAgreementToSrMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { ListItemCardRow } from '@/styles/shared/list-item/styles';
import { saveCurrentContractData } from '@/utils/assetStorage';

type Props = {
    hasDeclaration: boolean;
    agreementId: number;
};

const AgreementValidate: React.FC<Props> = ({ hasDeclaration, agreementId }) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { agreementDetails: ctxAgreementDetails } = useContext(AppContext);
    const { errorMessage, setError, clearError } = useError();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isWarning, setIsWarning] = useState(false);

    const [returnAgreementToSr] = useReturnAgreementToSrMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: () => {
            setIsWarning(false);
            setIsSuccess(true);
            clearError();
        }
    });

    const handleReturnAgreementToSr = () => {
        returnAgreementToSr({
            variables: {
                agreementId
            }
        });
    };

    const navigateToDashboard = () => {
        navigate(ROUTES.FullPaths.AgreementList);
    };

    const handlePreview = () => {
        if (ctxAgreementDetails.currentAgreementDetails) {
            saveCurrentContractData<AgreementDetailsDto>(
                ctxAgreementDetails.currentAgreementDetails
            );
        }

        navigate(ROUTES.AgreementList.AgreementPreview.Path);
    };

    return (
        <>
            {ctxAgreementDetails.currentAgreementDetails?.status ===
                DocumentStatusEnum.ForValidation && (
                <>
                    <ListItemCardRow $marginBottom={theme?.space[4]}>
                        <CustomButton
                            onClick={handlePreview}
                            label={t('VALIDATE')}
                            buttonType={
                                !hasDeclaration ? ButtonsTypesEnum.Disabled : ButtonsTypesEnum.Main
                            }
                            icon={!hasDeclaration ? undefined : <TickIcon />}
                        />
                    </ListItemCardRow>

                    <ListItemCardRow $marginBottom={theme?.space[16]}>
                        <CustomButton
                            onClick={() => setIsWarning(true)}
                            label={t('RETURN_TO_SR')}
                            buttonType={ButtonsTypesEnum.Tertiary}
                            icon={<ReturnIcon />}
                        />
                    </ListItemCardRow>
                </>
            )}
            {isWarning && (
                <WarningModal
                    isDestructive={true}
                    accept={handleReturnAgreementToSr}
                    deny={() => setIsWarning(false)}
                    title={t('RETURN_TO_SR')}
                    message={t('RETURN_TO_SR_WARNING_MESSAGE')}
                    subMessage={t('RETURN_TO_SR_WARNING_SUBMESSAGE')}
                />
            )}

            {isSuccess && (
                <SuccessModal
                    title={t('RETURN_TO_SR')}
                    message={t('RETURNED_TO_SR_SUCCESS_MESSAGE')}
                    modalType={SuccessModalTypeEnum.Draft}
                    onConfirm={navigateToDashboard}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} />}
        </>
    );
};

export default AgreementValidate;
