import { useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as EmailIcon } from '@/assets/svg-icons/email-icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/svg-icons/profile-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import { ReactComponent as TickIconGrey } from '@/assets/svg-icons/tick-icon-grey.svg';
import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomInput from '@/components/shared/UI/CustomInput';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import {
    DocumentStatusEnum,
    GetAgreementDetailsQuery,
    GetAgreementDetailsQueryVariables,
    useSetAgreementToStatusDoneMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { FlexContainer } from '@/styles/shared/container/styles';
import { ListItemCardLabel, ListItemCardRow } from '@/styles/shared/list-item/styles';

import CancelAgreement from '../CancelAgreement';

type Props = {
    customerEmail: string | undefined;
    pocEmployee: string | undefined;
    status: DocumentStatusEnum;
    agreementId: string;
    hasAttachedAdditionalDocuments?: boolean;
    refetchAgreementDetails: (
        variables?: GetAgreementDetailsQueryVariables | undefined
    ) => Promise<ApolloQueryResult<GetAgreementDetailsQuery>>;
};

const AgreementComplete: React.FC<Props> = ({
    customerEmail,
    pocEmployee,
    status,
    agreementId,
    hasAttachedAdditionalDocuments = false,
    refetchAgreementDetails
}) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const { setError, clearError, errorMessage } = useError();
    const [isSetStatusDoneSuccess, setIsSetStatusDoneSuccess] = useState(false);

    const completeBtnIcon = hasAttachedAdditionalDocuments ? (
        <TickIcon />
    ) : status === DocumentStatusEnum.Done ? (
        <TickIconGrey />
    ) : (
        <SignIcon />
    );
    const [setAgreementToStatusDone] = useSetAgreementToStatusDoneMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: () => {
            setIsSetStatusDoneSuccess(true);
            clearError();
        }
    });

    const handleSetStatusDone = () => {
        setAgreementToStatusDone({
            variables: {
                agreementId
            }
        });
    };
    const handleSuccessModalClose = () => {
        refetchAgreementDetails?.();
        setIsSetStatusDoneSuccess(false);
    };

    return (
        <>
            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[12]}>
                <ListItemCardLabel direction={'column'} label={t('CUSTOMER_EMAIL')} />
                <CustomInput
                    icon={<EmailIcon />}
                    inputType="email"
                    defaultValue={customerEmail}
                    isDisabled={true}
                />
            </FlexContainer>

            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[12]}>
                <ListItemCardLabel direction={'column'} label={t('POC_EMPLOYEE')} />

                <CustomInput icon={<ProfileIcon />} defaultValue={pocEmployee} isDisabled={true} />
            </FlexContainer>

            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[12]}>
                <ListItemCardRow $marginBottom={theme?.space[4]}>
                    <CustomButton
                        onClick={handleSetStatusDone}
                        label={t('COMPLETE')}
                        buttonType={
                            hasAttachedAdditionalDocuments && status !== DocumentStatusEnum.Done
                                ? ButtonsTypesEnum.Main
                                : ButtonsTypesEnum.Disabled
                        }
                        icon={completeBtnIcon}
                    />
                </ListItemCardRow>

                {status === DocumentStatusEnum.Signed && (
                    <CancelAgreement agreementId={agreementId} />
                )}

                {!hasAttachedAdditionalDocuments && status !== DocumentStatusEnum.Done && (
                    <ListItemCardRow $marginBottom={theme?.space[8]}>
                        <InfoMessage message={t('ATTACH_DOCUMENTS_INFO_MSG')} />
                    </ListItemCardRow>
                )}
            </FlexContainer>
            {isSetStatusDoneSuccess && (
                <SuccessModal
                    title={t('SET_AGREEMENT_DONE_SUCCESS')}
                    message={t('SET_AGREEMENT_DONE_SUCCESS_MESSAGE')}
                    modalType={SuccessModalTypeEnum.Success}
                    onConfirm={handleSuccessModalClose}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} />}
        </>
    );
};

export default AgreementComplete;
