import { Fragment, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/svg-icons/arrow-icon.svg';
import sidebarBtn from '@/assets/svg-icons/sidebar-button-icon.svg';
import logo from '@/assets/svgs/logo-text-left.svg';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { LogoTypeEnum } from '@/enums/logo-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import AppContext from '@/store/app-context/AppContext';
import { NavbarState } from '@/types/NavbarState';

import AppLogo from '../../logo/AppLogo';
import CustomButton from '../../UI/CustomButton';
import { BackButtonContainer } from '../../UI/CustomButton/styles';
import Sidebar from '../Sidebar';

import { Button, RightSide, StyledNavBar } from './styles';

const Navbar: React.FC = () => {
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [navState, setNavState] = useState<NavbarState>();
    const [isSidebarShown, setIsSidebarShown] = useState<boolean>(false);

    useEffect(() => {
        const result = navbar.navbarConfigs ? navbar.navbarConfigs : {};
        setNavState(result);
    }, [navbar.navbarConfigs?.leftSide, navbar.navbarConfigs, navbar.navbarConfigs?.rightSide]);

    const handleBack = () => {
        if (navbar.navbarConfigs?.onBackCallOverrideBackFunctionality) {
            navbar.navbarConfigs?.onBackCallOverrideBackFunctionality();
        } else {
            navbar.navbarConfigs?.onBackCallback && navbar.navbarConfigs?.onBackCallback();
            navigate(-1);
        }
    };

    const showSidebar = () => {
        setIsSidebarShown(true);
    };

    return (
        <Fragment>
            <StyledNavBar
                style={{
                    backgroundColor: navbar.navbarConfigs?.background ?? theme?.color.default.bg
                }}>
                {navState?.leftSide === NavbarElementsEnum.BackButton && (
                    <BackButtonContainer>
                        <CustomButton
                            onClick={handleBack}
                            buttonType={ButtonsTypesEnum.Tertiary}
                            label={t('BACK')}
                            labelClassName="back-btn-label"
                            icon={<ArrowIcon />}
                        />
                    </BackButtonContainer>
                )}

                {navState?.leftSide === NavbarElementsEnum.None && (
                    <BackButtonContainer>
                        <div></div>
                    </BackButtonContainer>
                )}

                {navState?.leftSide === NavbarElementsEnum.SidebarButton && (
                    <Button onClick={showSidebar}>
                        <img alt="" src={sidebarBtn} />
                    </Button>
                )}
                <RightSide>
                    {navState?.rightSide === NavbarElementsEnum.NavbarLogo && (
                        <AppLogo
                            logoType={LogoTypeEnum.NavbarLogo}
                            path={logo}
                            labelSize={theme?.fontSize.xlg}
                        />
                    )}
                </RightSide>
            </StyledNavBar>

            <Sidebar isSidebarShown={isSidebarShown} setIsSidebarShown={setIsSidebarShown} />
        </Fragment>
    );
};

export default Navbar;
