import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DocumentStatusEnum } from '@/graphql/main';
import useStatus from '@/hooks/status/useStatus';
import useLanguage from '@/hooks/useLanguage';
import {
    StyledAssetStatusOption,
    StyledStatusFilterCount,
    StyledStatusFilterLabel
} from '@/styles/shared/status/styles';

type Props = {
    status?: DocumentStatusEnum;
    isSelected: boolean;
    count: number;
    onClick: () => void;
};

const AssetStatusFilterOption: React.FC<Props> = ({ status, isSelected, count, onClick }) => {
    const [label, setLabel] = useState<string | undefined>('');
    const [styleType, setStyleType] = useState<string | undefined>('');
    const { t } = useTranslation();
    const { currentLanguage } = useLanguage();
    const { getStatusLabel, getStatusStyle } = useStatus();

    useEffect(() => {
        if (status) {
            const normalizedStatus = getStatusLabel(status);
            const statusStyle = getStatusStyle(status);
            setLabel(normalizedStatus);
            setStyleType(statusStyle);
        } else {
            setLabel(t('ALL_DOCS'));
            setStyleType('all-docs');
        }
    }, [t, currentLanguage, getStatusLabel, getStatusStyle, status]);

    return (
        <StyledAssetStatusOption onClick={onClick} selected={isSelected} $styleType={styleType}>
            <StyledStatusFilterLabel selected={isSelected}>{label}</StyledStatusFilterLabel>
            <StyledStatusFilterCount>{count}</StyledStatusFilterCount>
        </StyledAssetStatusOption>
    );
};

export default AssetStatusFilterOption;
