import { Input, InputProps } from 'antd';
import styled from 'styled-components';

export const RecoveryInputContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const RecoveryInput: React.FunctionComponent<InputProps> = styled(Input)`
    height: ${({ theme }) => theme.space[64]};
    width: ${({ theme }) => theme.space[64]};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.h0};
    color: ${({ theme }) => theme.color.grey[40]};
`;
