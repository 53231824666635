import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ContractModelDto, ProtocolMaterialDto } from '@/graphql/main';

import MaterialsTableRow from './partials/MaterialTableRow';
import { StyledMaterialsTable } from './styles';

type Props = {
    materials?: ProtocolMaterialDto[];
    models?: ContractModelDto[];
    isRemoveMode?: boolean;
    onArticlesChange: (id: number, quantity: number) => void;
    onRowsForRemoveSelection?: (ids: number[]) => void;
    noMaterialsMessage?: string;
};

const MaterialsTable: React.FC<Props> = ({
    materials,
    models,
    isRemoveMode,
    onArticlesChange,
    onRowsForRemoveSelection,
    noMaterialsMessage
}) => {
    const { t } = useTranslation('translation');
    const [selectedForRemoveRows, setSelectedForRemoveRows] = useState<number[]>([]);

    useEffect(() => {
        if (onRowsForRemoveSelection) {
            onRowsForRemoveSelection(selectedForRemoveRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedForRemoveRows]);

    useEffect(() => {
        if (!isRemoveMode) {
            setSelectedForRemoveRows(() => []);
        }
    }, [isRemoveMode]);

    const handleSelectDeselectRow = (id: number) => {
        if (isRemoveMode) {
            setSelectedForRemoveRows((prevState) => {
                const isSelected = prevState.includes(id);

                return isSelected ? prevState.filter((x) => x !== id) : [...prevState, id];
            });
        }
    };

    return (
        <Fragment>
            {materials && (
                <Fragment>
                    {materials.length > 0 && (
                        <StyledMaterialsTable $isRemoveMode={isRemoveMode} $isMaterialsTable={true}>
                            {materials.map((material) => (
                                <MaterialsTableRow
                                    key={material.id}
                                    id={material.id}
                                    title={material.name}
                                    subtitle={material.brand}
                                    quantity={material.quantity}
                                    onQuantityChange={onArticlesChange}
                                    onClick={handleSelectDeselectRow}
                                    isRemoveMode={isRemoveMode}
                                />
                            ))}
                        </StyledMaterialsTable>
                    )}

                    {!(materials.length > 0) && (
                        <h4 className="title">{noMaterialsMessage ?? t('NO_ARTICLES_IN_TABLE')}</h4>
                    )}
                </Fragment>
            )}

            {models && (
                <Fragment>
                    {models.length > 0 && (
                        <StyledMaterialsTable $isRemoveMode={isRemoveMode}>
                            {models.map((model) => (
                                <MaterialsTableRow
                                    key={model.id}
                                    id={model.id}
                                    title={model.model}
                                    subtitle={model.type}
                                    quantity={model.quantity}
                                    onQuantityChange={onArticlesChange}
                                    onClick={handleSelectDeselectRow}
                                    isRemoveMode={isRemoveMode}
                                />
                            ))}
                        </StyledMaterialsTable>
                    )}

                    {!(models.length > 0) && (
                        <h4 className="title">{noMaterialsMessage ?? t('NO_ARTICLES_IN_TABLE')}</h4>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default MaterialsTable;
