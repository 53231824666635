import { useState } from 'react';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorMessage from '@/components/shared/message/ErrorMessage';
import { StyledSubtitle } from '@/components/shared/UI/CustomSubtitle/styles';
import ERROR_CODES from '@/constants/errors/error-codes';
import ROUTES from '@/constants/routes/routes';
import { PASSWORD_RULES } from '@/constants/rules/password-rules';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { useChangePasswordMutation, useResetPasswordMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { useUserContext } from '@/store/user/useUserContext';
import { getCurrentUserEmail, passExpiredHandler } from '@/utils/authorizationUtils';

import {
    AuthButton,
    AuthButtonsContainer,
    AuthForm,
    AuthFormContainer,
    AuthHeader,
    AuthHeaderContainer
} from '../../shared/styles';

const ResetPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname, state } = useLocation();
    const { role } = useUserContext();
    const { setError, clearError, errorMessage } = useError();
    const [loading, setLoading] = useState<boolean>(false);

    const [resetPassword] = useResetPasswordMutation({
        onError: (error) => {
            setLoading(false);
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: () => {
            clearError();
            navigate(ROUTES.FullPaths.Login);
        }
    });
    const [changePassword] = useChangePasswordMutation({
        onError: (error) => {
            setLoading(false);
            passExpiredHandler(false);
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: () => {
            clearError();
            passExpiredHandler(false);
            (role === 'RCMC' || role === 'SUPERVISOR') && navigate(ROUTES.FullPaths.AgreementList);
            (role === 'ADMIN' || role === 'SUPER_ADMIN') && navigate(ROUTES.Admin.Users.Path);
            role === 'SALES_REPRESENTATIVE' && navigate(ROUTES.FullPaths.AssetList);
        }
    });

    const onFinishAsync = async (values: { newPassword: string; confirmPassword: string }) => {
        if (values.newPassword !== values.confirmPassword) {
            setError(ERROR_CODES.PasswordsNotMatch, ErrorTypeEnum.FrontEndError);

            return;
        }
        setLoading(true);
        if (pathname === ROUTES.FullPaths.ResetPassword) {
            await resetPassword({
                variables: {
                    input: {
                        email: state.email,
                        newPassword: values.newPassword,
                        resetPasswordCode: state.code
                    }
                }
            });
        } else if (pathname === ROUTES.FullPaths.ChangePassword) {
            const email = getCurrentUserEmail();

            await changePassword({
                variables: {
                    input: {
                        email,
                        newPassword: values.newPassword
                    }
                }
            });
        }
    };

    return (
        <AuthFormContainer>
            <AuthHeaderContainer>
                <AuthHeader>{t('RESET_PASSWORD_TITLE')}</AuthHeader>
                {!!errorMessage?.length && <ErrorMessage message={t(errorMessage.split(':')[0])} />}
                <StyledSubtitle>{t('RESET_PASSWORD_SUBTITLE')}</StyledSubtitle>
            </AuthHeaderContainer>

            <AuthForm
                name="basic"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinishAsync}
                autoComplete="off">
                <Form.Item name="newPassword" rules={PASSWORD_RULES}>
                    <Input.Password placeholder={t('NEW_PASSWORD')} size="large" />
                </Form.Item>
                <Form.Item name="confirmPassword" rules={PASSWORD_RULES}>
                    <Input.Password placeholder={t('CONFIRM_PASSWORD')} size="large" />
                </Form.Item>
                <AuthButtonsContainer>
                    <AuthButton
                        label={t('RESET_PASSWORD')}
                        buttonType={ButtonsTypesEnum.Main}
                        functionType={'submit'}
                        loading={loading}
                    />
                </AuthButtonsContainer>
            </AuthForm>
        </AuthFormContainer>
    );
};

export default ResetPassword;
