import { StyledModalBody } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
    backgroundColor?: string;
    padding?: string;
};

const ModalBody: React.FC<Props> = ({ children, className, backgroundColor, padding }) => {
    return (
        <StyledModalBody className={className} style={{ backgroundColor, padding }}>
            {children}
        </StyledModalBody>
    );
};

export default ModalBody;
