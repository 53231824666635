import { useState } from 'react';

import TABLE_CONSTANTS from '@constants/admin/tables';
import { message, Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import TextInput from '@/components/admin/TextInput';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    RegionDto,
    SortEnumType,
    useGetRegionsQuery,
    useUpdateRegionMutation
} from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import useError from '@/hooks/error/useError';
import useColDefs from '@/pages/admin/CommercialAgreements/Regions/hooks/useColDefs';
import CreateRegionModal from '@/pages/admin/CommercialAgreements/Regions/partials/CreateRegionModal';

const RegionsTable = () => {
    const {
        handlePageChange,
        pageSize,
        itemsToSkip,
        setTotalItems,
        totalItems,
        setSortingOrder,
        sortingOrder
    } = usePaginate();
    const { setError, clearError } = useError();
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');

    const { TableSize } = TABLE_CONSTANTS;
    const { loading, refetch, data } = useGetRegionsQuery({
        variables: {
            searchText,
            take: pageSize,
            skip: itemsToSkip,
            order: sortingOrder
        },
        onCompleted: (data) => {
            if (data?.regions?.items) {
                setTotalItems(data.regions.totalCount);
            }
            clearError();
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });
    const [updateRegionMutation] = useUpdateRegionMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            message.success(t('REGION_UPDATED_SUCCESSFULLY'));
            await refetch();
        }
    });

    const handeOnRowChange = async (row: RegionDto) =>
        await updateRegionMutation({
            variables: { input: { regionId: row.id, name: row.name, costCenter: row.costCenter } }
        });

    const { colDef } = useColDefs({
        onChangeSuccess: async () => await refetch()
    });
    const onSortChange = (sorterResult: SorterResult<RegionDto>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <>
            <SectionHeading heading={t('REGIONS')} />
            <Row justify={'space-between'} align={'bottom'} style={{ marginBottom: 18 }}>
                <TextInput inputValue={searchText} setInputValue={setSearchText} />
                <CreateRegionModal successCallback={refetch} />
            </Row>
            <ResizableTable
                onRowChange={handeOnRowChange}
                colDefs={colDef}
                tableData={(data?.regions?.items as RegionDto[]) || []}
                emptyText={t('NO_RESULTS')}
                loading={loading}
                onPageChange={handlePageChange}
                totalPaginationItems={totalItems}
                size={TableSize}
                onChange={(_, __, sorterResult) =>
                    onSortChange(sorterResult as SorterResult<RegionDto>)
                }
            />
        </>
    );
};

export default RegionsTable;
