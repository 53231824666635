import { useEffect, useState } from 'react';

import CustomInput from '@/components/shared/UI/CustomInput';
import TextLabelValue from '@/components/shared/UI/TextLabelValue';

import { StyledMaterialInfo, StyledMaterialRow, StyledMaterialTitle } from './styles';

type Props = {
    id: number;
    title: string;
    subtitle: string;
    quantity: number;
    isRemoveMode?: boolean;
    onQuantityChange: (id: number, quantity: number) => void;
    onClick: (id: number) => void;
};

const MaterialsTableRow: React.FC<Props> = ({
    id,
    title,
    subtitle,
    quantity,
    isRemoveMode,
    onQuantityChange,
    onClick
}) => {
    const [isValid, setIsValid] = useState<boolean>(quantity > 0);
    const [isSelected, setIsSelected] = useState<boolean>(isRemoveMode ?? false);

    useEffect(() => {
        if (!isRemoveMode) {
            setIsSelected(false);
        }
    }, [isRemoveMode]);

    const handleChangeQuantity = (newQuantity: string) => {
        const materialId = id;
        const quantity = +newQuantity;

        setIsValid(quantity > 0);
        onQuantityChange(materialId, quantity);
    };

    const handleRowSelection = () => {
        if (isRemoveMode) {
            setIsSelected((prevState) => !prevState);
            onClick(id);
        }
    };

    return (
        <StyledMaterialRow
            $isRemoveMode={isRemoveMode}
            $isSelected={isSelected}
            onClick={handleRowSelection}>
            <StyledMaterialInfo>
                <div>
                    <StyledMaterialTitle>{`${title},`}</StyledMaterialTitle>
                    <TextLabelValue direction="column" value={subtitle} />
                </div>
                <span>ID: {id}</span>
            </StyledMaterialInfo>
            <CustomInput
                onChange={handleChangeQuantity}
                defaultValue={quantity}
                invalid={!isValid}
                inputType="number"
            />
        </StyledMaterialRow>
    );
};

export default MaterialsTableRow;
