import React, { useEffect, useState } from 'react';

import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import { FilterTypesEnum } from '@/enums/assets/filter-types.enum';
import { AssetDto, DocumentStatusEnum, useGetAssetStatusesPerUserQuery } from '@/graphql/main';
import { StyledStatusFilterContainer } from '@/styles/shared/status/styles';

import { FilterWithType } from '../AssetDropdownArea';
import AssetStatusFilterOption from '../AssetStatusFilterOption';

type Props = {
    assets: AssetDto[];
    onStatusSelection: (key: DocumentStatusEnum | undefined) => void;
    filters: FilterWithType[];
};

type StatusCount = { status: DocumentStatusEnum; count: number };

const AssetStatusFilter: React.FC<Props> = ({ filters, assets, onStatusSelection }) => {
    const [selectedStatus, setSelectedStatus] = useState<DocumentStatusEnum | undefined>();
    const [statusesCount, setStatusesCount] = useState<StatusCount[]>();

    const { refetch, loading } = useGetAssetStatusesPerUserQuery({
        variables: {
            input: {
                customerName: filters.find((filter) => filter.type === FilterTypesEnum.Customer)
                    ?.filterKey,
                pocName: filters.find((filter) => filter.type === FilterTypesEnum.Poc)?.filterKey,
                status: filters.find((filter) => filter.type === FilterTypesEnum.AssetStatus)
                    ?.statusKey
            }
        },
        onCompleted: (data) => {
            setStatusesCount(() => {
                let sumCount = 0;

                const statusesCountArr = Array.from(
                    data?.assetStatusesPerUser?.statuses.map(
                        (status: { key: DocumentStatusEnum; value: number }) => {
                            sumCount += status.value;

                            return { status: status.key, count: status.value };
                        }
                    )
                );

                return [
                    { status: undefined, count: sumCount },
                    ...statusesCountArr
                ] as StatusCount[];
            });
        }
    });
    useEffect(() => {
        refetch({
            input: {
                customerName: filters.find((filter) => filter.type === FilterTypesEnum.Customer)
                    ?.filterKey,
                pocName: filters.find((filter) => filter.type === FilterTypesEnum.Poc)?.filterKey,
                status: filters.find((filter) => filter.type === FilterTypesEnum.AssetStatus)
                    ?.statusKey
            }
        }).then((data) => {
            setStatusesCount(() => {
                let sumCount = 0;

                const statusesCountArr = Array.from(
                    data?.data?.assetStatusesPerUser?.statuses.map(
                        (status: { key: DocumentStatusEnum; value: number }) => {
                            sumCount += status.value;

                            return { status: status.key, count: status.value };
                        }
                    )
                );

                return [
                    { status: undefined, count: sumCount },
                    ...statusesCountArr
                ] as StatusCount[];
            });
        });
    }, [filters, assets]); // eslint-disable-line

    useEffect(() => {
        handleStatusSelection(DocumentStatusEnum.Prepared);
    }, []); // eslint-disable-line

    const statusCountMap = new Map<DocumentStatusEnum, number>();

    // Loop over array and update count in map for each status
    for (let i = 0; i < assets?.length; i++) {
        const currentStatus = assets[i].status;
        const currentCount = statusCountMap.get(currentStatus) ?? 0;
        statusCountMap.set(currentStatus, currentCount + 1);
    }

    const handleStatusSelection = (status: DocumentStatusEnum | undefined) => {
        setSelectedStatus(status);
        onStatusSelection(status);
    };

    return (
        <SpinnerContainer
            loading={loading}
            styles={{
                fontSize: '3rem !important',
                marginTop: '10px'
            }}>
            <StyledStatusFilterContainer>
                {statusesCount?.map((item, index) => {
                    return (
                        <AssetStatusFilterOption
                            onClick={() => handleStatusSelection(item.status)}
                            key={index}
                            status={item.status}
                            isSelected={selectedStatus === item.status}
                            count={item.count}
                        />
                    );
                })}
            </StyledStatusFilterContainer>
        </SpinnerContainer>
    );
};

export default AssetStatusFilter;
