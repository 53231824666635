import { TableDataProvider } from '@/hooks/admin/useTableData';

import MaterialManagement from './partials/MaterialManagementTable';

const Materials = () => (
    <TableDataProvider>
        <MaterialManagement />
    </TableDataProvider>
);

export default Materials;
