import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as PencilIcon } from '@/assets/svg-icons/pencil-icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AgreementDefinitionAttributeDto,
    AgreementReasonEnum,
    AgreementSubjectEnum,
    AgreementTypeEnum,
    useGetAgreementDefinitionLazyQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { Container, FlexContainer } from '@/styles/shared/container/styles';

import { FormStateType } from '../..';

import ModalStepContent from './partials/ModalStepContent';
import { StyledMultiStepModal } from './styles';

type PrevAgreementDefinitionVariablesType = {
    type: string;
    subject: string;
    reason: string;
};

type Props = {
    disabled?: boolean;
    isEditMode?: boolean;
    formState: FormStateType;
    oldFormState?: FormStateType;
    handleFormState: (
        values: Pick<FormStateType, 'firstStep'> | Pick<FormStateType, 'secondStep'>
    ) => void;
    handleAgreementDefinitionId: (value: number) => void;
    submitEditedData?: () => void;
    isDefinitionChanged?: () => boolean;
    disableSaveButton?: (value: boolean) => void;
};

const AddAgreementDetailsModal: React.FC<Props> = ({
    disabled,
    isEditMode = false,
    formState,
    oldFormState,
    handleFormState,
    isDefinitionChanged,
    handleAgreementDefinitionId,
    submitEditedData,
    disableSaveButton
}) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const { setError, clearError, errorMessage } = useError();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [disableFinishButton, setDisableFinishButton] = useState(true);
    const [attributesData, setAttributesData] = useState<
        Array<AgreementDefinitionAttributeDto | null>
    >([]);
    const [prevAgreementDefinitionVariables, setPrevAgreementDefinitionVariables] =
        useState<PrevAgreementDefinitionVariablesType | null>(null);

    const [agreementDefinition] = useGetAgreementDefinitionLazyQuery();

    const showModal = () => setIsModalVisible(true);
    const handleFormSubmit = () => {
        disableSaveButton?.(false);
        isEditMode && submitEditedData?.();
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        isEditMode && oldFormState && handleFormState(oldFormState);
        isEditMode && setCurrentStep(0);
        setIsModalVisible(false);
    };

    const next = () => {
        const { type, subject, reason } = formState.firstStep;

        if (
            prevAgreementDefinitionVariables?.type === type &&
            prevAgreementDefinitionVariables?.subject === subject &&
            prevAgreementDefinitionVariables?.reason === reason
        ) {
            setCurrentStep((prev) => Math.min(prev + 1, 2));

            return;
        }

        agreementDefinition({
            variables: {
                type: type as AgreementTypeEnum,
                subject: subject as AgreementSubjectEnum,
                reason: reason as AgreementReasonEnum
            },
            onCompleted: (data) => {
                const orderedAttributes = [...data.agreementDefinition.attributes].sort((a, b) => {
                    if (a?.order && b?.order) return a?.order - b?.order;
                    if (a?.order) return -1;

                    return 1;
                });

                setAttributesData(orderedAttributes);
                handleAgreementDefinitionId(data.agreementDefinition.id);
                setPrevAgreementDefinitionVariables({ type, subject, reason });
                if (
                    (formState.secondStep.length && !isEditMode) ||
                    (isEditMode && isDefinitionChanged?.())
                ) {
                    handleFormState({
                        secondStep: []
                    });
                }

                setCurrentStep((prev) => Math.min(prev + 1, 2));
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const prev = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
    const handleNextButtonState = (value: boolean) => setDisableNextButton(value);
    const handleFinishButtonState = (value: boolean) => {
        setDisableFinishButton(value);

        if (value) disableSaveButton?.(value);
    };

    return (
        <div>
            {isEditMode ? (
                <CustomButton
                    onClick={showModal}
                    label={t('EDIT')}
                    buttonType={ButtonsTypesEnum.Tertiary}
                    icon={<PencilIcon />}
                />
            ) : (
                <CustomButton
                    label={t(`${disableFinishButton ? 'ADD' : 'EDIT'}`)}
                    onClick={showModal}
                    buttonType={disabled ? ButtonsTypesEnum.Disabled : ButtonsTypesEnum.Secondary}
                    icon={<PlusIcon />}
                    style={{ width: '142px' }}
                />
            )}

            <StyledMultiStepModal
                title={t('COMMERCIAL_AGREEMENT_DETAILS')}
                open={isModalVisible}
                onOk={handleFormSubmit}
                destroyOnClose={isEditMode}
                onCancel={handleCancel}
                footer={null}>
                <Container
                    $paddingX={theme?.space[0]}
                    $marginTop={theme?.space[20]}
                    $marginBottom={theme?.space[20]}
                    style={{ height: '445px' }}>
                    <ModalStepContent
                        isEditMode={isEditMode}
                        isDefinitionChanged={isDefinitionChanged}
                        currentStep={currentStep}
                        handleFormState={handleFormState}
                        handleNextButtonState={handleNextButtonState}
                        handleFinishButtonState={handleFinishButtonState}
                        attributesData={attributesData}
                        firstStepState={formState.firstStep}
                        oldFirstStepState={oldFormState?.firstStep}
                        secondStepState={formState.secondStep}
                    />
                </Container>

                <FlexContainer $justify="space-between" $gap={theme?.space[8]}>
                    {currentStep > 0 && (
                        <CustomButton
                            label={t('BACK')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            style={{ width: '100%' }}
                            onClick={() => prev()}
                        />
                    )}

                    {currentStep < 1 && (
                        <CustomButton
                            label={t('NEXT')}
                            buttonType={
                                disableNextButton
                                    ? ButtonsTypesEnum.Disabled
                                    : ButtonsTypesEnum.Main
                            }
                            onClick={() => next()}
                            style={{ width: '100%' }}
                        />
                    )}

                    {currentStep === 1 && (
                        <CustomButton
                            label={isEditMode ? t('SAVE') : t('FINISH')}
                            buttonType={
                                disableFinishButton
                                    ? ButtonsTypesEnum.Disabled
                                    : ButtonsTypesEnum.Main
                            }
                            onClick={handleFormSubmit}
                            style={{ width: '100%' }}
                        />
                    )}
                </FlexContainer>
            </StyledMultiStepModal>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </div>
    );
};

export default AddAgreementDetailsModal;
