import { useEffect, useRef } from 'react';

import { TranslationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as AgreementIcon } from '@/assets/svg-icons/agreement-icon.svg';
import { ReactComponent as AssetIcon } from '@/assets/svg-icons/asset-icon.svg';
import { ReactComponent as ExitIcon } from '@/assets/svg-icons/exit-icon.svg';
import { ReactComponent as SwitchIcon } from '@/assets/svg-icons/switch-icon.svg';
import logoIcon from '@/assets/svgs/logo-text-right-white.svg';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { LogoTypeEnum } from '@/enums/logo-type.enum';
import useLanguage from '@/hooks/useLanguage';
import { useUserContext } from '@/store/user/useUserContext';
import { getCurrentUserName, logout } from '@/utils/authorizationUtils';

import AppLogo from '../../logo/AppLogo';
import CustomButton from '../../UI/CustomButton';

import {
    CloseArrowIcon,
    CloseButton,
    ProfileContainer,
    ProfileIcon,
    SidebarButton,
    SidebarButtonContainer,
    SidebarContainer,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarUsernameLabel
} from './styles';

type Props = {
    isSidebarShown: boolean;
    setIsSidebarShown: (value: boolean) => void;
};

const Sidebar: React.FC<Props> = ({ isSidebarShown, setIsSidebarShown }) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { setLanguage } = useLanguage();
    const {
        isF2RCMC,
        permissions: { canAccessAdminRoutes }
    } = useUserContext();
    const containerRef = useRef<HTMLDivElement>(null);

    const hideSidebar = () => {
        setIsSidebarShown(false);
    };

    const navigateToRoute = (route: string) => {
        navigate(route);
        hideSidebar();
    };

    const switchToMobile = () => {
        if (canAccessAdminRoutes) {
            navigate(ROUTES.FullPaths.AdminUsers);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!containerRef?.current?.contains(event.target as Node)) {
                hideSidebar();
            }
        };

        if (isSidebarShown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarShown]); // eslint-disable-line

    if (!isSidebarShown) return null;

    return (
        isSidebarShown && (
            <SidebarContainer ref={containerRef}>
                <SidebarHeader>
                    <CloseButton onClick={hideSidebar}>
                        <CloseArrowIcon />
                    </CloseButton>

                    <AppLogo path={logoIcon} logoType={LogoTypeEnum.MenuLogo} />
                </SidebarHeader>
                <SidebarContent>
                    <div>
                        {!isF2RCMC && (
                            <SidebarButton
                                label={t('ASSETS')}
                                buttonType={ButtonsTypesEnum.Tertiary}
                                onClick={() => navigateToRoute(ROUTES.AssetList.Path)}
                                icon={<AssetIcon />}
                                $fill={theme?.color.default.black}
                            />
                        )}

                        <SidebarButton
                            label={t('COMMERCIAL_AGREEMENTS')}
                            buttonType={ButtonsTypesEnum.Tertiary}
                            onClick={() => navigateToRoute(ROUTES.AgreementList.Path)}
                            icon={<AgreementIcon />}
                            $fill={theme?.color.default.white}
                        />
                    </div>
                    <SidebarButton
                        label={t('CHANGE_LANGUAGE')}
                        buttonType={ButtonsTypesEnum.Tertiary}
                        onClick={setLanguage}
                        icon={<TranslationOutlined />}
                    />
                </SidebarContent>

                <SidebarFooter>
                    <ProfileContainer>
                        <ProfileIcon />
                        <SidebarUsernameLabel>{getCurrentUserName()}</SidebarUsernameLabel>
                    </ProfileContainer>
                    <SidebarButtonContainer>
                        {canAccessAdminRoutes && (
                            <CustomButton
                                label={t('SWITCH')}
                                buttonType={ButtonsTypesEnum.Secondary}
                                onClick={switchToMobile}
                                icon={<SwitchIcon />}
                            />
                        )}
                        <CustomButton
                            label={t('LOGOUT')}
                            buttonType={ButtonsTypesEnum.Tertiary}
                            onClick={logout}
                            icon={<ExitIcon />}
                        />
                    </SidebarButtonContainer>
                </SidebarFooter>
            </SidebarContainer>
        )
    );
};

export default Sidebar;
