import { AgreementDefinitionAttributeDto } from '@/graphql/main';
import { FormStateType } from '@/pages/agreement/CreateAgreement';

import ModalStepOne from '../ModalStepOne';
import ModalStepTwo from '../ModalStepTwo';

type Props = {
    currentStep: number;
    isEditMode?: boolean;
    handleFormState: (
        values: Pick<FormStateType, 'firstStep'> | Pick<FormStateType, 'secondStep'>
    ) => void;
    handleNextButtonState: (value: boolean) => void;
    handleFinishButtonState: (value: boolean) => void;
    isDefinitionChanged?: () => boolean;
    attributesData: Array<AgreementDefinitionAttributeDto | null>;
    firstStepState: FormStateType['firstStep'];
    oldFirstStepState?: FormStateType['firstStep'];
    secondStepState: FormStateType['secondStep'];
};

const ModalStepContent: React.FC<Props> = ({
    isEditMode = false,
    currentStep,
    handleFormState,
    isDefinitionChanged,
    handleNextButtonState,
    handleFinishButtonState,
    attributesData,
    firstStepState,
    secondStepState,
    oldFirstStepState
}) => {
    return (
        <>
            {currentStep === 0 && (
                <ModalStepOne
                    isEditMode={isEditMode}
                    isDefinitionChanged={isDefinitionChanged}
                    handleFormState={handleFormState}
                    disableNextButton={handleNextButtonState}
                    currentState={firstStepState}
                    oldState={oldFirstStepState}
                />
            )}

            {currentStep === 1 && (
                <ModalStepTwo
                    handleFormState={handleFormState}
                    disableFinishButton={handleFinishButtonState}
                    attributesData={attributesData}
                    currentState={secondStepState}
                />
            )}
        </>
    );
};

export default ModalStepContent;
