import styled from 'styled-components';

import CustomButton from '@/components/shared/UI/CustomButton';
import { ListContent } from '@/styles/shared/list/styles';

type AssetListContentProps = {
    $isSelectionMode: boolean;
};

export const AssetListContent = styled(ListContent)<AssetListContentProps>`
    ${({ $isSelectionMode, theme }) =>
        $isSelectionMode &&
        `
        overflow: hidden;
        padding-bottom: 48px;
        border: 2px dashed ${theme.color.blue[50]};
        background-color: ${theme.color.blue[15]};

        > div:last-child > div > div > div {
            border: 2px solid transparent;
        }
    `}
`;

export const AssetListButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.space[12]};
    padding-top: ${({ theme }) => theme.space[12]};
`;

export const AssetListContentTitle = styled.span`
    margin-right: ${({ theme }) => theme.space[4]};
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.h6};
`;

export const AssetListSelectionBtnsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.space[8]};
`;

export const AssetListShareBtn = styled(CustomButton)`
    font-size: ${({ theme }) => theme.fontSize.xxlg};
`;
