import { ApolloQueryResult } from '@apollo/client';
import { Button, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { Brand, GetBrandsQuery, useChangeBrandIsActiveMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetBrandsQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();
    const [changeBrandIsActive] = useChangeBrandIsActiveMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await onChangeSuccess();
        }
    });

    const handleToggleBrandStatus = async ({ id, isActive }: { id: string; isActive: boolean }) => {
        try {
            await changeBrandIsActive({
                variables: {
                    id,
                    isActive: !isActive
                }
            });

            await message.success(t(isActive ? 'DEACTIVATED_SUCCESS' : 'ACTIVATED_SUCCESS'));
        } catch (error) {
            setError(error, ErrorTypeEnum.ApolloError);
            await message.error(t(isActive ? 'DEACTIVATE_FAIL' : 'ACTIVATED_FAIL'));
        }
    };

    const colDef: ColumnsType<Brand> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            width: 200,
            sorter: true
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            dataIndex: 'active',
            width: 100,
            sorter: true,
            render: (_, { id, isActive }: Brand) => (
                <Button
                    key={`deactivate-${id as string}`}
                    danger={isActive}
                    onClick={async () => await handleToggleBrandStatus({ id, isActive })}>
                    {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                </Button>
            )
        }
    ];

    return { colDef };
};

export default useColDefs;
