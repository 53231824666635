import { ReactComponent as Icon } from '@/assets/svg-icons/protocol-icon.svg';

import { StyledProtocolIcon } from './styles';

const ProtocolIcon = () => {
    return (
        <StyledProtocolIcon>
            <Icon />
        </StyledProtocolIcon>
    );
};

export default ProtocolIcon;
