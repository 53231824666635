import { Layout } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const StyledAppLayout = styled(Layout)`
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.space.maxLayoutWidth};
    color: ${({ theme }) => theme.color.default.black};
    background-color: ${({ theme }) => theme.color.default.bg};
`;

export const StyledAppHeader = styled(Header)`
    display: flex;
    justify-content: center;
    align-self: center;
    padding-inline: 5%;
    padding: 0 !important;
    height: ${({ theme }) => theme.space[72]};
    width: 100%;
    background-color: ${({ theme }) => theme.color.default.bg};
`;

export const StyledAppContent = styled(Content)`
    width: 100%;
    align-self: center;
    overflow: hidden;
`;

export const StyledAppFooter = styled(Footer)`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    box-shadow: ${({ theme }) => theme.shadow.mediumShadow};
    background-color: ${({ theme }) => theme.color.default.white};
    border-radius: 8px 8px 0 0;
    z-index: 10;
`;
