import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

import CustomButton from '@/components/shared/UI/CustomButton';

type StyledMaterialsContainerProps = {
    $isRemoveMode: boolean;
};

export const StyledCreateContractContent = styled(Content)`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const StyledMaterialsContainer = styled.div<StyledMaterialsContainerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: ${({ theme }) => theme.space[12]};

    ${({ $isRemoveMode, theme }) =>
        $isRemoveMode &&
        `
            background-color: ${theme.color.red[60]};
            border: 2px dashed ${theme.color.red[50]};
            padding: ${theme.space[8]};
             padding-bottom: ${theme.space[32]} ;
    `};
`;

export const StyledMaterialsContainerAddBtn = styled(CustomButton)`
    width: 140px;
`;

export const StyledNoMaterialsHeader = styled.h2`
    display: flex;
    align-items: center;
    height: 25vh;
    font-size: ${({ theme }) => theme.fontSize.h2};
    color: ${({ theme }) => theme.color.grey[40]};
`;

export const StyledCreateMaterialBtnContainer = styled.div`
    position: absolute;
    bottom: 5vh;
    left: 50%;
    transform: translate(-50%);

    button {
        width: 380px;
    }
`;
