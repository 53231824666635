import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import { isLoggedIn, isPasswordExpired } from '@/utils/authorizationUtils';

type Props = {
    publicRoutes: string[];
    children: JSX.Element | JSX.Element[];
};

const AuthGuard: React.FC<Props> = (props) => {
    const { pathname } = useLocation();

    const routeIsPublic = props.publicRoutes.includes(pathname);
    let result: JSX.Element | JSX.Element[];
    if (isLoggedIn() && isPasswordExpired()) {
        result =
            pathname === ROUTES.FullPaths.ChangePassword ? (
                props.children
            ) : (
                <Navigate to={ROUTES.FullPaths.ChangePassword} />
            );
    } else if (isLoggedIn() || routeIsPublic) {
        result = props.children;
    } else {
        result = <Navigate to={ROUTES.FullPaths.Login} />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{result}</>;
};

export default AuthGuard;
