import { useContext, useEffect, useState } from 'react';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as AttachmentIcon } from '@/assets/svg-icons/attachment.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import { ReactComponent as ScanIcon } from '@/assets/svg-icons/scan-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import CustomInput from '@/components/shared/UI/CustomInput';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { dateFormat } from '@/constants/dates/dates';
import { INVOICE_FORM_KEYS } from '@/constants/keys/form-data-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import AppContext from '@/store/app-context/AppContext';
import {
    Container,
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { ListItemCardLabel, ListItemCardRow } from '@/styles/shared/list-item/styles';
import { isInvalidNumber } from '@/utils/validations';

type FlexWrapperProps = {
    children: JSX.Element[];
};

const FlexWrapper: React.FC<FlexWrapperProps> = ({ children }) => {
    const theme = useTheme();

    return (
        <FlexContainer
            $flexDirection="column"
            $marginBottom={theme?.space[16]}
            $gap={theme?.space[2]}>
            {children}
        </FlexContainer>
    );
};

const AddInvoice: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const { navbar, additionalDocsFormData } = useContext(AppContext);
    const { clearError, errorMessage } = useError();
    const { getFormData, setFormData } = useFormData();
    const [isValidAmount, setIsValidAmount] = useState(false);
    const [scannedFile, setScannedFile] = useState<File>();
    const [canProceed, setCanProceed] = useState(false);

    const handleScanDeclaration = () => {
        navigate(ROUTES.AgreementList.Agreement.AdditionalDocuments.Invoice.Scan);
    };

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        const utcDate = dayjs(date).utc().toISOString();

        setFormData(INVOICE_FORM_KEYS.INVOICE_DATE, utcDate);
    };

    const handleInvoiceNumber = (str: string) => {
        setFormData(INVOICE_FORM_KEYS.INVOICE_NUMBER, str);
    };

    const handleInvoiceAmount = (num: string) => {
        setIsValidAmount(!isInvalidNumber(num));
        setFormData(INVOICE_FORM_KEYS.INVOICE_AMOUNT, num);
    };

    const handleDateDefaultValue = () => {
        const date = getFormData(INVOICE_FORM_KEYS.INVOICE_DATE)?.toString();

        return date ? dayjs(date, dateFormat) : undefined;
    };

    const handleInvoiceAmountDefaultValue = () => {
        const number = getFormData(INVOICE_FORM_KEYS.INVOICE_AMOUNT);

        if (number) {
            return +number;
        }
    };

    const handleInvoiceNumberDefaultValue = () => {
        return getFormData(INVOICE_FORM_KEYS.INVOICE_NUMBER)?.toString() ?? '';
    };

    const navigateBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const date = getFormData(INVOICE_FORM_KEYS.INVOICE_DATE);
        const invoiceNumber = getFormData(INVOICE_FORM_KEYS.INVOICE_NUMBER);
        const invoiceAmount = getFormData(INVOICE_FORM_KEYS.INVOICE_AMOUNT);
        const invoiceScanned = getFormData(INVOICE_FORM_KEYS.SCANNED_INVOICE);

        if (date && invoiceNumber && invoiceAmount && invoiceScanned) {
            setCanProceed(true);

            return;
        }

        return () => setCanProceed(false);
    }, [additionalDocsFormData.data, getFormData]);

    useEffect(() => {
        setScannedFile(getFormData(INVOICE_FORM_KEYS.SCANNED_INVOICE) as File);
    }, [getFormData]);

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });
    }, []); // eslint-disable-line

    return (
        <ScrollableContainer $position="relative">
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[40]}>
                <StyledPageTitle $marginY={theme?.space[0]}>{t('ADD_INVOICE')}</StyledPageTitle>
            </HeaderContainer>

            <FlexWrapper>
                <ListItemCardLabel value={t('DATE')} />
                <DatePicker
                    inputReadOnly
                    onChange={(date) => handleDateChange(date)}
                    placeholder={t('PICK_DATE_FROM_CALENDAR')}
                    format={dateFormat}
                    defaultValue={handleDateDefaultValue()}
                    size="large"
                />
            </FlexWrapper>

            <FlexWrapper>
                <ListItemCardLabel value={t('INVOICE_NUMBER')} />
                <CustomInput
                    key="invoice-number"
                    onChange={handleInvoiceNumber}
                    placeholder={t('INVOICE_NUMBER')}
                    defaultValue={handleInvoiceNumberDefaultValue()}
                />
            </FlexWrapper>

            <FlexWrapper>
                <ListItemCardLabel value={t('INVOICE_AMOUNT_VAT')} />
                <CustomInput
                    key="invoice-amount"
                    inputType="number"
                    onChange={handleInvoiceAmount}
                    placeholder={t('INVOICE_AMOUNT')}
                    step={1}
                    min={1}
                    invalid={isValidAmount}
                    invalidMsg={isValidAmount ? t('AMOUNT_VALIDATION_MESSAGE') : ''}
                    defaultValue={handleInvoiceAmountDefaultValue()}
                />
            </FlexWrapper>

            <ListItemCardRow>
                <ListItemCardLabel label={t('INVOICE')} />

                <div>
                    {scannedFile?.name ? (
                        <CustomButton
                            label={scannedFile?.name}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<AttachmentIcon />}
                            maxWidth={200}
                            onClick={handleScanDeclaration}
                            style={{
                                color: theme?.color.blue[50]
                            }}
                        />
                    ) : (
                        <CustomButton
                            label={t('SCAN')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<ScanIcon />}
                            onClick={handleScanDeclaration}
                        />
                    )}
                </div>
            </ListItemCardRow>

            <Container
                $paddingX={theme?.space[0]}
                $position="absolute"
                style={{ bottom: '2vh', width: '92%' }}>
                <CustomButton
                    onClick={navigateBack}
                    label={t('ADD')}
                    buttonType={canProceed ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                    icon={<PlusIcon />}
                    style={{ width: '100%' }}
                />
            </Container>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </ScrollableContainer>
    );
};

export default AddInvoice;
