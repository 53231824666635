import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as SendIcon } from '@/assets/svg-icons/send-icon.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/svg-icons/trash-can-red-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import WarningModal from '@/components/shared/modal/WarningModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import {
    AgreementDetailsDto,
    DocumentStatusEnum,
    useSendAgreementForValidationMutation,
    useSoftDeleteAgreementMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { Container, FlexContainer } from '@/styles/shared/container/styles';
import { ListItemCardRow } from '@/styles/shared/list-item/styles';

type Props = {
    agreementId: AgreementDetailsDto['agreementId'];
    hasDeclaration: boolean;
    status: DocumentStatusEnum;
};

const AgreementSendForValidation: React.FC<Props> = ({ agreementId, hasDeclaration, status }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const navigate = useNavigate();
    const [sendForValidationSuccess, setSendForValidationSuccess] = useState(false);
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false);
    const [sendForValidationWarning, setSendForValidationWarning] = useState(false);
    const [isDeleteWarning, setIsDeleteWarning] = useState(false);
    const { errorMessage, setError, clearError } = useError();

    const [sendAgreementForValidation] = useSendAgreementForValidationMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: () => {
            setSendForValidationWarning(false);
            setSendForValidationSuccess(true);
            clearError();
        }
    });

    const [softDeleteAgreement] = useSoftDeleteAgreementMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: () => {
            setIsDeleteWarning(false);
            setIsDeletedSuccess(true);
            clearError();
        }
    });

    const handleDeleteAgreement = () => {
        softDeleteAgreement({
            variables: {
                agreementId
            }
        });
    };

    const handleSendAgreementForValidation = () => {
        sendAgreementForValidation({
            variables: {
                agreementId
            }
        });
    };

    const navigateToDashboard = () => {
        navigate(ROUTES.FullPaths.AgreementList);
    };

    return (
        <Container $paddingX={theme?.space[0]}>
            <ListItemCardRow $marginBottom={theme?.space[12]}>
                <CustomButton
                    onClick={() => setSendForValidationWarning(true)}
                    label={t('SEND_FOR_VALIDATION')}
                    buttonType={hasDeclaration ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                    icon={<SendIcon />}
                />
            </ListItemCardRow>

            <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[12]}>
                {!hasDeclaration && <InfoMessage message={t('SCAN_DECLARATION_INFO_MSG')} />}
            </Container>

            {status === DocumentStatusEnum.Draft && (
                <FlexContainer $justify="center" $marginBottom={theme?.space[20]}>
                    <CustomButton
                        onClick={() => setIsDeleteWarning(true)}
                        label={t('DELETE')}
                        icon={<TrashCanIcon />}
                        buttonType={ButtonsTypesEnum.DestructiveMain}
                        style={{ border: 'none', boxShadow: 'none' }}
                    />
                </FlexContainer>
            )}

            {sendForValidationWarning && (
                <WarningModal
                    accept={handleSendAgreementForValidation}
                    deny={() => setSendForValidationWarning(false)}
                    title={t('SEND_FOR_VALIDATION')}
                    message={t('SEND_FOR_VALIDATION_WARNING_MESSAGE')}
                    subMessage={t('SEND_FOR_VALIDATION_WARNING_SUBMESSAGE')}
                />
            )}

            {isDeleteWarning && (
                <WarningModal
                    isDestructive={true}
                    accept={handleDeleteAgreement}
                    deny={() => setIsDeleteWarning(false)}
                    title={t('DELETE_AGREEMENT')}
                    message={t('DELETE_AGREEMENT_WARNING_MESSAGE')}
                    subMessage={t('DELETE_AGREEMENT_WARNING_SUBMESSAGE')}
                />
            )}

            {sendForValidationSuccess && (
                <SuccessModal
                    title={t('SEND_FOR_VALIDATION_MESSAGE')}
                    message={t('SEND_FOR_VALIDATION_SUCCESS')}
                    modalType={SuccessModalTypeEnum.Draft}
                    onConfirm={navigateToDashboard}
                />
            )}

            {isDeletedSuccess && (
                <SuccessModal
                    title={t('DELETE_AGREEMENT_SUCCESS')}
                    message={t('DELETE_AGREEMENT_SUCCESS_MESSAGE')}
                    modalType={SuccessModalTypeEnum.Success}
                    onConfirm={navigateToDashboard}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} />}
        </Container>
    );
};

export default AgreementSendForValidation;
