import { useContext, useEffect, useState } from 'react';

import LOCAL_STORAGE_KEYS from '@constants/keys/local-storage-keys';
import { AutoComplete } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import { ReactComponent as ViewDeclarationIcon } from '@/assets/svg-icons/preview-declaration-icon.svg';
import { ReactComponent as ScanIcon } from '@/assets/svg-icons/scan-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import { ReactComponent as TrashCanIcon } from '@/assets/svg-icons/trash-can-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    ContractModelDto,
    CreateContractModelInput,
    CustomerDto,
    DocumentStatusEnum,
    useCreateContractMutation,
    useGetUserCustomerByNameLazyQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { setCacheVal } from '@/services/utils/cacheUtils';
import AppContext from '@/store/app-context/AppContext';
import {
    Container,
    FlexAlignCenter,
    FlexContainer,
    HeaderContainer
} from '@/styles/shared/container/styles';
import {
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardValue
} from '@/styles/shared/list-item/styles';

import MaterialsTable from '../../shared/components/MaterialsTable';

import CreateMaterialModal from './partials/CreateMaterialModal';
import {
    StyledCreateContractContent,
    StyledCreateMaterialBtnContainer,
    StyledMaterialsContainer,
    StyledMaterialsContainerAddBtn,
    StyledNoMaterialsHeader
} from './styles';

type MaterialWithId = {
    id: number;
    type: string;
    model: { id: number; name: string };
    quantity: number;
};

type CustomerNameOption = {
    label: string;
    value: string;
};

const CreateContract: React.FC = () => {
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [isRemoveMode, setIsRemoveMode] = useState<boolean>(false);
    const [customers, setCustomers] = useState<any>([]);
    const [customerNames, setCustomerNames] = useState<CustomerNameOption[]>([]);
    const [isMaterialModalOpened, setIsMaterialModalOpened] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDto>();
    const [areMaterialsValid, setAreMaterialsValid] = useState<boolean>();
    const [newContractId, setNewContractId] = useState<number | null>(null);

    const [materials, setMaterials] = useState<MaterialWithId[]>([]);
    const [selectedForRemove, setSelectedForRemove] = useState<number[]>([]);
    const [successMessage, setSuccessMessage] = useState<string>();

    const { setError, errorMessage } = useError();

    const [customersByName] = useGetUserCustomerByNameLazyQuery({ fetchPolicy: 'no-cache' });
    const [createContractMutation] = useCreateContractMutation();
    const location = useLocation();
    const locationState = location.state as { selectedCustomer: CustomerDto } | null;

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAreMaterialsValid(materials.every((material) => material.quantity > 0));
    }, [materials]);

    const handleRemoveMode = () => {
        setIsRemoveMode((prevState) => !prevState);

        if (isRemoveMode) {
            setSelectedForRemove([]); // We de-select all selected
        }
    };

    const handleMaterialCreation = (
        material: MaterialWithId,
        closeReason: ModalCloseReasonEnum
    ) => {
        if (closeReason === ModalCloseReasonEnum.Success) {
            setIsMaterialModalOpened(false);
            setMaterials((prevState) => {
                return [...prevState, material];
            });
        }
    };
    const removeSelectedMaterials = () => {
        setMaterials((prevState) => {
            return [...prevState.filter((m) => !selectedForRemove.includes(m.id))];
        });

        setIsRemoveMode(false);
        setSelectedForRemove([]);
    };

    const handelCustomerSelection = (selectedName: string) => {
        setCacheVal(LOCAL_STORAGE_KEYS.CustomerName, selectedName);

        setSelectedCustomer(
            customers.find((customer: CustomerDto) => customer.name === selectedName)
        );
    };

    const searchUserByName = (searchText: string) => {
        customersByName({
            variables: {
                input: searchText
            },
            onCompleted: (data) => {
                setCustomerNames(() => {
                    return data.userCustomersByName.map((customer) => {
                        return { label: customer.name, value: customer.name };
                    }) as CustomerNameOption[];
                });

                setCustomers(data.userCustomersByName);
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    useEffect(() => {
        if (locationState?.selectedCustomer) {
            customersByName({
                variables: { input: locationState.selectedCustomer.name },
                onCompleted: (data) => {
                    setSelectedCustomer(data.userCustomersByName[0]);
                },
                onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
            });
        }
    }, [customersByName, locationState]);

    const createContract = () => {
        const mappedModels = materials.map((material) => {
            return {
                modelId: material.model.id,
                quantity: material.quantity
            } as CreateContractModelInput;
        });

        createContractMutation({
            variables: {
                input: { models: mappedModels, customerId: selectedCustomer?.id }
            },
            onCompleted: ({ createContract }) => {
                setNewContractId(createContract);
                setSuccessMessage(t('CONTRACT_SUCCESSFULLY_CREATED'));
            }
        });
    };

    const handleMaterialChange = (id: number, quantity: number) => {
        setMaterials((prevState) => {
            return updateMaterialQuantity(prevState, id, quantity);
        });
    };

    function updateMaterialQuantity(
        materials: MaterialWithId[],
        id: number,
        quantity: number
    ): MaterialWithId[] {
        const materialIndex = materials.findIndex((material) => material.id === id);

        const updatedMaterial = { ...materials[materialIndex], quantity };
        const updatedMaterials = [...materials];
        updatedMaterials[materialIndex] = updatedMaterial;

        return updatedMaterials;
    }
    const handleViewDeclaration = () => {
        navigate(ROUTES.AssetList.CreateContract.Declaration.Preview, {
            state: {
                declaration: selectedCustomer?.declaration,
                status: DocumentStatusEnum.Draft,
                selectedCustomer
            }
        });
    };
    const handleScanDeclaration = () => {
        navigate(ROUTES.AssetList.CreateContract.Declaration.Scan, {
            state: {
                selectedCustomer,
                status: DocumentStatusEnum.Draft
            }
        });
    };
    const goToContract = () => {
        if (newContractId) {
            navigate(`../${ROUTES.AssetList.Contract.DynamicPath(newContractId)}`);
        }
    };

    return (
        <Container>
            <HeaderContainer $padding={theme?.space[0]} $marginTop={theme?.space[0]}>
                <StyledPageTitle $marginY={theme?.space[0]}>{t('CREATE_CONTRACT')}</StyledPageTitle>
            </HeaderContainer>

            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[32]}>
                <ListItemCardLabel direction={'row'} value={t('CUSTOMER_NAME')} />

                <AutoComplete
                    onSelect={handelCustomerSelection}
                    options={customerNames}
                    onSearch={searchUserByName}
                    value={selectedCustomer?.name}
                    disabled={!!selectedCustomer}
                />
            </FlexContainer>

            {!!selectedCustomer && (
                <>
                    <StyledCreateContractContent>
                        <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[12]}>
                            <FlexContainer $flexDirection="column" $marginBottom={theme?.space[12]}>
                                <ListItemCardLabel
                                    direction="column"
                                    label={t('CUSTOMER_ADDRESS')}
                                />
                                <ListItemCardValue
                                    direction="column"
                                    $fontSize={theme?.fontSize.xxlg}
                                    value={selectedCustomer.customerAddress!}
                                />
                            </FlexContainer>

                            <ListItemCardLabel direction="column" label={t('CUSTOMER_VAT')} />
                            <ListItemCardValue
                                direction="column"
                                $fontSize={theme?.fontSize.xxlg}
                                value={selectedCustomer.customerVat}
                            />
                        </Container>
                        <ListItemCardRow>
                            <ListItemCardLabel direction="column" label={t('DECLARATION')} />
                            <div>
                                {!selectedCustomer.declaration?.scannedDocumentName ? (
                                    <CustomButton
                                        label={t('SCAN')}
                                        buttonType={ButtonsTypesEnum.Secondary}
                                        icon={<ScanIcon />}
                                        onClick={handleScanDeclaration}
                                    />
                                ) : (
                                    <CustomButton
                                        label={t('VIEW')}
                                        buttonType={ButtonsTypesEnum.Secondary}
                                        icon={<ViewDeclarationIcon />}
                                        onClick={handleViewDeclaration}
                                    />
                                )}
                            </div>
                        </ListItemCardRow>

                        <StyledMaterialsContainer $isRemoveMode={isRemoveMode}>
                            <FlexAlignCenter $justify="space-between">
                                <ListItemCardRow>
                                    <ListItemCardValue
                                        direction="column"
                                        value={t(
                                            isRemoveMode ? 'DELETE_ARTICLES' : 'ARTICLES_NAME'
                                        )}
                                    />
                                </ListItemCardRow>

                                <FlexContainer
                                    $marginBottom={theme?.space[12]}
                                    $gap={theme?.space[12]}>
                                    {!isRemoveMode && (
                                        <>
                                            <CustomButton
                                                label={''}
                                                buttonType={
                                                    materials.length >= 1
                                                        ? ButtonsTypesEnum.Destructive
                                                        : ButtonsTypesEnum.Disabled
                                                }
                                                onClick={handleRemoveMode}
                                                icon={<TrashCanIcon />}
                                            />
                                            <StyledMaterialsContainerAddBtn
                                                label={t('ADD')}
                                                buttonType={
                                                    isRemoveMode
                                                        ? ButtonsTypesEnum.Disabled
                                                        : ButtonsTypesEnum.Secondary
                                                }
                                                icon={<PlusIcon />}
                                                onClick={() => setIsMaterialModalOpened(true)}
                                            />
                                        </>
                                    )}

                                    {isRemoveMode && (
                                        <>
                                            <CustomButton
                                                label={t('CANCEL')}
                                                buttonType={ButtonsTypesEnum.Tertiary}
                                                onClick={handleRemoveMode}
                                            />
                                            <CustomButton
                                                label={t('DELETE_SELECTED')}
                                                buttonType={
                                                    selectedForRemove.length > 0
                                                        ? ButtonsTypesEnum.Destructive
                                                        : ButtonsTypesEnum.Disabled
                                                }
                                                onClick={removeSelectedMaterials}
                                            />
                                        </>
                                    )}
                                </FlexContainer>
                            </FlexAlignCenter>

                            {materials.length > 0 && (
                                <FlexContainer>
                                    <MaterialsTable
                                        onArticlesChange={handleMaterialChange}
                                        models={materials.map((m) => {
                                            return {
                                                id: m.id,
                                                model: m.model.name,
                                                type: m.type,
                                                quantity: m.quantity
                                            } as ContractModelDto;
                                        })}
                                        isRemoveMode={isRemoveMode}
                                        onRowsForRemoveSelection={(selectedRows) =>
                                            setSelectedForRemove([...selectedRows])
                                        }
                                    />
                                </FlexContainer>
                            )}

                            {materials.length === 0 && (
                                <FlexContainer $justify="center">
                                    <StyledNoMaterialsHeader>
                                        {t('NO_MATERIALS')}
                                    </StyledNoMaterialsHeader>
                                </FlexContainer>
                            )}
                        </StyledMaterialsContainer>
                    </StyledCreateContractContent>

                    <StyledCreateMaterialBtnContainer>
                        <CustomButton
                            label={t('CREATE')}
                            buttonType={
                                materials.length > 0 &&
                                !isRemoveMode &&
                                areMaterialsValid &&
                                selectedCustomer.declaration?.scannedDocumentName
                                    ? ButtonsTypesEnum.Main
                                    : ButtonsTypesEnum.Disabled
                            }
                            onClick={createContract}
                            icon={<SignIcon />}
                        />
                    </StyledCreateMaterialBtnContainer>
                </>
            )}

            {isMaterialModalOpened && (
                <CreateMaterialModal
                    onMaterialCreation={handleMaterialCreation}
                    close={() => setIsMaterialModalOpened(false)}
                />
            )}
            {errorMessage && <ErrorModal errorMessage={errorMessage} />}
            {successMessage && <SuccessModal onConfirm={goToContract} message={successMessage} />}
        </Container>
    );
};

export default CreateContract;
