import styled from 'styled-components';

type StyledMaterialsTableProps = {
    $isRemoveMode: boolean | undefined;
    $isMaterialsTable?: boolean | undefined;
};

export const StyledMaterialsTable = styled.div<StyledMaterialsTableProps>`
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    height: 37vh;
    gap: ${({ theme }) => theme.space[12]};
    overflow-y: scroll;

    & > tbody > .header {
        padding-bottom: ${({ theme }) => theme.space[4]};
        font-weight: 600;
        font-size: ${({ theme }) => theme.fontSize.xxlg};
        color: ${({ theme }) => theme.color.grey[30]};
        border-bottom: 1px solid ${({ theme }) => theme.color.grey[30]};

        & > th {
            padding-bottom: ${({ theme }) => theme.space[8]};
            margin-bottom: ${({ theme }) => theme.space[12]};
        }
    }

    td,
    th {
        width: 10% !important;
    }

    ${({ $isRemoveMode, $isMaterialsTable, theme }) =>
        $isRemoveMode &&
        $isMaterialsTable &&
        `
            border: 1px dashed ${theme.color.red[50]};

    `};
`;
