import { TableDataProvider } from '@/hooks/admin/useTableData';

import UsersTable from './partials/UsersTable';

const Users = () => (
    <TableDataProvider>
        <UsersTable />
    </TableDataProvider>
);

export default Users;
