import styled, { css } from 'styled-components';

type ButtonProps = {
    $buttonType: string | undefined;
};
type EllipsisWrapperProps = {
    $maxWidth: string | undefined;
};
export const StyledButton = styled('button')<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ theme }) => theme.space[44]};
    padding: 0 ${({ theme }) => theme.space[12]};
    color: ${({ theme }) => theme.color.default.white};
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSize.h6};
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    ${({ $buttonType }) => {
        switch ($buttonType) {
            case 'main':
                return css`
                    background-color: ${({ theme }) => theme.color.blue[50]};
                `;
            case 'secondary':
                return css`
                    color: ${({ theme }) => theme.color.blue[50]};
                    background-color: ${({ theme }) => theme.color.blue[15]};
                    font-weight: 600;
                `;
            case 'disabled':
                return css`
                    color: ${({ theme }) => theme.color.grey[70]};
                    background-color: ${({ theme }) => theme.color.grey[120]};
                    cursor: not-allowed;
                `;
            case 'destructive':
                return css`
                    color: ${({ theme }) => theme.color.default.white};
                    background-color: ${({ theme }) => theme.color.red[50]};
                `;
            case 'destructive-main':
                return css`
                    color: ${({ theme }) => theme.color.red[50]};
                    background-color: transparent;
                    border: 1px solid ${({ theme }) => theme.color.red[50]};
                    font-weight: 600 !important;
                `;
            case 'tertiary':
                return css`
                    color: ${({ theme }) => theme.color.blue[50]};
                    background-color: white;
                    font-weight: 700;
                `;
            default:
                return null;
        }
    }}

    &.back-button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > .back-btn-label {
            font-size: ${({ theme }) => theme.fontSize.xxlg};
            color: ${({ theme }) => theme.color.grey[40]};
        }
    }
`;

export const BackButtonContainer = styled.div`
    & > .back-btn-label {
        font-size: ${({ theme }) => theme.fontSize.xxlg};
        color: ${({ theme }) => theme.color.grey[40]};
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${({ theme }) => theme.space[12]};

    &.empty-btn {
        margin-right: 0;
    }
`;

export const Icon = styled.span`
    display: flex;

    &.secondary > svg > path,
    &.tertiary > svg > path {
        fill: ${({ theme }) => theme.color.blue[50]};
    }

    &.disabled > svg > path {
        fill: ${({ theme }) => theme.color.grey[70]};
    }

    &.destructive > svg > path {
        fill: white;
    }

    &.destructive-main > svg > path {
        fill: ${({ theme }) => theme.color.red[50]};
    }
`;
export const EllipsisTextWrapper = styled.span<EllipsisWrapperProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${({ $maxWidth, theme }) => $maxWidth};
`;
