import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';

import CustomButton from '../../UI/CustomButton';
import { StyledTextarea } from '../../UI/CustomInput/styles';
import { StyledSubtitle } from '../../UI/CustomSubtitle/styles';
import { StyledPageTitle } from '../../UI/PageTitle/styles';
import CloseModalBar from '../partials/CloseModalBar';
import ModalBody from '../partials/ModalBody';
import ModalContainer from '../partials/ModalContainer';
import ModalFooter from '../partials/ModalFooter';
import ModalHeader from '../partials/ModalHeader';

import { CommentModalBtnContainer } from './styles';

type Props = {
    close: (comment: string, closeReason?: ModalCloseReasonEnum) => void;
    initialComment: string;
    viewOnly: boolean;
};

const CommentModal: React.FC<Props> = ({ close, initialComment, viewOnly }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [comment, setComment] = useState<string>(initialComment);

    return (
        <ModalContainer>
            <CloseModalBar
                onClick={() => close(initialComment, ModalCloseReasonEnum.Close)}
                backgroundColor={theme?.color.blue[10]}
            />
            <ModalHeader backgroundColor={theme?.color.blue[10]}>
                <StyledPageTitle>{t('COMMENT')}</StyledPageTitle>

                <>
                    {viewOnly && <StyledSubtitle>{t('WRITE_COMMENT_FOR_PROTOCOL')}</StyledSubtitle>}
                </>
            </ModalHeader>
            <ModalBody backgroundColor={theme?.color.blue[10]}>
                <StyledTextarea
                    disabled={viewOnly}
                    placeholder={t('WRITE_YOUR_COMMENT_HERE')}
                    onInput={(e) => setComment(e.currentTarget.value)}
                    defaultValue={initialComment}
                    rows={8}
                    cols={80}
                />
            </ModalBody>
            <ModalFooter backgroundColor={theme?.color.blue[10]}>
                <CommentModalBtnContainer>
                    {!viewOnly && (
                        <CustomButton
                            label={t('SAVE')}
                            buttonType={ButtonsTypesEnum.Main}
                            onClick={() => close(comment, ModalCloseReasonEnum.Success)}
                        />
                    )}
                    <CustomButton
                        label={t('CANCEL')}
                        buttonType={ButtonsTypesEnum.Destructive}
                        onClick={() => close(initialComment, ModalCloseReasonEnum.Close)}
                    />
                </CommentModalBtnContainer>
            </ModalFooter>
        </ModalContainer>
    );
};

export default CommentModal;
