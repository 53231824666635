import React from 'react';

import CompanyLogo from '@/components/shared/logo/CompanyLogo';

import AdminNavBar from '../AdminNavBar';

import { Content, Footer, Wrapper } from './styles';

type Props = {
    children: React.ReactNode;
};

const AdminLayout = ({ children }: Props) => {
    return (
        <Wrapper>
            <AdminNavBar />
            <Content>
                <div>{children}</div>
            </Content>
            <Footer>
                <CompanyLogo />
            </Footer>
        </Wrapper>
    );
};

export default AdminLayout;
