export const rolesEligibleForSRRoutes: Array<Partial<User['role']>> = [
    'SUPER_ADMIN',
    'SALES_REPRESENTATIVE',
    'RCMC',
    'SUPERVISOR'
];

export const rolesEligibleForAdminRoutes: Array<Partial<User['role']>> = ['SUPER_ADMIN', 'ADMIN'];

export type Permissions = {
    canCreateNewUsers: boolean;
    canAccessSRRoutes: boolean;
    canAccessAdminRoutes: boolean;
};
