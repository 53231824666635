import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import loadImage from 'blueimp-load-image';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CameraIcon } from '@/assets/svg-icons/camera-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import ErrorMessage from '@/components/shared/message/ErrorMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import envconfig from '@/config/envconfig';
import {
    BANK_CERTIFICATE_FORM_KEYS,
    GENERATE_PROTOCOL_FORM_KEYS,
    INVOICE_FORM_KEYS
} from '@/constants/keys/form-data-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { ScannedDocumentDto } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import AppContext from '@/store/app-context/AppContext';
import { Container, HeaderContainer } from '@/styles/shared/container/styles';
import { getToken } from '@/utils/authorizationUtils';

import {
    StyledDeclarationPreviewContent,
    StyledDeclarationPreviewFooter
} from '../../declaration/DeclarationPreview/styles';
import { StyledScanBtnContainer } from '../../declaration/DeclarationScan/styles';

dayjs.extend(utc);

type Props = {
    title: string;
    formDataKey: string;
};

const DocumentScan: React.FC<Props> = ({ title, formDataKey }) => {
    const { t } = useTranslation('translation');
    const {
        navbar,
        agreementDetails: { currentAgreementDetails }
    } = useContext(AppContext);
    const inputRef = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const { clearError, errorMessage, setError } = useError();
    const { getFormData, setFormData } = useFormData();
    const [invoiceImgSrc, setInvoiceImgSrc] = useState<string>();
    const [bankCertificateImgSrc, setBankCertificateImgSrc] = useState<string>();
    const [agreementProtocolImgSrc, setAgreementProtocolImgSrc] = useState<string>();
    const [isScanInvoice, setIsScanInvoice] = useState(false);
    const [isScanBankCertificate, setIsScanBankCertificate] = useState(false);
    const [isScanAgreementProtocol, setIsScanAgreementProtocol] = useState(false);
    const location = useLocation();
    const state = location.state as { bankCertificate?: ScannedDocumentDto } | null;

    const handleImageUpdate = useCallback(
        async (file: File) => {
            const updateBankCertificateImageSrc = async () =>
                await fetch(
                    envconfig.downloadScannedDocument(
                        state?.bankCertificate?.scannedDocumentName,
                        state?.bankCertificate?.scannedDocumentType
                    ),
                    {
                        headers: { Authorization: `bearer ${getToken()}` },
                        method: 'GET'
                    }
                )
                    .then(async (response) => await response.blob())
                    .then(async (imageBlob) => {
                        const imageObjectURL = URL.createObjectURL(imageBlob);
                        setBankCertificateImgSrc(imageObjectURL);
                    })
                    .catch((error) => {
                        setError(error, ErrorTypeEnum.Error);
                    });
            if (file) {
                if (isScanInvoice) {
                    setInvoiceImgSrc(URL.createObjectURL(file));
                }

                if (isScanBankCertificate) {
                    setBankCertificateImgSrc(URL.createObjectURL(file));
                }

                if (isScanAgreementProtocol) {
                    setAgreementProtocolImgSrc(URL.createObjectURL(file));
                }
            } else if (isScanBankCertificate && state?.bankCertificate?.scannedDocumentName)
                await updateBankCertificateImageSrc();
        },
        [
            isScanBankCertificate,
            state?.bankCertificate?.scannedDocumentName,
            state?.bankCertificate?.scannedDocumentType,
            isScanInvoice,
            isScanAgreementProtocol
        ]
    );

    const handleTakePhoto = () => {
        if (inputRef.current) {
            (inputRef.current as HTMLInputElement).click();
        }
    };
    const handleConfirmPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        processImage(file, (processedFile) => {
            // Here you get the processedFile with the correct orientation
            // Continue your logic here, such as updating the image and setting form data
            handleImageUpdate(processedFile); // Assuming handleImageUpdate is defined elsewhere and accepts a File
            setFormData(formDataKey, processedFile); // Assuming setFormData is defined elsewhere and accepts the formDataKey and a File
        });
    };

    const handleSign = () => {
        navigate(ROUTES.AgreementList.AgreementPreview.Sign.Path);
    };

    const handleBack = () => {
        navigate(ROUTES.FullPaths.Agreement(currentAgreementDetails?.agreementId), {
            state: { modalOpen: true }
        });
    };

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (isScanInvoice) {
            handleImageUpdate(getFormData(INVOICE_FORM_KEYS.SCANNED_INVOICE) as File);
        }

        if (isScanBankCertificate) {
            handleImageUpdate(
                getFormData(BANK_CERTIFICATE_FORM_KEYS.SCANNED_BANK_CERTIFICATE) as File
            );
        }

        if (isScanAgreementProtocol) {
            handleImageUpdate(getFormData(GENERATE_PROTOCOL_FORM_KEYS.SCANNED_PROTOCOL) as File);
        }
    }, [isScanInvoice, isScanBankCertificate, isScanAgreementProtocol, handleImageUpdate]);

    useEffect(() => {
        setIsScanInvoice(formDataKey === INVOICE_FORM_KEYS.SCANNED_INVOICE);
        setIsScanBankCertificate(
            formDataKey === BANK_CERTIFICATE_FORM_KEYS.SCANNED_BANK_CERTIFICATE
        );
        setIsScanAgreementProtocol(formDataKey === GENERATE_PROTOCOL_FORM_KEYS.SCANNED_PROTOCOL);
    }, [formDataKey]);

    const processImage = (file: File, callback: (processedFile: File) => void): void => {
        // Define the aspect ratio
        const aspectRatio = 3 / 4;

        loadImage(
            file,
            (canvas) => {
                // Verify the canvas is not null
                if (canvas instanceof HTMLCanvasElement) {
                    // Here you might need to crop or fit the image into the aspect ratio
                    // For example, to fit the image while keeping it within the aspect ratio
                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        // Calculate the desired width and height based on the aspect ratio
                        let width = canvas.width;
                        let height = canvas.height;
                        const currentAspectRatio = width / height;

                        if (currentAspectRatio > aspectRatio) {
                            // The image is too wide
                            width = height * aspectRatio;
                        } else if (currentAspectRatio < aspectRatio) {
                            // The image is too tall
                            height = width / aspectRatio;
                        }

                        // Optionally, center the image if it needs to be cropped
                        const offsetX = (canvas.width - width) / 2;
                        const offsetY = (canvas.height - height) / 2;

                        // Create a new canvas with the desired dimensions
                        const newCanvas = document.createElement('canvas');
                        newCanvas.width = width;
                        newCanvas.height = height;
                        const newCtx = newCanvas.getContext('2d');
                        if (newCtx) {
                            // Draw the image into the new canvas, possibly cropping it
                            newCtx.drawImage(
                                canvas,
                                offsetX,
                                offsetY,
                                width,
                                height,
                                0,
                                0,
                                width,
                                height
                            );
                        }

                        // Convert the new canvas to a blob
                        newCanvas.toBlob((blob) => {
                            if (blob) {
                                // Create a new File object
                                const processedFile = new File([blob], file.name, {
                                    type: 'image/png',
                                    lastModified: Date.now()
                                });
                                // Call the callback with the new File object
                                callback(processedFile);
                            }
                        }, 'image/png');
                    }
                }
            },
            {
                canvas: true,
                orientation: true // This auto-fixes the orientation
            }
        );
    };

    return (
        <Container $height={100} $position="relative">
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[12]}>
                <StyledPageTitle>{t(title)}</StyledPageTitle>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </HeaderContainer>

            <Content>
                {invoiceImgSrc || bankCertificateImgSrc || agreementProtocolImgSrc ? ( // eslint-disable-line
                    <StyledDeclarationPreviewContent>
                        {isScanInvoice && <img src={invoiceImgSrc} alt="Invoice scan" />}

                        {isScanBankCertificate && (
                            <img src={bankCertificateImgSrc} alt="Bank Certificate scan" />
                        )}

                        {isScanAgreementProtocol && (
                            <img src={agreementProtocolImgSrc} alt="Agreement Protocol scan" />
                        )}
                    </StyledDeclarationPreviewContent>
                ) : (
                    <div
                        style={{
                            backgroundColor: theme?.color.default.white,
                            height: '50vh'
                        }}
                    />
                )}
            </Content>

            <StyledScanBtnContainer>
                {invoiceImgSrc ?? bankCertificateImgSrc ?? agreementProtocolImgSrc ? (
                    <StyledDeclarationPreviewFooter>
                        <CustomButton
                            icon={<CameraIcon />}
                            onClick={handleTakePhoto}
                            label={t('RETAKE')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            style={{ marginRight: theme?.space[12] }}
                        />
                        <input
                            onInput={handleConfirmPhoto}
                            accept={'image/*'}
                            type="file"
                            ref={inputRef}
                            capture={'environment'}
                            style={{ display: 'none' }}
                        />
                        <CustomButton
                            onClick={handleBack}
                            label={t('SAVE')}
                            buttonType={ButtonsTypesEnum.Main}
                        />
                    </StyledDeclarationPreviewFooter>
                ) : isScanAgreementProtocol && agreementProtocolImgSrc ? (
                    <StyledDeclarationPreviewFooter>
                        <CustomButton
                            onClick={handleBack}
                            label={t('BACK')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            style={{ marginRight: theme?.space[12] }}
                        />
                        <CustomButton
                            icon={<SignIcon />}
                            onClick={handleSign}
                            label={t('SIGN')}
                            buttonType={ButtonsTypesEnum.Main}
                            style={{ width: '100%' }}
                        />
                    </StyledDeclarationPreviewFooter>
                ) : (
                    <>
                        <CustomButton
                            icon={<CameraIcon />}
                            onClick={handleTakePhoto}
                            label={t('TAKE_A_PICTURE')}
                            buttonType={ButtonsTypesEnum.Main}
                        />
                        <input
                            onInput={handleConfirmPhoto}
                            accept={'image/*'}
                            type="file"
                            ref={inputRef}
                            capture={'environment'}
                        />
                    </>
                )}
            </StyledScanBtnContainer>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Container>
    );
};

export default DocumentScan;
