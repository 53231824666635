import { StyledModalSubMsg } from './styles';

type Props = {
    message: string;
};

const ModalSubMessage: React.FC<Props> = ({ message }) => {
    return <StyledModalSubMsg>{message}</StyledModalSubMsg>;
};

export default ModalSubMessage;
