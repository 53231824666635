import React from 'react';

import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import { theme } from './config/theme';
import Routing from './pages/Routing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { UserProvider } from './store/user/useUserContext';

import './config/i18n';
import './index.css';
import './styles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <UserProvider>
                <Routing />
            </UserProvider>
        </ThemeProvider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
