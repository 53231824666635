const AGREEMENT_PROTOCOL_PREVIEW_STYLE = `
        #agreement {
            font-size: 16px;
            width: 100%;
            box-sizing: border-box;
            padding: 0pt;
            margin: auto;
            padding: 8px;
            background-color: white;
        }
        #title {
            font-size: 8pt;
            width: 75%;
        }
        #logo {
            left: 1rem;
            width: 3rem;
            height: 1.6rem;
            margin-top: 10px;
        }
        #signs-container {
            display: flex;
        }
        #employee-sign,
        #poc-personnel-sign {
            display: none;
        }

       .box1, .box2, .box3 { 
            float: unset;
            width: auto;
       }
     
      .main_table, tbody {
           display: table-header-group;
      }
      
      #sign_panel { 
          display: none;
      }
      
      .second-section-table {
          display: inline-table;
      }  
      
      td.section_subtitle, td.second-section-table, .container {
          text-align: inherit;
      }
      
      .container {
          border: none;
          display: unset;
      }
      
      .section_subtitle { 
          font-size: 25px;
      }
      
      #root {
          white-space: unset;
      }
        
      .title {
            display: none;
      }
`;

export default AGREEMENT_PROTOCOL_PREVIEW_STYLE;
