import { SearchOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
    buttonType?: 'button' | 'submit' | 'reset';
} & ButtonProps;

const SearchButton = ({ buttonType, ...rest }: Props) => {
    const { t } = useTranslation();

    return (
        <Button
            htmlType={buttonType ?? 'submit'}
            type="primary"
            icon={<SearchOutlined />}
            {...rest}>
            {t('SEARCH')}
        </Button>
    );
};

export default SearchButton;
