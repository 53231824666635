import { Navigate, Outlet } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import { useUserContext } from '@/store/user/useUserContext';

const ProtectedAdminRoute = () => {
    const {
        permissions: { canAccessAdminRoutes, canAccessSRRoutes }
    } = useUserContext();

    // Don't redirect unless we have received the role
    if (!canAccessSRRoutes && !canAccessAdminRoutes) {
        return null;
    }

    // If we have the role, redirect according to that
    return canAccessAdminRoutes ? <Outlet /> : <Navigate to={`../${ROUTES.AssetList.Path}`} />;
};
export default ProtectedAdminRoute;
