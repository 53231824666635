import styled from 'styled-components';

import { IconStroke } from '@/styles/shared/icon/styles';
import { ListItem } from '@/styles/shared/list-item/styles';
import { StyledStatusVerticalLine } from '@/styles/shared/status/styles';

type StatusProps = {
    $style: string | undefined;
};

export const AgreementCardContainer = styled(ListItem)``;

export const DeclarationIcon = styled(IconStroke)`
    display: flex;
    align-self: center;

    svg {
        width: 10px;
    }
`;

export const StyledAgreementCardBtnContainer = styled.div`
    margin: ${({ theme }) => theme.space[20]} 0;
`;

export const StyledAgreementCardStatus = styled.span<StatusProps>`
    font-weight: 600;

    color: ${({ $style, theme }) => {
        switch ($style) {
            case 'validation':
                return theme.color.yellow[10];
            case 'signed':
                return theme.color.green[50];
            case 'prepared':
                return theme.color.orange[10];
            case 'canceled':
                return theme.color.red[50];
            case 'draft':
                return theme.color.grey[100];
            case 'done':
                return theme.color.blue[50];
            default:
                return 'transparent';
        }
    }};
`;

export const StyledAgreementCardStatusLine = styled(StyledStatusVerticalLine)<StatusProps>`
    border-left: 5px solid
        ${({ $style, theme }) => {
            switch ($style) {
                case 'validation':
                    return theme.color.agreementStatus.validation;
                case 'signed':
                    return theme.color.agreementStatus.signed;
                case 'prepared':
                    return theme.color.agreementStatus.prepared;
                case 'canceled':
                    return theme.color.agreementStatus.canceled;
                case 'draft':
                    return theme.color.agreementStatus.draft;
                case 'done':
                    return theme.color.agreementStatus.done;
                default:
                    return 'transparent';
            }
        }};
`;
