import React, { useContext, useEffect, useState } from 'react';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ErrorMessage from '@/components/shared/message/ErrorMessage';
import { StyledSubtitle } from '@/components/shared/UI/CustomSubtitle/styles';
import ERROR_CODES from '@/constants/errors/error-codes';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { useSendUserResetPasswordMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';

import {
    AuthButton,
    AuthButtonsContainer,
    AuthForm,
    AuthFormContainer,
    AuthHeader,
    AuthHeaderContainer
} from '../../shared/styles';

const ForgottenPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const { setError, clearError, errorMessage } = useError();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton
        });

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const [sendUserResetPassword] = useSendUserResetPasswordMutation({
        onError: (error) => {
            setLoading(false);
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: () => {
            setLoading(false);
            clearError();
            navigate(ROUTES.FullPaths.ConfirmEmailSent, { state: { email } });
        }
    });

    const onFinish = async (values: { username: string }) => {
        setEmail(values.username);
        setLoading(true);
        await sendUserResetPassword({
            variables: {
                input: { email: values.username }
            }
        });
    };

    return (
        <AuthFormContainer>
            <AuthHeaderContainer>
                <AuthHeader>{t('FORGOT_PASSWORD')}</AuthHeader>
                {errorMessage && <ErrorMessage message={errorMessage} />}
                <StyledSubtitle>{t('FORGOT_PASSWORD_SUBTITLE')}</StyledSubtitle>
            </AuthHeaderContainer>
            <AuthForm
                name="basic"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: `${t(`${ERROR_CODES.EmailRequired}`)}`
                        },
                        {
                            type: 'email',
                            message: `${t(`${ERROR_CODES.EmailInvalid}`)}`
                        }
                    ]}>
                    <Input placeholder={t('ENTER_EMAIL')} size="large" />
                </Form.Item>
                <AuthButtonsContainer>
                    <AuthButton
                        label={t('SEND_EMAIL')}
                        buttonType={ButtonsTypesEnum.Main}
                        functionType={'submit'}
                        loading={loading}
                    />
                </AuthButtonsContainer>
            </AuthForm>
        </AuthFormContainer>
    );
};

export default ForgottenPassword;
