import { Fragment, useContext, useEffect, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    AgreementDetailsDto,
    useGetAgreementsQuery,
    useValidateAgreementMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import SignSection from '@/pages/shared/sign/SignSection';
import AppContext from '@/store/app-context/AppContext';
import { useUserContext } from '@/store/user/useUserContext';
import { HeaderContainer, ScrollableContainer } from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';
import { getCurrentCyrillicName, getCurrentUserEmail } from '@/utils/authorizationUtils';

const ValidateAgreement: React.FC = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { role } = useUserContext();
    const { setError, clearError, errorMessage } = useError();
    const { navbar, sign, agreementDetails: agreementDetailsState } = useContext(AppContext);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { refetch: refetchAgreements } = useGetAgreementsQuery({
        onCompleted: () => clearError(),
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    const [validateAgreement, { loading }] = useValidateAgreementMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await refetchAgreements();
            setIsSuccess(true);
            clearError();
        }
    });

    const agreement =
        agreementDetailsState.currentAgreementDetails ??
        getCurrentContractData<AgreementDetailsDto>();

    const F2RCMCSign = sign.signatures?.find(
        (signature) => signature.signer.toLocaleUpperCase() === role
    )?.signature;

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallback: sign.clearSigns
        });

        setIsValid(!!F2RCMCSign);

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleValidateAgreement = () => {
        if (!isValid) {
            return;
        }

        validateAgreement({
            variables: {
                agreementId: agreement.agreementId,
                validatorSignature: F2RCMCSign ?? ''
            }
        });
    };

    const handleCompleteFlow = () => {
        sign.clearSigns();
        setIsSuccess(false);
        navigate(ROUTES.FullPaths.AgreementList);
    };

    return (
        <Fragment>
            <ScrollableContainer>
                <HeaderContainer $padding={theme?.space[0]}>
                    <StyledPageTitle $marginY={theme?.space[0]}>
                        {t('ADD_SIGNATURE')}
                    </StyledPageTitle>

                    <CustomButton
                        label={t('VALIDATE')}
                        buttonType={F2RCMCSign ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                        onClick={handleValidateAgreement}
                        loading={loading}
                    />
                </HeaderContainer>

                <Content>
                    <SignSection
                        signatureIndex={1}
                        sectionTitle={t(role as string)}
                        signer={role as string}
                        name={getCurrentCyrillicName()}
                        email={getCurrentUserEmail()}
                        sign={F2RCMCSign}
                    />
                </Content>
            </ScrollableContainer>

            {isSuccess && (
                <SuccessModal
                    title={t('SUCCESSFULLY_VALIDATED')}
                    message={t('AGREEMENT_VALIDATED_SUCCESSFULY')}
                    onConfirm={handleCompleteFlow}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Fragment>
    );
};

export default ValidateAgreement;
