import { useNavigate } from 'react-router-dom';

import ERROR_CODES from '@/constants/errors/error-codes';
import ROUTES from '@/constants/routes/routes';
import { useUserContext } from '@/store/user/useUserContext';
import { login, logout, passExpiredHandler } from '@/utils/authorizationUtils';

const useErrorHandler = () => {
    const navigate = useNavigate();
    const { clearUserData } = useUserContext();

    // TODO: Change error type with the coming error type from the BE
    const handleActionError = (err: { key: string; value: string[] }) => {
        if (err.key === ERROR_CODES.PasswordExpired) {
            const token = err.value[0];

            login(token, '');
            passExpiredHandler(true);
            navigate(ROUTES.FullPaths.ChangePassword);
        } else if (err.key === ERROR_CODES.NotAuthenticated) {
            clearUserData();
            logout();
            navigate(ROUTES.FullPaths.Login);
        }
    };
    const handleStaticError = () => null;

    return {
        handleActionError,
        handleStaticError
    };
};

export default useErrorHandler;
