import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserRoleEnum, useCreateUserMutation, CreateUserInput } from '@/graphql/main';
import { useUserContext } from '@/store/user/useUserContext';

type Props = {
    successCallback?: () => void;
};

const CreateUserModal = ({ successCallback }: Props) => {
    const [createUserMutation] = useCreateUserMutation();
    const [form] = Form.useForm<CreateUserInput>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation('translation');
    const { isSuperAdmin } = useUserContext();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCreateUser = async () => {
        try {
            const values = await form.validateFields();
            await createUserMutation({
                variables: {
                    input: { ...values }
                },
                onCompleted: async () => {
                    successCallback?.();
                    message.success(t('CREATE_USER_SUCCESS'));
                },
                onError: async () => await message.error(t('CREATE_USER_FAIL'))
            });
            form.resetFields();
            setIsModalOpen(false);
        } catch {
            await message.error(t('CREATE_USER_FAIL'));
        } finally {
            form.resetFields();
        }
    };

    return (
        <>
            <Row justify="end" style={{ marginTop: 18 }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
                    {t('NEW_USER')}
                </Button>
            </Row>
            <Modal
                title={t('CREATE_NEW_USER')}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t('CANCEL')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleCreateUser}>
                        {t('CREATE_USER')}
                    </Button>
                ]}>
                <Form form={form}>
                    <Form.Item
                        name="name"
                        label={t('NAME')}
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('NAME_REQUIRED') }]}>
                        <Input placeholder={t('NAME')} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t('EMAIL')}
                        labelCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: t('PROVIDE_VALID_MAIL')
                            }
                        ]}>
                        <Input placeholder={t('EMAIL')} />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label={t('ROLE')}
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('ROLE_REQUIRED') }]}>
                        <Select placeholder={t('ROLE')}>
                            <Select.Option value={UserRoleEnum.Admin}>
                                {UserRoleEnum.Admin}
                            </Select.Option>
                            <Select.Option value={UserRoleEnum.SuperAdmin}>
                                {UserRoleEnum.SuperAdmin}
                            </Select.Option>
                            {isSuperAdmin && (
                                <>
                                    <Select.Option value={UserRoleEnum.Rcmc}>
                                        {UserRoleEnum.Rcmc}
                                    </Select.Option>
                                    <Select.Option value={UserRoleEnum.Supervisor}>
                                        {UserRoleEnum.Supervisor}
                                    </Select.Option>
                                </>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('ACTIVE')}
                        name="isActive"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('ACTIVE_STATUS_REQUIRED') }]}>
                        <Select placeholder={t('ACTIVE')}>
                            <Select.Option value={true}>{t('YES')}</Select.Option>
                            <Select.Option value={false}>{t('NO')}</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateUserModal;
