import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import AssetStatusTag from '@/components/admin/AssetStatusTag';
import { AdminCommercialAgreementDto } from '@/graphql/main';
import { formatDate } from '@/utils/formatDate';

import ActionButtons from '../partials/ActionButtons';

const useColDefs = () => {
    const { t } = useTranslation();
    const colDefs: ColumnsType<AdminCommercialAgreementDto> = [
        {
            key: 'caId',
            title: t('COMMERCIAL_AGREEMENT_NO'),
            dataIndex: 'caId',
            width: 30,
            sorter: true
        },
        {
            key: 'status',
            title: t('STATUS'),
            dataIndex: 'status',
            width: 60,
            sorter: true,
            render: (_, { status }: AdminCommercialAgreementDto) => (
                <AssetStatusTag status={status} />
            )
        },
        {
            key: 'caDate',
            title: t('DATE'),
            dataIndex: 'caDate',
            width: 100,
            sorter: true,
            render: (_, { caDate }) => formatDate(caDate)
        },
        {
            key: 'caFromDate',
            title: t('START_DATE'),
            dataIndex: 'caFromDate',
            width: 100,
            sorter: true,
            render: (_, { caFromDate }) => formatDate(caFromDate)
        },
        {
            key: 'caToDate',
            title: t('END_DATE'),
            dataIndex: 'caToDate',
            width: 100,
            sorter: true,
            render: (_, { caToDate }) => formatDate(caToDate)
        },
        {
            key: 'type',
            title: t('TYPE'),
            dataIndex: 'type',
            sorter: true,
            width: 100,
            render: (_, { type }) => t(type)
        },
        {
            key: 'subject',
            title: t('SUBJECT'),
            dataIndex: 'type',
            sorter: true,
            width: 150,
            render: (_, { subject }) => t(subject)
        },
        {
            key: 'reason',
            title: t('REASON'),
            dataIndex: 'reason',
            sorter: true,
            width: 120,
            render: (_, { reason }) => t(reason)
        },
        {
            key: 'pocHermesId',
            title: t('POC_ID'),
            dataIndex: 'pocHermesId',
            width: 30,
            sorter: true
        },
        {
            key: 'pocName',
            title: t('POC_NAME'),
            dataIndex: 'pocName',
            width: 100,
            sorter: true
        },
        {
            key: 'customerName',
            title: t('CUSTOMER'),
            dataIndex: 'customerName',
            width: 100,
            sorter: true
        },
        {
            key: 'salesRepName',
            title: t('SALES_REPRESENTATIVE'),
            dataIndex: 'salesRepName',
            width: 100,
            sorter: true
        },
        {
            key: 'zone',
            title: t('ZONE'),
            dataIndex: 'zone',
            width: 30,
            sorter: true
        },
        {
            key: 'ttlCostInvestmentAmount',
            title: t('INVESTMENT_AMOUNT'),
            dataIndex: 'ttlCostInvestmentAmount',
            width: 30,
            sorter: true,
            render: (_, { ttlCostInvestmentAmount }) => `${ttlCostInvestmentAmount} ${t('LV')}`
        },
        {
            key: 'documentSequenceNo',
            title: t('PROTOCOL_SEQUENCE'),
            dataIndex: 'documentSequenceNo',
            width: 30,
            sorter: true
        },
        {
            title: t('DOWNLOAD_ALL_TYPES_OF_DOCUMENTS'),
            key: 'actions',
            dataIndex: 'actions',
            width: 150,
            render: (_, { caId, protocolName, bankCertificate, invoice }) => (
                <ActionButtons
                    protocolName={protocolName}
                    caId={caId}
                    bankCertificateData={bankCertificate}
                    invoiceData={invoice}
                />
            )
        }
    ];

    return { colDefs };
};

export default useColDefs;
