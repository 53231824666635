import { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ErrorModal: React.FC = () => {
    const { t } = useTranslation('translation');

    const [versionModalOpen, setVersionModalOpen] = useState(false);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((reg) => {
                    reg.onupdatefound = () => {
                        const newWorker = reg.installing;
                        if (newWorker)
                            newWorker.onstatechange = () => {
                                if (
                                    newWorker.state === 'installed' &&
                                    navigator.serviceWorker.controller
                                )
                                    setVersionModalOpen(true);
                            };
                    };
                })
                .catch((err) => {
                    console.log('Service Worker registration failed: ', err);
                });
        }
    }, []);

    return (
        <Modal
            open={versionModalOpen}
            footer={null}
            maskClosable={false}
            onCancel={() => setVersionModalOpen(false)}
            centered>
            <h1>{t('NEW_VERSION_AVAILABLE')}</h1>
            <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={() => {
                    setVersionModalOpen(false);
                    window.location.reload();
                }}>
                {t('RELOAD_APP')}
            </Button>
        </Modal>
    );
};

export default ErrorModal;
