import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import AssetStatusTag from '@/components/admin/AssetStatusTag';
import { AssetRawDataReportModel } from '@/graphql/main';

const useColDefs = () => {
    const { t } = useTranslation();

    const colDefs: ColumnsType<AssetRawDataReportModel> = [
        {
            key: 'assetNo',
            title: t('ASSET_NO'),
            dataIndex: 'assetNo'
        },
        {
            key: 'contractNo',
            title: t('CONTRACT_NO'),
            dataIndex: 'contractNo'
        },
        {
            key: 'assetType',
            title: t('ASSET_TYPE'),
            dataIndex: 'assetType',
            render: (_, { assetType }) => t(assetType)
        },
        {
            key: 'assetStatus',
            title: t('ASSET_STATUS'),
            dataIndex: 'assetStatus',
            render: (_, { status }) => <AssetStatusTag status={status} />
        },
        {
            key: 'dateSigned',
            title: t('DATE_SIGNED'),
            dataIndex: 'dateSigned',
            render: (_, { dateSigned }) => dateSigned && dayjs(dateSigned).format('DD.MM.YYYY')
        },
        {
            key: 'customerId',
            title: t('CUSTOMER_ID'),
            dataIndex: 'customerId'
        },
        {
            key: 'customerName',
            title: t('CUSTOMER_NAME'),
            dataIndex: 'customerName'
        },
        {
            key: 'pocId',
            title: t('POC_ID'),
            dataIndex: 'pocId'
        },
        {
            key: 'pocName',
            title: t('POC_NAME'),
            dataIndex: 'pocName'
        },
        {
            key: 'employeeName',
            title: t('EMPLOYEE_NAME'),
            dataIndex: 'employeeName'
        },
        {
            key: 'deliveryType',
            title: t('DELIVERY_TYPE'),
            dataIndex: 'deliveryType'
        },
        {
            key: 'materialType',
            title: t('MATERIAL_TYPE'),
            dataIndex: 'materialType',
            render: (_, { materialType }) => {
                if (materialType) return t(materialType);
            }
        },
        {
            key: 'materialModel',
            title: t('MATERIAL_MODEL'),
            dataIndex: 'materialModel'
        },
        {
            key: 'brand',
            title: t('BRAND'),
            dataIndex: 'brand'
        },
        {
            key: 'serialNo',
            title: t('SERIAL_NO'),
            dataIndex: 'serialNo'
        },
        {
            key: 'quantity',
            title: t('QUANTITY'),
            dataIndex: 'quantity'
        }
    ];

    return { colDefs };
};

export default useColDefs;
