import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import completeIcon from '@/assets/svg-icons/complete-icon.svg';
import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import warningIcon from '@/assets/svg-icons/warning-icon.svg';
import { ReactComponent as XcIcon } from '@/assets/svg-icons/xc-icon.svg';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';

import CustomButton from '../../UI/CustomButton';
import CloseModalBar from '../partials/CloseModalBar';
import ModalBody from '../partials/ModalBody';
import ModalContainer from '../partials/ModalContainer';
import ModalFooter from '../partials/ModalFooter';
import ModalHeader from '../partials/ModalHeader';
import ModalSubMessage from '../partials/ModalSubMessage';

import { WarningModalButtonContainer, WarningModalTitle } from './styles';

type Props = {
    deny: () => void;
    accept: () => void;
    cancelLoading?: boolean;
    title: string;
    message: string;
    subMessage?: string;
    isDestructive?: boolean;
};

const WarningModal: React.FC<Props> = ({
    deny,
    accept,
    cancelLoading,
    title,
    message,
    subMessage,
    isDestructive
}) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();

    return (
        <ModalContainer>
            <CloseModalBar
                backgroundColor={isDestructive ? theme?.color.red[70] : theme?.color.blue[5]}
                xColor={theme?.color.default.black}
                onClick={deny}
            />

            <ModalHeader
                backgroundColor={isDestructive ? theme?.color.red[70] : theme?.color.blue[5]}>
                <img alt="Warning Icon" src={isDestructive ? warningIcon : completeIcon} />
                <WarningModalTitle
                    $titleColor={isDestructive ? theme?.color.red[50] : theme?.color.blue[50]}>
                    {title}
                </WarningModalTitle>
            </ModalHeader>

            <ModalBody backgroundColor={theme?.color.default.white}>
                <WarningModalTitle $titleColor={theme?.color.default.black}>
                    {message}
                </WarningModalTitle>
                {subMessage ? <ModalSubMessage message={subMessage} /> : <></>}
            </ModalBody>

            <ModalFooter backgroundColor={theme?.color.default.white}>
                <WarningModalButtonContainer>
                    <CustomButton
                        label={t('NO')}
                        buttonType={ButtonsTypesEnum.Secondary}
                        onClick={deny}
                        loading={cancelLoading}
                    />

                    <CustomButton
                        label={t('YES')}
                        buttonType={
                            isDestructive ? ButtonsTypesEnum.Destructive : ButtonsTypesEnum.Main
                        }
                        onClick={accept}
                        icon={isDestructive ? <XcIcon /> : <TickIcon />}
                    />
                </WarningModalButtonContainer>
            </ModalFooter>
        </ModalContainer>
    );
};

export default WarningModal;
