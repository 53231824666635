import { useEffect, useState } from 'react';

import { Button, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronIcon } from '@/assets/svg-icons/chevron-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { FilterTypesEnum } from '@/enums/assets/filter-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AssetDto,
    AssetDtoFilterInput,
    CustomerDto,
    DocumentStatusEnum,
    FilterRequestModelInput,
    Poc,
    useGetPocsWithAssetsPerUserQuery,
    useGetAssetOrderIdsPerUserQuery,
    useGetCustomersWithAssetsPerUserQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { DropdownContainer, StyledDropdown } from '@/styles/shared/dropdown/styles';

import AssetStatusFilter from '../AssetStatusFilter';

const getStoredFilters = () => {
    const storedFiltersData = localStorage.getItem(LOCAL_STORAGE_KEYS.AssetFilters);

    if (storedFiltersData) {
        const storedFilters = JSON.parse(storedFiltersData);

        return storedFilters;
    }

    return [];
};

type FilterLabels = {
    assetStatusLabel: string;
    customerLabel: string;
    pocLabel: string;
    oderLabel: string;
};

export type FilterWithType = {
    filter: AssetDtoFilterInput;
    type: FilterTypesEnum;
    filterKey: string;
    statusKey?: DocumentStatusEnum;
};

type Props = {
    onFiltersSelected: (filters: AssetDtoFilterInput[]) => void;
    assets: AssetDto[];
};

const AssetDropdownArea = ({ onFiltersSelected, assets }: Props) => {
    const { setError, clearError, errorMessage } = useError();
    const storedFilters = getStoredFilters();
    const { t } = useTranslation('translation');
    const filterLabelsInitial: FilterLabels = {
        assetStatusLabel: t('CONTRACT_STATUS'),
        customerLabel: t('CUSTOMER'),
        pocLabel: t('POC'),
        oderLabel: t('ORDER_ID')
    };

    const defaultSelectionItem: ItemType = {
        label: t('SELECT_ALL_DASHED'),
        key: t('SELECT_ALL_DASHED')
    };

    const [customers, setCustomers] = useState<CustomerDto[]>();
    const [pocs, setPocs] = useState<Poc[]>();
    const [assetOrderIds, setAssetOrderIds] = useState<string[]>();
    const [filters, setFilters] = useState<FilterWithType[]>(storedFilters || []);

    const { refetch: refetchCustomerPerUser } = useGetCustomersWithAssetsPerUserQuery({
        variables: {
            input: {
                status: DocumentStatusEnum.Prepared
            }
        },
        onCompleted: (data) => {
            setCustomers(data.customersWithAssetsPerUser as CustomerDto[]);
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });
    const { refetch: refetchPocsPerUser } = useGetPocsWithAssetsPerUserQuery({
        variables: {
            input: {
                status: DocumentStatusEnum.Prepared
            }
        },
        onCompleted: (data) => {
            setPocs(data.pocsWithAssetsPerUser as Poc[]);
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    const { refetch: refetchAssetOrderIds } = useGetAssetOrderIdsPerUserQuery({
        variables: {
            input: {
                status: DocumentStatusEnum.Prepared
            }
        },
        onCompleted: (data) => {
            setAssetOrderIds(data.assetOrderIdsPerUser);
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    useEffect(() => {
        const filterRequest: FilterRequestModelInput = {
            customerName: filters.find((filter) => filter.type === FilterTypesEnum.Customer)
                ?.filterKey,
            pocName: filters.find((filter) => filter.type === FilterTypesEnum.Poc)?.filterKey,
            status: filters.find((filter) => filter.type === FilterTypesEnum.AssetStatus)?.statusKey
        };

        refetchFilters(filterRequest);
    }, [assets]); // eslint-disable-line

    useEffect(() => {
        onFiltersSelected(filters.map((f) => f.filter));
        localStorage.setItem(LOCAL_STORAGE_KEYS.AssetFilters, JSON.stringify(filters));
    }, [filters]); // eslint-disable-line

    const customerItems: MenuProps['items'] = customers?.map((customer) => {
        return {
            label: customer.name,
            key: customer.name
        };
    });
    const pocItems: MenuProps['items'] = pocs?.map((poc) => {
        return {
            label: poc.pocName,
            key: poc.pocName
        };
    });
    const assetOrderIdsItems: MenuProps['items'] = assetOrderIds?.map((id: string) => {
        return {
            label: id,
            key: id
        };
    });

    const onAssetStatusSelection = (key: DocumentStatusEnum | undefined) => {
        removeFilter(FilterTypesEnum.AssetStatus);

        if (!key) {
            reloadFilters(FilterTypesEnum.AssetStatus);
        } else {
            const filter: AssetDtoFilterInput = {
                and: [{ status: { eq: key } }]
            };
            fullUpdateFilter({
                type: FilterTypesEnum.AssetStatus,
                filter,
                filterKey: t(key),
                statusKey: key
            });
            reloadFilters(FilterTypesEnum.AssetStatus, {
                type: FilterTypesEnum.AssetStatus,
                filter,
                filterKey: t(key),
                statusKey: key
            });
        }
    };

    const onCustomerSelection: MenuProps['onClick'] = ({ key }) => {
        if (key === defaultSelectionItem.key) {
            removeFilter(FilterTypesEnum.Customer);
            reloadFilters(FilterTypesEnum.Customer);
        } else {
            const filter: AssetDtoFilterInput = {
                and: [{ customerName: { eq: key } }]
            };
            fullUpdateFilter({
                type: FilterTypesEnum.Customer,
                filter,
                filterKey: key
            });
            reloadFilters(FilterTypesEnum.Customer, {
                type: FilterTypesEnum.Customer,
                filter,
                filterKey: key
            });
        }
    };

    const onPocSelection: MenuProps['onClick'] = ({ key }) => {
        if (key === defaultSelectionItem.key) {
            removeFilter(FilterTypesEnum.Poc);
            reloadFilters(FilterTypesEnum.Poc);
        } else {
            const filter: AssetDtoFilterInput = {
                and: [{ pocName: { eq: key } }]
            };

            fullUpdateFilter({ type: FilterTypesEnum.Poc, filter, filterKey: key });
            reloadFilters(FilterTypesEnum.Poc, {
                type: FilterTypesEnum.Poc,
                filter,
                filterKey: key
            });
        }
    };

    const onOrderIdSelection: MenuProps['onClick'] = ({ key }) => {
        if (key === defaultSelectionItem.key) {
            removeFilter(FilterTypesEnum.Order);
            void reloadFilters(FilterTypesEnum.Order);
        } else {
            const filter: AssetDtoFilterInput = {
                and: [{ orderIds: { some: { eq: key } } }]
            };

            fullUpdateFilter({ type: FilterTypesEnum.Order, filter, filterKey: key });
            void reloadFilters(FilterTypesEnum.Order, {
                type: FilterTypesEnum.Order,
                filter,
                filterKey: key
            });
        }
    };

    const fullUpdateFilter = (filter: FilterWithType) => {
        const filterIndex = filters.map((f) => f.type).indexOf(filter.type);

        if (filterIndex === -1) {
            setFilters((prevState) => [...prevState, filter]);
        } else {
            setFilters((prevState) => {
                prevState[filterIndex] = filter;

                return [...prevState];
            });
        }
    };

    const removeFilter = (filterType: FilterTypesEnum) => {
        setFilters((prevState) => [...prevState.filter((f) => f.type !== filterType)]);
    };

    const getLabelOrDefault = (filters: FilterWithType[], type: FilterTypesEnum) => {
        const filter = filters.find((f) => f.type === type);

        if (filter) {
            return filter.filterKey;
        } else {
            switch (type) {
                case FilterTypesEnum.Customer:
                    return filterLabelsInitial.customerLabel;
                case FilterTypesEnum.AssetStatus:
                    return filterLabelsInitial.assetStatusLabel;
                case FilterTypesEnum.Order:
                    return filterLabelsInitial.oderLabel;
                case FilterTypesEnum.Poc:
                    return filterLabelsInitial.pocLabel;
            }
        }
    };

    const reloadFilters = async (filterType: FilterTypesEnum, filter?: FilterWithType) => {
        const filterRequest: FilterRequestModelInput = {
            customerName:
                filterType === FilterTypesEnum.Customer
                    ? filter?.filterKey
                    : filters.find((x) => x.type === FilterTypesEnum.Customer)?.filterKey,
            pocName:
                filterType === FilterTypesEnum.Poc
                    ? filter?.filterKey
                    : filters.find((x) => x.type === FilterTypesEnum.Poc)?.filterKey,
            status:
                filterType === FilterTypesEnum.AssetStatus
                    ? filter?.statusKey
                    : filters.find((x) => x.type === FilterTypesEnum.AssetStatus)?.statusKey
        };

        await refetchFilters(filterRequest);
    };

    const refetchFilters = async (filterRequest: FilterRequestModelInput) => {
        const variables = { input: filterRequest };
        await refetchPocsPerUser(variables);
        await refetchCustomerPerUser(variables);
        await refetchAssetOrderIds(variables);
    };

    return (
        <DropdownContainer>
            <StyledDropdown
                overlayClassName={'dropdown-overlay'}
                menu={{
                    items: customerItems ? [defaultSelectionItem, ...customerItems] : [],
                    onClick: onCustomerSelection
                }}>
                <Button>
                    {getLabelOrDefault(filters, FilterTypesEnum.Customer)}
                    <ChevronIcon />
                </Button>
            </StyledDropdown>

            <StyledDropdown
                overlayClassName={'dropdown-overlay'}
                menu={{
                    items: pocItems ? [defaultSelectionItem, ...pocItems] : [],
                    onClick: onPocSelection
                }}>
                <Button>
                    {getLabelOrDefault(filters, FilterTypesEnum.Poc)}
                    <ChevronIcon />
                </Button>
            </StyledDropdown>
            <StyledDropdown
                overlayClassName={'dropdown-overlay'}
                menu={{
                    items: assetOrderIdsItems ? [defaultSelectionItem, ...assetOrderIdsItems] : [],
                    onClick: onOrderIdSelection
                }}>
                <Button>
                    {getLabelOrDefault(filters, FilterTypesEnum.Order)}
                    <ChevronIcon />
                </Button>
            </StyledDropdown>
            <AssetStatusFilter
                assets={assets}
                onStatusSelection={onAssetStatusSelection}
                filters={filters}
            />
            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </DropdownContainer>
    );
};

export default AssetDropdownArea;
