import { StyledIconContainer } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
};

const IconContainer: React.FC<Props> = ({ children, className = '' }) => {
    return <StyledIconContainer className={className}>{children}</StyledIconContainer>;
};

export default IconContainer;
