import { useContext, useEffect, useRef } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SignaturePad from 'signature_pad';
import { useTheme } from 'styled-components';

import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import { ReactComponent as XCIcon } from '@/assets/svg-icons/xc-icon.svg';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import AppContext from '@/store/app-context/AppContext';
import { Container, FlexContainer, HeaderContainer } from '@/styles/shared/container/styles';
import { ListItemCardLabel, ListItemCardValue } from '@/styles/shared/list-item/styles';

import {
    SignAreaCard,
    SignAreaContainer,
    SignAreaContent,
    SignAreaDottedLine,
    SignAreaSignerName,
    StyledCanvas
} from './styles';

let signaturePad: SignaturePad;

const SigningArea: React.FC = () => {
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const { sign } = useContext(AppContext);
    const { state } = useLocation();
    const canvasRef = useRef(null);
    const theme = useTheme();

    const { signer, signerName, signatureIndex, title, email } = state;

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        const canvas = document.getElementById('signing-area') as HTMLCanvasElement;
        signaturePad = new SignaturePad(canvas, {
            minWidth: 1,
            maxWidth: 2,
            throttle: 0
        });

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleSign = () => {
        if (signaturePad.isEmpty()) {
            return message.error(t('SIGNATURE_REQUIRED'));
        }

        sign.addNewSignature({
            signature: signaturePad.toDataURL('image/png'),
            signer,
            signatureIndex
        });

        navigate(-1);
    };

    const handleClear = () => {
        signaturePad.clear();
    };

    return (
        <SignAreaContainer>
            <HeaderContainer>
                <StyledPageTitle>{t('ADD_SIGNATURE')}</StyledPageTitle>
                <CustomButton
                    label={t('DONE')}
                    buttonType={ButtonsTypesEnum.Main}
                    icon={<TickIcon />}
                    onClick={handleSign}
                />
            </HeaderContainer>

            <SignAreaContent>
                <FlexContainer $flexDirection="column">
                    <ListItemCardLabel label={title} />
                    <SignAreaSignerName>{signerName}</SignAreaSignerName>
                </FlexContainer>

                <Container $paddingX={theme?.space[0]} $marginBottom={theme?.space[12]}>
                    <ListItemCardLabel label={t('EMAIL')} />
                    <ListItemCardValue value={email} />
                </Container>

                <ListItemCardLabel label={t('SIGNATURE')} />
                <SignAreaCard>
                    <StyledCanvas id="signing-area" ref={canvasRef} width="386px" height="400px" />
                    <div></div>
                    <SignAreaDottedLine />
                </SignAreaCard>

                <CustomButton
                    label="Clear"
                    buttonType={ButtonsTypesEnum.Secondary}
                    icon={<XCIcon />}
                    onClick={handleClear}
                />
            </SignAreaContent>
        </SignAreaContainer>
    );
};

export default SigningArea;
