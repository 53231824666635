import { CloseOutlined } from '@ant-design/icons';

import { StyledCloseContainer } from './styles';

type Props = {
    xColor?: string;
    backgroundColor?: string;
    onClick: () => void;
};

const CloseModalBar: React.FC<Props> = ({ xColor, backgroundColor, onClick }) => {
    return (
        <StyledCloseContainer style={{ backgroundColor }}>
            <CloseOutlined onClick={onClick} style={{ color: xColor }} />
        </StyledCloseContainer>
    );
};

export default CloseModalBar;
