import { TableDataProvider } from '@/hooks/admin/useTableData';
import ThirdPartiesTable from '@/pages/admin/CommercialAgreements/ThirdParties/partials/ThirdPartiesTable';

const ThirdParties = () => (
    <TableDataProvider>
        <ThirdPartiesTable />
    </TableDataProvider>
);

export default ThirdParties;
