import { StyledModalFooter } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
    backgroundColor?: string;
};

const ModalFooter: React.FC<Props> = ({ children, className, backgroundColor }) => {
    return (
        <StyledModalFooter className={className} style={{ backgroundColor }}>
            {children}
        </StyledModalFooter>
    );
};

export default ModalFooter;
