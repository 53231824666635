const LOCAL_STORAGE_KEYS = {
    Token: 'token',
    RefreshToken: 'refreshToken',
    TokenExpirationTime: 'expirationTime',
    IsPassExpired: 'isPassExpired',
    AssetFilters: 'assetFilters',
    AgreementFilters: 'agreementFilters',
    Language: 'language',
    CommercialAgreement: 'CA',
    CustomerName: 'customerName'
};

export default LOCAL_STORAGE_KEYS;
