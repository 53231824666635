import { Fragment, useContext, useEffect, useState } from 'react';

import { SIGNATURE_ROLES } from '@constants/keys/signature-roles-keys';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { ProtocolDto, useCompleteProtocolMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import SignSection from '@/pages/shared/sign/SignSection';
import AppContext from '@/store/app-context/AppContext';
import {
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import {
    clearCommentFromStorage,
    getCommentFromStorage,
    getCurrentContractData
} from '@/utils/assetStorage';

const SignProtocol = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { setError, clearError, errorMessage } = useError();
    const { navbar, sign, protocol: protocolState } = useContext(AppContext);
    const [completeProtocol, { loading: completeProtocolLoading }] = useCompleteProtocolMutation();
    const [isSuccessModalShown, setIsSuccessModalOpened] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(false);

    const protocol = protocolState.currentProtocol ?? getCurrentContractData<ProtocolDto>();
    const POCSign = sign.signatures?.find(
        (signature) => signature.signer === SIGNATURE_ROLES.POC
    )?.signature;
    const SRSign = sign.signatures?.find(
        (signature) => signature.signer === SIGNATURE_ROLES.SALES_REPRESENTATIVE
    )?.signature;

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallback: sign.clearSigns
        });

        setValid(!!POCSign && !!SRSign);

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleFinish = () => {
        if (!valid) {
            return;
        }

        completeProtocol({
            variables: {
                input: {
                    customerEmail: protocol.customerEmail,
                    pocPersonnelName: protocol.pocPersonnelName!,
                    protocolId: protocol.id,
                    srSignature: SRSign!,
                    pocPersonnelSignature: POCSign!,
                    protocolMaterials: protocol.materials.map((material) => {
                        return { protocolMaterialId: material.id, quantity: material.quantity };
                    }),
                    comment: getCommentFromStorage(protocol.id)
                }
            }
        })
            .then(() => {
                setIsSuccessModalOpened(true);
                clearCommentFromStorage(protocol.id);
            })
            .catch((error) => {
                setError(error, ErrorTypeEnum.ApolloError);
            });
    };
    const handleConfirmSuccess = () => {
        sign.clearSigns();
        setIsSuccessModalOpened(false);
        navigate(ROUTES.FullPaths.AssetList);
    };

    return (
        <Fragment>
            <ScrollableContainer>
                <HeaderContainer $padding={theme?.space[0]}>
                    <StyledPageTitle $marginY={theme?.space[0]}>
                        {t('SIGN_PROTOCOL')}
                    </StyledPageTitle>
                    <CustomButton
                        label={t('FINISH_AND_SEND')}
                        buttonType={
                            POCSign && SRSign ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled
                        }
                        icon={<TickIcon />}
                        onClick={handleFinish}
                        loading={completeProtocolLoading}
                    />
                </HeaderContainer>

                <Content>
                    <FlexContainer $justify="center" $marginBottom={theme?.space[32]}>
                        <InfoMessage message={t('SIGN_SUBTITLE')} />
                    </FlexContainer>

                    <SignSection
                        signatureIndex={1}
                        sectionTitle={t('SALES_REPRESENTATIVE')}
                        signer={SIGNATURE_ROLES.SALES_REPRESENTATIVE}
                        name={protocol.srCyrillicName}
                        email={protocol.srEmail}
                        sign={SRSign}
                    />
                    <SignSection
                        signatureIndex={1}
                        sectionTitle={t('POC_EMPLOYEE')}
                        signer={SIGNATURE_ROLES.POC}
                        name={protocol.pocPersonnelName!}
                        email={protocol.customerEmail!}
                        sign={POCSign}
                    />
                </Content>
            </ScrollableContainer>

            {isSuccessModalShown && (
                <SuccessModal
                    title={t('SUCCESSFULLY_SIGNED')}
                    message={t('PROTOCOL_FINISHED_AND_SENT')}
                    onConfirm={handleConfirmSuccess}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Fragment>
    );
};

export default SignProtocol;
