import { Fragment, useContext, useEffect, useState } from 'react';

import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import ErrorMessage from '@/components/shared/message/ErrorMessage';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import envconfig from '@/config/envconfig';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { AgreementDetailsDto } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { useUserContext } from '@/store/user/useUserContext';
import { Container, HeaderContainer, ScrollableContainer } from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';
import { getToken } from '@/utils/authorizationUtils';

import AGREEMENT_PREVIEW_STYLE from './preview-style';

const AgreementPreview: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const { isF2RCMC } = useUserContext();
    const { navbar, agreementDetails } = useContext(AppContext);
    const { setError, clearError, errorMessage } = useError();

    const [data, setData] = useState<any>(); // eslint-disable-line
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const currentAgreement =
        agreementDetails.currentAgreementDetails ?? getCurrentContractData<AgreementDetailsDto>();
    const sanitizedHTML = DOMPurify.sanitize(data);

    document.body.style.width = 'unset';
    document.body.style.height = 'unset';

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        const requestUrl = envconfig.previewAgreementUrl;

        fetch(envconfig.apiAddress + requestUrl, {
            headers: {
                Authorization: `bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(currentAgreement)
        })
            .then(async (res) => {
                const data = await res.text();
                setData(data);
                setIsLoading(false);
                clearError();
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error, ErrorTypeEnum.Error);
            });

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleSign = () => {
        if (isF2RCMC) {
            navigate(ROUTES.AgreementList.AgreementPreview.Validate.Path);

            return;
        }

        navigate(ROUTES.AgreementList.AgreementPreview.Sign.Path);
    };

    return (
        <Fragment>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <ScrollableContainer $height={90}>
                <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[0]}>
                    <StyledPageTitle>
                        {isF2RCMC ? t('VALIDATE_CA') : t('PREVIEW_CA')}
                    </StyledPageTitle>
                </HeaderContainer>

                <SpinnerContainer loading={isLoading}>
                    {data && <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />}
                </SpinnerContainer>
            </ScrollableContainer>
            <Container>
                <CustomButton
                    icon={<SignIcon />}
                    onClick={handleSign}
                    title={
                        !currentAgreement?.pocEmployee && !isF2RCMC
                            ? t('MISSING_POC_EMPLOYEE')
                            : undefined
                    }
                    label={isF2RCMC ? t('VALIDATE') : t('SIGN')}
                    buttonType={
                        !currentAgreement.pocEmployee && !isF2RCMC
                            ? ButtonsTypesEnum.Disabled
                            : ButtonsTypesEnum.Main
                    }
                    style={{ width: '100%' }}
                />
            </Container>
            <style id="front-end-style">{AGREEMENT_PREVIEW_STYLE}</style>
        </Fragment>
    );
};

export default AgreementPreview;
