import { ApolloQueryResult } from '@apollo/client';
import { Button, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    DistributorDto,
    GetDistributorsQuery,
    useGetZonesWithSupervisorsQuery,
    useUpdateDistributorMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { EditableColumn } from '@/types/admin/Table';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetDistributorsQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();

    const [updateDistributorMutation] = useUpdateDistributorMutation({
        onError: (error) => {
            message.error(t(error.message));
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: async () => {
            message.success(t('DISTRIBUTOR_UPDATED_SUCCESSFULLY'));
            await onChangeSuccess();
        }
    });
    const { data: allZones } = useGetZonesWithSupervisorsQuery({
        fetchPolicy: 'network-only',
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const colDef: Array<EditableColumn<DistributorDto>> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            width: '25%',
            editable: true,
            sorter: true
        },
        {
            key: 'zones',
            width: '50%',
            title: t('ZONES'),
            render: (_, { id, zones: zonesData }) => (
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('SELECT_ZONES')}
                    optionFilterProp="label"
                    defaultValue={zonesData.map((zone) => zone.id)}
                    onChange={(value) => {
                        updateDistributorMutation({
                            variables: { input: { distributorId: id, zones: value } }
                        });
                    }}
                    options={allZones?.zonesWithSupervisors?.map((zone) => ({
                        value: zone?.id,
                        label: zone?.name
                    }))}
                />
            )
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            width: '25%',
            sorter: true,
            render: (_, { id, isActive }) => {
                return (
                    <Button
                        key={`deactivate-${id as string}`}
                        danger={isActive}
                        onClick={async () =>
                            await updateDistributorMutation({
                                variables: { input: { isActive: !isActive, distributorId: id } }
                            })
                        }>
                        {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                    </Button>
                );
            }
        }
    ];

    return { colDef };
};

export default useColDefs;
