import styled from 'styled-components';

import CustomButton from '@/components/shared/UI/CustomButton';

export const CreateMaterialQuantityContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: ${({ theme }) => theme.space[56]};
    margin-top: ${({ theme }) => theme.space[4]};

    input {
        height: 100%;
        text-align: center;
    }
`;

const CreateMaterialArrows = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: ${({ theme }) => theme.space[32]};
    height: ${({ theme }) => theme.space[32]};
    background-color: ${({ theme }) => theme.color.blue[15]};
    box-shadow: ${({ theme }) => theme.shadow.lightShadow};
    border-radius: 4px;
    z-index: 1000;
`;

export const StyledCreateMaterialLeftArrow = styled(CreateMaterialArrows)`
    left: 0;
    margin-left: ${({ theme }) => theme.space[12]};
`;

export const StyledCreateMaterialRightArrow = styled(CreateMaterialArrows)`
    right: 0;
    margin-right: ${({ theme }) => theme.space[12]};
`;

export const StyledCreateMaterialButton = styled(CustomButton)`
    width: 200px;
`;
