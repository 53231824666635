import { Divider, Row, Typography } from 'antd';
import { DefaultTFuncReturn } from 'i18next';

type SectionHeadingProps = {
    heading: string | DefaultTFuncReturn;
};

const SectionHeading = ({ heading }: SectionHeadingProps) => {
    const { Title } = Typography;

    return (
        <Row>
            <Title level={3} style={{ marginTop: '30px', marginBottom: '5px' }}>
                {heading}
            </Title>
            <Divider style={{ marginTop: '10px' }} />
        </Row>
    );
};

export default SectionHeading;
