import { useTheme } from 'styled-components';

import svg from '@/assets/svgs/logo-text-left.svg';
import AppLogo from '@/components/shared/logo/AppLogo';
import { LogoTypeEnum } from '@/enums/logo-type.enum';

import { StyledLogoWrapper } from '../../styles';

const Logo: React.FC = () => {
    const theme = useTheme();

    return (
        <StyledLogoWrapper>
            <AppLogo
                logoType={LogoTypeEnum.NavbarLogo}
                path={svg}
                labelSize={theme?.fontSize.xlg}
            />
        </StyledLogoWrapper>
    );
};

export default Logo;
