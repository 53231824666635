import { useEffect, useState } from 'react';

import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { DocumentStatusEnum } from '@/graphql/main';

type Props = {
    status: string;
};

const AssetStatusTag: React.FC<Props> = ({ status }) => {
    const { t } = useTranslation();
    const [color, setColor] = useState('');

    useEffect(() => {
        if (status === DocumentStatusEnum.Signed) {
            setColor('green');
        }

        if (status === DocumentStatusEnum.Prepared) {
            setColor('gold');
        }

        if (status === DocumentStatusEnum.Canceled) {
            setColor('red');
        }
        if (status === DocumentStatusEnum.ForValidation) {
            setColor('gold');
        }
        if (status === DocumentStatusEnum.Draft) {
            setColor('gray');
        }
        if (status === DocumentStatusEnum.Done) {
            setColor('blue');
        }
    }, [status]);

    return (
        <Tag color={color} key={status}>
            {t(`${status}_SIMPLIFIED`).toUpperCase()}
        </Tag>
    );
};

export default AssetStatusTag;
