export const fontSize = {
    h0: '30px',
    h1: '25px',
    h2: '24px',
    h3: '22px',
    h4: '20px',
    h5: '18px',
    h6: '16px',
    xxlg: '14px',
    xlg: '12px',
    lg: '11px',
    m: '10px',
    s: '9px',
    xsm: '8px'
};
