import React, { Fragment, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Input, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';
import { useSearchEmployeeEmailsQuery, useShareProtocolsMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';

import CustomButton from '../../UI/CustomButton';
import { StyledSubtitle } from '../../UI/CustomSubtitle/styles';
import { StyledPageTitle } from '../../UI/PageTitle/styles';
import SpinnerContainer from '../../UI/SpinnerContainer';
import ErrorModal from '../ErrorModal';
import CloseModalBar from '../partials/CloseModalBar';
import ModalBody from '../partials/ModalBody';
import ModalContainer from '../partials/ModalContainer';
import ModalFooter from '../partials/ModalFooter';
import ModalHeader from '../partials/ModalHeader';
import SuccessModal from '../SuccessModal';

import {
    ShareModalDropdownMenu,
    ShareModalNoContentContainer,
    ShareModalNoContentLabel,
    ShareModalSearchContainer
} from './styles';

type Props = {
    close: (closeReason?: ModalCloseReasonEnum) => void;
    assetsIds: number[];
};

const ShareModal: React.FC<Props> = ({ close, assetsIds }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const defaultSelection = t('CHOOSE_EMPLOYEE');
    const { setError, clearError, errorMessage } = useError();

    const [emails, setEmails] = useState<string[]>([]);
    const [selectedEmail, setSelectedEmail] = useState<string>(defaultSelection);
    const [shareLoading, setShareLoading] = useState<boolean>(false);
    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [multipleProtocolsShare] = useShareProtocolsMutation();

    const { refetch: refechSearch } = useSearchEmployeeEmailsQuery({
        variables: {
            input: ''
        },
        onCompleted: (data) => {
            const emails = data.srEmailSearch.map((sr) => sr.email);

            if (!!emails && emails.length > 0) {
                setEmails(emails);
                clearError();
            }
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    const emailItems: MenuProps['items'] = emails?.map((email: string) => {
        return {
            label: email,
            key: email
        };
    });

    const handleEmailSelected: MenuProps['onClick'] = ({ key }) => {
        setSelectedEmail(key);
    };

    const handleShare = () => {
        setShareLoading(true);
        multipleProtocolsShare({
            variables: {
                input: {
                    srEmail: selectedEmail,
                    protocolIds: assetsIds
                }
            }
        })
            .then(() => {
                setShareLoading(false);
                setIsSuccessModalOpened(true);
            })
            .catch((error) => {
                setShareLoading(false);
                setError(error, ErrorTypeEnum.ApolloError);
            });
    };

    const handleEmailSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = e.target.value;

        setIsLoading(true);

        refechSearch({
            input: searchString
        })
            .then((response) => {
                setIsLoading(false);
                const emails = response.data.srEmailSearch.map((sr) => sr.email);

                setEmails(emails);
                clearError();
            })
            .catch((error) => {
                setError(error, ErrorTypeEnum.ApolloError);
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            {!errorMessage && !isSuccessModalOpened && (
                <ModalContainer>
                    <CloseModalBar
                        backgroundColor={theme?.color.default.white}
                        xColor={'black'}
                        onClick={() => close(ModalCloseReasonEnum.Close)}
                    />
                    <ModalHeader backgroundColor={theme?.color.default.white}>
                        <StyledPageTitle>{t('SHARE_PROTOCOLS')}</StyledPageTitle>
                        <StyledSubtitle>{t('SHARE_PROTOCOLS_MESSAGE')}</StyledSubtitle>
                    </ModalHeader>
                    <ModalBody backgroundColor={theme?.color.default.white}>
                        <ShareModalDropdownMenu
                            menu={{
                                items: emailItems,
                                onClick: handleEmailSelected
                            }}
                            dropdownRender={(menu) => (
                                <ShareModalSearchContainer>
                                    <Input
                                        placeholder={t('SEARCH')}
                                        size="large"
                                        onInput={handleEmailSearch}
                                    />
                                    <SpinnerContainer loading={isLoading}>
                                        {emails.length > 0 ? (
                                            React.cloneElement(menu as React.ReactElement)
                                        ) : emails.length === 0 ? (
                                            <ShareModalNoContentContainer>
                                                <ShareModalNoContentLabel>
                                                    {t('NO_SUCH_EMAIL_FOUND')}
                                                </ShareModalNoContentLabel>
                                            </ShareModalNoContentContainer>
                                        ) : (
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <></>
                                        )}
                                    </SpinnerContainer>
                                </ShareModalSearchContainer>
                            )}>
                            <Button>
                                {selectedEmail}
                                <DownOutlined />
                            </Button>
                        </ShareModalDropdownMenu>
                    </ModalBody>

                    <ModalFooter backgroundColor={theme?.color.default.white}>
                        <CustomButton
                            loading={shareLoading}
                            onClick={handleShare}
                            label={t('SHARE')}
                            buttonType={
                                selectedEmail === defaultSelection
                                    ? ButtonsTypesEnum.Disabled
                                    : ButtonsTypesEnum.Main
                            }
                            style={{ width: '100%' }}
                        />
                    </ModalFooter>
                </ModalContainer>
            )}
            {errorMessage && (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => close(ModalCloseReasonEnum.Error)}
                />
            )}
            {isSuccessModalOpened && (
                <SuccessModal
                    message={t('SHARE_SUCCESSFUL')}
                    onConfirm={() => close(ModalCloseReasonEnum.Success)}
                />
            )}
        </Fragment>
    );
};

export default ShareModal;
