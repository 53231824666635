import { useContext, useEffect, useState } from 'react';

import routes from '@constants/routes/routes';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import ErrorModal from '@/components/shared/modal/ErrorModal';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    AgreementDetailsDto,
    AgreementDto,
    DocumentStatusEnum,
    useGetAgreementDetailsQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { getCacheVal } from '@/services/utils/cacheUtils';
import AppContext from '@/store/app-context/AppContext';
import { FlexContainer, ScrollableContainer } from '@/styles/shared/container/styles';

import AdditionalDocuments from './partials/AdditionalDocuments';
import AgreementDetailsCard from './partials/AgreementDetailsCard';
import AgreementDetailsSummary from './partials/AgreementDetailsSummary';

const AgreementDetails: React.FC = () => {
    const { navbar, agreementDetails: ctxAgreementDetails } = useContext(AppContext);
    const theme = useTheme();
    const { agreementId } = useParams();
    const { setError, clearError, errorMessage } = useError();
    const [currentAgreement, setCurrentAgreement] = useState<AgreementDto>();
    const [agreementDetails, setAgreementDetails] = useState<AgreementDetailsDto>();
    const [agreementStatus, setAgreementStatus] = useState<DocumentStatusEnum>();
    const navigate = useNavigate();

    useEffect(() => {
        agreementId === getCacheVal(LOCAL_STORAGE_KEYS.CommercialAgreement)?.agreementId &&
            setCurrentAgreement(getCacheVal(LOCAL_STORAGE_KEYS.CommercialAgreement));
    }, [agreementId]);

    const { loading, refetch } = useGetAgreementDetailsQuery({
        fetchPolicy: 'network-only',
        variables: {
            agreementId: currentAgreement?.agreementId ?? Number(agreementId)
        },
        onCompleted: (data) => {
            setAgreementDetails(data?.agreementDetails as AgreementDetailsDto);
            setAgreementStatus(data?.agreementDetails.status);
            ctxAgreementDetails.setAgreementDetails(data?.agreementDetails as AgreementDetailsDto);

            clearError();
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallOverrideBackFunctionality: () => navigate(routes.FullPaths.AgreementList)
        });
    }, []); // eslint-disable-line

    const loadingSpinner = (
        <FlexContainer $justify="center" $marginTop={theme?.space[100]}>
            <Spin />
        </FlexContainer>
    );

    return (
        <>
            <ScrollableContainer>
                {!loading && agreementDetails ? (
                    <>
                        <AgreementDetailsCard
                            agreementDetails={agreementDetails}
                            refetchAgreementDetails={refetch}
                        />
                        <AgreementDetailsSummary
                            agreementDetails={agreementDetails}
                            isEditMode={true}
                            refetchAgreementDetails={refetch}
                        />
                        {agreementStatus !== DocumentStatusEnum.ForValidation && (
                            <AdditionalDocuments
                                refetchAgreementDetails={refetch}
                                agreementStatus={agreementStatus}
                            />
                        )}
                    </>
                ) : (
                    loadingSpinner
                )}
            </ScrollableContainer>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </>
    );
};

export default AgreementDetails;
