import styled, { keyframes } from 'styled-components';

const slideDown = keyframes`
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledModalOverlay = styled.div`
    position: fixed;
    top: 25%;
    width: 90%;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: ${slideDown} 300ms ease-out forwards;
    display: flex;
    justify-content: center;
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
