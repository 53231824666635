import { useState } from 'react';

import { AutoComplete, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchButton from '@/components/admin/AdminButtons/SearchButton';
import DateRangePicker from '@/components/shared/UI/DateRangePicker';
import {
    AdminAssetFilterRequestModelInput,
    DocumentStatusEnum,
    useGetAdminAssetStatusesQuery
} from '@/graphql/main';

import useFilters from '../../hooks/useFilters';
import { assetStatuses, assetTypes, defaultFilterState, materialTypes } from '../../utils';

import { StyledAssetFilterContainer } from './styles';

type Props = {
    onSearch: (filterValues: AdminAssetFilterRequestModelInput) => void;
};

const AssetsFilters: React.FC<Props> = ({ onSearch }) => {
    const { t } = useTranslation('translation');
    const [form] = Form.useForm<AdminAssetFilterRequestModelInput>();
    const {
        searchCustomers,
        customers,
        searchPocsHermesIds,
        pocIds,
        searchEmployeesByName,
        employees
    } = useFilters();
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const { data } = useGetAdminAssetStatusesQuery();
    const handleSearch = () => {
        const values = form.getFieldsValue();

        const filterValues: AdminAssetFilterRequestModelInput = {
            ...values,
            fromDate,
            toDate
        };

        onSearch(filterValues);
    };

    const formItemStyle = {
        minWidth: 180
    };

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={defaultFilterState}
            onFinish={handleSearch}
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                <Form.Item name="assetType" label={t('ASSET_TYPE')} style={formItemStyle}>
                    <Select
                        allowClear={true}
                        placeholder={t('ASSET_TYPE')}
                        options={assetTypes.map((v) => ({
                            value: v.toString(),
                            label: t(`ASSET_TYPE_${v}`)
                        }))}
                    />
                </Form.Item>
                <Form.Item name="status" label={t('ASSET_STATUS')} style={formItemStyle}>
                    <Select
                        allowClear={true}
                        placeholder={t('ASSET_STATUS')}
                        options={
                            data?.adminAssetStatuses.length
                                ? data?.adminAssetStatuses.map((v: DocumentStatusEnum) => ({
                                      value: v,
                                      label: t(`${v}_SIMPLIFIED`)
                                  }))
                                : assetStatuses.map((v) => ({
                                      value: v,
                                      label: t(`${v}_SIMPLIFIED`)
                                  }))
                        }
                    />
                </Form.Item>
                <Form.Item name="customer" label={t('CUSTOMER')} style={formItemStyle}>
                    <AutoComplete
                        allowClear={true}
                        placeholder={t('CUSTOMER')}
                        options={customers}
                        onSearch={searchCustomers}
                    />
                </Form.Item>
                <Form.Item name="pocId" label={t('POC_ID')} style={formItemStyle}>
                    <AutoComplete
                        allowClear={true}
                        placeholder={t('POC_ID')}
                        options={pocIds}
                        onSearch={searchPocsHermesIds}
                    />
                </Form.Item>
                <Form.Item name="employee" label={t('EMPLOYEE')} style={formItemStyle}>
                    <AutoComplete
                        allowClear={true}
                        placeholder={t('EMPLOYEE')}
                        options={employees}
                        onSearch={searchEmployeesByName}
                    />
                </Form.Item>
                <Form.Item name="materialType" label={t('MATERIAL_TYPE')} style={formItemStyle}>
                    <Select
                        allowClear={true}
                        placeholder={t('MATERIAL_TYPE')}
                        options={materialTypes.map((materialType) => ({
                            value: materialType,
                            label: t(materialType)
                        }))}
                    />
                </Form.Item>
            </div>
            <StyledAssetFilterContainer>
                <Form.Item label={t('DATE_SIGNED')}>
                    <DateRangePicker setFromDate={setFromDate} setToDate={setToDate} />
                </Form.Item>

                <Form.Item>
                    <SearchButton onClick={handleSearch} />
                </Form.Item>
            </StyledAssetFilterContainer>
        </Form>
    );
};

export default AssetsFilters;
