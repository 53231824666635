import { Navigate, Outlet } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';
import { useUserContext } from '@/store/user/useUserContext';

const ProtectedSRRoute = () => {
    const {
        permissions: { canAccessSRRoutes, canAccessAdminRoutes }
    } = useUserContext();

    // Don't redirect unless we have received the role
    if (!canAccessSRRoutes && !canAccessAdminRoutes) {
        return null;
    }

    // If we have the role, redirect according to that
    return canAccessSRRoutes ? <Outlet /> : <Navigate to={`../${ROUTES.FullPaths.AdminUsers}`} />;
};
export default ProtectedSRRoute;
