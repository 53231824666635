import { ApolloQueryResult } from '@apollo/client';
import { Button, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    GetPaymentFrequenciesQuery,
    useChangePaymentFrequencyIsActiveMutation,
    PaymentFrequency
} from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetPaymentFrequenciesQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();

    const [changeIsActiveMutation] = useChangePaymentFrequencyIsActiveMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await onChangeSuccess();
        }
    });

    const handleTogglePaymentFrequencyStatus = async ({
        id,
        isActive
    }: {
        id: PaymentFrequency['id'];
        isActive: PaymentFrequency['isActive'];
    }) => {
        try {
            await changeIsActiveMutation({ variables: { id, isActive: !isActive } });
            message.success(t(isActive ? 'DEACTIVATED_SUCCESS' : 'ACTIVATED_SUCCESS'));
        } catch (error) {
            setError(error, ErrorTypeEnum.ApolloError);
            message.error(t(isActive ? 'DEACTIVATE_FAIL' : 'ACTIVATED_FAIL'));
        }
    };

    const colDef: ColumnsType<PaymentFrequency> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            sortDirections: ['descend', 'ascend'],
            sorter: true
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            dataIndex: 'active',
            sorter: true,
            render: (_, { id, isActive }) => (
                <Button
                    key={`deactivate-${id as string}`}
                    danger={isActive}
                    onClick={async () =>
                        await handleTogglePaymentFrequencyStatus({ id, isActive })
                    }>
                    {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                </Button>
            )
        }
    ];

    return { colDef };
};

export default useColDefs;
