import { useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as AgreementAssetIcon } from '@/assets/svg-icons/agreement-asset-icon.svg';
import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { SuccessModalTypeEnum } from '@/enums/success-modal-type.enum';
import {
    AgreementAttributeValueDto,
    AgreementDetailsDto,
    AgreementReasonEnum,
    AttributeTypeEnum,
    AttributeValuesInput,
    DocumentStatusEnum,
    GetAgreementDetailsQuery,
    GetAgreementDetailsQueryVariables,
    useUpdateAgreementMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { AttributeValueTypesEnum, FormStateType } from '@/pages/agreement/CreateAgreement';
import AddAgreementDetailsModal from '@/pages/agreement/CreateAgreement/partials/AddAgreementDetailsModal';
import {
    Container,
    FlexAlignCenter,
    FlexContainer,
    HeaderContainer
} from '@/styles/shared/container/styles';
import {
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardValue
} from '@/styles/shared/list-item/styles';
import { formatDate } from '@/utils/formatDate';

type PartialAgreementDetailsDto = Pick<
    AgreementDetailsDto,
    | 'type'
    | 'subject'
    | 'reason'
    | 'agreementDate'
    | 'period'
    | 'attributeValues'
    | 'status'
    | 'dateTo'
    | 'dateFrom'
    | 'invoicesAmount'
    | 'freeBeerQuantity'
>;
type Props = {
    agreementDetails: AgreementDetailsDto | PartialAgreementDetailsDto;
    showHeader?: boolean;
    isEditMode?: boolean;
    refetchAgreementDetails?: (
        variables?: GetAgreementDetailsQueryVariables | undefined
    ) => Promise<ApolloQueryResult<GetAgreementDetailsQuery>>;
};
const initialFormState = {
    firstStep: {
        type: '',
        subject: '',
        subjectList: [],
        reason: '',
        reasonList: [],
        agreementTree: [],
        agreementDate: '',
        dateTo: '',
        dateFrom: ''
    },
    secondStep: []
};

const AgreementDetailsSummary: React.FC<Props> = ({
    agreementDetails,
    showHeader = true,
    isEditMode = false,
    refetchAgreementDetails
}) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [updateAgreement] = useUpdateAgreementMutation();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { setError, clearError, errorMessage } = useError();

    const [formState, setFormState] = useState<FormStateType>(initialFormState);
    const [originalFormState, setOriginalFormState] = useState<FormStateType>(initialFormState);
    const [agreementDefinitionId, setAgreementDefinitionId] = useState<number | null>(null);
    const { type, subject, reason, agreementDate, attributeValues, status, dateTo, dateFrom } =
        agreementDetails;

    useEffect(() => {
        const fillFormOnEditWithValues = () => {
            const agreementDetailsAsDto = agreementDetails as AgreementDetailsDto;
            setFormState({
                firstStep: {
                    type: agreementDetailsAsDto.type,
                    subject: agreementDetailsAsDto.subject,
                    subjectList: [],
                    reason: agreementDetailsAsDto.reason,
                    reasonList: [],
                    agreementTree: [],
                    agreementDate: agreementDetailsAsDto.agreementDate,
                    dateTo: agreementDetailsAsDto.dateTo,
                    dateFrom: agreementDetailsAsDto.dateFrom
                },
                secondStep: getAgreementValuesFromDto()
            });
            setOriginalFormState({
                firstStep: {
                    type: agreementDetailsAsDto.type,
                    subject: agreementDetailsAsDto.subject,
                    subjectList: [],
                    reason: agreementDetailsAsDto.reason,
                    reasonList: [],
                    agreementTree: [],
                    agreementDate: agreementDetailsAsDto.agreementDate,
                    dateTo: agreementDetailsAsDto.dateTo,
                    dateFrom: agreementDetailsAsDto.dateFrom
                },
                secondStep: getAgreementValuesFromDto()
            });
        };
        isEditMode && fillFormOnEditWithValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleAgreementSubmit = () => {
        const filteredFields = filterOnlyUpdatedAttributes();
        updateAgreement({
            variables: {
                input: transformUpdateAgreementData(
                    isDefinitionChanged() ? formState.secondStep : filteredFields
                )
            },
            onCompleted: (data) => {
                refetchAgreementDetails?.();
                setOriginalFormState(formState);
                setIsSuccess(true);
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };
    const filterOnlyUpdatedAttributes = () => {
        return formState.secondStep.filter(
            (ar) =>
                !originalFormState.secondStep.find(
                    (rm) =>
                        rm.value === ar.value &&
                        ar.attributeId === rm.attributeId &&
                        ar.type === rm.type &&
                        ar.label === rm.label
                )
        );
    };

    const isDefinitionChanged = () => {
        return (
            agreementDetails.type !== formState.firstStep.type ||
            agreementDetails.subject !== formState.firstStep.subject ||
            agreementDetails.reason !== formState.firstStep.reason
        );
    };

    const transformUpdateAgreementData = (
        filteredSecondStepData: Array<
            Pick<AttributeValuesInput, 'attributeId' | 'type'> & {
                key: string;
                value?: string;
                booleanValue?: boolean;
                id?: number;
                order: number;
                label: string;
            }
        >
    ) => {
        const { agreementDate, dateFrom, dateTo } = formState.firstStep;
        const agreementDetailsAsDto = agreementDetails as AgreementDetailsDto;
        const transformedAttributes = filteredSecondStepData.map((item) => {
            const { value, booleanValue, attributeId, type, id: enumId } = item;
            let attributeValueType;
            let attributeValue;
            switch (type) {
                case AttributeTypeEnum.Int:
                    attributeValueType = AttributeValueTypesEnum.Int;
                    attributeValue = value && parseInt(value, 10);
                    break;
                case AttributeTypeEnum.Decimal:
                    attributeValueType = AttributeValueTypesEnum.Decimal;
                    attributeValue = value && parseFloat(parseFloat(value).toFixed(2));
                    break;
                case AttributeTypeEnum.Enum:
                    attributeValueType = AttributeValueTypesEnum.Enum;
                    attributeValue = enumId;
                    break;
                case AttributeTypeEnum.String:
                    attributeValueType = AttributeValueTypesEnum.String;
                    attributeValue = value;
                    break;
                case AttributeTypeEnum.Boolean:
                    attributeValueType = AttributeValueTypesEnum.Boolean;
                    attributeValue = booleanValue;
                    break;
                default:
                    throw new Error(`Unexpected type: ${item.type}`);
            }

            return {
                attributeId,
                type,
                [attributeValueType]: attributeValue
            };
        });
        if (isDefinitionChanged()) {
            return {
                id: agreementDetailsAsDto.agreementId,
                agreementDefinitionId,
                agreementDate,
                dateFrom,
                dateTo,
                attributeValues: transformedAttributes
            };
        } else {
            return {
                id: agreementDetailsAsDto.agreementId,
                agreementDate,
                dateFrom,
                dateTo,
                attributeValues: transformedAttributes
            };
        }
    };
    const handleAgreementDefinitionId = (id: number) => setAgreementDefinitionId(id);
    const getFieldValueForType = (field: AgreementAttributeValueDto) => {
        let attributeValueType;
        let attributeValue;
        switch (field.type) {
            case AttributeTypeEnum.Int:
                attributeValueType = AttributeValueTypesEnum.Int;
                attributeValue = field.intValue;
                break;
            case AttributeTypeEnum.Decimal:
                attributeValueType = AttributeValueTypesEnum.Decimal;
                attributeValue = field.decimalValue && parseFloat(field.decimalValue).toFixed(2);
                break;
            case AttributeTypeEnum.Enum:
                attributeValueType = AttributeValueTypesEnum.Enum;
                attributeValue = field.enumValue?.value;
                break;
            case AttributeTypeEnum.String:
                attributeValueType = AttributeValueTypesEnum.String;
                attributeValue = field.stringValue;
                break;
            case AttributeTypeEnum.Boolean:
                attributeValueType = AttributeValueTypesEnum.Boolean;
                attributeValue = field.boolValue;
                break;
            default:
                throw new Error(`Unexpected type: ${field.type}`);
        }

        return attributeValue;
    };
    const handleFormState = (
        values: Pick<FormStateType, 'firstStep'> | Pick<FormStateType, 'secondStep'>
    ) => {
        setFormState((prev) => ({ ...prev, ...values }));
    };
    const getAgreementValuesFromDto = () => {
        if (
            agreementDetails.attributeValues.findIndex(
                (attribute) => attribute?.attributeId === 13
            ) >= 0
        ) {
            return [
                ...agreementDetails.attributeValues.map((attribute) => ({
                    attributeId: attribute.attributeId,
                    label: attribute.label,
                    type: attribute.type,
                    order: attribute.order,
                    key: attribute.label,
                    value: getFieldValueForType(attribute)
                })),
                ...[
                    {
                        attributeId: 14,
                        order: 4,
                        label: 'TARGET_SKU_2',
                        type: AttributeTypeEnum.Enum,
                        key: 'TARGET_SKU_2',
                        value: ''
                    },
                    {
                        attributeId: 15,
                        order: 6,
                        label: 'TARGET_SKU_3',
                        type: AttributeTypeEnum.Enum,
                        key: 'TARGET_SKU_3',
                        value: ''
                    },
                    {
                        attributeId: 17,
                        order: 5,
                        label: 'NUMBER_OF_TARGET_SKU_2',
                        type: AttributeTypeEnum.Int,
                        key: 'NUMBER_OF_TARGET_SKU_2',
                        value: undefined
                    },
                    {
                        attributeId: 18,
                        order: 7,
                        label: 'NUMBER_OF_TARGET_SKU_3',
                        type: AttributeTypeEnum.Int,
                        key: 'NUMBER_OF_TARGET_SKU_3',
                        value: undefined
                    }
                ]
            ];
        } else {
            return agreementDetails.attributeValues.map((attribute) => ({
                attributeId: attribute.attributeId,
                label: attribute.label,
                type: attribute.type,
                order: attribute.order,
                key: attribute.label,
                value: getFieldValueForType(attribute)
            }));
        }
    };

    return (
        <>
            {showHeader && (
                <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[8]}>
                    <h3>{t('CA_DETAILS')} </h3>
                    {status === DocumentStatusEnum.Draft && (
                        <AddAgreementDetailsModal
                            isEditMode={true}
                            formState={formState}
                            oldFormState={originalFormState}
                            handleFormState={handleFormState}
                            isDefinitionChanged={isDefinitionChanged}
                            handleAgreementDefinitionId={handleAgreementDefinitionId}
                            submitEditedData={handleAgreementSubmit}
                        />
                    )}
                </HeaderContainer>
            )}

            <Container
                $paddingX={theme?.space[24]}
                $paddingY={theme?.space[16]}
                $bgColor={theme?.color.default.white}>
                <ListItemCardRow>
                    <div>
                        <ListItemCardLabel direction="column" label={t('TYPE')} />
                        <FlexAlignCenter $gap={theme?.space[4]}>
                            <AgreementAssetIcon />
                            <ListItemCardValue
                                $fontWeight={700}
                                direction="column"
                                value={t(type) ?? undefined}
                            />
                        </FlexAlignCenter>
                    </div>
                </ListItemCardRow>
                <FlexContainer
                    $flexDirection="column"
                    $gap={theme?.space[16]}
                    $marginBottom={theme?.space[36]}>
                    <div>
                        <ListItemCardLabel direction="column" label={t('AGREEMENT_SUBJECT')} />
                        <ListItemCardValue direction="column" value={t(subject)} />
                    </div>
                    <div>
                        <ListItemCardLabel direction="column" label={t('AGREEMENT_REASON')} />
                        <ListItemCardValue direction="column" value={t(reason) ?? undefined} />
                    </div>
                </FlexContainer>
                <ListItemCardRow $marginBottom={theme?.space[36]}>
                    <div>
                        <ListItemCardLabel direction="column" label={t('DATE')} />

                        <FlexAlignCenter $gap={theme?.space[4]}>
                            <CalendarIcon />
                            <ListItemCardValue
                                direction="column"
                                value={formatDate(agreementDate) ?? undefined}
                            />
                        </FlexAlignCenter>
                    </div>
                    <div>
                        <ListItemCardLabel direction="column" label={t('PERIOD')} />
                        <ListItemCardValue
                            direction="column"
                            value={`${formatDate(dateFrom)} - ${formatDate(dateTo)}`}
                        />
                    </div>
                </ListItemCardRow>
                {attributeValues?.map((attribute, idx) => {
                    let value;

                    switch (attribute.type) {
                        case AttributeTypeEnum.Int:
                            value = attribute.intValue;
                            break;
                        case AttributeTypeEnum.Decimal:
                            value = attribute.decimalValue;
                            break;
                        case AttributeTypeEnum.Enum:
                            value = attribute.enumValue?.value;
                            break;
                        case AttributeTypeEnum.String:
                            value = attribute.stringValue;
                            break;
                        case AttributeTypeEnum.Boolean:
                            value = attribute.boolValue;
                            break;
                        default:
                            throw new Error(`Unexpected type: ${attribute.type}`);
                    }

                    return (
                        <ListItemCardRow key={idx}>
                            <div>
                                <ListItemCardLabel direction="column" label={t(attribute.label)} />
                                <ListItemCardValue
                                    direction="column"
                                    value={`${value} ${attribute.requiresCurrency ? t('LV') : ''}`}
                                />
                            </div>
                        </ListItemCardRow>
                    );
                })}
                {reason === AgreementReasonEnum.FreeBeer &&
                    agreementDetails.freeBeerQuantity !== undefined && (
                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel
                                    direction="column"
                                    label={t('COMPENSATED_QUANTITIES')}
                                />
                                <ListItemCardValue
                                    direction="column"
                                    value={`${agreementDetails.freeBeerQuantity}`}
                                />
                            </div>
                        </ListItemCardRow>
                    )}
                {reason !== AgreementReasonEnum.FreeBeer &&
                    agreementDetails.invoicesAmount !== undefined && (
                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="column" label={t('PAID_AMOUNT')} />
                                <ListItemCardValue
                                    direction="column"
                                    value={`${agreementDetails.invoicesAmount} ${t('LV')}`}
                                />
                            </div>
                        </ListItemCardRow>
                    )}
            </Container>
            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
            {isSuccess && (
                <SuccessModal
                    title={t('AGREEMENT_EDITED')}
                    message={t('AGREEMENT_EDITED_message')}
                    modalType={SuccessModalTypeEnum.Success}
                    onConfirm={() => {
                        setIsSuccess(false);
                    }}
                />
            )}
        </>
    );
};

export default AgreementDetailsSummary;
