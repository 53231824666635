import { useEffect, useRef, useState } from 'react';

import { DropdownProps, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as ChevronIcon } from '@/assets/svg-icons/chevron-icon.svg';
import { StyledDropdown } from '@/styles/shared/dropdown/styles';

import { DropdownButton, DropdownWrapper, DropdownTextWrapper } from './styles';

type CustomDropdownProps = {
    value?: string | undefined;
    placeholder: string;
    items: Array<{
        label: string;
        key: string;
    }>;
    onClick: MenuProps['onClick'];
    menuProps?: DropdownProps['menu'];
} & Omit<DropdownProps, 'menu'>;

const CustomDropdown: React.FC<CustomDropdownProps> = ({
    value,
    placeholder,
    items,
    onClick,
    menuProps,
    ...props
}) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [menuWidth, setMenuWidth] = useState(0);

    useEffect(() => {
        if (dropdownRef.current) {
            const { width } = dropdownRef.current.getBoundingClientRect();
            setMenuWidth(width);
        }
    }, []);

    return (
        <DropdownWrapper ref={dropdownRef}>
            <StyledDropdown
                $color={value ? theme?.color.grey[80] : undefined}
                menu={{
                    items,
                    onClick,
                    ...menuProps
                }}
                overlayClassName={'dropdown-overlay'}
                overlayStyle={{ width: menuWidth }} // menu scroll ->> maxHeight: '300px', overflowY: 'auto'
                {...props}>
                <DropdownButton>
                    <DropdownTextWrapper>
                        {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                        {value || t(`${placeholder}`)}
                    </DropdownTextWrapper>
                    <span>
                        <ChevronIcon />
                    </span>
                </DropdownButton>
            </StyledDropdown>
        </DropdownWrapper>
    );
};

export default CustomDropdown;
