import styled from 'styled-components';

import TextLabelValue from '@/components/shared/UI/TextLabelValue';
import { ListItem } from '@/styles/shared/list-item/styles';
import { StyledStatusVerticalLine } from '@/styles/shared/status/styles';

type AssetCardProps = {
    $isSelected: boolean;
    $isSelectionMode: boolean;
    $isShareDisabled: boolean;
};

type StatusProps = {
    $style: string | undefined;
};

export const AssetCardContainer = styled(ListItem)<AssetCardProps>`
    display: ${({ $isShareDisabled }) => ($isShareDisabled ? 'none' : 'block')};

    ${({ $isSelectionMode, $isSelected, theme }) =>
        $isSelectionMode &&
        $isSelected &&
        `
            border: 2px solid ${theme.color.blue[50]} !important;
            background-color: ${theme.color.blue[5]};
            transition: 0.1s border ease-in-out;
    `};
`;

export const AssetCardValue = styled(TextLabelValue)`
    font-size: ${({ theme }) => theme.fontSize.xxlg};
`;

export const AssetCardRightCol = styled.div`
    width: 25%;
`;

export const AssetCardStatusLabel = styled.span<StatusProps>`
    font-weight: 600;

    color: ${({ $style, theme }) => {
        switch ($style) {
            case 'signed':
                return theme.color.green[50];
            case 'prepared':
                return theme.color.yellow[10];
            case 'canceled':
                return theme.color.red[40];
            default:
                return 'transparent';
        }
    }};
`;

export const AssetCardStatusLine = styled(StyledStatusVerticalLine)<StatusProps>`
    border-left: 5px solid
        ${({ $style, theme }) => {
            switch ($style) {
                case 'signed':
                    return theme.color.assetStatus.signed;
                case 'prepared':
                    return theme.color.assetStatus.prepared;
                case 'canceled':
                    return theme.color.assetStatus.canceled;
                default:
                    return 'transparent';
            }
        }};
`;

export const StyledAssetCardProtocolType = styled.div`
    display: flex;
    align-items: baseline;
`;

export const StyledProtocolTypeLabel = styled.span`
    font-size: ${({ theme }) => theme.fontSize.xlg};
    font-weight: 600;
`;

export const CommentButton = styled.button`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.grey[60]};
    width: 35px;
    height: 23px;
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
`;
