import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import { ReactComponent as ShareIcon } from '@/assets/svg-icons/share-icon.svg';
import { ReactComponent as XIcon } from '@/assets/svg-icons/xc-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import ShareModal from '@/components/shared/modal/ShareModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { ModalCloseReasonEnum } from '@/enums/modal-close-reason.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { AssetDto, AssetDtoFilterInput, useGetAssetsQuery } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { ListContainer, ListHeader, ListHeadingContainer } from '@/styles/shared/list/styles';

import AssetDropdownArea from './partials/AssetDropdownArea';
import AssetsListTable from './partials/AssetsListTable';
import {
    AssetListButtonWrapper,
    AssetListContent,
    AssetListContentTitle,
    AssetListSelectionBtnsContainer,
    AssetListShareBtn
} from './styles';

const AssetList: React.FC = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { setError, clearError, errorMessage } = useError();
    const [assets, setAssets] = useState<AssetDto[]>([]);
    const { navbar, protocol } = useContext(AppContext);
    const [isSelectionMode, setIsSelectionMode] = useState<boolean>(false);
    const [isShareModalOpened, setIsShareModalOpened] = useState<boolean>(false);
    const [selectedAssets, setSelectedAssets] = useState<number[]>([]);
    const [endCursor, setEndCursor] = useState('');
    const [hasMore, setHasMore] = useState(false);

    const { refetch, loading, fetchMore } = useGetAssetsQuery({
        onCompleted: (data) => {
            setHasMore(data.assets?.pageInfo.hasNextPage ?? false);
            setEndCursor(data?.assets?.pageInfo.endCursor ?? '');
            setAssets(
                data?.assets?.edges?.map((edge) => {
                    return edge.node;
                }) as AssetDto[]
            );
            clearError();
        },
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.SidebarButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            background: theme?.color.default.white
        });
        protocol.setProtocol(undefined);

        return () => {
            navbar.disposeNavbar();

            refetch();
        };
    }, []); // eslint-disable-line

    const handleFiltering = (filters: AssetDtoFilterInput[]) => {
        refetch({
            filters
        })
            .then((data) => {
                setAssets(
                    data.data?.assets?.edges?.map((x) => {
                        return x.node;
                    }) as AssetDto[]
                );
            })
            .catch((error) => setError(error, ErrorTypeEnum.Error));
    };
    const handleAssetCancellation = () => {
        refetch();
    };
    const handleShare = () => {
        setIsShareModalOpened(true);
    };

    const handleFinishSharing = (reason?: ModalCloseReasonEnum) => {
        if (reason === ModalCloseReasonEnum.Success) {
            refetch();
        }

        setIsShareModalOpened(false);
        setIsSelectionMode(false);
        setSelectedAssets([]);
    };

    const handleSelectionMode = (selectionMode: boolean) => {
        setIsSelectionMode(selectionMode);
        setSelectedAssets([]);
    };

    const handleAssetsSelection = (assetIds: number[]) => {
        setSelectedAssets(() => [...assetIds]);
    };

    const handleLoadMore = () => {
        fetchMore({
            variables: { after: endCursor }
        })
            .then(({ data: { assets } }) => {
                setHasMore(assets?.pageInfo.hasNextPage ?? false);
                setEndCursor(assets?.pageInfo.endCursor ?? '');

                const newItems = assets?.edges?.map((edge) => {
                    return edge.node;
                }) as AssetDto[];

                setAssets((prev) => [...prev, ...newItems]);
            })
            .catch((err) => {
                setError(err, ErrorTypeEnum.ApolloError);
            });
    };

    return (
        <ListContainer>
            <ListHeader>
                <ListHeadingContainer>
                    <StyledPageTitle style={{ margin: 0 }}>{t('ASSETS')}</StyledPageTitle>
                    <CustomButton
                        onClick={() => navigate(ROUTES.AssetList.CreateContract.Path)}
                        label={t('NEW_CONTRACT')}
                        buttonType={ButtonsTypesEnum.Main}
                        icon={<PlusIcon />}
                    />
                </ListHeadingContainer>
                <AssetDropdownArea assets={assets} onFiltersSelected={handleFiltering} />
            </ListHeader>

            <AssetListContent $isSelectionMode={isSelectionMode}>
                <AssetListButtonWrapper>
                    <AssetListContentTitle>{t('ASSETS_LIST')}</AssetListContentTitle>

                    {!isSelectionMode && (
                        <CustomButton
                            onClick={() => handleSelectionMode(true)}
                            label={t('SHARE')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<ShareIcon />}
                        />
                    )}

                    {isSelectionMode && (
                        <AssetListSelectionBtnsContainer>
                            <AssetListShareBtn
                                onClick={() => handleSelectionMode(false)}
                                label={t('CANCEL')}
                                buttonType={ButtonsTypesEnum.DestructiveMain}
                                icon={<XIcon />}
                            />
                            <AssetListShareBtn
                                onClick={handleShare}
                                label={t('SHARE_SELECTED')}
                                buttonType={
                                    selectedAssets.length > 0
                                        ? ButtonsTypesEnum.Main
                                        : ButtonsTypesEnum.Disabled
                                }
                                icon={<ShareIcon />}
                            />
                        </AssetListSelectionBtnsContainer>
                    )}
                </AssetListButtonWrapper>

                <SpinnerContainer loading={loading}>
                    <AssetsListTable
                        assets={assets}
                        isSelectionMode={isSelectionMode}
                        onAssetCancellation={handleAssetCancellation}
                        onAssetsSelected={handleAssetsSelection}
                        fetchNext={handleLoadMore}
                        hasMore={hasMore}
                    />
                </SpinnerContainer>
            </AssetListContent>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}

            {isShareModalOpened && (
                <ShareModal close={handleFinishSharing} assetsIds={selectedAssets} />
            )}
        </ListContainer>
    );
};

export default AssetList;
