import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as ClearIcon } from '@/assets/svg-icons/clear-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import DropdownWithSearch from '@/components/shared/UI/DropdownWithSearch';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { FilterTypesEnum } from '@/enums/assets/filter-types.enum';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AgreementDto,
    AgreementDtoFilterInput,
    DocumentStatusEnum,
    FilterRequestModelInput,
    useGetCustomersWithAgreementsPerUserLazyQuery,
    useGetPocsWithAgreementsPerUserLazyQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { FlexContainer } from '@/styles/shared/container/styles';
import { DropdownContainer } from '@/styles/shared/dropdown/styles';
import { SelectItem } from '@/types/SelectItem';

import AgreementStatusFilter from '../AgreementStatusFilter';

const getStoredFilters = () => {
    const storedFiltersData = localStorage.getItem(LOCAL_STORAGE_KEYS.AgreementFilters);

    if (storedFiltersData) return JSON.parse(storedFiltersData);

    return [];
};

const clearStoredFilters = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AgreementFilters);
};

export type FilterWithType = {
    filter: AgreementDtoFilterInput;
    type: FilterTypesEnum;
    filterKey: string;
    statusKey?: DocumentStatusEnum;
};

type Props = {
    onFiltersSelected: (filters: AgreementDtoFilterInput[]) => void;
    agreements: AgreementDto[];
};

const AgreementFilters: React.FC<Props> = ({ onFiltersSelected, agreements }) => {
    const { setError, clearError, errorMessage } = useError();
    const storedFilters = getStoredFilters();
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [filters, setFilters] = useState<FilterWithType[]>(storedFilters || []);
    const [isStatusSelected, setIsStatusSelected] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
    const [selectedPoc, setSelectedPoc] = useState<string | null>(null);

    const [getCustomer, { data: customersData, refetch: refetchCustomerPerUser }] =
        useGetCustomersWithAgreementsPerUserLazyQuery({
            variables: {
                input: {}
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });

    const [getPocs, { refetch: refetchPocsPerUser, data: pocsData }] =
        useGetPocsWithAgreementsPerUserLazyQuery({
            variables: {
                input: {}
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });

    const refetchFilters = useCallback(
        async (filterRequest: FilterRequestModelInput) => {
            const variables = { input: filterRequest };
            await refetchCustomerPerUser(variables);
            await refetchPocsPerUser(variables);
        },
        [refetchCustomerPerUser, refetchPocsPerUser]
    );

    const reloadFilter = async (filterType: FilterTypesEnum, filter?: FilterWithType) => {
        const filterRequest: FilterRequestModelInput = {
            customerName:
                filterType === FilterTypesEnum.Customer
                    ? filter?.filterKey
                    : filters.find((filter) => filter.type === FilterTypesEnum.Customer)?.filterKey,
            pocName:
                filterType === FilterTypesEnum.Poc
                    ? filter?.filterKey
                    : filters.find((filter) => filter.type === FilterTypesEnum.Poc)?.filterKey
        };

        await refetchFilters(filterRequest);
    };

    useEffect(() => {
        const filterRequest: FilterRequestModelInput = {
            customerName: filters.find((filter) => filter.type === FilterTypesEnum.Customer)
                ?.filterKey,
            pocName: filters.find((filter) => filter.type === FilterTypesEnum.Poc)?.filterKey,
            status: filters.find((filter) => filter.type === FilterTypesEnum.AssetStatus)?.statusKey
        };

        refetchFilters(filterRequest);
    }, [agreements, filters, refetchFilters]);

    useEffect(() => {
        filters.length && onFiltersSelected(filters.map((f) => f.filter));
        localStorage.setItem(LOCAL_STORAGE_KEYS.AgreementFilters, JSON.stringify(filters));
    }, [filters]); // eslint-disable-line

    const customerItems: SelectItem[] | undefined =
        customersData?.customersWithAgreementsPerUser?.map((customer) => {
            return {
                label: customer.name,
                key: customer.name
            };
        });

    const pocItems: SelectItem[] | undefined = pocsData?.pocsWithAgreementsPerUser?.map((poc) => {
        return {
            label: poc.pocName,
            key: poc.pocName
        };
    });

    const removeAndReloadFilter = async (filterType: FilterTypesEnum) => {
        setFilters((prevState) => [...prevState.filter((f) => f.type !== filterType)]);
        await reloadFilter(filterType);
    };

    const onAgreementStatusSelection = async (key: DocumentStatusEnum | undefined) => {
        setIsStatusSelected(true);

        if (!key) {
            await reloadFilter(FilterTypesEnum.AssetStatus);
        } else {
            const filter: AgreementDtoFilterInput = {
                and: [{ status: { eq: key } }]
            };
            fullUpdateFilter({
                type: FilterTypesEnum.AssetStatus,
                filter,
                filterKey: t(key),
                statusKey: key
            });
            await reloadFilter(FilterTypesEnum.AssetStatus, {
                type: FilterTypesEnum.AssetStatus,
                filter,
                filterKey: t(key),
                statusKey: key
            });
        }
    };

    const onCustomerSelection = async (selectedValue: string) => {
        if (!selectedValue) {
            await removeAndReloadFilter(FilterTypesEnum.Customer);

            return;
        }

        const filter: AgreementDtoFilterInput = {
            and: [{ customerName: { eq: selectedValue } }]
        };

        fullUpdateFilter({
            type: FilterTypesEnum.Customer,
            filter,
            filterKey: selectedValue
        });

        await reloadFilter(FilterTypesEnum.Customer, {
            type: FilterTypesEnum.Customer,
            filter,
            filterKey: selectedValue
        });

        setSelectedCustomer(selectedValue);
    };

    const onPocSelection = async (selectedValue: string) => {
        if (!selectedValue) {
            await removeAndReloadFilter(FilterTypesEnum.Poc);

            return;
        }

        const filter: AgreementDtoFilterInput = {
            and: [{ pocName: { eq: selectedValue } }]
        };

        fullUpdateFilter({ type: FilterTypesEnum.Poc, filter, filterKey: selectedValue });

        await reloadFilter(FilterTypesEnum.Poc, {
            type: FilterTypesEnum.Poc,
            filter,
            filterKey: selectedValue
        });

        setSelectedPoc(selectedValue);
    };

    const fullUpdateFilter = (filter: FilterWithType) => {
        const filterIndex = filters.map((f) => f.type).indexOf(filter.type);

        if (filterIndex === -1) {
            setFilters((prevState) => [...prevState, filter]);
        } else {
            setFilters((prevState) => {
                prevState[filterIndex] = filter;

                return [...prevState];
            });
        }
    };

    const clearFilters = async () => {
        setFilters([]);
        onFiltersSelected([]);
        setIsStatusSelected(false);
        clearStoredFilters();
        setSelectedCustomer(null);
        setSelectedPoc(null);
        await removeAndReloadFilter(FilterTypesEnum.Poc);
        await removeAndReloadFilter(FilterTypesEnum.Customer);
    };

    const storedCustomerLabel = storedFilters
        .filter((filter: FilterWithType) => filter.type === FilterTypesEnum.Customer)
        .map((filter: FilterWithType) => {
            return (
                filter.type === FilterTypesEnum.Customer && {
                    customerName: filter.filterKey
                }
            );
        })[0];

    const storedPocLabel = storedFilters
        .filter((filter: FilterWithType) => filter.type === FilterTypesEnum.Poc)
        .map((filter: FilterWithType) => {
            return (
                filter.type === FilterTypesEnum.Poc && {
                    pocName: filter.filterKey
                }
            );
        })[0];

    useEffect(() => {
        setSelectedCustomer(storedCustomerLabel?.customerName);
        setSelectedPoc(storedPocLabel?.pocName);
    }, [storedCustomerLabel, storedPocLabel]);

    return (
        <>
            <AgreementStatusFilter
                agreements={agreements}
                onStatusSelection={onAgreementStatusSelection}
                isStatusSelected={isStatusSelected}
                filters={filters}
            />

            <DropdownContainer>
                <DropdownWithSearch
                    items={customerItems}
                    onChangeHandler={onCustomerSelection}
                    placeholder={t('CUSTOMER')}
                    value={selectedCustomer}
                    onClear={() => setSelectedCustomer(null)}
                />

                <DropdownWithSearch
                    items={pocItems}
                    onChangeHandler={onPocSelection}
                    placeholder={t('POC')}
                    value={selectedPoc}
                    onClear={() => setSelectedPoc(null)}
                />

                {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
            </DropdownContainer>

            {filters.length > 0 && (
                <FlexContainer $justify="center" $marginTop={theme?.space[20]}>
                    <CustomButton
                        onClick={clearFilters}
                        label={t('CLEAR_FILTER')}
                        icon={<ClearIcon />}
                        buttonType={ButtonsTypesEnum.Tertiary}
                        style={{ boxShadow: 'none' }}
                    />
                </FlexContainer>
            )}
        </>
    );
};

export default AgreementFilters;
