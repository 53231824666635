import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import xcIcon from '@/assets/svg-icons/cerror-icon.svg';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';

import CustomButton from '../../UI/CustomButton';
import { StyledPageTitle } from '../../UI/PageTitle/styles';
import CloseModalBar from '../partials/CloseModalBar';
import ModalBody from '../partials/ModalBody';
import ModalContainer from '../partials/ModalContainer';
import ModalFooter from '../partials/ModalFooter';
import ModalHeader from '../partials/ModalHeader';

type Props = {
    onClose?: () => void;
    errorMessage: string;
};

const ErrorModal: React.FC<Props> = ({ onClose, errorMessage }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();

    return (
        <ModalContainer>
            <CloseModalBar
                backgroundColor={theme?.color.red[40]}
                xColor={'white'}
                onClick={onClose!}
            />
            <ModalHeader backgroundColor={theme?.color.red[40]}>
                <img alt={''} src={xcIcon} />
                <StyledPageTitle>{t('ERROR')}</StyledPageTitle>
            </ModalHeader>
            <ModalBody backgroundColor={theme?.color.default.white}>
                <p>{errorMessage}</p>
            </ModalBody>
            <ModalFooter backgroundColor={theme?.color.default.white}>
                <CustomButton
                    label={t('OK')}
                    buttonType={ButtonsTypesEnum.Default}
                    onClick={onClose}
                    style={{ width: '100px' }}
                />
            </ModalFooter>
        </ModalContainer>
    );
};

export default ErrorModal;
