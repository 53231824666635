import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const StyledAssetContent = styled(Content)`
    position: relative;
    padding: 0 ${({ theme }) => theme.space[16]};
    background-color: ${({ theme }) => theme.color.default.bg};
`;

export const StyledAssetCard = styled.div`
    position: relative;
    border-radius: 4px;
    width: 100%;
    box-shadow: 1px 5px 4px rgba(0, 0, 0, 0.05);
    background-color: white;
    padding: 10px ${({ theme }) => theme.space[16]};
`;

export const StyledCommentButton = styled.button`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.space[4]};
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[8]};
    background: ${({ theme }) => theme.color.grey[60]};
    border-radius: 4px;
`;
