import React, { useEffect, useState } from 'react';

import { Table, TableProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import type { ColumnsType } from 'antd/es/table';
import { DefaultTFuncReturn } from 'i18next';
import type { ResizeCallbackData } from 'react-resizable';

import TABLE_CONSTANTS from '@/constants/admin/tables';
import { useTableData } from '@/hooks/admin/useTableData';
import { EditableColumn } from '@/types/admin/Table';

import { EditableCell, EditableNumberCell, EditableRow } from './partials/EditableCell';
import ResizableTitle from './partials/ResizableTitle';

const { InitialPageSize, PageSizeOptions } = TABLE_CONSTANTS;

type ResizableTableProps<T> = {
    tableHeading?: string | DefaultTFuncReturn;
    colDefs: Array<ColumnsType<T> | EditableColumn<T>>;
    tableData: T[];
    emptyText: string;
    loading?: boolean;
    hasSearchBar?: boolean;
    onPageChange?: (page: number, pageSize: number) => void;
    totalPaginationItems?: number;
    size?: SizeType;
    editableCellsType?: 'number' | 'default';
    onRowChange?: (row: T) => void;
};

const ResizableTable = <T,>({
    colDefs,
    tableData,
    emptyText,
    loading,
    onPageChange,
    totalPaginationItems,
    size,
    editableCellsType = 'default',
    onRowChange,
    ...rest
}: ResizableTableProps<T> & TableProps<object>) => {
    const [data, setData] = useState<any[]>(tableData);
    const [columns, setColumns] = useState<Array<ColumnsType<T> | EditableColumn<T>>>(
        colDefs || []
    );
    const isNumericEditableCell = editableCellsType === 'number';
    const { setDataState } = useTableData<T>();

    const handleResize =
        (index: number) =>
        (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
            const newColumns: any = [...columns];
            newColumns[index] = {
                ...newColumns[index],
                width: size.width
            };
            setColumns(newColumns);
        };

    useEffect(() => {
        setData(tableData);
    }, [tableData]);

    useEffect(() => {
        colDefs && setColumns(colDefs);
    }, [colDefs]);

    const handleSave = (row: any) => {
        if (!row?.price && isNumericEditableCell) {
            return;
        }

        const newData = [...data];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        const hasDataChanged =
            JSON.stringify(row) !== JSON.stringify(newData.find((data) => data.id === row.id));

        newData.splice(index, 1, {
            ...item,
            ...row
        });

        hasDataChanged && onRowChange?.(row);
        setData(newData);
        setDataState(newData);
    };

    const components = {
        header: {
            cell: ResizableTitle
        },
        body: {
            row: EditableRow,
            cell: isNumericEditableCell ? EditableNumberCell : EditableCell
        }
    };

    const cols = columns.map((col: any, index: number) => {
        const newCol = {
            ...col,
            onHeaderCell: (column: ColumnsType<T>[number]) => ({
                width: column.width,
                onResize: handleResize(index) as unknown as React.ReactEventHandler<T>
            })
        };

        if (col.editable) {
            newCol.onCell = (record: EditableColumn<T>) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave
            });
        }

        return newCol;
    });

    return (
        <Table
            rowKey="id"
            bordered
            columns={cols}
            dataSource={data}
            components={components}
            rowClassName="editable-row"
            locale={{ emptyText }}
            loading={loading}
            scroll={{ x: 'max-content' }}
            size={size}
            pagination={
                totalPaginationItems && totalPaginationItems > InitialPageSize
                    ? {
                          pageSizeOptions: PageSizeOptions,
                          showSizeChanger: true,
                          defaultPageSize: InitialPageSize,
                          onChange: (page, pageSize) => onPageChange?.(page, pageSize),
                          total: totalPaginationItems
                      }
                    : false
            }
            {...rest}
        />
    );
};

export default ResizableTable;
