import envconfig from '@/config/envconfig';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { AssetTypeEnum, DocumentTypeEnum, ScannedDocumentDto } from '@/graphql/main';
import { getToken } from '@/utils/authorizationUtils';

export const downloadPdf = (
    id: number,
    documentType: DocumentTypeEnum,
    setError: (error: string, errorType: ErrorTypeEnum) => void,
    assetType?: AssetTypeEnum
) => {
    fetch(
        documentType === DocumentTypeEnum.Asset
            ? `${envconfig.downloadDocument}/${id}/${documentType}/${assetType}`
            : `${envconfig.downloadDocument}/${id}/${documentType}`,
        {
            headers: {
                Authorization: `bearer ${getToken()}`
            },
            method: 'GET'
        }
    )
        .then(async (res) => {
            const filename = res.headers
                .get('content-disposition')
                ?.split(';')
                ?.find((n) => n.includes("filename*=UTF-8''"))
                ?.replace("filename*=UTF-8''", '')
                ?.trim();
            const decodedFileName = decodeURI(filename ?? 'protocolPdf');
            const blob = await res.blob();
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = decodedFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            setError(error, ErrorTypeEnum.Error);
        });
};
export const downloadAdditionalDocumentProtocolPdf = (
    protocolName: string,
    setError: (error: string, errorType: ErrorTypeEnum) => void
) => {
    fetch(`${envconfig.downloadCommercialAgreementProtocol}/${protocolName}`, {
        headers: {
            Authorization: `bearer ${getToken()}`
        },
        method: 'GET'
    })
        .then(async (res) => {
            const filename = res.headers
                .get('content-disposition')
                ?.split(';')
                ?.find((n) => n.includes("filename*=UTF-8''"))
                ?.replace("filename*=UTF-8''", '')
                ?.trim();
            const decodedFileName = decodeURI(filename ?? 'protocolPdf');
            const blob = await res.blob();
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = decodedFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            setError(error, ErrorTypeEnum.Error);
        });
};
export const downloadBankStatementAndInvoicePdf = (
    documentData: ScannedDocumentDto,
    setError: (error: string, errorType: ErrorTypeEnum) => void
) => {
    fetch(
        `${envconfig.downloadScannedDocument(
            documentData.scannedDocumentName,
            documentData.scannedDocumentType
        )}`,
        {
            headers: {
                Authorization: `bearer ${getToken()}`
            },
            method: 'GET'
        }
    )
        .then(async (res) => {
            const filename = res.headers
                .get('content-disposition')
                ?.split(';')
                ?.find((n) => n.includes("filename*=UTF-8''"))
                ?.replace("filename*=UTF-8''", '')
                ?.trim();
            const decodedFileName = decodeURI(filename ?? 'protocolPdf');
            const blob = await res.blob();
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = decodedFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((error) => {
            setError(error, ErrorTypeEnum.Error);
        });
};
