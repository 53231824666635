import { ApolloQueryResult } from '@apollo/client';
import { Button, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { GetTargetSkusQuery, useChangeTargetSkuIsActiveMutation, TargetSku } from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetTargetSkusQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();

    const [changeTargetSkusIsActive] = useChangeTargetSkuIsActiveMutation({
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError),
        onCompleted: async () => {
            await onChangeSuccess();
        }
    });

    const handleToggleTargetSkuStatus = async ({
        id,
        isActive
    }: {
        id: TargetSku['id'];
        isActive: TargetSku['isActive'];
    }) => {
        try {
            await changeTargetSkusIsActive({ variables: { id, isActive: !isActive } });
            message.success(t(isActive ? 'DEACTIVATED_SUCCESS' : 'ACTIVATED_SUCCESS'));
        } catch (error) {
            setError(error, ErrorTypeEnum.ApolloError);
            message.error(t(isActive ? 'DEACTIVATE_FAIL' : 'ACTIVATED_FAIL'));
        }
    };

    const colDef: ColumnsType<TargetSku> = [
        {
            key: 'name',
            title: t('NAME'),
            dataIndex: 'name',
            sorter: true
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            dataIndex: 'active',
            sorter: true,
            render: (_, { id, isActive }) => (
                <Button
                    key={`deactivate-${id as string}`}
                    danger={isActive}
                    onClick={async () => await handleToggleTargetSkuStatus({ id, isActive })}>
                    {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                </Button>
            )
        }
    ];

    return { colDef };
};

export default useColDefs;
