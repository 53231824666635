import { createPortal } from 'react-dom';

import Backdrop from '../CustomBackdrop';
import ModalOverlay from '../ModalOverlay';

type Props = {
    children: JSX.Element | JSX.Element[];
    overlayClassName?: string;
};

const ModalContainer: React.FC<Props> = ({ children, overlayClassName, ...rest }) => {
    const portalElement = document.getElementById('overlays') as Element;

    return createPortal(
        <>
            <Backdrop />
            <ModalOverlay className={overlayClassName} {...rest}>
                {children}
            </ModalOverlay>
        </>,
        portalElement
    );
};

export default ModalContainer;
