import { useEffect, useState } from 'react';

import { Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchButton from '@/components/admin/AdminButtons/SearchButton';
import DateRangePicker from '@/components/shared/UI/DateRangePicker';
import { DateFilterTypeEnum, AssetsRawDataReportRequestModelInput } from '@/graphql/main';

type Props = {
    onSearch: (filterValues: AssetsRawDataReportRequestModelInput) => void;
};

const RawDataReportFilters = ({ onSearch }: Props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const [filterBy, setFilterBy] = useState<DateFilterTypeEnum | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (filterBy) {
            setError(null);
        }
    }, [filterBy]);

    const handleSearch = () => {
        if (!filterBy) {
            setError(t('FILTER_VALIDATION_MESSAGE'));

            return;
        }

        const filterValues: AssetsRawDataReportRequestModelInput = {
            fromDate: fromDate ? fromDate.toISOString() : '',
            toDate: toDate ? toDate.toISOString() : '',
            filterBy: filterBy ?? DateFilterTypeEnum.DateCreated
        };

        onSearch(filterValues);
    };

    const handleFilterChange = (value: DateFilterTypeEnum) => {
        setFilterBy(value);
        form.setFieldsValue({ filterBy: value });
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSearch}>
            <Row style={{ gap: 18, alignItems: 'flex-end' }}>
                <Form.Item label={t('SELECT_DATE_RANGE')}>
                    <DateRangePicker setFromDate={setFromDate} setToDate={setToDate} />
                </Form.Item>

                <Form.Item name="filterBy" label={t('FILTER_BY')} style={{ minWidth: 180 }}>
                    <Select
                        allowClear={true}
                        placeholder={t('FILTER_BY')}
                        options={[
                            {
                                label: t('DATE_SIGNED'),
                                value: DateFilterTypeEnum.DateSigned
                            },
                            {
                                label: t('DATE_CREATED'),
                                value: DateFilterTypeEnum.DateCreated
                            }
                        ]}
                        onChange={handleFilterChange}
                        value={filterBy}
                        style={{ minWidth: 180, position: 'relative' }}
                    />
                    {error && <div style={{ color: 'red', position: 'absolute' }}>{error}</div>}
                </Form.Item>

                <Form.Item>
                    <SearchButton onClick={handleSearch} />
                </Form.Item>
            </Row>
        </Form>
    );
};

export default RawDataReportFilters;
