import styled from 'styled-components';

type ContainerProps = {
    $flexWrap?: string;
    $marginBottom?: string;
    $gap?: string;
};

type LabelProps = {
    selected: boolean;
};

type StatusFilterOptionProps = {
    selected: boolean;
    $styleType?: string;
};

export const StyledStatusFilterContainer = styled.div<ContainerProps>`
    display: flex;
    flex-wrap: ${({ $flexWrap }) => $flexWrap};
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: ${({ theme }) => theme.space[16]};
    margin-bottom: ${({ $marginBottom }) => $marginBottom};
    gap: ${({ $gap }) => $gap};

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledStatusFilterLabel = styled.div<LabelProps>`
    color: ${({ selected, theme }) => (selected ? theme.color.blue[50] : 'inherit')};
    font-weight: ${({ selected }) => (selected ? '700' : 'inherit')};
`;

export const StyledStatusFilterCount = styled.div`
    font-size: ${({ theme }) => theme.fontSize.xlg};
`;

const StatusFilterOption = styled.div<StatusFilterOptionProps>`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: ${({ theme }) => theme.space[8]};
    padding: 0 ${({ theme }) => theme.space[12]};
    height: ${({ theme }) => theme.space[36]};
    font-size: ${({ theme }) => theme.fontSize.xxlg};
    background-color: ${({ selected, theme }) => (selected ? theme.color.blue[15] : 'transparent')};
    white-space: nowrap;
    border-radius: 4px;

    & > div {
        display: flex;
        height: inherit;
        justify-content: center;
        align-items: center;
    }

    & > div:last-child {
        padding: 0 5px;
        height: ${({ theme }) => theme.space[20]};
        border-radius: 4px;
        font-weight: 700;
    }
`;

export const StyledAssetStatusOption = styled(StatusFilterOption)`
    & > div:last-child {
        background-color: ${({ $styleType, theme }) => {
            switch ($styleType) {
                case 'all-docs':
                    return theme.color.assetStatus.all;
                case 'signed':
                    return theme.color.assetStatus.signed;
                case 'prepared':
                    return theme.color.assetStatus.prepared;
                case 'canceled':
                    return theme.color.assetStatus.canceled;
                default:
                    return 'transparent';
            }
        }};
    }
`;

export const StyledAgreementStatusOption = styled(StatusFilterOption)`
    & > div:last-child {
        background-color: ${({ $styleType, theme }) => {
            switch ($styleType) {
                case 'validation':
                    return theme.color.agreementStatus.validation;
                case 'signed':
                    return theme.color.agreementStatus.signed;
                case 'prepared':
                    return theme.color.agreementStatus.prepared;
                case 'canceled':
                    return theme.color.agreementStatus.canceled;
                case 'done':
                    return theme.color.agreementStatus.done;
                case 'draft':
                    return theme.color.agreementStatus.draft;
                default:
                    return 'transparent';
            }
        }};
    }
`;

export const StyledStatusVerticalLine = styled.span`
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
`;
