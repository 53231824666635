import { Route, Routes } from 'react-router-dom';

import ROUTES from '@/constants/routes/routes';

import NotFound from '../misc/NotFound';

import ConfirmEmailSent from './pages/confirm-email-sent';
import ForgottenPassword from './pages/forgotten-password';
import Login from './pages/login';
import Recovery from './pages/recovery';
import ResetPassword from './pages/reset-password';

import Authentication from '.';

const AuthRoutes: React.FC = () => (
    <Routes>
        <Route key="authentication" path={ROUTES.Authentication.Path} element={<Authentication />}>
            <Route index element={<NotFound />} />
            <Route path={ROUTES.Authentication.Login} element={<Login />} />
            <Route path={ROUTES.Authentication.ForgottenPassword} element={<ForgottenPassword />} />
            <Route path={ROUTES.Authentication.ResetPassword} element={<ResetPassword />} />
            <Route path={ROUTES.Authentication.ChangePassword} element={<ResetPassword />} />
            <Route path={ROUTES.Authentication.ConfirmEmailSent} element={<ConfirmEmailSent />} />
            <Route path={ROUTES.Authentication.Recovery} element={<Recovery />} />
        </Route>
    </Routes>
);

export default AuthRoutes;
