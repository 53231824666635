import React, { Fragment } from 'react';

import { Row } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';

import svg from '@/assets/svgs/logo-text-bottom.svg';
import AppLogo from '@/components/shared/logo/AppLogo';
import ROUTES from '@/constants/routes/routes';
import { LogoTypeEnum } from '@/enums/logo-type.enum';

import { StyledAuthContent, StyledAuthHeader } from './styles';

const Authentication: React.FC = () => {
    const { pathname } = useLocation();

    return (
        <Fragment>
            {pathname !== ROUTES.FullPaths.ConfirmEmailSent && (
                <StyledAuthHeader>
                    <Row justify="center">
                        <AppLogo logoType={LogoTypeEnum.AuthorizationPagesLogo} path={svg} />
                    </Row>
                </StyledAuthHeader>
            )}
            <StyledAuthContent>
                <Outlet />
            </StyledAuthContent>
        </Fragment>
    );
};

Authentication.defaultProps = {
    hasHeader: true
};

export default Authentication;
