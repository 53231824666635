import styled from 'styled-components';

export const StyledModalFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    padding: 10px 10px 25px 10px;
    width: 100%;
    white-space: pre-line;
    margin-top: -1px;
`;
