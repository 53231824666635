import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    CreateRegionInput,
    useCreateRegionMutation,
    useGetZonesWithSupervisorsQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    successCallback?: () => void;
};

const CreateRegionModal = ({ successCallback }: Props) => {
    const [createRegionMutation] = useCreateRegionMutation();
    const [form] = Form.useForm<CreateRegionInput>();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setError } = useError();

    const toggleModalShow = () => {
        form.resetFields();
        setIsModalOpen(!isModalOpen);
    };
    const { data: zones } = useGetZonesWithSupervisorsQuery({
        fetchPolicy: 'network-only',
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const handleCreateRegion = async (values: CreateRegionInput) => {
        try {
            await createRegionMutation({
                variables: { input: { ...values } },
                onCompleted: () => {
                    successCallback?.();
                    message.success(t('CREATE_ITEM_SUCCESS'));
                },
                onError: () => message.error(t('CREATE_ITEM_FAIL'))
            });
            toggleModalShow();
        } catch {
            message.error(t('CREATE_ITEM_FAIL'));
        } finally {
            form.resetFields();
        }
    };

    return (
        <>
            <Row justify="end" style={{ marginTop: 18 }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={toggleModalShow}>
                    {t('NEW_REGION')}
                </Button>
            </Row>
            <Modal
                title={t('CREATE_NEW_REGION')}
                open={isModalOpen}
                onCancel={toggleModalShow}
                footer={[
                    <Button key="back" onClick={toggleModalShow}>
                        {t('CANCEL')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => form.submit()}>
                        {t('CREATE_NEW_REGION')}
                    </Button>
                ]}>
                <Form form={form} initialValues={{ isActive: true }} onFinish={handleCreateRegion}>
                    <Form.Item
                        name="name"
                        label={t('NAME')}
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('FIELD_REQUIRED') }]}>
                        <Input placeholder={t('NAME')} />
                    </Form.Item>
                    <Form.Item
                        name="costCenter"
                        label={t('COST_CENTER')}
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('FIELD_REQUIRED') }]}>
                        <Input placeholder={t('COST_CENTER')} />
                    </Form.Item>
                    <Form.Item name="zones" label={t('ZONES')} labelCol={{ span: 24 }}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t('SELECT_ZONES')}
                            optionFilterProp="label"
                            onChange={(value) => form.setFieldValue('zones', value)}
                            options={zones?.zonesWithSupervisors?.map((zone) => ({
                                value: zone?.id,
                                label: zone?.name
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('ACTIVE')}
                        name="isActive"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('ACTIVE_STATUS_REQUIRED') }]}>
                        <Select placeholder={t('ACTIVE')}>
                            <Select.Option value={true}>{t('YES')}</Select.Option>
                            <Select.Option value={false}>{t('NO')}</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateRegionModal;
