import styled from 'styled-components';

export const StyledContractIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background-color: ${({ theme }) => theme.color.green[60]};
    border-radius: 4px;
`;
