import React, { CSSProperties, ReactElement } from 'react';

import { useTheme } from 'styled-components';

import { space } from '@/config/theme/spacing';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';

import SpinnerContainer from '../SpinnerContainer';

import { EllipsisTextWrapper, Icon, IconContainer, StyledButton } from './styles';

type Props = {
    label?: string;
    labelClassName?: string;
    icon?: ReactElement;
    buttonType?: ButtonsTypesEnum;
    style?: CSSProperties;
    title?: string;
    onClick?: () => void;
    className?: string;
    maxWidth?: keyof typeof space;
    loading?: boolean;
    functionType?: 'button' | 'submit' | 'reset' | undefined;
};

const CustomButton: React.FC<Props> = ({
    label,
    labelClassName,
    icon = '',
    buttonType,
    style,
    onClick,
    className,
    loading,
    maxWidth,
    functionType,
    title
}) => {
    const theme = useTheme();

    return (
        <StyledButton
            style={style}
            disabled={buttonType === ButtonsTypesEnum.Disabled || loading}
            className={`${className as string}`}
            onClick={onClick}
            title={title}
            type={functionType ?? 'button'}
            $buttonType={buttonType}>
            {(loading ?? icon) && (
                <IconContainer className={`${label === '' ? 'empty-btn' : ''}`}>
                    <SpinnerContainer loading={loading} spinnerSize={'1rem'} spinnerColor={'white'}>
                        <Icon className={buttonType}>{icon ?? icon}</Icon>
                    </SpinnerContainer>
                </IconContainer>
            )}
            {maxWidth ? (
                <EllipsisTextWrapper $maxWidth={theme?.space[maxWidth]} className={labelClassName}>
                    {label}
                </EllipsisTextWrapper>
            ) : (
                <span className={labelClassName}>{label}</span>
            )}
        </StyledButton>
    );
};

export default CustomButton;
