import { TableDataProvider } from '@/hooks/admin/useTableData';
import ZonesTable from '@/pages/admin/CommercialAgreements/Zones/partials/ZonesTable';

const Zones = () => (
    <TableDataProvider>
        <ZonesTable />
    </TableDataProvider>
);

export default Zones;
