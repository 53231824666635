import jwtDecode, { JwtPayload } from 'jwt-decode';

import envconfig from '@/config/envconfig';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import ROUTES from '@/constants/routes/routes';
import { getCacheVal, removeCacheVal, setCacheVal } from '@/services/utils/cacheUtils';

interface CustomJwtPayload extends JwtPayload {
    CyrillicName: string;
}

export const login = (token: string, refreshToken: string) => {
    const exp = jwtDecode<JwtPayload>(token).exp!;
    setCacheVal(LOCAL_STORAGE_KEYS.Token, token.toString());
    setCacheVal(LOCAL_STORAGE_KEYS.RefreshToken, refreshToken.toString());
    setCacheVal(LOCAL_STORAGE_KEYS.TokenExpirationTime, exp.toString());
};

export const getToken = (): string => getCacheVal(LOCAL_STORAGE_KEYS.Token);

export const logout = () => {
    removeCacheVal(LOCAL_STORAGE_KEYS.Token);
    removeCacheVal(LOCAL_STORAGE_KEYS.RefreshToken);
    removeCacheVal(LOCAL_STORAGE_KEYS.IsPassExpired);
    removeCacheVal(LOCAL_STORAGE_KEYS.TokenExpirationTime);
    removeCacheVal(LOCAL_STORAGE_KEYS.AssetFilters); // We remove the saved document filters
    removeCacheVal(LOCAL_STORAGE_KEYS.AgreementFilters); // We remove the saved document filters
    window.location.pathname !== ROUTES.FullPaths.Login &&
        window.location.replace(ROUTES.FullPaths.Login); // Redirect to login page
};

export const passExpiredHandler = (isExpired: boolean) => {
    setCacheVal(LOCAL_STORAGE_KEYS.IsPassExpired, isExpired.toString());
};

export const getCurrentUserEmail = (): string => {
    const token = getToken();

    if (token !== '') {
        return jwtDecode<JwtPayload>(token)[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress' as keyof JwtPayload
        ]!.toString();
    }

    return '';
};

export const getCurrentUserName = (): string => {
    const token = getToken();

    if (token !== '') {
        return jwtDecode<JwtPayload>(token)[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name' as keyof JwtPayload
        ]!.toString();
    }

    return '';
};

export const getCurrentCyrillicName = (): string => {
    const token = getToken();

    if (token !== '') {
        const payload = jwtDecode<CustomJwtPayload>(token);

        const cyrillicName = payload?.CyrillicName;

        if (cyrillicName !== undefined) {
            return cyrillicName;
        }
    }

    return '';
};

export const getCurrentUserRole = (): User['role'] => {
    const token = getToken();

    let role = null;

    if (token && token !== '') {
        role = jwtDecode<JwtPayload>(token)[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' as keyof JwtPayload
        ]?.toString() as User['role'];
    }

    return role;
};

const rolesEligibleForSRRoutes: Array<Partial<User['role']>> = [
    'SUPER_ADMIN',
    'SALES_REPRESENTATIVE',
    'RCMC',
    'SUPERVISOR'
];

const rolesEligibleForAdminRoutes: Array<Partial<User['role']>> = ['SUPER_ADMIN', 'ADMIN'];

export const canAccessSRRoutes = rolesEligibleForSRRoutes.includes(getCurrentUserRole());
export const canAccessAdminRoutes = rolesEligibleForAdminRoutes.includes(getCurrentUserRole());

export const isValidToken = (accessToken: string) => {
    if (!accessToken) {
        return false;
    }

    const decoded: JwtPayload = jwtDecode(accessToken);
    const currentDate = new Date();

    return decoded.exp! * 1000 > currentDate.getTime();
};

export const refreshToken = async () => {
    const refreshToken = getCacheVal(LOCAL_STORAGE_KEYS.RefreshToken);

    if (refreshToken) {
        const formData = new FormData();
        formData.append(LOCAL_STORAGE_KEYS.RefreshToken, refreshToken.toString());
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(refreshToken),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(envconfig.refreshTokenConfigs.refreshTokenUrl, requestOptions);
        if (!response.ok) {
            logout();

            return null;
        }

        return await response.json();
    }

    return null;
};

export const isTokenNearToExpire = (token: string) => {
    const exp = jwtDecode<JwtPayload>(token).exp!;

    const currentTime = new Date().getTime();

    return exp * 1000 - currentTime < envconfig.refreshTokenConfigs.closeToTokenExpirationTime;
};

export const autoTokenRefresh = () => {
    // @todo - fix lint
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
        const newToken = (await refreshToken())?.token;
        if (newToken) {
            setCacheVal(LOCAL_STORAGE_KEYS.Token, newToken);
        }

        autoTokenRefresh();
    }, envconfig.refreshTokenConfigs.closeToTokenExpirationTime); // callback
};

export const isLoggedIn = (): boolean => isValidToken(getToken());

export const isPasswordExpired = () => getCacheVal(LOCAL_STORAGE_KEYS.IsPassExpired) === 'true';
