import { color } from '@/config/theme/color';
import { fontFamily } from '@/config/theme/fontFamilies';
import { fontSize } from '@/config/theme/fontSize';
import { lineHeight } from '@/config/theme/lineHeight';
import { shadow } from '@/config/theme/shadow';
import { space } from '@/config/theme/spacing';

export const theme = {
    color,
    fontSize,
    fontFamily,
    space,
    lineHeight,
    shadow
};
