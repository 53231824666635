import { useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CurrencyIcon } from '@/assets/svg-icons/currency-icon.svg';
import { ReactComponent as FrequencyIcon } from '@/assets/svg-icons/frequency-arrow-icon.svg';
import { ReactComponent as ViewDeclarationIcon } from '@/assets/svg-icons/preview-declaration-icon.svg';
import { ReactComponent as ScanIcon } from '@/assets/svg-icons/scan-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AgreementDetailsDto,
    AgreementTypeEnum,
    DocumentStatusEnum,
    DocumentTypeEnum,
    GetAgreementDetailsQuery,
    GetAgreementDetailsQueryVariables
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { setCacheVal } from '@/services/utils/cacheUtils';
import { useUserContext } from '@/store/user/useUserContext';
import { FlexAlignCenter } from '@/styles/shared/container/styles';
import {
    ListItemCardCustomerName,
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardRowHeader,
    ListItemCardValue,
    ListItemRightCol
} from '@/styles/shared/list-item/styles';

import AgreementComplete from '../AgreementComplete';
import AgreementSendForValidation from '../AgreementSendForValidation';
import AgreementSign from '../AgreementSign';
import AgreementValidate from '../AgreementValidate';

type Props = {
    agreementDetails: AgreementDetailsDto;
    refetchAgreementDetails: (
        variables?: GetAgreementDetailsQueryVariables | undefined
    ) => Promise<ApolloQueryResult<GetAgreementDetailsQuery>>;
};

const AgreementDetailsInfo: React.FC<Props> = ({ agreementDetails, refetchAgreementDetails }) => {
    const { setError, clearError, errorMessage } = useError();
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const { isF2RCMC } = useUserContext();
    const {
        agreementId,
        status,
        customerName,
        customerEmail,
        pocName,
        pocEmployee,
        hermesPocId,
        agreementNumber,
        additionalDocuments,
        customerAgreementsSpentAmount,
        globalCustomerLimit,
        investmentAmount,
        paymentFrequency,
        declaration
    } = agreementDetails;

    const [declarationState, setDeclarationState] =
        useState<AgreementDetailsDto['declaration']>(declaration);

    const handleScanDeclaration = () => {
        if (!params.agreementId) {
            setError(
                'agreementId is not defined in the route parameters.',
                ErrorTypeEnum.FrontEndError
            );

            return;
        }

        navigate(ROUTES.AgreementList.Declaration.Scan, {
            state: {
                route: ROUTES.FullPaths.Agreement(+params.agreementId),
                assetType: DocumentTypeEnum.CommercialAgreement
            }
        });
    };

    const handleViewDeclaration = () => {
        navigate(ROUTES.AgreementList.Declaration.Preview, {
            state: { declaration, status }
        });
    };

    useEffect(() => {
        if (!customerName) {
            setError('missing customerName', ErrorTypeEnum.FrontEndError);

            return;
        }

        setCacheVal(LOCAL_STORAGE_KEYS.CustomerName, customerName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (declarationState === null || declarationState === undefined) {
            refetchAgreementDetails();
            setDeclarationState(declarationState);
        }
    }, [declarationState]); // eslint-disable-line

    return (
        <>
            <ListItemCardRowHeader>
                <ListItemCardCustomerName value={customerName} bolder={true} />
            </ListItemCardRowHeader>

            <ListItemCardRow>
                <div>
                    <ListItemCardLabel label={t('POC_NAME')} />
                    <ListItemCardValue value={pocName ?? undefined} />
                </div>
                <ListItemRightCol>
                    <ListItemCardLabel label={t('POC_ID')} />
                    <ListItemCardValue value={hermesPocId ?? undefined} />
                </ListItemRightCol>
            </ListItemCardRow>

            <ListItemCardRow>
                <FlexAlignCenter $gap={theme?.space[12]}>
                    <ListItemCardLabel
                        $marginBottom={theme?.space[0]}
                        label={t('COMMERCIAL_AGREEMENT_NO')}
                    />
                    <ListItemCardValue value={agreementNumber ?? undefined} $fontWeight={600} />
                </FlexAlignCenter>
            </ListItemCardRow>

            <ListItemCardRow>
                <div>
                    <ListItemCardLabel label={t('INVESTMENT_AMOUNT')} />
                    <FlexAlignCenter $gap={theme?.space[4]}>
                        <CurrencyIcon />
                        <ListItemCardValue
                            value={investmentAmount ? `${investmentAmount} ${t('LV')}` : undefined}
                        />
                    </FlexAlignCenter>
                </div>
                <ListItemRightCol>
                    <div>
                        <ListItemCardLabel label={t('TOTAL_BALANCE')} />
                        <FlexAlignCenter $gap={theme?.space[4]}>
                            <ListItemCardValue
                                value={
                                    `${customerAgreementsSpentAmount} / ${globalCustomerLimit} ${t(
                                        'LV'
                                    )}` ?? undefined
                                }
                                $color={theme?.color.grey[70]}
                            />
                        </FlexAlignCenter>
                    </div>
                </ListItemRightCol>
            </ListItemCardRow>

            <ListItemCardRow>
                <div>
                    {paymentFrequency && (
                        <>
                            <ListItemCardLabel label={t('PAYMENT_FREQUENCY')} />
                            <FlexAlignCenter $gap={theme?.space[4]}>
                                <FrequencyIcon />
                                <ListItemCardValue
                                    value={paymentFrequency ?? undefined}
                                    $color={theme?.color.grey[70]}
                                />
                            </FlexAlignCenter>
                        </>
                    )}
                </div>
            </ListItemCardRow>

            <ListItemCardRow $marginBottom={theme?.space[32]}>
                <ListItemCardLabel label={t('DECLARATION')} />
                <div>
                    {declaration ? (
                        <CustomButton
                            label={t('VIEW')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<ViewDeclarationIcon />}
                            onClick={handleViewDeclaration}
                        />
                    ) : (
                        <CustomButton
                            label={t('SCAN')}
                            buttonType={ButtonsTypesEnum.Secondary}
                            icon={<ScanIcon />}
                            onClick={handleScanDeclaration}
                        />
                    )}
                </div>
            </ListItemCardRow>

            {isF2RCMC && declaration ? (
                <AgreementValidate
                    agreementId={agreementId}
                    hasDeclaration={!!declaration?.scannedDocumentName}
                />
            ) : (
                <>
                    {status === DocumentStatusEnum.Prepared ? (
                        <AgreementSign
                            customerEmail={customerEmail ?? undefined}
                            pocEmployee={pocEmployee ?? undefined}
                            agreementId={agreementId}
                        />
                    ) : status === DocumentStatusEnum.ForValidation ? (
                        <></>
                    ) : status === DocumentStatusEnum.Draft &&
                      agreementDetails.type !== AgreementTypeEnum.FwStock &&
                      agreementDetails.type !== AgreementTypeEnum.Ptc ? (
                        <AgreementSendForValidation
                            hasDeclaration={!!declaration?.scannedDocumentName}
                            status={status}
                            agreementId={agreementId}
                        />
                    ) : status === DocumentStatusEnum.Draft &&
                      (agreementDetails.type === AgreementTypeEnum.FwStock ||
                          agreementDetails.type === AgreementTypeEnum.Ptc) ? (
                        <AgreementSign
                            customerEmail={customerEmail ?? undefined}
                            pocEmployee={pocEmployee ?? undefined}
                            agreementId={agreementId}
                        />
                    ) : status === DocumentStatusEnum.Done || DocumentStatusEnum.Signed ? (
                        <AgreementComplete
                            customerEmail={customerEmail ?? undefined}
                            pocEmployee={pocEmployee ?? undefined}
                            status={status}
                            agreementId={agreementId}
                            hasAttachedAdditionalDocuments={!!additionalDocuments?.length}
                            refetchAgreementDetails={refetchAgreementDetails}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </>
    );
};

export default AgreementDetailsInfo;
