import styled from 'styled-components';

type StyledMaterialRowProps = {
    $isRemoveMode: boolean | undefined;
    $isSelected: boolean;
};

export const StyledMaterialRow = styled.div<StyledMaterialRowProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.space[8]} ${({ theme }) => theme.space[16]};
    background: ${({ theme }) => theme.color.default.white};
    border: 1px solid ${({ theme }) => theme.color.default.white};
    border-radius: 4px;

    ${({ $isRemoveMode, $isSelected, theme }) =>
        $isRemoveMode &&
        $isSelected &&
        `
            background: linear-gradient(0deg, #ffc0c0, #ffc0c0), ${theme.color.default.white};
            border: 1px solid ${theme.color.red[50]} ;
    `};

    input {
        width: ${({ theme }) => theme.space[64]} !important;
        text-align: center;
        padding: ${({ theme }) => theme.space[8]} ${({ theme }) => theme.space[4]};
    }
`;

export const StyledMaterialTitle = styled.span`
    font-weight: 600;
`;

export const StyledMaterialInfo = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.space[4]};
`;
