import { Fragment, useContext, useEffect, useState } from 'react';

import { SIGNATURE_ROLES } from '@constants/keys/signature-roles-keys';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ReactComponent as TickIcon } from '@/assets/svg-icons/tick-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { ContractDto, useCompleteContractMutation } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import SignSection from '@/pages/shared/sign/SignSection';
import AppContext from '@/store/app-context/AppContext';
import {
    FlexContainer,
    HeaderContainer,
    ScrollableContainer
} from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';

const SignContract: React.FC = () => {
    const { t } = useTranslation('translation');
    const { setError, clearError, errorMessage } = useError();
    const { navbar, sign, contract: currentContract } = useContext(AppContext);
    const [completeContract, { loading: completeContractLoading }] = useCompleteContractMutation();
    const [isSuccessModalShown, setIsSuccessModalOpened] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const theme = useTheme();

    const contractFromStorage = getCurrentContractData<ContractDto>();

    const contract = currentContract.currentContract ?? contractFromStorage;
    const POCSigns = sign.signatures?.filter(
        (signature) => signature.signer === SIGNATURE_ROLES.POC
    );
    const SRSigns = sign.signatures?.filter(
        (signature) => signature.signer === SIGNATURE_ROLES.SALES_REPRESENTATIVE
    );

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallback: sign.clearSigns
        });

        validateForm();

        return () => {
            // This will be executed on component destroy
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        validateForm();
    }, [sign.signatures]); // eslint-disable-line

    const handleFinish = () => {
        if (!isValid) {
            return;
        }

        completeContract({
            variables: {
                input: {
                    customerEmail: contract.customerEmail,
                    customerPersonnelName: contract.customerPersonnelName!,
                    contractId: contract.id,
                    customerPersonnelRole: contract.customerPersonnelRole!,
                    srSignatures: SRSigns!.map((srSign) => srSign.signature),
                    customerPersonnelSignatures: POCSigns!.map((pocSign) => pocSign.signature),
                    models: contract.models.map((model) => {
                        return { contractModelId: model.id, quantity: model.quantity };
                    })
                }
            }
        })
            .then(() => {
                setIsSuccessModalOpened(true);
            })
            .catch((error) => {
                setError(error, ErrorTypeEnum.ApolloError);
            });
    };
    const handleConfirmSuccess = () => {
        sign.clearSigns();
        setIsSuccessModalOpened(false);
        window.location.replace(ROUTES.FullPaths.AssetList);
    };

    const validateForm = () => {
        const isValid =
            !!SRSigns &&
            SRSigns.length === contract.signaturesCount &&
            !!POCSigns &&
            POCSigns.length === contract.signaturesCount;

        setIsValid(isValid);
    };

    return (
        <Fragment>
            <ScrollableContainer>
                <HeaderContainer $padding={theme?.space[0]}>
                    <StyledPageTitle $marginY={theme?.space[0]}>
                        {t('SIGN_CONTRACT')}
                    </StyledPageTitle>
                    <CustomButton
                        label={t('FINISH_AND_SEND')}
                        buttonType={isValid ? ButtonsTypesEnum.Main : ButtonsTypesEnum.Disabled}
                        icon={<TickIcon />}
                        onClick={handleFinish}
                        loading={completeContractLoading}
                    />
                </HeaderContainer>

                <Content>
                    <FlexContainer $justify="center" $marginBottom={theme?.space[32]}>
                        <InfoMessage message={t('SIGN_SUBTITLE')} />
                    </FlexContainer>

                    {[...Array(contract.signaturesCount)].map((_, i) => (
                        <SignSection
                            key={i}
                            sectionTitle={t('SALES_REPRESENTATIVE')}
                            email={contract.srEmail}
                            name={contract.srCyrillicName}
                            sign={SRSigns!.find((srSign) => srSign.signatureIndex === i)?.signature}
                            signer={SIGNATURE_ROLES.SALES_REPRESENTATIVE}
                            signatureIndex={i}
                        />
                    ))}

                    {[...Array(contract.signaturesCount)].map((_, i) => (
                        <SignSection
                            key={i}
                            sectionTitle={t('CUSTOMER_PERSONNEL')}
                            email={contract.customerEmail!}
                            name={contract.customerPersonnelName!}
                            sign={
                                POCSigns!.find((pocSign) => pocSign.signatureIndex === i)?.signature
                            }
                            signer={SIGNATURE_ROLES.POC}
                            signatureIndex={i}
                        />
                    ))}
                </Content>
            </ScrollableContainer>

            {isSuccessModalShown && (
                <SuccessModal
                    title={t('SUCCESSFULLY_SIGNED')}
                    message={t('PROTOCOL_FINISHED_AND_SENT')}
                    onConfirm={handleConfirmSuccess}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Fragment>
    );
};

export default SignContract;
