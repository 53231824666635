import { DatePicker, Modal } from 'antd';
import styled from 'styled-components';

export const StyledMultiStepModal = styled(Modal)`
    .ant-modal-title {
        font-size: ${({ theme }) => theme.fontSize.h4};
        margin-bottom: ${({ theme }) => theme.space[24]};
        font-family: ${({ theme }) => theme.fontFamily.interNormal};
        color: ${({ theme }) => theme.color.default.black};
    }

    .ant-modal-close {
        top: ${({ theme }) => theme.space[24]};
    }
`;

export const StyledCheckboxLabel = styled.span`
    color: ${({ theme }) => theme.color.grey[80]};
`;

export const CustomDatePicker = styled(DatePicker)`
    width: 100%;
    .ant-picker-input > input {
        color: #5c5c5c; // cannot apply theme.color.grey[80] here
    }
`;
