import envconfig from '@/config/envconfig';
import { AssetsRawDataReportRequestModelInput } from '@/graphql/main';
import { getToken } from '@/utils/authorizationUtils';

export const downloadRawDataReportApiCall = async (
    request: AssetsRawDataReportRequestModelInput
) => {
    return await fetch(`${envconfig.apiAddress}Reports/DownloadAssetsRawDataReport`, {
        headers: {
            Authorization: `bearer ${getToken()}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(request)
    });
};

export const downloadAgreementsRawDataReportApiCall = async (fromDate: string, toDate: string) => {
    return await fetch(
        `${envconfig.apiAddress}Reports/DownloadAgreementsRawDataReport/${fromDate}/${toDate}`,
        {
            headers: {
                Authorization: `bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        }
    );
};
