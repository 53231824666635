const CURRENT_CONTRACT_KEY = 'currentContract';
const COMMENT_KEY = 'comment';

const saveCurrentContractData = <T>(contractData: T) => {
    const data = JSON.stringify(contractData);
    window.localStorage.setItem(CURRENT_CONTRACT_KEY, data);
};

const getCurrentContractData = <T>() => {
    const storageData = window.localStorage.getItem(CURRENT_CONTRACT_KEY);
    const parsedData: T = storageData ? JSON.parse(storageData) : undefined;

    return parsedData;
};

type Comment = {
    comment: string;
    assetId: string;
} | null;

const commentKeyGenerator = (assetId: string) => {
    return `${COMMENT_KEY}_${assetId}`;
};

const saveCommentToStorage = ({ comment, assetId }: { comment: string; assetId: string }) => {
    const data = JSON.stringify({ comment, assetId });
    const key = commentKeyGenerator(assetId);
    window.localStorage.setItem(key, data);
};

const getCommentFromStorage = (assetId?: string) => {
    if (!assetId) {
        return '';
    }

    const key = commentKeyGenerator(assetId);
    const storageData = window.localStorage.getItem(key);
    const data: Comment = storageData ? JSON.parse(storageData) : null;

    return data?.comment ?? '';
};

const clearCommentFromStorage = (assetId: string) => {
    const key = commentKeyGenerator(assetId);
    window.localStorage.removeItem(key);
};

export {
    saveCurrentContractData,
    getCurrentContractData,
    saveCommentToStorage,
    getCommentFromStorage,
    clearCommentFromStorage
};
