import { useState } from 'react';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    useGetCustomersNamesLazyQuery,
    useGetPocsHermesIdsLazyQuery,
    useGetUsersNamesLazyQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';

type SelectProps = Array<{
    label: string;
    value: string;
}>;

const useFilters = () => {
    const { setError, clearError } = useError();
    const [customersByName] = useGetCustomersNamesLazyQuery();
    const [pocsHermesIds] = useGetPocsHermesIdsLazyQuery();
    const [usersNames] = useGetUsersNamesLazyQuery();

    const [customers, setCustomers] = useState<SelectProps>([]);
    const [pocIds, setPocIds] = useState<SelectProps>([]);
    const [employees, setEmployees] = useState<SelectProps>([]);

    const searchCustomers = (searchText: string) => {
        if (!searchText) {
            return setCustomers([]);
        }

        customersByName({
            variables: {
                input: searchText
            },
            onCompleted: (data) => {
                clearError();
                setCustomers(() => {
                    return data.customersNames.map((customerName) => {
                        return { label: customerName, value: customerName };
                    }) as SelectProps;
                });
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const searchPocsHermesIds = (searchText: string) => {
        if (!searchText) {
            return setPocIds([]);
        }

        pocsHermesIds({
            variables: {
                input: searchText
            },
            onCompleted: (data) => {
                clearError();
                setPocIds(() => {
                    return data.pocsHermesIds.map((id) => {
                        return { label: id, value: id };
                    }) as SelectProps;
                });
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    const searchEmployeesByName = (searchText: string) => {
        if (!searchText) {
            return setEmployees([]);
        }

        usersNames({
            variables: {
                input: searchText
            },
            onCompleted: (data) => {
                clearError();
                setEmployees(() => {
                    return data.usersNames.map((username) => {
                        return { label: username, value: username };
                    }) as SelectProps;
                });
            },
            onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
        });
    };

    return {
        searchCustomers,
        customers,
        searchPocsHermesIds,
        pocIds,
        searchEmployeesByName,
        employees
    };
};

export default useFilters;
