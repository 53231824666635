import { ErrorMessages } from '@/graphql/main';

// IF you change something here, be sure that you change it in public/locales/*/translation.json as well
const ERROR_CODES = {
    PasswordsNotMatch: 'PASSWORDS_NOT_MATCH',
    CodeIsRequired: 'CODE_REQUIRED',
    DeclarationNotScanned: 'DECLARATION_NOT_SCANNED',
    EmailRequired: 'EMAIL_REQUIRED',
    EmailInvalid: 'NOT_VALID_EMAIL',
    NotAuthenticated: 'AUTH_NOT_AUTHENTICATED',
    ...ErrorMessages
};

export default ERROR_CODES;
