import styled from 'styled-components';

export const StyledModalSubMsg = styled.p`
    margin-top: 0;
    padding: ${({ theme }) => theme.space[16]};
    line-height: ${({ theme }) => theme.lineHeight[24]};
    font-size: ${({ theme }) => theme.fontSize.h6};
    font-family: ${({ theme }) => theme.fontFamily.interNormal};
    color: ${({ theme }) => theme.color.default.black};
`;
