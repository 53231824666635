import { Form } from 'antd';

import DownloadButton from '@/components/admin/AdminButtons/DownloadButton';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { AssetsRawDataReportRequestModelInput } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { downloadRawDataReportApiCall } from '@/services/admin/downloadRawDataReportApiCall';

type Props = {
    dates: AssetsRawDataReportRequestModelInput;
    hasResults: boolean;
};

const RawDataReportActions: React.FC<Props> = ({ dates, hasResults }) => {
    const { setError } = useError();
    const handleDownload = () => {
        downloadRawDataReportApiCall(dates)
            .then(async (res) => {
                const filename = res.headers
                    .get('content-disposition')
                    ?.split(';')
                    ?.find((n) => n.includes("filename*=UTF-8''"))
                    ?.replace("filename*=UTF-8''", '')
                    ?.trim();
                const decodedFileName = decodeURI(filename ?? 'excelSheet');
                const blob = await res.blob();
                const url = URL.createObjectURL(new Blob([blob], { type: 'application/xlsx' }));
                const a = document.createElement('a');
                a.href = url;
                a.download = decodedFileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => {
                setError(error, ErrorTypeEnum.Error);
            });
    };

    return (
        <Form.Item>
            <DownloadButton
                style={{ alignSelf: 'flex-end' }}
                onClick={handleDownload}
                disabled={!dates.fromDate || !dates.toDate || !hasResults}
            />
        </Form.Item>
    );
};

export default RawDataReportActions;
