import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

type StyledActionsProps = {
    $hasMoreThanOneItem: boolean;
};

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 25px 50px;

    & > div {
        display: flex;
        align-items: center;
    }
`;

export const StyledLogoWrapper = styled.div`
    margin-right: 50px;
`;

export const StyledAdminNavigation = styled.div`
    & > a:first-of-type {
        margin-left: 100px;
    }

    @media (max-width: 1024px) {
        & > a:first-of-type {
            margin-left: 50px;
        }
    }
`;

export const StyledNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.color.other.dark};
    font-weight: bold;
    text-decoration: none;
    font-size: 20px;
    margin-left: 30px;
    padding-bottom: 5px;

    &.active {
        color: ${({ theme }) => theme.color.other.violet};
        border-bottom-width: 3px;
        border-color: ${({ theme }) => theme.color.other.violet};
        border-bottom-style: solid;
    }

    @media (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSize.h6};
    }
`;

export const StyledDropdownNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.color.other.dark};
    text-decoration: none;
    font-size: 16px;

    @media (max-width: 1024px) {
        font-size: 12px;
    }
`;
export const StyledBorderlessNavLink = styled(StyledNavLink)`
    color: ${({ theme }) => theme.color.other.dark} !important;
    border: none !important;
`;

export const StyledActions = styled.div<StyledActionsProps>`
    display: flex;
    ${($hasMoreThanOneItem) =>
        $hasMoreThanOneItem &&
        css`
            justify-content: space-between;
        `}
`;
