import { useState } from 'react';

import TABLE_CONSTANTS from '@constants/admin/tables';
import { Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import TextInput from '@/components/admin/TextInput';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { PaymentFrequency, SortEnumType, useGetPaymentFrequenciesQuery } from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import useError from '@/hooks/error/useError';
import useColDefs from '@/pages/admin/CommercialAgreements/PaymentFrequencies/hooks/useColDefs';
import CreatePaymentFrequencyModal from '@/pages/admin/CommercialAgreements/PaymentFrequencies/partials/CreatePaymentFrequencyModal';

const PaymentFrequenciesTable = () => {
    const {
        handlePageChange,
        pageSize,
        itemsToSkip,
        setTotalItems,
        totalItems,
        setSortingOrder,
        sortingOrder
    } = usePaginate();
    const { setError, clearError } = useError();
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const { TableSize } = TABLE_CONSTANTS;
    const { loading, refetch, data } = useGetPaymentFrequenciesQuery({
        variables: {
            searchText,
            take: pageSize,
            skip: itemsToSkip,
            order: sortingOrder
        },
        onCompleted: (data) => {
            if (data?.paymentFrequencies?.items) {
                setTotalItems(data.paymentFrequencies.totalCount);
            }
            clearError();
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const { colDef } = useColDefs({
        onChangeSuccess: async () => await refetch()
    });
    const onSortChange = (sorterResult: SorterResult<PaymentFrequency>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <>
            <SectionHeading heading={t('PAYMENT_FREQUENCIES')} />
            <Row justify={'space-between'} align={'bottom'} style={{ marginBottom: 18 }}>
                <TextInput inputValue={searchText} setInputValue={setSearchText} />
                <CreatePaymentFrequencyModal successCallback={refetch} />
            </Row>
            <ResizableTable
                colDefs={colDef}
                tableData={(data?.paymentFrequencies?.items as PaymentFrequency[]) || []}
                emptyText={t('NO_RESULTS')}
                loading={loading}
                onPageChange={handlePageChange}
                totalPaginationItems={totalItems}
                size={TableSize}
                onChange={(_, __, sorterResult) =>
                    onSortChange(sorterResult as SorterResult<PaymentFrequency>)
                }
            />
        </>
    );
};

export default PaymentFrequenciesTable;
