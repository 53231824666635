import { TableDataProvider } from '@/hooks/admin/useTableData';
import DistributorsTable from '@/pages/admin/CommercialAgreements/Distributors/partials/DistributorsTable';

const Distributors = () => (
    <TableDataProvider>
        <DistributorsTable />
    </TableDataProvider>
);

export default Distributors;
