import { useContext, useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as AttachmentIcon } from '@/assets/svg-icons/attachment.svg';
import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import { ReactComponent as SendIcon } from '@/assets/svg-icons/send-icon.svg';
import InfoMessage from '@/components/shared/message/InfoMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import SuccessModal from '@/components/shared/modal/SuccessModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import IconContainer from '@/components/shared/UI/IconContainer';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    DocumentStatusEnum,
    GetAgreementDetailsQuery,
    GetAgreementDetailsQueryVariables,
    useResendAdditionalDocumentEmailMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useFormData from '@/hooks/form-data/useFormData';
import { previewAdditionalDocumentPdf } from '@/pages/asset/shared/utils/preview-pdf';
import AppContext from '@/store/app-context/AppContext';
import { FlexContainer, HeaderContainer } from '@/styles/shared/container/styles';
import {
    ListItem,
    ListItemCardCustomerName,
    ListItemCardDate,
    ListItemCardFooter,
    ListItemCardFooterWrapper,
    ListItemCardRowHeader
} from '@/styles/shared/list-item/styles';
import { formatDate } from '@/utils/formatDate';

import AdditionalDocumentsModal from './partials/AttachDocumentsModal';

type Props = {
    agreementStatus?: DocumentStatusEnum;
    refetchAgreementDetails?: (
        variables?: GetAgreementDetailsQueryVariables | undefined
    ) => Promise<ApolloQueryResult<GetAgreementDetailsQuery>>;
};

const AdditionalDocuments: React.FC<Props> = ({ agreementStatus, refetchAgreementDetails }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const {
        additionalDocsFormData,
        agreementDetails: { currentAgreementDetails },
        modal: { isModalOpened, setIsModalOpened }
    } = useContext(AppContext);
    const { clearAllFormData } = useFormData();
    const [resendAdditionalDocuments] = useResendAdditionalDocumentEmailMutation();
    const { errorMessage, setError, clearError } = useError();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { state } = useLocation();

    const isAddButtonDisabled =
        agreementStatus !== DocumentStatusEnum.Signed ||
        (!currentAgreementDetails?.multipleAdditionalDocumentsAllowed &&
            currentAgreementDetails?.additionalDocuments?.length &&
            currentAgreementDetails?.additionalDocuments?.length >= 1);

    const handleCloseModal = () => {
        setIsModalOpened(false);
        clearAllFormData();
        refetchAgreementDetails?.();
    };
    const handleResendDocument = (documentId: number) => {
        resendAdditionalDocuments({
            variables: {
                documentId
            },
            onCompleted: () => {
                setIsSuccess(true);
            },
            onError: (error) => {
                setError(error, ErrorTypeEnum.ApolloError);
            }
        });
    };
    useEffect(() => {
        if (!additionalDocsFormData.data) {
            additionalDocsFormData.setFormData(new FormData());
        }
    }, [additionalDocsFormData]);
    useEffect(() => {
        if (state?.modalOpen) setIsModalOpened(true);
    }, [setIsModalOpened, state]);

    return (
        <>
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[0]}>
                <h3>{t('ADDITIONAL_FILES')}</h3>
                <CustomButton
                    onClick={() => setIsModalOpened(true)}
                    label={t('ADD')}
                    buttonType={
                        isAddButtonDisabled ? ButtonsTypesEnum.Disabled : ButtonsTypesEnum.Main
                    }
                    icon={<PlusIcon />}
                />
            </HeaderContainer>

            {agreementStatus === DocumentStatusEnum.Draft && (
                <InfoMessage message={t('ATTACH_DOCUMENTS_INFO_MESSAGE')} />
            )}

            {isModalOpened && (
                <AdditionalDocumentsModal
                    isModalOpened={isModalOpened}
                    closeModal={handleCloseModal}
                />
            )}
            {!!currentAgreementDetails?.additionalDocuments?.length &&
                currentAgreementDetails.additionalDocuments.map((document, index) => (
                    <FlexContainer key={index} $marginBottom={theme?.space[12]}>
                        <ListItem>
                            <ListItemCardRowHeader>
                                <ListItemCardCustomerName
                                    direction="row"
                                    value={t('DOCUMENT_STACK')}
                                    bolder={true}
                                />

                                <CustomButton
                                    onClick={() =>
                                        document?.additionalDocumentId &&
                                        handleResendDocument(document?.additionalDocumentId)
                                    }
                                    label={t('RESEND')}
                                    buttonType={ButtonsTypesEnum.Secondary}
                                    icon={<SendIcon />}
                                />
                            </ListItemCardRowHeader>
                            {document?.pdfName && (
                                <CustomButton
                                    label={document?.pdfName}
                                    maxWidth={252}
                                    buttonType={ButtonsTypesEnum.Tertiary}
                                    icon={
                                        <AttachmentIcon
                                            style={{
                                                height: theme?.space[24],
                                                width: theme?.space[24]
                                            }}
                                        />
                                    }
                                    onClick={() =>
                                        previewAdditionalDocumentPdf(document.pdfName, setError)
                                    }
                                    style={{
                                        color: theme?.color.blue[50],
                                        height: theme?.space[36],
                                        marginTop: theme?.space[20],
                                        marginBottom: theme?.space[20]
                                    }}
                                />
                            )}
                            <ListItemCardFooterWrapper $justify="flex-end">
                                <ListItemCardFooter $gap={theme?.space[12]}>
                                    <ListItemCardDate>
                                        <IconContainer>
                                            <CalendarIcon />
                                        </IconContainer>
                                        <span>{formatDate(document?.dateCreated)}</span>
                                    </ListItemCardDate>
                                </ListItemCardFooter>
                            </ListItemCardFooterWrapper>
                        </ListItem>
                    </FlexContainer>
                ))}
            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
            {isSuccess && (
                <SuccessModal
                    message={t('EMAIL_RESEND_ADDITIONAL_DOCUMENTS')}
                    onConfirm={() => setIsSuccess(false)}
                />
            )}
        </>
    );
};

export default AdditionalDocuments;
