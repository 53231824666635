import { Route, Routes } from 'react-router-dom';

import {
    BANK_CERTIFICATE_FORM_KEYS,
    GENERATE_PROTOCOL_FORM_KEYS,
    INVOICE_FORM_KEYS
} from '@/constants/keys/form-data-keys';
import ROUTES from '@/constants/routes/routes';
import AddFreeBeerQuantity from '@/pages/agreement/AgreementDetails/partials/AdditionalDocuments/partials/AddFreeBeerQuantity';

import ProtectedSRRoute from '../ProtectedSRRoute';
import DeclarationPreview from '../shared/declaration/DeclarationPreview';
import DeclarationScan from '../shared/declaration/DeclarationScan';
import DocumentScan from '../shared/document/DocumentScan';
import SigningArea from '../shared/sign/SignArea';

import AgreementDetails from './AgreementDetails';
import AddInvoice from './AgreementDetails/partials/AdditionalDocuments/partials/AddInvoice';
import GenerateAgreementProtocol from './AgreementDetails/partials/AdditionalDocuments/partials/GenerateAgreementProtocol';
import AgreementPreview from './AgreementPreview';
import Agreements from './Agreements';
import CreateAgreement from './CreateAgreement';
import SignAgreement from './SignAgreement';
import ValidateAgreement from './ValidateAgreement';

const AgreementRoutes: React.FC = () => (
    <Routes>
        <Route key="agreements" path={ROUTES.AgreementList.Path} element={<ProtectedSRRoute />}>
            <Route index element={<Agreements />} />
            <Route path={ROUTES.AgreementList.Agreement.Path}>
                <Route index element={<AgreementDetails />} />
                <Route path={ROUTES.AgreementList.Declaration.Scan} element={<DeclarationScan />} />
                <Route
                    path={ROUTES.AgreementList.Declaration.Preview}
                    element={<DeclarationPreview />}
                />

                <Route path={ROUTES.AgreementList.AgreementPreview.Path}>
                    <Route index element={<AgreementPreview />} />

                    <Route path={ROUTES.AgreementList.AgreementPreview.Sign.Path}>
                        <Route index element={<SignAgreement />} />
                        <Route
                            path={ROUTES.AgreementList.AgreementPreview.Sign.SigningArea}
                            element={<SigningArea />}
                        />
                    </Route>
                    <Route path={ROUTES.AgreementList.AgreementPreview.Validate.Path}>
                        <Route index element={<ValidateAgreement />} />
                        <Route
                            path={ROUTES.AgreementList.AgreementPreview.Sign.SigningArea}
                            element={<SigningArea />}
                        />
                    </Route>
                </Route>

                <Route path={ROUTES.AgreementList.Agreement.AdditionalDocuments.Invoice.AddInvoice}>
                    <Route index element={<AddInvoice />} />

                    <Route path={ROUTES.AgreementList.Agreement.AdditionalDocuments.Invoice.Scan}>
                        <Route
                            index
                            element={
                                <DocumentScan
                                    formDataKey={INVOICE_FORM_KEYS.SCANNED_INVOICE}
                                    title={'SCAN_INVOICE'}
                                />
                            }
                        />
                    </Route>
                </Route>
                <Route
                    path={ROUTES.AgreementList.Agreement.AdditionalDocuments.FreeBeerQuantity}
                    element={<AddFreeBeerQuantity />}
                />

                <Route
                    path={ROUTES.AgreementList.Agreement.AdditionalDocuments.ScanBankCertificate}>
                    <Route
                        index
                        element={
                            <DocumentScan
                                formDataKey={BANK_CERTIFICATE_FORM_KEYS.SCANNED_BANK_CERTIFICATE}
                                title={'SCAN_BANK_CERTIFICATE'}
                            />
                        }
                    />
                </Route>

                <Route
                    path={
                        ROUTES.AgreementList.Agreement.AdditionalDocuments.AgreementProtocol
                            .Generate
                    }>
                    <Route index element={<GenerateAgreementProtocol />} />

                    <Route
                        path={
                            ROUTES.AgreementList.Agreement.AdditionalDocuments.AgreementProtocol
                                .Scan
                        }>
                        <Route
                            index
                            element={
                                <DocumentScan
                                    formDataKey={GENERATE_PROTOCOL_FORM_KEYS.SCANNED_PROTOCOL}
                                    title={'SCAN_AGREEMENT_PROTOCOL'}
                                />
                            }
                        />

                        <Route path={ROUTES.AgreementList.AgreementPreview.Sign.Path}>
                            <Route
                                index
                                element={
                                    <SignAgreement
                                        isAdditionalDocuments={true}
                                        isGenerateProtocol={true}
                                    />
                                }
                            />
                            <Route
                                path={ROUTES.AgreementList.AgreementPreview.Sign.SigningArea}
                                element={<SigningArea />}
                            />
                        </Route>
                    </Route>

                    <Route path={ROUTES.AgreementList.AgreementPreview.Sign.Path}>
                        <Route index element={<SignAgreement isAdditionalDocuments={true} />} />
                        <Route
                            path={ROUTES.AgreementList.AgreementPreview.Sign.SigningArea}
                            element={<SigningArea />}
                        />
                    </Route>
                </Route>

                <Route path={ROUTES.AgreementList.Agreement.Path} />
            </Route>
            <Route path={ROUTES.AgreementList.CreateAgreement.Path} element={<CreateAgreement />} />
        </Route>
    </Routes>
);

export default AgreementRoutes;
