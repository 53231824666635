import React from 'react';

import { StyledLabel, StyledLabelContainer, StyledValue } from './styles';

type Props = {
    label?: string;
    value?: string | number;
    direction?: 'column' | 'row';
    className?: string;
    onClickLabel?: () => void;
    onClickValue?: () => void;
    bolder?: boolean;
};

const TextLabelValue: React.FC<Props> = ({
    label,
    value,
    direction,
    className,
    onClickLabel,
    onClickValue,
    bolder
}) => {
    return (
        <StyledLabelContainer className={className} $direction={direction}>
            {label && (
                <StyledLabel
                    onClick={onClickLabel}
                    $direction={direction}
                    $hasMargin={!!(label && value)}>
                    {label}
                </StyledLabel>
            )}
            {value && (
                <StyledValue $bolder={bolder} onClick={onClickValue}>
                    {value}
                </StyledValue>
            )}
        </StyledLabelContainer>
    );
};

export default TextLabelValue;
