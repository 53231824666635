import { Button } from 'antd';
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
    display: inline-block;
    width: 100%;
`;

export const DropdownButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const DropdownTextWrapper = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
