import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateTargetSkuInput, useCreateTargetSkuMutation } from '@/graphql/main';

type Props = {
    successCallback?: () => void;
};

const CreateTargetSkuModal = ({ successCallback }: Props) => {
    const [createTargetSkuMutation] = useCreateTargetSkuMutation();
    const [form] = Form.useForm<CreateTargetSkuInput>();
    const { t } = useTranslation('translation');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModalShow = () => {
        form.resetFields();
        setIsModalOpen(!isModalOpen);
    };

    const handleCreateTargetSku = async (values: CreateTargetSkuInput) => {
        try {
            await createTargetSkuMutation({
                variables: { input: { ...values } },
                onCompleted: () => {
                    successCallback?.();
                    message.success(t('CREATE_ITEM_SUCCESS'));
                },
                onError: () => message.error(t('CREATE_ITEM_FAIL'))
            });
            toggleModalShow();
        } catch {
            message.error(t('CREATE_ITEM_FAIL'));
        } finally {
            form.resetFields();
        }
    };

    return (
        <>
            <Row justify="end" style={{ marginTop: 18 }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={toggleModalShow}>
                    {t('NEW_TARGET_SKU')}
                </Button>
            </Row>
            <Modal
                title={t('CREATE_TARGET_SKU')}
                open={isModalOpen}
                onCancel={toggleModalShow}
                footer={[
                    <Button key="back" onClick={toggleModalShow}>
                        {t('CANCEL')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => form.submit()}>
                        {t('CREATE_TARGET_SKU')}
                    </Button>
                ]}>
                <Form
                    form={form}
                    initialValues={{ isActive: true }}
                    onFinish={handleCreateTargetSku}>
                    <Form.Item
                        name="name"
                        label={t('NAME')}
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('NAME_REQUIRED') }]}>
                        <Input placeholder={t('NAME')} />
                    </Form.Item>
                    <Form.Item
                        label={t('ACTIVE')}
                        name="isActive"
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: t('ACTIVE_STATUS_REQUIRED') }]}>
                        <Select placeholder={t('ACTIVE')}>
                            <Select.Option value={true}>{t('YES')}</Select.Option>
                            <Select.Option value={false}>{t('NO')}</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateTargetSkuModal;
