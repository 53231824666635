import { CSSProperties, Dispatch, SetStateAction } from 'react';

import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
    setFromDate: Dispatch<SetStateAction<Date | undefined>>;
    setToDate: Dispatch<SetStateAction<Date | undefined>>;
    style?: CSSProperties;
};

const DateRangePicker: React.FC<Props> = ({ setFromDate, setToDate, style }) => {
    const { RangePicker } = DatePicker;

    const { t } = useTranslation('translation');

    return (
        <RangePicker
            inputReadOnly
            style={style}
            placeholder={[t('START_DATE'), t('END_DATE')]}
            onChange={(e) => {
                if (e?.[0] && e?.[1]) {
                    const fromDate = e[0].toDate();
                    const toDate = e[1].toDate();
                    setFromDate(fromDate);
                    setToDate(toDate);
                } else {
                    setFromDate(undefined);
                    setToDate(undefined);
                }
            }}
        />
    );
};

export default DateRangePicker;
