import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { setCacheVal } from '@/services/utils/cacheUtils';
import {
    autoTokenRefresh,
    getToken,
    isTokenNearToExpire,
    refreshToken
} from '@/utils/authorizationUtils';

import envconfig from './envconfig';

const httpLink = createHttpLink({
    uri: envconfig.apolloClientAddress
});

let isFirstRequest = false;
// Adds token to every graphQL request
const authLink = setContext(async (_, { headers }) => {
    const token = getToken();
    if (!!token && isTokenNearToExpire(token)) {
        const newToken = (await refreshToken())?.token;
        if (newToken) {
            setCacheVal(LOCAL_STORAGE_KEYS.Token, newToken);
        }
    }

    if (!isFirstRequest) {
        isFirstRequest = true;
        autoTokenRefresh();
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `bearer ${token}` : ''
        }
    };
});
export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});
