import { StyledModalHeader } from './styles';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
    backgroundColor?: string;
};
const ModalHeader: React.FC<Props> = ({ children, className, backgroundColor }) => {
    return (
        <StyledModalHeader className={className} style={{ backgroundColor }}>
            {children}
        </StyledModalHeader>
    );
};

export default ModalHeader;
