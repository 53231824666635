import React, { useContext, useEffect, useRef, useState } from 'react';

import ROUTES from '@constants/routes/routes';
import { DatePicker, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as CameraIcon } from '@/assets/svg-icons/camera-icon.svg';
import ErrorMessage from '@/components/shared/message/ErrorMessage';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import envconfig from '@/config/envconfig';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import {
    AssetDto,
    CustomerDto,
    DocumentStatusEnum,
    useGetUserCustomerByNameQuery
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import { getCacheVal } from '@/services/utils/cacheUtils';
import AppContext from '@/store/app-context/AppContext';
import { Container, FlexContainer, HeaderContainer } from '@/styles/shared/container/styles';
import {
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardValue
} from '@/styles/shared/list-item/styles';
import { getToken } from '@/utils/authorizationUtils';

import { StyledScanBtnContainer } from './styles';

dayjs.extend(utc);

const AssetDeclarationScan: React.FC = () => {
    const { t } = useTranslation('translation');
    const { navbar } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef(null);
    const theme = useTheme();
    const [customerName, setCustomerName] = useState<string>();
    const [customer, setCustomer] = useState<CustomerDto | undefined>();
    const { setError, clearError, errorMessage } = useError();
    const [utcDate, setUtcDate] = useState<string>(new Date().toUTCString());
    const [scanDeclarationLoading, setScanDeclarationLoading] = useState<boolean>(false);
    const locationState = location.state as {
        status: DocumentStatusEnum;
        selectedCustomer?: CustomerDto;
    } | null;
    const params = useParams<{ assetId?: AssetDto['assetId'] }>();
    const isInCreateContractProcess = !!locationState?.selectedCustomer;

    const { loading: customerDataLoading } = useGetUserCustomerByNameQuery({
        variables: {
            input: customerName!
        },
        onCompleted: (data) => {
            setCustomer(data.userCustomersByName[0]);
            clearError();
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    useEffect(() => {
        const customerName = getCacheVal(LOCAL_STORAGE_KEYS.CustomerName);

        if (!customerName) {
            return;
        }
        setCustomerName(customerName);

        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            onBackCallOverrideBackFunctionality: () =>
                isInCreateContractProcess
                    ? navigate(ROUTES.FullPaths.CreateContract, {
                          state: { selectedCustomer: locationState?.selectedCustomer }
                      })
                    : navigate(-1)
        });

        return () => {
            navbar.disposeNavbar();
        };
    }, []);

    const handleTakePhoto = () => {
        if (inputRef.current) {
            (inputRef.current as HTMLInputElement).click();
        }
    };

    const handleConfirmPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !locationState?.status || !customer?.id) {
            return;
        }

        setScanDeclarationLoading(true);

        const formData = new FormData();
        formData.append('declaration', e.target.files[0]);

        return await fetch(
            `${envconfig.uploadDeclarationUrl}?${new URLSearchParams({
                customerId: customer?.id,
                declarationDate: utcDate,
                status: locationState?.status
            })}`,
            {
                headers: {
                    Authorization: `bearer ${getToken()}`
                },
                method: 'POST',
                body: formData
            }
        )
            .then(async (resp) => {
                setScanDeclarationLoading(false);

                if (!resp.ok) {
                    const jsonResp = await resp.json();

                    setError(jsonResp, ErrorTypeEnum.Error);
                } else {
                    message.success(t('PICTURE_UPDATED_SUCCESSFULLY'));
                    isInCreateContractProcess
                        ? navigate(ROUTES.FullPaths.CreateContract, {
                              state: { selectedCustomer: locationState?.selectedCustomer }
                          })
                        : navigate(ROUTES.FullPaths.Protocol(params.assetId));
                }
            })
            .catch((errors) => {
                setScanDeclarationLoading(false);
                setError(errors, ErrorTypeEnum.Error);
            });
    };

    const handleDateChange = (e: any) => {
        setUtcDate(
            dayjs(e as dayjs.Dayjs)
                .utc()
                .toString()
        ); // Parse to UTC
    };

    return (
        <Container $height={100} $position="relative">
            <HeaderContainer $padding={theme?.space[0]} $marginBottom={theme?.space[12]}>
                <StyledPageTitle>{t('SCAN_DECLARATION')}</StyledPageTitle>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </HeaderContainer>

            <SpinnerContainer loading={scanDeclarationLoading}>
                <SpinnerContainer loading={customerDataLoading}>
                    <Content>
                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="column" label={t('CUSTOMER')} />
                                <ListItemCardValue
                                    $color={theme?.color.grey[70]}
                                    direction="column"
                                    value={customer?.name ?? undefined}
                                />
                            </div>
                        </ListItemCardRow>

                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="row" label={t('CUSTOMER_ADDRESS')} />
                                <ListItemCardValue
                                    $color={theme?.color.grey[70]}
                                    direction="column"
                                    value={customer?.customerAddress ?? undefined}
                                />
                            </div>
                        </ListItemCardRow>

                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="row" label={t('CUSTOMER_VAT')} />
                                <ListItemCardValue
                                    $color={theme?.color.grey[70]}
                                    direction="column"
                                    value={customer?.customerVat ?? undefined}
                                />
                            </div>
                        </ListItemCardRow>

                        <FlexContainer $flexDirection="column">
                            <ListItemCardLabel direction="row" label={t('DECLARATION_DATE')} />

                            <DatePicker
                                inputReadOnly
                                onChange={handleDateChange}
                                placeholder={t('PICK_DATE_FROM_CALENDAR')}
                                format="YYYY-MM-DD HH:mm"
                                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
                                defaultValue={dayjs(Date())}
                            />
                        </FlexContainer>
                    </Content>
                </SpinnerContainer>

                <StyledScanBtnContainer>
                    <CustomButton
                        icon={<CameraIcon />}
                        onClick={handleTakePhoto}
                        label={t('TAKE_A_PICTURE')}
                        buttonType={ButtonsTypesEnum.Main}
                    />
                    <input
                        onInput={handleConfirmPhoto}
                        accept={'image/*'}
                        type="file"
                        ref={inputRef}
                        capture={'environment'}
                    />
                </StyledScanBtnContainer>
            </SpinnerContainer>

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </Container>
    );
};

export default AssetDeclarationScan;
