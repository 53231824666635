import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '@/assets/svg-icons/calendar-icon.svg';
import { ReactComponent as ChevronIcon } from '@/assets/svg-icons/chevron-icon.svg';
import { ReactComponent as CommentIcon } from '@/assets/svg-icons/comment-icon.svg';
import { ReactComponent as DotIcon } from '@/assets/svg-icons/dot-icon.svg';
import { ReactComponent as InstallIcon } from '@/assets/svg-icons/install-icon.svg';
import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import { ReactComponent as UninstallIcon } from '@/assets/svg-icons/uninstall-icon.svg';
import { ReactComponent as XCIcon } from '@/assets/svg-icons/xc-icon.svg';
import ContractIcon from '@/components/shared/asset-icons/ContractIcon';
import ProtocolIcon from '@/components/shared/asset-icons/ProtocolIcon';
import CommentModal from '@/components/shared/modal/CommentModal';
import EmailHistoryModal from '@/components/shared/modal/EmailHistoryModal';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import WarningModal from '@/components/shared/modal/WarningModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import IconContainer from '@/components/shared/UI/IconContainer';
import LOCAL_STORAGE_KEYS from '@/constants/keys/local-storage-keys';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AssetDto,
    AssetTypeEnum,
    DocumentStatusEnum,
    DocumentTypeEnum,
    useCancelAssetMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';
import useStatus from '@/hooks/status/useStatus';
import { downloadPdf } from '@/pages/asset/shared/utils/download-pdf';
import { setCacheVal } from '@/services/utils/cacheUtils';
import { ChevronArrowIconContainer } from '@/styles/shared/icon/styles';
import {
    ListItemCardCustomerName,
    ListItemCardDate,
    ListItemCardFooter,
    ListItemCardLabel,
    ListItemCardRow,
    ListItemCardRowHeader
} from '@/styles/shared/list-item/styles';
import { formatDate } from '@/utils/formatDate';

import {
    AssetCardContainer,
    AssetCardRightCol,
    AssetCardStatusLabel,
    AssetCardStatusLine,
    AssetCardValue,
    CommentButton,
    StyledAssetCardProtocolType,
    StyledProtocolTypeLabel
} from './styles';

type Props = {
    asset: AssetDto;
    isSelectionMode: boolean;
    onAssetCancellation?: () => void;
    onSelection: (assetId: number) => void;
};

const AssetCard: React.FC<Props> = ({
    asset: {
        assetType,
        assetId,
        status,
        customerName,
        pocName,
        hermesPocId,
        contractNumber,
        contracted,
        contractedQuantity,
        comment,
        lastModified,
        protocolType
    },
    isSelectionMode,
    onAssetCancellation,
    onSelection
}) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { getStatusLabel, getStatusStyle, getNormalizedProtocolType } = useStatus();
    const { setError, errorMessage, clearError } = useError();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [isResendModalOpened, setIsResendModalOpened] = useState(false);
    const [isCommentModalOpened, setIsCommentModalOpened] = useState(false);

    const [cancelAsset] = useCancelAssetMutation();

    const isNonShareableStatus =
        status === DocumentStatusEnum.Signed || status === DocumentStatusEnum.Canceled;
    const isNonShareableDocType = assetType === AssetTypeEnum.Contract;
    const isShareDisabled = isSelectionMode && (isNonShareableDocType || isNonShareableStatus);

    const handleAssetSign = () => {
        setCacheVal(LOCAL_STORAGE_KEYS.CustomerName, customerName);

        assetType === AssetTypeEnum.Protocol
            ? navigate(ROUTES.AssetList.Protocol.DynamicPath(assetId))
            : navigate(ROUTES.AssetList.Contract.DynamicPath(assetId));
    };

    const handleAssetCancel = () => {
        setCancelLoading(true);
        cancelAsset({
            variables: {
                assetId,
                assetType
            }
        })
            .then(() => {
                onAssetCancellation!();
                setIsExpanded(false);
                setCancelLoading(false);
                setIsWarningModalOpened(false);
            })
            .catch((error) => {
                setCancelLoading(false);
                setIsWarningModalOpened(false);
                setError(error, ErrorTypeEnum.ApolloError);
            });
    };

    const handleSelect = () => {
        if (
            isSelectionMode &&
            status === DocumentStatusEnum.Prepared &&
            assetType !== AssetTypeEnum.Contract
        ) {
            setIsSelected((prevState) => !prevState);
            onSelection(assetId);
        }
    };

    useEffect(() => {
        if (!isSelectionMode) {
            setIsSelected(false);
        } else {
            setIsExpanded(false);
        }
    }, [isSelectionMode]);

    return (
        <>
            <AssetCardContainer
                $isSelected={isSelected}
                $isSelectionMode={isSelectionMode}
                $isShareDisabled={isShareDisabled}
                onClick={handleSelect}>
                <ListItemCardRowHeader>
                    <ListItemCardCustomerName direction="row" value={customerName} bolder={true} />
                    {!isExpanded && !isSelectionMode && (
                        <ChevronArrowIconContainer
                            onClick={() => setIsExpanded(true)}
                            $isRotated={isExpanded}>
                            <ChevronIcon />
                        </ChevronArrowIconContainer>
                    )}
                    {isExpanded && (
                        <ChevronArrowIconContainer
                            onClick={() => setIsExpanded(false)}
                            $isRotated={isExpanded}>
                            <ChevronIcon />
                        </ChevronArrowIconContainer>
                    )}
                </ListItemCardRowHeader>
                {assetType === AssetTypeEnum.Protocol && (
                    <ListItemCardRow>
                        <div>
                            <ListItemCardLabel direction="column" label={t('POC_NAME')} />
                            <AssetCardValue direction="column" value={pocName ?? undefined} />
                        </div>
                        {isExpanded && (
                            <AssetCardRightCol>
                                <ListItemCardLabel direction="column" label={t('POC_ID')} />
                                <AssetCardValue
                                    direction="column"
                                    value={hermesPocId ?? undefined}
                                />
                            </AssetCardRightCol>
                        )}
                    </ListItemCardRow>
                )}
                {isExpanded && (
                    <>
                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="column" label={t('STATUS')} />
                                <AssetCardStatusLabel $style={getStatusStyle(status)}>
                                    {getStatusLabel(status)}
                                </AssetCardStatusLabel>
                            </div>
                            <AssetCardRightCol>
                                <ListItemCardLabel direction="column" label={t('CONTRACT_NO')} />
                                <AssetCardValue
                                    direction="column"
                                    value={contractNumber ?? undefined}
                                />
                            </AssetCardRightCol>
                        </ListItemCardRow>
                        <ListItemCardRow>
                            <div>
                                <ListItemCardLabel direction="column" label={t('CONTRACTED')} />
                                <AssetCardValue
                                    direction="column"
                                    value={contracted ?? undefined}
                                />
                            </div>

                            <AssetCardRightCol>
                                <ListItemCardLabel direction="column" label={t('CONT_QUANTITY')} />
                                <AssetCardValue
                                    direction="column"
                                    value={contractedQuantity.toString()}
                                />
                            </AssetCardRightCol>
                        </ListItemCardRow>

                        {status === DocumentStatusEnum.Signed && (
                            <ListItemCardRow>
                                <CustomButton
                                    onClick={() => setIsResendModalOpened(true)}
                                    label={t('PREVIEW_EMAIL')}
                                    buttonType={ButtonsTypesEnum.Secondary}
                                    style={{ whiteSpace: 'nowrap' }}
                                />
                                <CustomButton
                                    onClick={() =>
                                        downloadPdf(
                                            assetId,
                                            DocumentTypeEnum.Asset,
                                            setError,
                                            assetType
                                        )
                                    }
                                    label={t('DOWNLOAD_PDF')}
                                    buttonType={ButtonsTypesEnum.Main}
                                />
                            </ListItemCardRow>
                        )}

                        {status === DocumentStatusEnum.Prepared && (
                            <ListItemCardRow>
                                <CustomButton
                                    onClick={() => setIsWarningModalOpened(true)}
                                    label={t('CANCEL')}
                                    buttonType={ButtonsTypesEnum.DestructiveMain}
                                    icon={<XCIcon />}
                                />
                                <CustomButton
                                    onClick={handleAssetSign}
                                    label={t('SIGN')}
                                    buttonType={ButtonsTypesEnum.Secondary}
                                    icon={<SignIcon />}
                                />
                            </ListItemCardRow>
                        )}
                    </>
                )}

                <ListItemCardFooter>
                    <div>
                        {assetType === AssetTypeEnum.Protocol ? <ProtocolIcon /> : <ContractIcon />}
                    </div>
                    <ListItemCardFooter>
                        <ListItemCardDate>
                            <IconContainer>
                                <CalendarIcon />
                            </IconContainer>
                            <span>{formatDate(lastModified)}</span>
                        </ListItemCardDate>
                        {protocolType && (
                            <StyledAssetCardProtocolType>
                                {getNormalizedProtocolType(protocolType) === 'Install' && (
                                    <IconContainer>
                                        <InstallIcon />
                                    </IconContainer>
                                )}
                                {getNormalizedProtocolType(protocolType) === 'Uninstall' && (
                                    <IconContainer>
                                        <UninstallIcon />
                                    </IconContainer>
                                )}
                                <StyledProtocolTypeLabel>
                                    {getNormalizedProtocolType(protocolType)}
                                </StyledProtocolTypeLabel>
                            </StyledAssetCardProtocolType>
                        )}

                        {comment && (
                            <CommentButton onClick={() => setIsCommentModalOpened(true)}>
                                <CommentIcon />
                                <DotIcon />
                            </CommentButton>
                        )}
                    </ListItemCardFooter>
                </ListItemCardFooter>
                <AssetCardStatusLine $style={getStatusStyle(status)} />
            </AssetCardContainer>

            {isWarningModalOpened && (
                <WarningModal
                    isDestructive={true}
                    accept={handleAssetCancel}
                    deny={() => setIsWarningModalOpened(false)}
                    cancelLoading={cancelLoading}
                    title={t('CANCEL_ASSET')}
                    message={t('CANCEL_ASSET_MESSAGE')}
                    subMessage={t('CANCEL_ASSET_SUBMESSAGE')}
                />
            )}

            {isResendModalOpened && (
                <EmailHistoryModal
                    close={() => setIsResendModalOpened(false)}
                    documentId={assetId}
                    documentType={DocumentTypeEnum.Asset}
                    assetType={assetType}
                />
            )}

            {isCommentModalOpened && (
                <CommentModal
                    initialComment={comment ?? ''}
                    viewOnly={true}
                    close={() => setIsCommentModalOpened(false)}
                />
            )}

            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </>
    );
};

export default AssetCard;
