import { Fragment, useContext, useEffect, useState } from 'react';

import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SignIcon } from '@/assets/svg-icons/sign-icon.svg';
import ErrorMessage from '@/components/shared/message/ErrorMessage';
import CustomButton from '@/components/shared/UI/CustomButton';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import envconfig from '@/config/envconfig';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { ProtocolDto, ProtocolTypeEnum } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { Container, ScrollableContainer } from '@/styles/shared/container/styles';
import { getCurrentContractData } from '@/utils/assetStorage';
import { getToken } from '@/utils/authorizationUtils';

import PREVIEW_STYLE from './preview-style';

const ProtocolPreview: React.FC = () => {
    const { navbar, protocol } = useContext(AppContext);
    const [data, setData] = useState<any>(); // eslint-disable-line
    const { setError, clearError, errorMessage } = useError();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { t } = useTranslation('translation');

    const currentProtocol = protocol.currentProtocol ?? getCurrentContractData<ProtocolDto>();
    const sanitizedHTML = DOMPurify.sanitize(data);

    document.body.style.width = 'unset';
    document.body.style.height = 'unset';

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.BackButton,
            rightSide: NavbarElementsEnum.NavbarLogo
        });

        const requestUrl = {
            [ProtocolTypeEnum.PosmInstall]: envconfig.previewInstallProtocolUrl,
            [ProtocolTypeEnum.PosmUninstall]: envconfig.previewUninstallProtocolUrl,
            [ProtocolTypeEnum.FrdiInstall]: envconfig.previewFrDiInstallProtocolUrl,
            [ProtocolTypeEnum.FrdiUninstall]: envconfig.previewFrDiUninstallProtocolUrl
        };

        fetch(envconfig.apiAddress + requestUrl[currentProtocol.protocolType], {
            headers: {
                Authorization: `bearer ${getToken()}`,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(currentProtocol)
        })
            .then(async (res) => {
                const data = await res.text();
                setData(data);
                setIsLoading(false);
                clearError();
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error, ErrorTypeEnum.Error);
            });

        return () => {
            navbar.disposeNavbar();
        };
    }, []); // eslint-disable-line

    const handleSign = () => {
        navigate(ROUTES.AssetList.Protocol.ProtocolPreview.Sign.Path);
    };

    return (
        <Fragment>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <ScrollableContainer $height={90}>
                <SpinnerContainer loading={isLoading}>
                    {data && <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />}
                </SpinnerContainer>
            </ScrollableContainer>
            <Container>
                <CustomButton
                    icon={<SignIcon />}
                    onClick={handleSign}
                    label={t('SIGN')}
                    buttonType={ButtonsTypesEnum.Main}
                    style={{ width: '100%' }}
                />
            </Container>
            <style id="front-end-style">{PREVIEW_STYLE}</style>
        </Fragment>
    );
};

export default ProtocolPreview;
