import React from 'react';

type TableDataContextType<T> = {
    dataState: T[];
    setDataState: (data: T[]) => void;
};

const TableDataContext = React.createContext<TableDataContextType<any> | undefined>(undefined);

export const useTableData = <T,>() => {
    const context = React.useContext<TableDataContextType<T> | undefined>(TableDataContext);
    if (context === undefined) {
        throw new Error('useTableData must be used within a TableDataProvider');
    }

    return context;
};

export const TableDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [dataState, setDataState] = React.useState<any[]>([]);

    return (
        <TableDataContext.Provider value={{ dataState, setDataState }}>
            {children}
        </TableDataContext.Provider>
    );
};
