import TABLE_CONSTANTS from '@constants/admin/tables';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib';
import { useTranslation } from 'react-i18next';

import ResizableTable from '@/components/admin/ResizableTable';
import SectionHeading from '@/components/admin/SectionHeading';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { AgreementDefinition, SortEnumType, useGetAdminDefinitionsQuery } from '@/graphql/main';
import usePaginate from '@/hooks/admin/usePaginate';
import useError from '@/hooks/error/useError';
import useColDefs from '@/pages/admin/CommercialAgreements/Definitions/hooks/useColDefs';

const DefinitionsTable = () => {
    const {
        handlePageChange,
        pageSize,
        itemsToSkip,
        setTotalItems,
        totalItems,
        sortingOrder,
        setSortingOrder
    } = usePaginate();
    const { setError, clearError } = useError();
    const { t } = useTranslation();
    const { TableSize } = TABLE_CONSTANTS;
    const { loading, refetch, data } = useGetAdminDefinitionsQuery({
        variables: {
            request: {},
            take: pageSize,
            skip: itemsToSkip,
            order: sortingOrder
        },
        onCompleted: (data) => {
            if (data?.adminAgreementDefinitions?.items) {
                setTotalItems(data.adminAgreementDefinitions.totalCount);
            }
            clearError();
        },
        onError: (error) => {
            setError(error, ErrorTypeEnum.ApolloError);
        }
    });

    const { colDef } = useColDefs({
        onChangeSuccess: async () =>
            await refetch({ request: {}, take: pageSize, skip: itemsToSkip })
    });

    const onSortChange = (sorterResult: SorterResult<AgreementDefinition>) =>
        setSortingOrder(
            sorterResult.order
                ? {
                      [sorterResult.columnKey as string]:
                          sorterResult.order === 'ascend' ? SortEnumType.Asc : SortEnumType.Desc
                  }
                : undefined
        );

    return (
        <>
            <SectionHeading heading={t('DEFINITION_OF_CA')} />

            <ResizableTable
                colDefs={colDef}
                tableData={(data?.adminAgreementDefinitions?.items as AgreementDefinition[]) || []}
                emptyText={t('NO_RESULTS')}
                loading={loading}
                onPageChange={handlePageChange}
                totalPaginationItems={totalItems}
                size={TableSize}
                onChange={(_, __, sorterResult) => {
                    onSortChange(sorterResult as SorterResult<AgreementDefinition>);
                }}
            />
        </>
    );
};

export default DefinitionsTable;
