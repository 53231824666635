import React, { createContext, useState } from 'react';

import { ApolloError } from '@apollo/client';

type ErrorContextType = {
    addCustomError: (err: any, type: 'error' | 'warning') => void;
    addError: (err: ApolloError, type: 'error' | 'warning') => void;
    clearErrors: () => void;
    errors: Error[] | null;
};
type Error = { message: string; type: 'error' | 'warning' };
export const ErrorContext = createContext<ErrorContextType>({} as ErrorContextType);

type ContextProviderType = {
    children: React.ReactNode;
};

const ErrorContextProvider: React.FC<ContextProviderType> = ({ children }) => {
    const [errors, setErrors] = useState<Error[] | null>(null);

    const clearErrors = () => setErrors(null);
    const addError = (err: ApolloError, type: 'error' | 'warning') => {
        if (err.networkError) {
            // @todo
        } else if (!!err.graphQLErrors && err.graphQLErrors.length > 0) {
            err.graphQLErrors.forEach((singleError) => {
                const error: Error = {
                    type,
                    // @todo
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string
                    message: `${singleError?.extensions?.code || singleError?.message}`
                };
                setErrors((errors) => (errors ? [...errors, error] : [error]));
            });
        } else if (!!err.clientErrors && err.clientErrors.length > 0) {
            // @todo
        }
    };
    const addCustomError = (err: any, type: 'error' | 'warning') => {
        const error: Error = {
            type,
            message: err?.code || err?.message
        };

        setErrors((errors) => (errors ? [...errors, error] : [error]));
    };
    // const getErrorMessage = (err: ApolloError) => {};

    return (
        <ErrorContext.Provider
            value={{
                addCustomError,
                addError,
                clearErrors,
                errors
            }}>
            {children}
        </ErrorContext.Provider>
    );
};

export default ErrorContextProvider;
