import { Content, Footer } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const StyledDeclarationPreviewContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 ${({ theme }) => theme.space[16]};
`;

export const StyledDeclarationPreviewContent = styled(Content)`
    height: 80%;
    background-color: white;
    box-shadow: ${({ theme }) => theme.shadow.lightShadow};

    img {
        width: 100%;
    }
`;

export const StyledDeclarationPreviewFooter = styled(Footer)`
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.default.bg};

    button {
        width: 100%;
    }
`;
