import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ReactComponent as PlusIcon } from '@/assets/svg-icons/plus-icon.svg';
import ErrorModal from '@/components/shared/modal/ErrorModal';
import CustomButton from '@/components/shared/UI/CustomButton';
import { StyledPageTitle } from '@/components/shared/UI/PageTitle/styles';
import SpinnerContainer from '@/components/shared/UI/SpinnerContainer';
import ROUTES from '@/constants/routes/routes';
import { ButtonsTypesEnum } from '@/enums/buttons-types.enum';
import { ErrorTypeEnum } from '@/enums/error-type.enum';
import { NavbarElementsEnum } from '@/enums/navbar-elements.enum';
import { AgreementDto, useGetAgreementsQuery } from '@/graphql/main';
import useError from '@/hooks/error/useError';
import AppContext from '@/store/app-context/AppContext';
import { useUserContext } from '@/store/user/useUserContext';
import {
    ListContainer,
    ListContent,
    ListHeader,
    ListHeadingContainer
} from '@/styles/shared/list/styles';

import AgreementFilters from './partials/AgreementFilters';
import AgreementList from './partials/AgreementList';
import { AgreementListHeader, AgreementListHeaderContainer, AgreementListLength } from './styles';

const Agreements: React.FC = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const theme = useTheme();
    const { setError, clearError, errorMessage } = useError();
    const { navbar, protocol } = useContext(AppContext);
    const { isF2RCMC } = useUserContext();

    const { refetch, loading, fetchMore, data } = useGetAgreementsQuery({
        onCompleted: () => clearError(),
        onError: (error) => setError(error, ErrorTypeEnum.ApolloError)
    });

    const agreements = (data?.agreements?.edges?.map((edge) => edge.node) as AgreementDto[]) ?? [];
    const hasMore = data?.agreements?.pageInfo.hasNextPage ?? false;
    const endCursor = data?.agreements?.pageInfo.endCursor ?? '';

    useEffect(() => {
        navbar.setNewNavbarConfigs({
            leftSide: NavbarElementsEnum.SidebarButton,
            rightSide: NavbarElementsEnum.NavbarLogo,
            background: theme?.color.default.white
        });

        protocol.setProtocol(undefined);

        return () => {
            navbar.disposeNavbar();

            refetch();
        };
    }, []);

    const handleLoadMore = async () => {
        await fetchMore({
            variables: { after: endCursor },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult?.agreements?.edges || !prevResult?.agreements?.edges)
                    return prevResult;

                return {
                    ...fetchMoreResult,
                    agreements: {
                        pageInfo: fetchMoreResult.agreements?.pageInfo,
                        totalCount: fetchMoreResult.agreements?.totalCount,
                        edges: [...prevResult.agreements.edges, ...fetchMoreResult.agreements.edges]
                    }
                };
            }
        });
    };

    return (
        <ListContainer>
            <ListHeader>
                <ListHeadingContainer>
                    <StyledPageTitle style={{ margin: 0 }}>{t('AGREEMENTS')}</StyledPageTitle>
                    {!isF2RCMC && (
                        <CustomButton
                            onClick={() => navigate(ROUTES.FullPaths.CreateAgreement)}
                            label={t('NEW_COMMERCIAL_AGREEMENT')}
                            buttonType={ButtonsTypesEnum.Main}
                            icon={<PlusIcon />}
                        />
                    )}
                </ListHeadingContainer>
                <SpinnerContainer loading={loading}>
                    <AgreementFilters
                        agreements={agreements}
                        onFiltersSelected={async (filters) => await refetch({ filters })}
                    />
                </SpinnerContainer>
            </ListHeader>
            <ListContent>
                <SpinnerContainer loading={loading && data?.agreements?.totalCount === undefined}>
                    <AgreementListHeaderContainer>
                        {!!data?.agreements?.totalCount && data.agreements.totalCount > 0 && (
                            <>
                                <AgreementListHeader>{t('AGREEMENTS_LIST')}</AgreementListHeader>
                                <AgreementListLength>{`(${data.agreements.totalCount})`}</AgreementListLength>
                            </>
                        )}
                    </AgreementListHeaderContainer>
                </SpinnerContainer>
                <SpinnerContainer loading={loading}>
                    <AgreementList
                        agreements={agreements}
                        fetchNext={handleLoadMore}
                        hasMore={hasMore}
                    />
                </SpinnerContainer>
            </ListContent>
            {errorMessage && <ErrorModal errorMessage={errorMessage} onClose={clearError} />}
        </ListContainer>
    );
};

export default Agreements;
