import React, { createContext, useMemo, useState } from 'react';

import { AgreementDetailsDto, AgreementDto, ContractDto, ProtocolDto } from '@/graphql/main';
import { NavbarState } from '@/types/NavbarState';
import { Signature } from '@/types/Signature';

type ContextValue = {
    navbar: {
        navbarConfigs?: NavbarState;
        setNewNavbarConfigs: (newState: NavbarState) => void;
        disposeNavbar: () => void;
    };
    sign: {
        signatures?: Signature[];
        addNewSignature: (sign: Signature) => void;
        clearSigns: () => void;
    };
    protocol: {
        currentProtocol?: ProtocolDto;
        setProtocol: (protocol?: ProtocolDto) => void;
    };
    contract: {
        currentContract?: ContractDto;
        setContract: (contract?: ContractDto) => void;
    };
    agreement: {
        currentAgreement?: AgreementDto;
        setAgreement: (agreement?: AgreementDto) => void;
    };
    agreementDetails: {
        currentAgreementDetails?: AgreementDetailsDto;
        setAgreementDetails: (agreementDetails: AgreementDetailsDto) => void;
    };
    additionalDocsFormData: {
        data?: FormData;
        setFormData: (data: FormData) => void;
    };
    modal: {
        isModalOpened?: boolean;
        setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    };
};

const AppContext = createContext<ContextValue>({
    navbar: {
        navbarConfigs: {},
        setNewNavbarConfigs: () => undefined,
        disposeNavbar: () => undefined
    },
    sign: {
        signatures: [],
        addNewSignature: (sign: Signature) => undefined,
        clearSigns: () => undefined
    },
    protocol: {
        setProtocol: (protocol?: ProtocolDto) => undefined
    },
    contract: {
        setContract: (contract?: ContractDto) => undefined
    },
    agreement: {
        setAgreement: (agreement?: AgreementDto) => undefined
    },
    agreementDetails: {
        setAgreementDetails: (agreementDetails: AgreementDetailsDto) => undefined
    },
    additionalDocsFormData: {
        data: undefined,
        setFormData: () => undefined
    },
    modal: {
        isModalOpened: false,
        setIsModalOpened: () => undefined
    }
});

type Props = {
    children: JSX.Element | JSX.Element[];
};

export const AppContextProvider: React.FC<Props> = (props) => {
    const [navbarState, setNavbarState] = useState<NavbarState>();
    const [signatures, setSignatures] = useState<Signature[]>([]);
    const [protocolState, setProtocolState] = useState<ProtocolDto>();
    const [contractState, setContractState] = useState<ContractDto>();
    const [agreementState, setAgreementState] = useState<AgreementDto>();
    const [agreementDetailsState, setAgreementDetailsState] = useState<AgreementDetailsDto>();
    const [formDataState, setFormDataState] = useState<FormData>();
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    const setNewNavbarConfigs = (newState: NavbarState) => {
        setNavbarState(newState);
    };

    const disposeNavbar = () => {
        setNavbarState({});
    };

    const addNewSignature = (newSignature: Signature) => {
        setSignatures((currentSignatures) => {
            const oldSign = currentSignatures.find(
                (currentSignature) =>
                    currentSignature.signer === newSignature.signer &&
                    currentSignature.signatureIndex === newSignature.signatureIndex
            );

            if (oldSign) {
                currentSignatures[currentSignatures.indexOf(oldSign)].signature =
                    newSignature.signature;

                return [...currentSignatures];
            }

            return [...currentSignatures, newSignature];
        });
    };

    const clearSigns = () => {
        setSignatures([]);
    };

    const contextValue = useMemo(
        () => ({
            navbar: {
                navbarConfigs: navbarState,
                setNewNavbarConfigs,
                disposeNavbar
            },
            sign: {
                signatures,
                addNewSignature,
                clearSigns
            },
            protocol: {
                currentProtocol: protocolState,
                setProtocol: setProtocolState
            },
            contract: {
                currentContract: contractState,
                setContract: setContractState
            },
            agreement: {
                currentAgreement: agreementState,
                setAgreement: setAgreementState
            },
            agreementDetails: {
                currentAgreementDetails: agreementDetailsState,
                setAgreementDetails: setAgreementDetailsState
            },
            additionalDocsFormData: {
                data: formDataState,
                setFormData: setFormDataState
            },
            modal: {
                isModalOpened,
                setIsModalOpened
            }
        }),
        [
            navbarState,
            signatures,
            protocolState,
            contractState,
            agreementState,
            agreementDetailsState,
            formDataState,
            isModalOpened
        ]
    );

    return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};
export default AppContext;
