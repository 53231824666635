import {
    DocumentStatusEnum,
    AgreementTypeEnum,
    AgreementReasonEnum,
    AgreementSubjectEnum,
    AdminCommercialAgreementFilterRequestModelInput
} from '@/graphql/main';

export const agreementTypes = Object.values(AgreementTypeEnum);
export const agreementStatuses = Object.values(DocumentStatusEnum);
export const agreementReasons = Object.values(AgreementReasonEnum);
export const agreementSubjects = Object.values(AgreementSubjectEnum);
export const defaultFilterState: AdminCommercialAgreementFilterRequestModelInput = {
    caStatus: undefined,
    salesRepName: undefined,
    customerName: undefined,
    caZone: undefined,
    caFromDate: undefined,
    caToDate: undefined,
    pocHermesId: undefined,
    type: undefined,
    subject: undefined,
    reason: undefined
};
