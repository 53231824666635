import { ApolloQueryResult } from '@apollo/client';
import { Button, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { ErrorTypeEnum } from '@/enums/error-type.enum';
import {
    AgreementDefinition,
    GetAdminDefinitionsQuery,
    useChangeAdminDefinitionIsActiveMutation
} from '@/graphql/main';
import useError from '@/hooks/error/useError';

type Props = {
    onChangeSuccess: () => Promise<ApolloQueryResult<GetAdminDefinitionsQuery>>;
};

const useColDefs = ({ onChangeSuccess }: Props) => {
    const { t } = useTranslation();
    const { setError } = useError();

    const [changeIsActiveMutation] = useChangeAdminDefinitionIsActiveMutation({
        onError: (error) => {
            message.error(t(error.message));
            setError(error, ErrorTypeEnum.ApolloError);
        },
        onCompleted: async () => {
            await onChangeSuccess();
        }
    });
    const handleToggleDefinitionStatus = async ({
        definitionId,
        isActive
    }: {
        definitionId: string;
        isActive: boolean;
    }) => {
        try {
            await changeIsActiveMutation({
                variables: {
                    definitionId,
                    isActive: !isActive
                }
            });

            message.success(t(isActive ? 'DEACTIVATED_SUCCESS' : 'ACTIVATED_SUCCESS'));
        } catch (error) {
            setError(error, ErrorTypeEnum.ApolloError);
            await message.error(t(isActive ? 'DEACTIVATE_FAIL' : 'ACTIVATED_FAIL'));
        }
    };

    const colDef: ColumnsType<AgreementDefinition> = [
        {
            key: 'type',
            title: t('TYPE'),
            dataIndex: 'type',
            width: 50,
            render: (_, { type }) => t(type),
            sorter: true
        },
        {
            key: 'reason',
            title: t('REASON'),
            dataIndex: 'reason',
            width: 50,
            render: (_, { reason }) => t(reason),
            sorter: true
        },
        {
            key: 'subject',
            title: t('SUBJECT'),
            width: 300,
            dataIndex: 'subject',
            sorter: true,
            render: (_, { subject }) => t(subject)
        },
        {
            key: 'validationBy',
            width: 50,
            title: t('VALIDATION_BY'),
            dataIndex: 'validationBy',
            render: (_, { validationBy }) => t(validationBy),
            sorter: true
        },
        {
            key: 'additionalFormula',
            title: t('ADDITIONAL_FORMULA'),
            dataIndex: 'additionalFormula',
            width: 50,
            render: (_, { additionalFormula }) => t(additionalFormula),
            sorter: true
        },
        {
            key: 'transmissionProtocolType',
            width: 50,
            title: t('TRANSMISSION_PROTOCOL_TYPE'),
            dataIndex: 'transmissionProtocolType',
            render: (_, { transmissionProtocolType }) => t(transmissionProtocolType),
            sorter: true
        },
        {
            key: 'bankCertificateRequired',
            title: t('BANK_CERTIFICATE_REQUIRED'),
            width: 50,
            dataIndex: 'bankCertificateRequired',
            render: (_, { bankCertificateRequired }) =>
                bankCertificateRequired ? t('YES') : t('NO'),
            sorter: true
        },
        {
            key: 'includeInLimit',
            width: 50,
            title: t('INCLUDE_IN_LIMIT'),
            dataIndex: 'includeInLimit',
            render: (_, { includeInLimit }) => (includeInLimit ? t('YES') : t('NO')),
            sorter: true
        },
        {
            key: 'invoiceRequired',
            width: 50,
            title: t('INVOICE_REQUIRED'),
            dataIndex: 'invoiceRequired',
            render: (_, { invoiceRequired }) => (invoiceRequired ? t('YES') : t('NO')),
            sorter: true
        },
        {
            key: 'paymentFrequencyRequired',
            width: 50,
            title: t('PAYMENT_FREQUENCY_REQUIRED'),
            dataIndex: 'paymentFrequencyRequired',
            render: (_, { paymentFrequencyRequired }) =>
                paymentFrequencyRequired ? t('YES') : t('NO'),
            sorter: true
        },
        {
            key: 'isActive',
            title: t('ACTIVE'),
            dataIndex: 'active',
            width: 50,
            sorter: true,
            render: (_, { id, isActive }) => (
                <Button
                    key={`deactivate-${id as string}`}
                    danger={isActive}
                    onClick={async () =>
                        await handleToggleDefinitionStatus({ definitionId: id, isActive })
                    }>
                    {t(isActive ? 'DEACTIVATE' : 'ACTIVATE')}
                </Button>
            )
        }
    ];

    return { colDef };
};

export default useColDefs;
