import { useEffect, useState } from 'react';

import { AutoComplete, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchButton from '@/components/admin/AdminButtons/SearchButton';
import DateRangePicker from '@/components/shared/UI/DateRangePicker';
import {
    AdminCommercialAgreementFilterRequestModelInput,
    DocumentStatusEnum
} from '@/graphql/main';

import useFilters from '../../hooks/useFilters';
import {
    agreementReasons,
    agreementSubjects,
    agreementTypes,
    defaultFilterState
} from '../../utils';

import { StyledAssetFilterContainer } from './styles';

type Props = {
    onSearch: (filterValues: AdminCommercialAgreementFilterRequestModelInput) => void;
};
const sortedAgreementStatuses: DocumentStatusEnum[] = [
    DocumentStatusEnum.Draft,
    DocumentStatusEnum.ForValidation,
    DocumentStatusEnum.Prepared,
    DocumentStatusEnum.Signed,
    DocumentStatusEnum.Done,
    DocumentStatusEnum.Canceled
];
const CAFilters: React.FC<Props> = ({ onSearch }) => {
    const { t } = useTranslation('translation');
    const [form] = Form.useForm<AdminCommercialAgreementFilterRequestModelInput>();
    const {
        searchCustomers,
        customers,
        searchPocsHermesIds,
        pocIds,
        searchEmployeesByName,
        employees,
        zones,
        searchZones
    } = useFilters();
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const handleSearch = () => {
        const values = form.getFieldsValue();

        const filterValues: AdminCommercialAgreementFilterRequestModelInput = {
            ...values,
            caFromDate: fromDate,
            caToDate: toDate
        };

        onSearch(filterValues);
    };

    const formItemStyle = {
        minWidth: 180
    };
    useEffect(() => {
        !zones.length && searchZones();
    }, [searchZones, zones.length]);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={defaultFilterState}
            onFinish={handleSearch}
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                <Form.Item name="caStatus" label={t('STATUS')} style={formItemStyle}>
                    <Select
                        allowClear
                        placeholder={t('STATUS')}
                        options={sortedAgreementStatuses.map((status) => ({
                            value: status,
                            label: t(`${status}_SIMPLIFIED`)
                        }))}
                    />
                </Form.Item>
                <Form.Item name="customerName" label={t('CUSTOMER')} style={formItemStyle}>
                    <AutoComplete
                        allowClear
                        placeholder={t('CUSTOMER')}
                        options={customers}
                        onSearch={searchCustomers}
                    />
                </Form.Item>
                <Form.Item name="pocHermesId" label={t('POC_ID')} style={formItemStyle}>
                    <AutoComplete
                        allowClear
                        placeholder={t('POC_ID')}
                        options={pocIds}
                        onSearch={searchPocsHermesIds}
                    />
                </Form.Item>
                <Form.Item
                    name="salesRepName"
                    label={t('SALES_REPRESENTATIVE')}
                    style={formItemStyle}>
                    <AutoComplete
                        allowClear
                        placeholder={t('SALES_REPRESENTATIVE')}
                        options={employees}
                        onSearch={searchEmployeesByName}
                    />
                </Form.Item>
                <Form.Item name="caZone" label={t('ZONE')} style={formItemStyle}>
                    <AutoComplete
                        allowClear
                        placeholder={t('ZONE')}
                        options={zones}
                        onSearch={searchZones}
                    />
                </Form.Item>
            </div>
            <StyledAssetFilterContainer>
                <Form.Item name="type" label={t('AGREEMENT_TYPE')} style={formItemStyle}>
                    <Select
                        allowClear
                        placeholder={t('AGREEMENT_TYPE')}
                        options={agreementTypes.map((type) => ({
                            value: type,
                            label: t(type)
                        }))}
                    />
                </Form.Item>
                <Form.Item name="subject" label={t('AGREEMENT_SUBJECT')} style={formItemStyle}>
                    <Select
                        allowClear
                        placeholder={t('AGREEMENT_SUBJECT')}
                        options={agreementSubjects.map((type) => ({
                            value: type,
                            label: t(type)
                        }))}
                    />
                </Form.Item>
                <Form.Item name="reason" label={t('AGREEMENT_REASON')} style={formItemStyle}>
                    <Select
                        allowClear
                        placeholder={t('AGREEMENT_REASON')}
                        options={agreementReasons.map((type) => ({
                            value: type,
                            label: t(type)
                        }))}
                    />
                </Form.Item>
                <Form.Item label={t('DATE_FROM_TO')}>
                    <DateRangePicker setFromDate={setFromDate} setToDate={setToDate} />
                </Form.Item>
                <Form.Item>
                    <SearchButton onClick={handleSearch} />
                </Form.Item>
            </StyledAssetFilterContainer>
        </Form>
    );
};

export default CAFilters;
